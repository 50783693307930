import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { fetchData } from "../../Helper/ApiCall";
import {
    END_POINT,
    BASE_URL_IMAGE,
    METHODS,
    NumberSeprator,
    numberToEnglish,
    TabBarLabels,
    BASE_URL,
} from "../../Helper/helper";
import EditorRead from "../../components/EditorRead";
import Testimonial from "../../components/Testimonial";
import PartnersCarousel from "../../components/PartnersCarousel";
import Subscribe from "../../components/Subscribe";
import { useSelector, useDispatch } from "react-redux";
import { homedataUpdate } from "../../redux/reducers/homeReducer";
import ActivityLoader from "../../components/ActivityLoader";

export const NewWeddingScreen = () => {
    const dispatch = useDispatch();

    const { HomeBanner, SectionSecond, RecomendedBlog, SectionForth, loading } =
        useSelector((store) => store.homeReducer);

    const navigate = useNavigate();
    const [data, setdata] = useState({
        WeddingDetail: [],
        Wedding: [],
        WeddingBanner: [],
        WeddingReal: [],
        WeddingInspiration: [],
    });
    const [TestimonialData, setTestimonialData] = useState([]);

    useEffect(() => {
        handlerData();
        if (SectionForth == "") {
            homeHandler();
        }
        // handler();
        // handlerInspiration();
    }, []);
    const handlerData = async () => {
        try {
            const result = await fetchData(END_POINT.GetWeddingPageData);
            if (result.success == true) {
                setdata({
                    WeddingDetail: result.data.WeddingDetail,
                    Wedding: result.data.Wedding,
                    WeddingBanner: result.data.WeddingBanner,
                    WeddingReal: result.data.WeddingReal,
                    WeddingInspiration: result.data.WeddingInspiration,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const homeHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetWebHomeAllData);
            console.log(result);
            if (result.success == true) {
                dispatch(homedataUpdate(result.data));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handler = async () => {
        try {
            const result = await fetchData(
                END_POINT.GetDetailByPageDataTestimonial + "Wedding"
            );
            if (result.success == true) {
                setTestimonialData(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
        </>
    );
};
