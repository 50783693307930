import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { fetchData } from "../Helper/ApiCall";
import EditorRead from "./EditorRead";
import { GOOGLE_MAPS_APIKEY, END_POINT } from "../Helper/helper";

function ProductPagePolicySection({ hotepolicy, location, heading, latitude, longitude, id }) {
  const loadMap = async (map, maps) => {
    console.log("Loaded!");
    let marker = new maps.Marker({
      position: { lat: location.latitude, lng: location.longitude },
      map,
      draggable: false,
    });
    return marker;
  };

  const [faq, setFaq] = useState([]);
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  const FaqHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetActivityFaq + id);
      console.log(result);
      if (result.success === true) {
        setFaq(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    FaqHandler();
  }, []);

  return (
    <div className="">
      <div className="location-details-wrapper" id="location-map">
        <div className="container pt-4 pb-4">
          <div className="location-details row d-flex justify-content-between">
            <div className="col-lg-5 md-5 sm-10">
              <h3>Where you will be at </h3>
              {location.latitude !== "" && location.longitude !== "" && (
                <div style={{ height: "60vh", width: "100%" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLE_MAPS_APIKEY, language: "en" }}
                    defaultCenter={{ lat: location.latitude, lng: location.longitude }}
                    defaultZoom={10}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
                    options={{ fullscreenControl: false }}
                  ></GoogleMapReact>
                </div>
              )}
            </div>
            <div className="col-lg-6 md-6 sm-10">
              <h3 style={{ color: "black" }}>{heading} policies</h3>
              {faq.length > 0 ? (
                faq.map((item, index) => (
                  <div key={index} className="faq-item">
                    <div className="faq-question" onClick={() => toggleFaq(index)}>
                      <span>{item.question}</span>
                      <span className="faq-toggle-icon">{openFaqIndex === index ? '-' : '+'}</span>
                    </div>
                    {openFaqIndex === index && <div className="faq-answer"><EditorRead description={item.answer} /></div>}
                  </div>
                ))
              ) : (
                <p>FAQ Not Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPagePolicySection;
