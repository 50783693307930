import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { END_POINT } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import "../../dashscreen/DashCss.css";
import TableHead from "../../components/TableHead";

const RoomTypeScreen = () => {
  const [roomType, setRoomTypeData] = useState([]);
  const { id, name } = useParams();

  useEffect(() => {
    roomTypeHandler();
  }, []);

  const roomTypeHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetRoomTypeById + id);
      if (result.success == true) {
        setRoomTypeData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const deleteHandler = async (id) => {
    try {
      const result = await fetchData(END_POINT.DeleteRoomType + id);
      if (result.success == true) {
        roomTypeHandler();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Link style={{ textDecoration: "none" }} to={"../add-room-type/" + id}>
        <button
          className="btn btn-primary mt-4"
          // onClick={() => {
          //   Navigate("add-hotel-listing");
          // }}
        >
          Add Room Type&nbsp;<i class="fa-solid fa-plus"></i>
        </button>
      </Link>

      <div className="mt-4">
        <div
          id="example_wrapper"
          class="dataTables_wrapper no-footer table-overflow"
        >
          <div id="example_filter" class="dataTables_filter mb-4">
            <label>
              Search:
              <input
                type="search"
                class=""
                placeholder=""
                aria-controls="example"
              />
            </label>
          </div>
          <table
            id="example"
            class="table table-striped table-bordered dataTable no-footer"
            style={{ width: "100%", whiteSpace: "nowrap" }}
            role="grid"
            aria-describedby="example_info"
          >
            <thead>
              <tr role="row">
                {[
                  "S.N",
                  "Room Name",
                  "Price Start From",
                  "Stock",
                  "Live Sell",
                  "Live Stock",
                  "Edit Room Details",
                  "Price Stock",
                  "Edit Date",
                  "Summary",
                ].map((item) => {
                  return <TableHead title={item} />;
                })}
              </tr>
            </thead>

            <tbody>
              {roomType.length > 0 ? (
                roomType.map((item, index) => (
                  <tr role="row" class="odd" key={index}>
                    <td class="sorting_1"> {index + 1} </td>
                    <td> {item.name} </td>
                    <td>12000</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>

                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../add-room-type/" + id,
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{ id: item._id }}
                      >
                        <i class="fa fa-edit"></i>
                      </Link>
                      <a
                        // href="#"
                        type="button"
                        class="btn btn-danger m-1"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: `You won't to delete ${item.name}!`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteHandler(item._id);

                              Swal.fire(
                                "Deleted!",
                                `${item.name} has been deleted.`,
                                "success"
                              );
                            }
                          });
                          //  handleDelete(item._id)
                        }}
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>
                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../price-stock/" + id + "/" + name,
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{
                          id: item._id,
                          name: item.name,
                          hotel_name: name,
                        }}
                      >
                        <i class="fa fa-plus"></i>Price Stock
                      </Link>
                    </td>
                    <td>{item.updatedAt.split("T")[0]}</td>
                    <td>
                      <a href="#" class="btn btn-info">
                        <i class="fa fa-eye"></i>View Summary
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <p>Hotel List Not Available</p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RoomTypeScreen;
