import React, { useState, useRef, useEffect } from "react";
import {
  END_POINT,
  METHODS,
  hotelTypeOptions,
  hotelpromo_type,
  hotelstarratingOptions,
  GOOGLE_MAPS_APIKEY,
  hotelplaceoffers,
} from "../../Helper/helper";

import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";

function AutoCompleteAddress(props) {
  const { onChangeTextPlace = () => {} } = props;
  return (
    <Autocomplete
      className="input-fields-style"
      apiKey={GOOGLE_MAPS_APIKEY}
      style={{ width: "90%" }}
      onPlaceSelected={(place) => {
        onChangeTextPlace(place);
        // LatLong_Api(place.formatted_address);

        // setformData({
        //   ...formData,
        //   // location: responseJson.results[0].formatted_address,
        //   address: place.formatted_address,
        // });
      }}
      options={{
        types: ["(regions)"],
        componentRestrictions: { country: "in" },
      }}
      defaultValue={props.address}
    />
  );
}

export default AutoCompleteAddress;
