import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: true,
  HomeBanner: "",
  SectionSecond: "",
  RecomendedBlog: "",
  SectionForth: "",
};

export const homeReducer = createSlice({
  name: "homeReducer",
  initialState,
  reducers: {
    homedataUpdate: (state, actions) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.HomeBanner = actions.payload.HomeBanner;
      state.SectionSecond = actions.payload.SectionSecond;
      state.RecomendedBlog = actions.payload.RecomendedBlog;
      state.SectionForth = actions.payload.SectionForth;

      state.error = "";
      state.loading = false;
      state.token = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { homedataUpdate } = homeReducer.actions;

export default homeReducer.reducer;
