import React, { useState, useEffect } from "react";
import {
  END_POINT,
  METHODS,
  BASE_URL_IMAGE,
  displaypageOptions,
} from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const AddTestimonial = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = location?.state?.id;

  const [formData, setformData] = useState({
    name: "",
    designation: "",
    comments: "",
    detail_link: "",
    image: "",
    display_page: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");

  useEffect(() => {
    // categoryHandler();
    if (cityid != undefined) {
      handler();
    }
  }, []);

  const handler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetDetailDataTestimonial + cityid
      );
      console.log("raw", result);

      if (result.success == true) {
        setformData({
          name: result.data.name,
          designation: result.data.designation,
          comments: result.data.comments,
          detail_link: result.data.detail_link,
          image: result.data.image,
          display_page: result.data.display_page,
        });

        setcomments(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.comments)
            )
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (uploadedImage) => {
    var raw = JSON.stringify({
      name: formData.name,
      designation: formData.designation,
      comments: formData.comments,
      detail_link: formData.detail_link,
      image: uploadedImage,
      display_page: formData.display_page,
    });
    console.log("raw", raw);
    try {
      const finalEndpoint =
        cityid != undefined
          ? END_POINT.UpdateTestimonial + cityid
          : END_POINT.UploadTestimonial;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../testimonial-list");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        const uploadedImage = values[0].image;
        handleSubmit(uploadedImage);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const AllFileUpdate = async () => {
    Promise.all([image != "" && (await ImageUpload(image))])
      .then((values) => {
        const uploadedImage =
          values[0].image == false ? formData.image : values[0].image;

        handleSubmit(uploadedImage);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [comments, setcomments] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState, key) => {
    setcomments(editorState);

    setformData({
      ...formData,
      [key]: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add Testimonial</b>
        </h3>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Name:</span> <br />
            <input
              type="text"
              id="name"
              name="name"
              className="input-fields-style"
              value={formData.name}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Designation:</span> <br />
          <input
            type="text"
            id="designation"
            name="designation"
            className="input-fields-style"
            value={formData.designation}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Comments:</span> <br />
          <Editor
            editorState={comments}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(e) => {
              onEditorStateChange(e, "comments");
            }}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Detail Link:</span> <br />
            <input
              type="text"
              id="detail_link"
              name="detail_link"
              className="input-fields-style"
              value={formData.detail_link}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Select Display Page:</span> <br />
            <select
              class="form-control form-element-style"
              name="display_page"
              value={formData.display_page}
              onChange={(e) => handleChange(e)}
            >
              <option selected>Select</option>
              {displaypageOptions.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Upload Image *</span> <br />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
            {formData.image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.image}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-5"
              >
                view image
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            if (cityid != undefined) {
              if (image != "") {
                AllFileUpdate();
              } else {
                handleSubmit(formData.image);
              }
            } else {
              AllFileUpload();
            }
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../wedding-list");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddTestimonial;
