import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  country: "",
  currency: "",
  error: "",
  loading: false,
};

export const currencyReducer = createSlice({
  name: "currencyReducer",
  initialState,
  reducers: {
    countryUpdate: (state, actions) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.country = actions.payload;
      state.error = "";
      state.loading = "";
    },
    currencyUpdate: (state, actions) => {
      state.currency = actions.payload;
      state.error = "";
      state.loading = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { countryUpdate, currencyUpdate } = currencyReducer.actions;

export default currencyReducer.reducer;
