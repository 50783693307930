import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { fetchData } from "../../../Helper/ApiCall";
import {
  END_POINT,
  METHODS,
  pricestockbenefitOptions,
  pricestockpaymentmethod,
  pricestockbookingtype,
  pricestockcampaignname,
  getDateNow,
  stockbenefitOptions,
} from "../../../Helper/helper";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Select from "react-select";

const PackagePriceStock = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  let location = useLocation();
  const roomfacilityid = location?.state?.id;
  const { name, dinningname, package_type } = location?.state;
  const [data, setdata] = useState([]);
  const [hoteldata, sethoteldata] = useState([]);
  const [roomType, setRoomTypeData] = useState([]);

  const [formData, setformData] = useState({
    package_id: "",
    package_item_type_id: "",
    payment_mode: "",
    booking_type: "",
    date_from: "",
    date_to: "",
    status: "",
    campaign_name: "",
    payment_option: "",
    cost: "",
    sell: "",

    bookingPeriod: "",
    No_Pax: "",
    campaign_rooms: [],
    benifits: [],

    No_Pax_Child: "",
    No_Pax_Adult: "",

    package_type: package_type,
    hotel: "",
    hotel_room_type: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const [formDataPax, setformDataPax] = useState([
    {
      numberPax: 1,
      number_room: 1,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 2,
      number_room: 1,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 3,
      number_room: 1,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 4,
      number_room: 2,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 5,
      number_room: 2,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 6,
      number_room: 3,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 7,
      number_room: 3,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 8,
      number_room: 4,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 9,
      number_room: 4,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 10,
      number_room: 5,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 11,
      number_room: 5,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 12,
      number_room: 6,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 13,
      number_room: 6,
      number_adult: "",
      number_child: "",
    },
    {
      numberPax: 14,
      number_room: 7,
      number_adult: "",
      number_child: "",
    },
  ]);
  const handleChangeFormData = (e, index) => {
    const { name, value } = e.target;
    const newArray = [...formDataPax];
    newArray[index][name] = Number(value);

    setformDataPax(newArray);
  };
  console.log(formDataPax);
  const handleSubmit = async () => {
    var startDate = new Date(formData.date_from); //YYYY-MM-DD
    var endDate = new Date(formData.date_to); //YYYY-MM-DD

    const campaign_rooms = getDateArray(startDate, endDate);

    const raw = JSON.stringify({
      package_item_type_id: roomfacilityid,
      package_id: id,
      benifits: formData.benifits,
      campaign_name: dinningname,
      payment_option: formData.payment_option,
      cost: Number(formData.cost),
      sell: Number(formData.sell),
      bookingPeriod: formData.bookingPeriod,
      // No_Pax: Number(formData.No_Pax_Child) + Number(formData.No_Pax_Adult),
      // No_Pax_Child: formData.No_Pax_Child,
      // No_Pax_Adult: formData.No_Pax_Adult,
      No_Pax: formDataPax,
      campaign_rooms: campaign_rooms,
      package_type: formData.package_type,
      hotel_name: formData.hotel?.name,
      hotel_id: formData.hotel?._id,
      hotel_room_type_id: formData.hotel_room_type?._id,
      hotel_room_type: formData.hotel_room_type?.name,
    });
    console.log(raw);

    try {
      const result = await fetchData(
        END_POINT.PostPackageStock,
        METHODS.POST,
        raw
      );

      console.log(result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            GetStockhandler();
            // navigate("../hotel-listing");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  var getDateArray = function (start, end) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
      arr.push({
        date: dt.toISOString().split("T")[0],
        booking_type: formData.booking_type,

        VoucherNumber: "",
        livesell: 0,
      });

      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  useEffect(() => {
    GetStockhandler();
  }, []);
  const GetStockhandler = async () => {
    try {
      const raw = JSON.stringify({
        package_item_type_id: roomfacilityid,
        package_id: id,
      });
      const result = await fetchData(
        END_POINT.GetPackageStockByid,
        METHODS.POST,
        raw
      );

      console.log(raw);
      if (result.success == true) {
        setdata(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (formData.package_type == "Hotel") {
      GetHotelhandler();
    }
  }, [formData.package_type]);

  const GetHotelhandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetHotelList);

      if (result.success == true) {
        sethoteldata(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (formData.hotel != "") {
      roomTypeHandler();
    }
  }, [formData.hotel]);
  const roomTypeHandler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetRoomTypeById + formData.hotel._id
      );
      if (result.success == true) {
        setRoomTypeData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const UpdateHotelStockStatusHandler = async (campaign_name, date, status) => {
    try {
      const raw = JSON.stringify({
        package_item_type_id: roomfacilityid,
        package_id: id,
        campaign_name: campaign_name,
        date: date,
        status: status == "Active" ? "Inactive" : "Active",
      });
      const result = await fetchData(
        END_POINT.UpdatePackageStockStatus,
        METHODS.POST,
        raw
      );

      console.log(result);
      if (result.success == true) {
        GetStockhandler(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCheckinChange = (e) => {
    const selectedCheckinDate = new Date(e.target.value);
    const nextDay = new Date(selectedCheckinDate);

    nextDay.setDate(selectedCheckinDate.getDate() + 1);
    console.log(nextDay);

    const formattedNextDay = nextDay.toISOString().split("T")[0];

    setformData({
      ...formData,
      date_from: e.target.value,
      date_to: formattedNextDay,
    });
  };
  const minCheckinDate = getDateNow();
  // console.log(new Date().toISOString());
  const packageType = [
    { value: "Hotel", label: "Hotel" },
    { value: "Other", label: "Other" },
  ];

  return (
    <div>
      <h2 style={{ color: "#000", fontWeight: "bold", marginBottom: 20 }}>
        Price Stock
      </h2>

      <div className="formwraptable mx-auto">
        <div className="row mb-4 d-flex justify-content-between">
          <div className="col-4">
            <h5 style={{ textDecoration: "underline" }}>
              Package Name : {dinningname}
            </h5>

            <h7 style={{ textDecoration: "underline" }}>Item Name : {name}</h7>
          </div>
          <div className="col-6">
            <button
              className="btn btn-success m-1"
              type="button"
              onClick={() => {
                navigate("../package-list");
              }}
            >
              <i class="fa-solid fa-eye p-1"></i> Package
            </button>
            <button
              className="btn btn-success m-1"
              type="button"
              onClick={() => {
                navigate("../package-item-type/" + id + "/" + dinningname);
              }}
            >
              <i class="fa-solid fa-eye p-1"></i>View Item
            </button>
          </div>
        </div>
        <div className="row mb-4">
          {/* <div className="col-lg-3 md-3 sm-12">
            <span className="label-style">Package Type</span> <br />
            <Select
              id="type"
              name="type"
              value={packageType.filter(
                (item) => item.value == formData.package_type
              )}
              options={packageType}
              className="input-fields-style"
              // onChange={hotelTypeHandleChange}
              placeholder="Select an option"
              isSearchable
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) =>
                formData.package_type === option.value ? true : false
              }
              onChange={(e) => {
                console.log(e);
                setformData({
                  ...formData,
                  package_type: e.value,
                });
              }}
            />
          </div> */}
          {formData.package_type == "Hotel" && (
            <>
              <div className="col-lg-3 md-3 sm-12">
                <span className="label-style">Hotel</span> <br />
                <Select
                  id="type"
                  name="type"
                  value={hoteldata.filter(
                    (item) => item._id == formData?.hotel?._id
                  )}
                  options={hoteldata}
                  className="input-fields-style"
                  // onChange={hotelTypeHandleChange}
                  placeholder="Select an option"
                  isSearchable
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => `${option}`}
                  isOptionSelected={(option) =>
                    formData?.hotel?._id === option._id ? true : false
                  }
                  onChange={(e) => {
                    console.log(e);
                    setformData({
                      ...formData,
                      hotel: e,
                    });
                  }}
                />
              </div>
              <div className="col-lg-3 md-3 sm-12">
                <span className="label-style">Room Type</span> <br />
                <Select
                  id="type"
                  name="type"
                  value={roomType.filter(
                    (item) => item._id == formData?.hotel_room_type?._id
                  )}
                  options={roomType}
                  className="input-fields-style"
                  // onChange={hotelTypeHandleChange}
                  placeholder="Select an option"
                  isSearchable
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => `${option}`}
                  isOptionSelected={(option) =>
                    formData?.hotel_room_type?._id === option._id ? true : false
                  }
                  onChange={(e) => {
                    console.log(e);
                    setformData({
                      ...formData,
                      hotel_room_type: e,
                    });
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="row mb-4">
          <div className="col-lg-3 md-3 sm-12">
            <div>
              <span className="label-style">Payment Mode *</span> <br />
              <Select
                id="type"
                name="type"
                value={pricestockpaymentmethod.filter(
                  (item) => item.value == formData.payment_mode
                )}
                options={pricestockpaymentmethod}
                className="input-fields-style"
                // onChange={hotelTypeHandleChange}
                placeholder="Select an option"
                isSearchable
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) =>
                  formData.payment_mode === option.value ? true : false
                }
                onChange={(e) => {
                  console.log(e);
                  setformData({
                    ...formData,
                    payment_mode: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 md-3 sm-12">
            <div>
              <span className="label-style">Booking Type *</span> <br />
              <Select
                id="type"
                name="type"
                value={pricestockbookingtype.filter(
                  (item) => item.value == formData.booking_type
                )}
                options={pricestockbookingtype}
                className="input-fields-style"
                // onChange={hotelTypeHandleChange}
                placeholder="Select an option"
                isSearchable
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) =>
                  formData.booking_type === option.value ? true : false
                }
                onChange={(e) => {
                  console.log(e);
                  setformData({
                    ...formData,
                    booking_type: e.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="col-lg-2 md-2 sm-12">
            <div>
              <span className="label-style">Date From *</span> <br />
              <input
                type="date"
                className="input-fields-style"
                name="date_from"
                min={minCheckinDate}
                value={formData.date_from}
                onChange={(e) => handleCheckinChange(e)}
              />
            </div>
          </div>
          <div className="col-lg-2 md-2 sm-12">
            <div>
              <span className="label-style">Date To *</span> <br />
              <input
                type="date"
                className="input-fields-style"
                name="date_to"
                min={formData.date_from}
                value={formData.date_to}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <table>
              <thead>
                <td className="table-elements head">Active campaign</td>
                <td className="table-elements head">Campaign name</td>
                <td className="table-elements head">Benefit</td>

                <td className="table-elements head">Payment option</td>
                {/* <td className="table-elements head">Price </td> */}
                {/* <td className="table-elements head">
                  {formData.package_type == "Hotel" ? "Price" : "Adult"}
                </td> */}
                {/* <td className="table-elements head">
                  {formData.package_type == "Hotel" ? "Extra Bed" : "Child"}
                </td> */}
                <td className="table-elements head">Booking Period</td>
                {/* <td className="table-elements head">No. OF Pax</td> */}
              </thead>
              <tbody>
                <td className="table-elements">
                  <select
                    className=""
                    name="status"
                    value={formData.status}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select an option</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </td>
                <td className="table-elements">{name}</td>
                <td className="table-elements">
                  <Select
                    options={stockbenefitOptions}
                    className="input-fields-style"
                    placeholder="Select an option"
                    isSearchable
                    isMulti={true}
                    onChange={(e) => {
                      console.log(e);
                      setformData({
                        ...formData,
                        benifits: e,
                      });
                    }}
                  />
                </td>
                <td className="table-elements">
                  <input
                    type="number"
                    className=""
                    name="payment_option"
                    value={formData.payment_option}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                {/* <td className="table-elements">
                  <span>Cost</span>
                  <input
                    type="number"
                    className=""
                    name="cost"
                    value={formData.cost}
                    onChange={(e) => handleChange(e)}
                  />
                  <span>Sell</span>
                  <input
                    type="number"
                    className=""
                    name="sell"
                    value={formData.sell}
                    onChange={(e) => handleChange(e)}
                  />
                </td> */}
                {/* <td className="table-elements">
                  <span>Cost</span>
                  <input
                    type="number"
                    className=""
                    name="childcost"
                    value={formData.childcost}
                    onChange={(e) => handleChange(e)}
                  />
                  <span>Sell</span>
                  <input
                    type="number"
                    className=""
                    name="childsell"
                    value={formData.childsell}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                 */}
                <td className="table-elements">
                  <input
                    type="number"
                    className=""
                    name="bookingPeriod"
                    value={formData.bookingPeriod}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                {/* <td className="table-elements">
                  <input
                    className=""
                    placeholder="Adult No."
                    type="number"
                    name="No_Pax_Adult"
                    value={formData.No_Pax_Adult}
                    onChange={(e) => handleChange(e)}
                  />
                  <hr />
                  <input
                    className=""
                    placeholder="Child No."
                    type="number"
                    name="No_Pax_Child"
                    value={formData.No_Pax_Child}
                    onChange={(e) => handleChange(e)}
                  />
                </td> */}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <table>
              <thead>
                <td className="table-elements head">No. OF Pax</td>
                {formData.package_type == "Hotel" && (
                  <td className="table-elements head">No. OF Room</td>
                )}

                <td className="table-elements head">Adult Price</td>
                <td className="table-elements head">Child Price</td>
              </thead>
              <tbody>
                {formDataPax.map((item, index) => {
                  return (
                    <tr>
                      <td className="table-elements">
                        <input
                          type="number"
                          className=""
                          name="numberPax"
                          value={item.numberPax}
                          // onChange={(e) => handleChange(e)}
                        />
                      </td>
                      {formData.package_type == "Hotel" && (
                        <td className="table-elements">
                          <input
                            className=""
                            placeholder="Child No."
                            type="number"
                            name="number_room"
                            value={item.number_room}
                            onChange={(e) => handleChangeFormData(e, index)}
                          />
                        </td>
                      )}
                      <td className="table-elements">
                        <input
                          type="number"
                          className=""
                          name="number_adult"
                          value={item.number_adult}
                          onChange={(e) => handleChangeFormData(e, index)}
                        />
                      </td>
                      <td className="table-elements">
                        <input
                          type="number"
                          className=""
                          name="number_child"
                          value={item.number_child}
                          onChange={(e) => handleChangeFormData(e, index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-4 md-4 sm-10 ">
            <button
              className="btn btn-warning m-2"
              type="button"
              onClick={handleSubmit}
            >
              Update
            </button>
          </div>
        </div>
        {/* <div className="row mb-4 d-flex justify-content-between">
          <div className="col-5">
            <h5 style={{}}>Campaign Review </h5>
          </div>
          <div className="col-5 d-flex">
            <div>
              <span className="label-style">Date To *</span>
              <input type="date" className="m-1" />
            </div>
            <div>
              <span className="label-style">Date From *</span>
              <input type="date" className="m-1" />
            </div>
          </div>
        </div> */}

        {data.length > 0 &&
          data.map((item, index) => {
            return (
              <div key={index}>
                <h5 style={{ textDecoration: "underline" }}>
                  Campaign Review : {item.campaign_name}
                </h5>
                <div className="row mb-4">
                  <div className="col-12">
                    <table>
                      <thead>
                        <td className="table-elements head">Date</td>
                        <td className="table-elements head">No. OF Pax</td>
                        <td className="table-elements head">Payment Option</td>
                        <td className="table-elements head">Adult Cost</td>
                        <td className="table-elements head">Adult Sell</td>
                        <td className="table-elements head">Child Cost</td>
                        <td className="table-elements head">Child Sell</td>
                        <td className="table-elements head">Booking Period </td>

                        <td className="table-elements head">Live Sell </td>

                        <td className="table-elements head">Voucher Number </td>
                      </thead>
                      {item.campaign_rooms.length > 0 &&
                        item.campaign_rooms.map(
                          (itemCampaign, indexCampaign) => {
                            return (
                              <tbody>
                                <td className="table-elements">
                                  {itemCampaign.date}
                                </td>
                                {/* <td className="table-elements">
                                  <button
                                    className={`btn ${
                                      itemCampaign.status == "Active"
                                        ? "btn-success"
                                        : "btn-danger"
                                    }`}
                                    type="button"
                                    onClick={() => {
                                      UpdateHotelStockStatusHandler(
                                        item.campaign_name,
                                        itemCampaign.date,
                                        itemCampaign.status
                                      );
                                    }}
                                  >
                                    {itemCampaign.No_Pax}
                                  </button>
                                </td> */}
                                <td className="table-elements">
                                  {itemCampaign.No_Pax}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.payment_option}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.adultcost}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.adultsell}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.childcost}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.childsell}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.bookingPeriod}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.livesell}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.VoucherNumber}
                                </td>
                              </tbody>
                            );
                          }
                        )}
                    </table>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PackagePriceStock;
