import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";

import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { Button, Container, Row } from "react-bootstrap";
import { fetchData } from "../../Helper/ApiCall";
import {
  END_POINT,
  BASE_URL_IMAGE,
  METHODS,
  NumberSeprator,
  numberToEnglish,
  TabBarLabels,
} from "../../Helper/helper";
import PartnersCarousel from "../../components/PartnersCarousel";
import Subscribe from "../../components/Subscribe";
export const BlogsScreen = () => {
  const cityid = "659cf0b87b67205779a17ef8";

  const [data, setdata] = useState([]);
  const [destinationdata, setdestinationdata] = useState();
  const [eventData, seteventData] = useState([]);
  const [weddingData, setweddingData] = useState([]);
  const [travelData, settravelData] = useState([]);
  const [miceData, setmiceData] = useState([]);
  const [trendingData, settrendingData] = useState([]);

  const [banner, setblogbannerdata] = useState();

  useEffect(() => {
    handler();
    bloghandler();
  }, []);
  const handler = async () => {
    try {
      const result = await fetchData(END_POINT.GetWebBlogCategoryData);

      if (result.success == true) {
        const finalArray = result.data.filter((item) => {
          return item.categoryname == "Destination";
        });
        setdestinationdata(finalArray);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const bloghandler = async () => {
    try {
      const bannerresult = await fetchData(
        END_POINT.GetBlogBannerByid + cityid
      );

      if (bannerresult.success == true) {
        setblogbannerdata(bannerresult.data);
      }

      const result = await fetchData(END_POINT.GetDataBlog);

      if (result.success == true) {
        const TravelfinalArray = [];
        const MicefinalArray = [];
        const WeddingfinalArray = [];
        const EventfinalArray = [];
        const TrendingfinalArray = [];

        result.data.map((item) => {
          if (item.category_detail == "Travel") {
            TravelfinalArray.push(item);
          }
          if (item.category_detail == "Mice") {
            MicefinalArray.push(item);
          }
          if (item.category_detail == "Wedding") {
            WeddingfinalArray.push(item);
          }
          if (item.category_detail == "Event") {
            EventfinalArray.push(item);
          }
          if (item.category_detail == "Trending") {
            EventfinalArray.push(item);
          }
        });

        seteventData(EventfinalArray);
        setweddingData(WeddingfinalArray);
        settravelData(TravelfinalArray);
        setmiceData(MicefinalArray);
        settrendingData(TrendingfinalArray);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const htmlString = banner?.short_desc;

  // Create a temporary DOM element
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Get the text content without HTML tags
  const textContent = tempElement.textContent || tempElement.innerText;

  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}

      {/* Hero Banner */}
      <div class="mice-outer">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          //   modules={[Autoplay]}
          //   autoplay={{
          //     delay: 3500,
          //     disableOnInteraction: true,
          //   }}
          //   breakpoints={{
          //     "@0.00": {
          //       slidesPerView: 2,
          //       spaceBetween: 5,
          //     },
          //     "@0.75": {
          //       slidesPerView: 3,
          //       spaceBetween: 10,
          //     },
          //     "@1.00": {
          //       slidesPerView: 4,
          //       spaceBetween: 30,
          //     },
          //     "@1.50": {
          //       slidesPerView: 5,
          //       spaceBetween: 40,
          //     },
          //   }}

          className="micecarousel"
        >
          <SwiperSlide>
            <div
              className="blogs-banner"
              style={{
                backgroundImage: `url(${BASE_URL_IMAGE + banner?.image})`,
              }}
            >
              <div className="row">
                <div className="col-9 mice-banner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                  <h1>{banner?.title}</h1>

                  <p className="blogs-p">{textContent}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* Welcome Section */}
      <div className="lifelong-carousel-wrapper">
        <Container>
          <div className="col-lg-12 md-12 sm-12 mx-auto">
            <h2 className="lifelong-text-dest">Destinations</h2>
            <p className="lifelong-text-p">
              Through our blogs you will explore what each city amazing Thailand
              has to offer from attraction places, food and much more.
            </p>
          </div>
          <div className="row carouselcontainer">
            <div className="col-lg-12 md-12 sm-12 mx-auto slider-container">
              <Swiper
                // navigation={true}

                slidesPerView={2}
                spaceBetween={5}
                loop={true}
                className="why-caro-swiper"
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                    spaceBetween: 5,
                  },
                  "@0.75": {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  "@1.00": {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  "@1.50": {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                }}
              >
                {destinationdata != undefined &&
                  destinationdata.map((item) => {
                    console.log("dbckdbckbdbck");
                    return (
                      <SwiperSlide>
                        <Link
                          to={
                            "/blogs/" +
                            item.categoryname +
                            "/" +
                            item?.sub_category?.sub_category_name
                          }
                          state={{ id: item._id }}
                        >
                          <div
                            className="destination-slides"
                            style={{
                              backgroundImage: `url(${BASE_URL_IMAGE + item.sub_category.image
                                })`,
                            }}
                          >
                            <p
                              style={{
                                color: "white",
                                fontSize: "x-large",
                                fontWeight: "600",
                              }}
                            >
                              {item?.sub_category?.sub_category_name}
                            </p>
                          </div>
                        </Link>
                      </SwiperSlide>
                    );
                  })}
                {/* <SwiperSlide>
                  <div className="destination-slides">
                    <p
                      style={{
                        color: "white",
                        fontSize: "x-large",
                        fontWeight: "600",
                      }}
                    >
                      Chiangmai
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="destination-slides">
                    <p
                      style={{
                        color: "white",
                        fontSize: "x-large",
                        fontWeight: "600",
                      }}
                    >
                      Huahin
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="destination-slides">
                    <p
                      style={{
                        color: "white",
                        fontSize: "x-large",
                        fontWeight: "600",
                      }}
                    >
                      Bangkok
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="destination-slides">
                    <p
                      style={{
                        color: "white",
                        fontSize: "x-large",
                        fontWeight: "600",
                      }}
                    >
                      Kanchanaburi
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="destination-slides">
                    <p
                      style={{
                        color: "white",
                        fontSize: "x-large",
                        fontWeight: "600",
                      }}
                    >
                      Samui Island
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="destination-slides">
                    <p
                      style={{
                        color: "white",
                        fontSize: "x-large",
                        fontWeight: "600",
                      }}
                    >
                      Khao yai
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="destination-slides">
                    <p
                      style={{
                        color: "white",
                        fontSize: "x-large",
                        fontWeight: "600",
                      }}
                    >
                      Phuket
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="destination-slides">
                    <p
                      style={{
                        color: "white",
                        fontSize: "x-large",
                        fontWeight: "600",
                      }}
                    >
                      Pattaya
                    </p>
                  </div>
                </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
        </Container>
      </div>

      {travelData.length > 0 && (
        <div className="container welcome-section">
          {/* <img
          src="/assets/img/mid-left-leaf.svg"
          className="img-fluid mid-left-leaf"
        /> */}
          <h2>Travel blogs</h2>
          <p className="events-intro">
            Our travel blogs will transport you to far-off lands, where you can
            indulge in exotic flavors, revel in festivals, and let your mind
            wander.
          </p>
          <div className="row">
            {travelData.length > 0 &&
              travelData.map((item, index) => {
                return (
                  index <= 2 && (
                    <div className="col-lg-4 md-5 sm-12 venue-cards">
                      <Link
                        to={"/blogs/" + item?.category_detail?.categoryname}
                      >
                        <img
                          src={BASE_URL_IMAGE + item.image}
                          className="img-fluid"
                        />
                        <div className="venue-card-text">
                          <h4>{item?.title}</h4>
                          <p>
                            Choice of hotels promising the best deals in town
                          </p>
                        </div>
                      </Link>
                    </div>
                  )
                );
              })}
          </div>
          <Row>
            <Button className="btn mx-auto view-all-butt col-lg-2 md-2 sm-6  mt-4">
              <Link to={"/blogs/Travel"}>View All</Link>
            </Button>
          </Row>
          {/* <img
          src="/assets/img/bottom-right-leaf.svg"
          className="img-fluid bottom-right-leaf"
        /> */}
        </div>
      )}
      {/* Special Events Section */}
      {weddingData.length > 0 && (
        <div className="unique-carousel-wrapper">
          <div
            className="col-lg-12 md-12 sm-12 mx-auto container"
            id="unique-cont"
          >
            <h2 className="unique-text">Wedding</h2>
            <p className="unique-text-p mb-4">
              Our travel blogs will transport you to far-off lands, where you
              can indulge in exotic flavors, revel in festivals, and let your
              mind wander.
            </p>
          </div>
          <div className="row carouselcontainer container mx-auto">
            <div className="row">
              {weddingData.length > 0 &&
                weddingData.map((item, index) => {
                  return (
                    index <= 2 && (
                      <div className="col-lg-4 md-5 sm-12 venue-cards">
                        <Link
                          to={"/blogs/" + item?.category_detail?.categoryname}
                        >
                          <img
                            src={BASE_URL_IMAGE + item.image}
                            className="img-fluid"
                          />
                          <div className="venue-card-text">
                            <h4>{item?.title}</h4>
                            <p>
                              Choice of hotels promising the best deals in town
                            </p>
                          </div>
                        </Link>
                      </div>
                    )
                  );
                })}
            </div>
            <Row>
              <Button className="btn mx-auto view-all-butt col-lg-2 md-2 sm-4 mt-4">
                <Link to={"/blogs/Wedding"}>View All</Link>
              </Button>
            </Row>
          </div>
        </div>
      )}
      {/* Experiences Section */}
      <div className="container welcome-section">
        {/* <img
          src="/assets/img/mid-left-leaf.svg"
          className="img-fluid mid-left-leaf"
        /> */}
        <h2>Events</h2>
        <p className="events-intro">
          Our travel blogs will transport you to far-off lands, where you can
          indulge in exotic flavors, revel in festivals, and let your mind
          wander.
        </p>
        <div className="row">
          {eventData.length > 0 &&
            eventData.map((item, index) => {
              return (
                index <= 2 && (
                  <div className="col-lg-4 md-5 sm-12 venue-cards">
                    <Link to={"/blogs/" + item?.category_detail?.categoryname}>
                      <img
                        src={BASE_URL_IMAGE + item.image}
                        className="img-fluid"
                      />
                      <div className="venue-card-text">
                        <h4>{item?.title}</h4>
                        <p>Choice of hotels promising the best deals in town</p>
                      </div>
                    </Link>
                  </div>
                )
              );
            })}
          {/* <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/TravelBlogThumb.svg" className="img-fluid" />
            <div className="venue-card-text">
              <h4>Guide to coral Island at Pattaya</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div>
          <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/TravelBlogThumb.svg" className="img-fluid" />
            <div className="venue-card-text">
              <h4>Pattaya dining place</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div>
          <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/TravelBlogThumb.svg" className="img-fluid" />
            <div className="venue-card-text">
              <h4>Traveling blog</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div> */}
        </div>
        <Row>
          <Button className="btn mx-auto view-all-butt col-lg-2 md-2 sm-4 mt-4">
            <Link to={"/blogs/Event"}>View All</Link>
          </Button>
        </Row>
        {/* <img
          src="/assets/img/bottom-right-leaf.svg"
          className="img-fluid bottom-right-leaf"
        /> */}
      </div>
      <div className="mice-blogs-wrapper">
        <div className="container mice-blogs-section">
          {/* <img
          src="/assets/img/mid-left-leaf.svg"
          className="img-fluid mid-left-leaf"
        /> */}
          <h2>MICE</h2>
          <p className="intro-white">
            Our travel blogs will transport you to far-off lands, where you can
            indulge in exotic flavors, revel in festivals, and let your mind
            wander.
          </p>
          <div className="row">
            {miceData.length > 0 &&
              miceData.map((item, index) => {
                return (
                  index <= 2 && (
                    <div className="col-lg-4 md-5 sm-12 venue-cards">
                      <Link
                        to={"/blogs/" + item?.category_detail?.categoryname}
                      >
                        <img
                          src={BASE_URL_IMAGE + item.image}
                          className="img-fluid"
                        />
                        <div className="venue-card-text">
                          <h4>{item?.title}</h4>
                          <p>
                            Choice of hotels promising the best deals in town
                          </p>
                        </div>
                      </Link>
                    </div>
                  )
                );
              })}
            {/* <div className="col-lg-4 md-5 sm-12 venue-cards">
              <img
                src="/assets/img/TravelBlogThumb.svg"
                className="img-fluid"
              />
              <div className="mice-card-text">
                <h4>Guide to coral Island at Pattaya</h4>
                <p>Choice of hotels promising the best deals in town</p>
              </div>
            </div>
            <div className="col-lg-4 md-5 sm-12 venue-cards">
              <img
                src="/assets/img/TravelBlogThumb.svg"
                className="img-fluid"
              />
              <div className="mice-card-text">
                <h4>Pattaya dining place</h4>
                <p>Choice of hotels promising the best deals in town</p>
              </div>
            </div>
            <div className="col-lg-4 md-5 sm-12 venue-cards">
              <img
                src="/assets/img/TravelBlogThumb.svg"
                className="img-fluid"
              />
              <div className="mice-card-text">
                <h4>Traveling blog</h4>
                <p>Choice of hotels promising the best deals in town</p>
              </div>
            </div> */}
          </div>
          <Row>
            <Button className="btn mx-auto view-all-butt col-lg-2 md-2 sm-4 mt-4">
              <Link to={"/blogs/Mice"}>View All</Link>
            </Button>
          </Row>
          {/* <img
          src="/assets/img/bottom-right-leaf.svg"
          className="img-fluid bottom-right-leaf"
        /> */}
        </div>
      </div>

      <div className="container welcome-section">
        {/* <img
          src="/assets/img/mid-left-leaf.svg"
          className="img-fluid mid-left-leaf"
        /> */}
        <h2>Trending news </h2>
        <p className="events-intro">
          Our travel blogs will transport you to far-off lands, where you can
          indulge in exotic flavors, revel in festivals, and let your mind
          wander.
        </p>
        <div className="row">
          {trendingData.length > 0 &&
            trendingData.map((item, index) => {
              return (
                index <= 2 && (
                  <div className="col-lg-4 md-5 sm-12 venue-cards">
                    <Link to={"/blogs/" + item?.category_detail?.categoryname}>
                      <img
                        src={BASE_URL_IMAGE + item.image}
                        className="img-fluid"
                      />
                      <div className="venue-card-text">
                        <h4>{item?.title}</h4>
                        <p>Choice of hotels promising the best deals in town</p>
                      </div>
                    </Link>
                  </div>
                )
              );
            })}
          {/* <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/TravelBlogThumb.svg" className="img-fluid" />
            <div className="venue-card-text">
              <h4>Guide to coral Island at Pattaya</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div>
          <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/TravelBlogThumb.svg" className="img-fluid" />
            <div className="venue-card-text">
              <h4>Pattaya dining place</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div>
          <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/TravelBlogThumb.svg" className="img-fluid" />
            <div className="venue-card-text">
              <h4>Traveling blog</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div> */}
        </div>
        <Row>
          <Button className="btn mx-auto view-all-butt col-lg-2 md-2 sm-4 mt-4">
            <Link to={"/blogs/Trending"}>View All</Link>
          </Button>
        </Row>
        {/* <img
          src="/assets/img/bottom-right-leaf.svg"
          className="img-fluid bottom-right-leaf"
        /> */}
      </div>

      {/* CTA Section */}

      <Subscribe
        title={`Stay Connected: Get Exclusive Updates with Our Newsletter`}
      />
      {/* <div className="experience-wrapper">
        <div className="container experience-section">
          <h2>Testimonials: Discover Their Experience</h2>

          <div className="row experince-cards-wrapper">
            <div className="col-lg-3 md-6 sm-12 experience-cards m-2">
              <div className="cards-inner-row">
                <div className="w-40">
                  <img src="/assets/img/userimg.png" className="img-fluid" />
                </div>
                <div className="w-40">
                  <img
                    src="/assets/img/Buildingicon.png"
                    className="img-fluid"
                  />
                </div>
              </div>
              <p style={{ fontStyle: "italic", color: "#061F40" }}>
                Bharat Choice will upscale the potential of service providers to
                various establishments and be ready according to the needs of
                Indian Worldwide consumers.
              </p>
              <p style={{ fontStyle: "bold", color: "#383838" }}>
                Traveler name
              </p>
            </div>
            <div className="col-lg-3 md-6 sm-12 experience-cards m-2">
              <div className="cards-inner-row">
                <div className="w-40">
                  <img src="/assets/img/userimg.png" className="img-fluid" />
                </div>
                <div className="w-40">
                  <img
                    src="/assets/img/Buildingicon.png"
                    className="img-fluid"
                  />
                </div>
              </div>
              <p style={{ fontStyle: "italic", color: "#061F40" }}>
                Bharat Choice will upscale the potential of service providers to
                various establishments and be ready according to the needs of
                Indian Worldwide consumers.
              </p>
              <p style={{ fontStyle: "bold", color: "#383838" }}>
                Traveler name
              </p>
            </div>
            <div className="col-lg-3 md-6 sm-12 experience-cards m-2">
              <div className="cards-inner-row">
                <div className="w-40">
                  <img src="/assets/img/userimg.png" className="img-fluid" />
                </div>
                <div className="w-40">
                  <img
                    src="/assets/img/Buildingicon.png"
                    className="img-fluid"
                  />
                </div>
              </div>
              <p style={{ fontStyle: "italic", color: "#061F40" }}>
                Bharat Choice will upscale the potential of service providers to
                various establishments and be ready according to the needs of
                Indian Worldwide consumers.
              </p>
              <p style={{ fontStyle: "bold", color: "#383838" }}>
                Traveler name
              </p>
            </div>
          </div>
          <div className="col-lg-2 md-2 sm-42 text-center mt-3">
            <button class="btn see-more-btn">See More</button>
          </div>
        </div>
      </div> */}
      {/* PartnerShip Section */}
      <PartnersCarousel />
      {/* Footer */}

      {/* <Footer /> */}
    </div>
  );
};
