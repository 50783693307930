import React, { useState, useEffect } from "react";
import { END_POINT, METHODS, BASE_URL_IMAGE } from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const AddWedding = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = location?.state?.id;

  const [formData, setformData] = useState({
    title: "",
    galore_title: "",
    desc: "",
    wedding_title: "",
    wedding_desc: "",
    inspiration_title: "",
    inspiration_desc: "",
    venue_title: "",
    venue_desc: "",
    service_offer: "",
    request_url: "",
    banner_image: "",
    image: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");
  const [banner, setbanner] = useState("");
  const [catData, setcatData] = useState([]);

  useEffect(() => {
    // categoryHandler();
    if (cityid != undefined) {
      handler();
    }
  }, []);

  const handler = async () => {
    try {
      const result = await fetchData(END_POINT.GetWeddingByid + cityid);

      if (result.success == true) {
        setformData({
          title: result.data.title,
          galore_title: result.data.galore_title,
          desc: result.data.desc,
          wedding_title: result.data.wedding_title,
          wedding_desc: result.data.wedding_desc,
          inspiration_title: result.data.inspiration_title,
          inspiration_desc: result.data.inspiration_desc,
          venue_title: result.data.venue_title,
          venue_desc: result.data.venue_desc,
          service_offer: result.data.service_offer,
          request_url: result.data.request_url,
          banner_image: result.data.banner_image,
          image: result.data.image,
        });

        setwedding_desc(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.wedding_desc)
            )
          )
        );

        setinspiration_desc(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.inspiration_desc)
            )
          )
        );

        setvenue_desc(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.venue_desc)
            )
          )
        );

        setdesc(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(result.data.desc))
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (uploadedImagebanner, uploadedImage) => {
    var raw = JSON.stringify({
      title: formData.title,
      galore_title: formData.galore_title,
      desc: formData.desc,
      wedding_title: formData.wedding_title,
      wedding_desc: formData.wedding_desc,
      inspiration_title: formData.inspiration_title,
      inspiration_desc: formData.inspiration_desc,
      venue_title: formData.venue_title,
      venue_desc: formData.venue_desc,
      service_offer: formData.service_offer,
      request_url: formData.request_url,
      banner_image: uploadedImagebanner,
      image: uploadedImage,
    });
    console.log("raw", raw);
    try {
      const finalEndpoint =
        cityid != undefined
          ? END_POINT.UpdateWedding + cityid
          : END_POINT.AddWedding;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../wedding-list");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(banner), await ImageUpload(image)])
      .then((values) => {
        const uploadedImagebanner = values[0].image;
        const uploadedImage = values[1].image;
        handleSubmit(uploadedImagebanner, uploadedImage);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const AllFileUpdate = async () => {
    Promise.all([
      banner != "" && (await ImageUpload(banner)),
      image != "" && (await ImageUpload(image)),
    ])
      .then((values) => {
        const uploadedImagedininglist =
          values[0].image == false ? formData.banner_image : values[0].image;
        const uploadedImage =
          values[1].image == false ? formData.image : values[1].image;

        handleSubmit(uploadedImagedininglist, uploadedImage);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [wedding_desc, setwedding_desc] = useState(EditorState.createEmpty());
  const [inspiration_desc, setinspiration_desc] = useState(
    EditorState.createEmpty()
  );
  const [venue_desc, setvenue_desc] = useState(EditorState.createEmpty());
  const [desc, setdesc] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState, key) => {
    if (key == "wedding_desc") {
      setwedding_desc(editorState);
    }
    if (key == "inspiration_desc") {
      setinspiration_desc(editorState);
    }
    if (key == "venue_desc") {
      setvenue_desc(editorState);
    }
    if (key == "desc") {
      setdesc(editorState);
    }
    setformData({
      ...formData,
      [key]: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add Wedding</b>
        </h3>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Title:</span> <br />
            <input
              type="text"
              id="title"
              name="title"
              className="input-fields-style"
              value={formData.title}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Galore Title:</span> <br />
            <input
              type="text"
              id="galore_title"
              name="galore_title"
              className="input-fields-style"
              value={formData.galore_title}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Description:</span> <br />
          <Editor
            editorState={desc}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(e) => {
              onEditorStateChange(e, "desc");
            }}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Wedding Title:</span> <br />
            <input
              type="text"
              id="wedding_title"
              name="wedding_title"
              className="input-fields-style"
              value={formData.wedding_title}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Wedding Description:</span> <br />
          <Editor
            editorState={wedding_desc}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(e) => {
              onEditorStateChange(e, "wedding_desc");
            }}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Inspiration Title:</span> <br />
            <input
              type="text"
              id="inspiration_title"
              name="inspiration_title"
              className="input-fields-style"
              value={formData.inspiration_title}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Inspiration Description:</span> <br />
          <Editor
            editorState={inspiration_desc}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(e) => {
              onEditorStateChange(e, "inspiration_desc");
            }}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Venue Title:</span> <br />
            <input
              type="text"
              id="venue_title"
              name="venue_title"
              className="input-fields-style"
              value={formData.venue_title}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Venue Description:</span> <br />
          <Editor
            editorState={venue_desc}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(e) => {
              onEditorStateChange(e, "venue_desc");
            }}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Service Offer:</span> <br />
            <input
              type="text"
              id="service_offer"
              name="service_offer"
              className="input-fields-style"
              value={formData.service_offer}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">URL:</span> <br />
            <input
              type="text"
              id="request_url"
              name="request_url"
              className="input-fields-style"
              value={formData.request_url}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Banner Image *</span> <br />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                setbanner(e.target.files[0]);
              }}
            />
            {formData.banner_image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.banner_image}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-5"
              >
                view image
              </a>
            )}
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Image *</span> <br />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
            {formData.image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.image}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-5"
              >
                view image
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            if (cityid != undefined) {
              if (image != "") {
                AllFileUpdate();
              } else {
                handleSubmit(formData.banner_image, formData.image);
              }
            } else {
              AllFileUpload();
            }
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../wedding-list");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddWedding;
