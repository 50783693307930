import React, { useState, useEffect } from "react";
import { END_POINT, METHODS, BASE_URL_IMAGE } from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const SocialLinkDetail = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = "651db2452fcf6e9a9489be29";

  const [formData, setformData] = useState({
    facebook_link: "",
    instgram_link: "",
    youtube_link: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    handler();
  }, []);

  const handler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetDetailByIdSocialLink + cityid
      );

      if (result.success == true) {
        setformData({
          facebook_link: result.data.facebook_link,
          instgram_link: result.data.instgram_link,
          youtube_link: result.data.youtube_link,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async () => {
    var raw = JSON.stringify({
      facebook_link: formData.facebook_link,
      instgram_link: formData.instgram_link,
      youtube_link: formData.youtube_link,
    });
    console.log("raw", raw);
    try {
      const finalEndpoint =
        cityid != undefined
          ? END_POINT.UpdateSocialLink + cityid
          : END_POINT.UploadSocialLink;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(formData);
  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Social Links</b>
        </h3>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Facebook:</span> <br />
            <input
              type="text"
              id="facebook_link"
              name="facebook_link"
              className="input-fields-style"
              value={formData.facebook_link}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Instagram:</span> <br />
            <input
              type="text"
              id="instgram_link"
              name="instgram_link"
              className="input-fields-style"
              value={formData.instgram_link}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">YouTube:</span> <br />
            <input
              type="text"
              id="youtube_link"
              name="youtube_link"
              className="input-fields-style"
              value={formData.youtube_link}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default SocialLinkDetail;
