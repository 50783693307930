import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import VideoSwiper from "./VideoSwiper";
import { BASE_URL_IMAGE } from "../Helper/helper";
import ImageSwiper from "./ImageSwiper";
import ReactImageMagnify from "react-image-magnify";
function SharedProductPageHeader({ hotel, sharedurl }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [copyMessage, setCopyMessage] = useState('');

  const handleCopyLink = () => {
    const link = `${window.location.origin}/${sharedurl}/${hotel?._id}`;
    navigator.clipboard.writeText(link)
      .then(() => {
        setCopyMessage('Link copied to clipboard!');
        setTimeout(() => setCopyMessage(''), 2000); // Hide the message after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  ///magnifier
  function ImageMagnifier({
    src,
    width,
    height,
    styleImage,
    magnifierHeight = 150,
    magnifierWidth = 150,
    zoomLevel = 1.5,
  }) {
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);

    return (
      <div
        style={{
          position: "relative",
          height: height,
          width: width,
        }}
      >
        <img
          src={src}
          style={{ height: height, width: width, ...styleImage }}
          onMouseEnter={(e) => {
            const elem = e.currentTarget;
            const { width, height } = elem.getBoundingClientRect();
            setSize([width, height]);
            setShowMagnifier(true);
          }}
          onMouseMove={(e) => {
            const elem = e.currentTarget;
            const { top, left } = elem.getBoundingClientRect();
            const x = e.pageX - left - window.pageXOffset;
            const y = e.pageY - top - window.pageYOffset;
            setXY([x, y]);
          }}
          onMouseLeave={() => {
            setShowMagnifier(false);
          }}
          alt={"img"}
        />

        <div
          style={{
            display: showMagnifier ? "" : "none",
            position: "absolute",
            pointerEvents: "none",
            height: `${magnifierHeight}px`,
            width: `${magnifierWidth}px`,
            top: `${y - magnifierHeight / 2}px`,
            left: `${x + magnifierWidth / 2}px`, // Adjusted to show on the left side
            opacity: "1",
            border: "1px solid lightgray",
            backgroundColor: "white",
            backgroundImage: `url('${src}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel
              }px`,
            backgroundPositionX: `${-x * zoomLevel - magnifierWidth / 2}px`, // Adjusted
            backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
          }}
        ></div>
      </div>
    );
  }

  return (
    <div>
      <Modal
        className="image-modal custom-img-modal"
        show={show}
        onHide={handleClose}
        style={{
          maxWidth: '1000px', // Adjust the width as needed
          maxHeight: '100vh', // Adjust the height as needed
          margin: '0 auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{hotel?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>

            <ImageSwiper hotel={hotel} />

          </div>
        </Modal.Body>
      </Modal>
      <div className="hotel-header container">
        <h3
          style={{
            textTransform: "capitalize",
          }}
        >
          {hotel?.name}
        </h3>
        <div className="ratings d-flex align-items-center justify-contentcenter mb-4">
          <img src="/assets/img/star.svg" className="img-fluid" />
          <span>{hotel?.starRating}</span>
          <span>|</span>
          <span>{hotel?.city}</span>
          <span>|</span>
          <button onClick={handleCopyLink} className="btn btn-link">
            {/* <i className="fa fa-share blue-icon"></i> */}
            <span className="black-text"> Share</span>
          </button>
          {copyMessage && <div className="copy-message">{copyMessage}</div>}
          <span>|</span>
          <img
            src="/assets/img/bharat-logo-ratings.svg"
            className="img-fluid"
          />
          <span>Bharatchoice standard</span>
        </div>
      </div>
      <div className="images-container container" id="prev-images-container">
        <div className="row mb-4">
          <div className="col-lg-6 md-6 main-box">
            {hotel != undefined && (
              <img
                src={BASE_URL_IMAGE + hotel.images}
                className="img-fluid main-box-img"
              />

            )}
          </div>

          <div className="col-lg-6 md-6 moreimages">
            <div className="row d-flex mx-1">
              {hotel?.moreImages?.map((item, index) => {
                return (
                  index <= 3 && (
                    <div
                      className="col-6 image-box-more"
                      style={{
                        // marginBottom: index == 0 || index == 1 ? 20 : 0,
                        justifyContent:
                          index == 0 || index == 1 ? "start" : "end",
                        marginTop: index == 0 || index == 1 ? 0 : 10,
                      }}
                    >
                      <img
                        // src="/assets/img/grid-small-left.svg"
                        src={BASE_URL_IMAGE + item}
                        className="img-fluid hotel-more-img"
                        style={{
                          borderTopRightRadius: index == 1 ? 20 : 0,
                          borderBottomRightRadius: index == 3 ? 20 : 0,
                          // justifyContent:
                          //   index == 0 || index == 1 ? "start" : "end",
                        }}
                      />
                      {/* <ImageMagnifier
                        src={BASE_URL_IMAGE + item}
                        styleImage={{
                          borderTopRightRadius: index == 1 ? 20 : 0,
                          borderBottomRightRadius: index == 3 ? 20 : 0,
                          width: 320,
                          height: 210,
                          // marginLeft: 10,
                          marginTop: index == 2 || index == 3 ? 5 : 0,
                          objectFit: "cover",
                        }}
                      /> */}
                      {/* <img
                        // src="/assets/img/grid-small-left.svg"
                        src={BASE_URL_IMAGE + item}
                        className="img-fluid hotel-more-img"
                        style={{
                          borderTopRightRadius: index == 1 ? 20 : 0,
                          borderBottomRightRadius: index == 3 ? 20 : 0,
                        }}
                      /> */}

                      {/* <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: "Additional Image",
                            isFluidWidth: true,
                            src: BASE_URL_IMAGE + item,
                          },
                          largeImage: {
                            src: BASE_URL_IMAGE + item,
                            width: 600,
                            height: 300,
                          },
                          style: {
                            borderTopRightRadius: index === 1 ? 20 : 0,
                            borderBottomRightRadius: index === 3 ? 20 : 0,
                          },
                          enlargedImageStyle: {
                            zIndex: 99999999999,
                            position: "relative",
                          },
                          imageStyle: {
                            borderTopRightRadius: index === 1 ? 20 : 0,
                            borderBottomRightRadius: index === 3 ? 20 : 0,
                            // marginTop: index == 2 || index == 3 ? 5 : 0,
                          },
                          enlargedImageContainerStyle: {
                            left: "-110%",
                          },
                        }}
                      /> */}
                    </div>
                  )
                );
              })}
            </div>
          </div>
          <button className="btn-see-more" onClick={handleShow}>
            See more photos
          </button>
        </div>
      </div>
    </div>
  );
}

export default SharedProductPageHeader;
