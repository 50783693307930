import React from "react";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
function EditorRead({ description }) {
  const descriptionRead = EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(description))
  );
  return <Editor toolbarHidden editorState={descriptionRead} readOnly />;
}

export default EditorRead;
