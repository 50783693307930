import React, { useState, useEffect } from "react";
import { BASE_URL_IMAGE, END_POINT, METHODS } from "../../Helper/helper";
import { fetchData, ImageUpload } from "../../Helper/ApiCall";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Ticketupcoming from "../../assets/svg/profile/Ticketupcoming";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/reducers/userReducer";
import { userdataUpdate } from "../../redux/reducers/userReducer";
import Swal from "sweetalert2";

const PersonalDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((store) => store.userReducer);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    usersetimage(file);
  };

  useEffect(() => {
    // Initialize state variables with user data when the component mounts
    setfirstName(userData.firstName);
    setlastName(userData.lastName);
    setmobile(userData.mobile);
    setemail(userData.email);
    usersetimage(userData?.user_image);
  }, [userData]); // This effect will run whenever 'userData' changes

  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [userimage, usersetimage] = useState();

  const HandelEditProfile = async (uploadedimage) => {
    try {
      const raw = JSON.stringify({
        email: email,
        mobile: mobile,
        firstName: firstName,
        lastName: lastName,
        user_image: uploadedimage,
      });
      const result = await fetchData(
        END_POINT.UpdateUser + userData._id,
        METHODS.POST,
        raw
      );
      console.log("result", raw);
      if (result.success == true) {
        dispatch(userdataUpdate(result.data));

        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        });
      } else {
        Swal.fire({
          title: "Wrong",
          text: result.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Cancel",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const ImageuploadHandler = async () => {
    Promise.all([await ImageUpload(selectedFile)])
      .then((values) => {
        const uploadedimage =
          values[0].image == undefined ? userimage : values[0].image;

        console.log(uploadedimage);
        HandelEditProfile(uploadedimage);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // console.log(userimage);

  return (
    <div>
      <Container>
        <h3
          style={{
            color: "#0A3874",
            marginTop: "10px",
            marginBottom: "30px",
          }}
        >
          Personal details
        </h3>
        <div className="d-flex align-items-center user-name-img">
          <div
            style={{
              height: "4.5rem",
              width: "4.5rem",
              borderRadius: "4.5rem",
              objectFit: "cover",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "pink",
              overflow: "hidden",
            }}
          >
            <img
              src={
                userData?.user_image != undefined
                  ? BASE_URL_IMAGE + userData?.user_image
                  : "/assets/img/UserCircle.svg"
              }
              className="img-fluid"
              style={{ objectFit: "contain" }}
            />
          </div>

          {/* <p style={{ color: "#E8BA00", textDecoration: "underline" }}>
            Edit Image
          </p> */}
          <div className="custom-file-input">
            <label htmlFor="fileInput">
              {selectedFile ? selectedFile?.name : "Edit Profile Image"}
            </label>
            <input
              type="file"
              id="fileInput"
              name="fileInput"
              accept=".jpg, .jpeg, .png"
              onChange={handleFileChange}
            />
          </div>
          {/* <input
            placeholder="Edit Image"
            style={{
              color: "#E8BA00",
              textDecoration: "underline",
              width: "50%",
            }}
            type={"file"}
            onChange={(e) => {
              usersetimage(e.target.files[0]);
            }}
          /> */}
        </div>
        <Row className="d-flex justify-content-between ">
          <Col xs={6} md={6}>
            <div style={{ marginRight: 5 }} className="form-element-each">
              <span style={{ display: "block", marginLeft: 1 }}>
                First name
              </span>
              <div className="d-flex align-items-center">
                <input
                  style={{ border: "none" }}
                  placeholder="Enter First name"
                  value={firstName}
                  onChange={(e) => {
                    setfirstName(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} md={6}>
            <div style={{ marginRight: 5 }} className="form-element-each">
              <span style={{ display: "block", marginLeft: 1 }}>Last name</span>
              <div className="d-flex align-items-center">
                <input
                  style={{ border: "none" }}
                  placeholder="Enter Last name"
                  value={lastName}
                  onChange={(e) => {
                    setlastName(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ">
          <Col xs={6} md={6}>
            <div style={{ marginRight: 5 }} className="form-element-each">
              <span style={{ display: "block", marginLeft: 1 }}>
                Mobile Number
              </span>
              <div className="d-flex align-items-center">
                <input
                  style={{ border: "none" }}
                  placeholder="Enter mobile number"
                  value={mobile}
                  onChange={(e) => {
                    setmobile(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} md={6}>
            <div style={{ marginRight: 5 }} className="form-element-each">
              <span style={{ display: "block", marginLeft: 1 }}>Email</span>
              <div className="d-flex align-items-center">
                <input
                  style={{ border: "none" }}
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end mt-4">
          <Col className="d-flex justify-content-end align">
            <button className="btn prsnl-dtl-btn ">
              <span className="canc-txt">Cancel</span>
            </button>
            <Button
              className="btn prsnl-dtl-btn sav-btn"
              onClick={() => ImageuploadHandler()}
            >
              <span className="sav-txt">Save</span>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PersonalDetails;
