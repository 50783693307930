import React, { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { END_POINT } from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import EditorRead from "../../components/EditorRead";

const PagesScreen = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    handler();
  }, []);

  const handler = async () => {
    try {
      const url = window.location.pathname.split("/")[1];
      const result = await fetchData(END_POINT.GetDetailByTypeData + url);

      console.log(END_POINT.GetDetailByTypeData + url);
      if (result.success == true) {
        setData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}

      {/* About Us Content */}
      <div className="container about-us-section">
        <h1>{data?.title}</h1>
        <p>
          {data?.description ? (
            <EditorRead description={data.description} />
          ) : (
            ""
          )}
        </p>
      </div>

      {/* Footer */}
      {/* <Footer /> */}
    </div>
  );
};

export default PagesScreen;
