import React, { useRef, useState } from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import Swal from "sweetalert2";
import { END_POINT, METHODS, BASE_URL_IMAGE } from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import { useLocation, useNavigate } from "react-router-dom";

const WeddingServiceForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const title = location?.state?.title;
  const [formData, setformData] = useState({
    fullName: "",
    emial: "",
    mobile: "",
    type: "service",
    category_type: title,
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handlerSubmit = async () => {
    try {
      var raw = JSON.stringify(formData);
      console.log("raw", raw);
      const result = await fetchData(
        END_POINT.CreateWeddingEnquiryForm,
        METHODS.POST,
        raw
      );
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}
      {/* Form */}
      <div class="container mt-5 mx-auto ">
        <h1 style={{ color: "#0A3874", fontWeight: "bold" }}>
          *{title}* service offer form
        </h1>
        <p className="mt-4">
          Focusing and providing the correct platforms is beacon of success
          story. Our list of Thailand many <br /> award-winning and globally
          accredited convention centres.
        </p>
        <div className="formcontainer d-flex justify-content-center align-items-center">
          <form>
            <div class="form-row row">
              <div class="col-md-5 sm-12 lg-5 m-2">
                <div className="form-element-each">
                  <span>Full name</span>
                  <div className="d-flex align-items-center">
                    <input
                      class="form-control form-element-style"
                      placeholder="Enter full name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-5 sm-12 lg-5 m-2">
                <div className="form-element-each">
                  <span>Mobile number</span>
                  <div className="d-flex align-items-center">
                    <input
                      class="form-control form-element-style"
                      placeholder="Enter number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-5 sm-12 lg-5 m-2">
                <div className="form-element-each">
                  <span>Email address</span>
                  <div className="d-flex align-items-center">
                    <input
                      class="form-control form-element-style"
                      placeholder="Enter email"
                      name="email"
                      value={formData.email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="formbtn-row row mb-4">
              <button
                class="btn sub-btn-form col-md-2 sm-10 lg-3 m-2"
                type="button"
                onClick={handlerSubmit}
              >
                Submit
              </button>
              <button class="btn canc-btn-form col-md-2 sm-10 lg-3 m-2">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Footer */}
      {/* <Footer /> */}
    </div>
  );
};

export default WeddingServiceForm;
