import React, { useRef, useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import useDeviceType from "./useDeviceType";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import {
  BASE_URL_IMAGE,
  END_POINT,
  METHODS,
  TabBarLabels,
} from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import Select from "react-select";

import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import ActivitySelector from "../../components/ActivitySelector";
import PartnersCarousel from "../../components/PartnersCarousel";
import Testimonial from "../../components/Testimonial";
import Subscribe from "../../components/Subscribe";
import EditorRead from "../../components/EditorRead";
import ActivityLoader from "../../components/ActivityLoader";
import { homedataUpdate } from "../../redux/reducers/homeReducer";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";

export const HomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { HomeBanner, SectionSecond, RecomendedBlog, SectionForth, loading } =
    useSelector((store) => store.homeReducer);

  const [showModal, setShowModal] = useState(true);
  const isMobile = useDeviceType();


  const [selectedtab, setselectedtab] = useState(TabBarLabels.Hotel);
  const [selectedcity, setselectedcity] = useState("");
  const [city, setHotelCityData] = useState([]);
  const [room, setroom] = useState(1);

  const [defaultValuecheckin, setdefaultValuecheckin] = useState("");
  const [defaultValuecheckout, setdefaultValuecheckout] = useState("");

  const today = new Date();
  const date = today.setDate(today.getDate());
  // const defaultValuecheckin = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd
  const nextdate = today.setDate(today.getDate() + 1);
  // const defaultValuecheckout = new Date(nextdate).toISOString().split("T")[0]; // yyyy-mm-dd

  const [navTitle, setnavTitle] = useState();

  const ImageModal = ({ show, onClose, imageSrc }) => {
    if (!show) return null;

    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <button className="modal-close-button" onClick={onClose}>X</button>
          <img src={imageSrc} alt="Popup" className="modal-image" />
        </div>
      </div>
    );
  };


  useEffect(() => {
    setdefaultValuecheckin(new Date(date).toISOString().split("T")[0]);
    setdefaultValuecheckout(new Date(nextdate).toISOString().split("T")[0]);
    // cityHandler();
    homeHandler();
    setShowModal(true);

  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const data =
      HomeBanner != "" &&
      HomeBanner.find((item) => {
        return item.type == selectedtab;
      });
    setnavTitle(data);
    cityHandler();
  }, [selectedtab]);
  const cityHandler = async () => {
    try {
      const finalEndpoint =
        selectedtab == TabBarLabels.Hotel
          ? END_POINT.GetHotelCity
          : selectedtab == TabBarLabels.Dinning
            ? END_POINT.GetDiningCity
            : selectedtab == TabBarLabels.Activities
              ? END_POINT.GetActivityCity
              : selectedtab == TabBarLabels.Vehicles
                ? END_POINT.GetVehiclesCity
                : selectedtab == TabBarLabels.Packages
                  ? END_POINT.GetPackageCity
                  : null;
      const result = await fetchData(finalEndpoint);
      console.log(selectedtab);
      if (result.success == true) {
        setHotelCityData(result.data);
        setselectedcity(result.data[0].city_name);

        setdefaultValuecheckin(new Date(date).toISOString().split("T")[0]);
        setdefaultValuecheckout(new Date(nextdate).toISOString().split("T")[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const homeHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetWebHomeAllData);
      console.log(result);
      if (result.success == true) {
        dispatch(homedataUpdate(result.data));

        // sethomeData({
        //   HomeBanner: result.data.HomeBanner,
        //   SectionSecond: result.data.SectionSecond,
        //   RecomendedBlog: result.data.RecomendedBlog,
        //   SectionForth: result.data.SectionForth,
        // });
        const data = result.data.HomeBanner.find((item) => {
          return item.type == TabBarLabels.Hotel;
        });
        setnavTitle(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckinChange = (e) => {
    const selectedCheckinDate = new Date(e.target.value);
    const nextDay = new Date(selectedCheckinDate);
    nextDay.setDate(selectedCheckinDate.getDate() + 1);
    const formattedNextDay = nextDay.toISOString().split("T")[0];
    setdefaultValuecheckin(e.target.value);
    setdefaultValuecheckout(formattedNextDay);
  };

  const minCheckinDate = new Date().toISOString().split("T")[0];
  const ButtonDiv = ({ title, img, imgYellow }) => {
    return (
      <li class="nav-item bord-btm-yellow">
        <a
          class="nav-link tab-item bord-btm-yellow"
          type="button"
          onClick={() => {
            setselectedtab(title);
          }}
        >
          {selectedtab !== title ? (
            <img src={img} className="img-fluid tab-item-img" id="tab1-img" />
          ) : (
            <img
              src={imgYellow}
              className="img-fluid tab-item-img"
              id="tab1-img"
            />
          )}
          {title}
        </a>
      </li>
    );
  };
  return (
    <div>
      <ActivityLoader showModal={loading} />

      <ImageModal
        show={showModal}
        onClose={handleCloseModal}
        imageSrc={isMobile ? '/assets/img/mobile.jpg' : '/assets/img/desktop.jpg'}
      />

      {/* Navbar */}
      {/* <Header /> */}
      {/* <ActivityLoader showModal={true} /> */}
      {/* Navbar phone and tablet bottom */}
      {/* <nav class="sticky-bottom-navbar">
        <a class="nav-link mob-nav-item " href="#">
          <i
            class="fa-solid fa-baht-sign fa-lg"
            style={{ color: "#ffcc00", padding: 5 }}
          ></i>
          THB
        </a>

        <a class="nav-link mob-nav-item" href="#">
          <i
            class="fa-solid fa-bookmark fa-1x"
            style={{ color: "#ffcc00", padding: 5 }}
          ></i>
          Saved
        </a>

        <a class="nav-link mob-nav-item" href="#">
          <img
            src="/assets/img/ChatsCircle.svg"
            style={{ padding: 5 }}
            // width="50"
            // height="50"
          />
          Support
        </a>

        <a class="nav-link mob-nav-item " href="#">
          <i
            class="fa-regular fa-user"
            style={{ color: "#ffcc00", fontSize: 20, padding: 5 }}
          ></i>
          Sign Up
        </a>
      </nav> */}
      {/* Banner 1 */}
      <div
        class="booking-banner"
        style={{
          backgroundImage: `url(${BASE_URL_IMAGE + navTitle?.image})`,
        }}
      >
        <div class="overlay-banner"></div>
        <div className="container homebanner-cont">
          <div
            class="row "
            style={{
              position: "relative",
              zIndex: "999",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              class="col-lg-6 md-12 sm-12 align-items-center d-flex p-0 mobile-banner-title-col"
            // style={{
            //   backgroundImage: `url(${BASE_URL_IMAGE + navTitle?.image})`,
            // }}
            >
              <p class="booking-banner-title">
                {/* Explore Indian friendly <span>hotel</span> in <br />
              amazing Thailand */}
                {navTitle?.title}
              </p>
            </div>

            <ActivitySelector
              selectedtab={selectedtab}
              onChange={(text) => {
                setselectedtab(text);
                setdefaultValuecheckin();
                setdefaultValuecheckout();
              }}
              cityData={city}
              selectedcity={selectedcity}
              onChangeselectedcity={(text) => {
                setselectedcity(text);
              }}
              defaultValuecheckin={defaultValuecheckin}
              minCheckinDate={minCheckinDate}
              handleCheckinChange={handleCheckinChange}
              defaultValuecheckout={defaultValuecheckout}
              minCheckoutDate={defaultValuecheckin}
              onChangeCheckoutDate={(text) => {
                setdefaultValuecheckout(text);
              }}
              room={room}
              onChangeroom={(text) => {
                setroom(text);
              }}
              submitButton={() => {
                navigate(
                  `/booking/` +
                  selectedcity.trim() +
                  "/" +
                  defaultValuecheckin +
                  "/" +
                  defaultValuecheckout +
                  "/" +
                  room,
                  {
                    state: {
                      tabBar: selectedtab,
                    },
                  }
                );
              }}
            />
          </div>
        </div>
      </div>
      {/* Welcome Section */}
      <div className="container welcome-section">
        <h2>Welcome to Bharat Choice World</h2>
        <p className="intro">
          Introducing a Globally Recognized Initiative: Promoting Exclusively
          Indian-Friendly <br /> Hospitality Establishments Catering to
          Stringent Dietary Requirements Abroad
        </p>
        <Row className="">
          {SectionSecond != "" &&
            SectionSecond.map((item) => {
              return (
                <Col lg={4} md={6} sm={12} className="welcomecards">
                  <div className="d-flex sm-justify-content-center">
                    <Link
                      to={
                        `/booking/` +
                        selectedcity.trim() +
                        "/" +
                        defaultValuecheckin +
                        "/" +
                        defaultValuecheckout +
                        "/" +
                        room
                      }
                      state={{
                        tabBar: item.type,
                      }}
                    >
                      <div className="welcome-card-img-cont">
                        <img
                          // src="/assets/img/Rectangle1.png"
                          src={BASE_URL_IMAGE + item.image}
                          className="img-fluid"
                        />
                      </div>

                      <h5 style={{ maxWidth: "260px", overflow: "hidden" }}>
                        {item.title.trim()}
                      </h5>
                      <p style={{ maxWidth: "280px", overflow: "hidden" }}>
                        {item.description.trim()}
                      </p>
                    </Link>
                  </div>
                </Col>
              );
            })}
          {/* <div className="col-lg-4 md-6 sm-12 welcomecards">
            <Link
              to={
                `/booking/` +
                selectedcity.trim() +
                "/" +
                defaultValuecheckin +
                "/" +
                defaultValuecheckout +
                "/" +
                room
              }
              state={{
                tabBar: TabBarLabels.Hotel,
              }}
            >
              <div className="welcome-card-img-cont">
                <img src="/assets/img/Rectangle1.png" className="img-fluid" />
              </div>

              <h5>Bharatchoice hotel</h5>
              <p>
                Unparalleled Hospitality in Thailand for Indian-Friendly Hotel
                with Best Location, Rooms, Rates & Dining Options
              </p>
            </Link>
          </div>
          <div className="col-lg-4 md-6 sm-12 welcomecards">
            <Link
              to={
                `/booking/` +
                selectedcity.trim() +
                "/" +
                defaultValuecheckin +
                "/" +
                defaultValuecheckout +
                "/" +
                room
              }
              state={{
                tabBar: TabBarLabels.Dinning,
              }}
            >
              <div className="welcome-card-img-cont">
                <img src="/assets/img/Rectangle2.png" className="img-fluid" />
              </div>

              <h5>Best fine dining</h5>
              <p>
                Exquisite Dining with Best Food, Buffet & Fine Dining with
                Vegetarian, Vegan & Jain Options in Thailand
              </p>
            </Link>
          </div>
          <div className="col-lg-4 md-6 sm-12 welcomecards">
            <Link
              to={
                `/booking/` +
                selectedcity.trim() +
                "/" +
                defaultValuecheckin +
                "/" +
                defaultValuecheckout +
                "/" +
                room
              }
              state={{
                tabBar: TabBarLabels.Activities,
              }}
            >
              <div className="welcome-card-img-cont">
                <img src="/assets/img/Rectangle3.png" className="img-fluid" />
              </div>

              <h5>Must do things</h5>
              <p>
                Unforgettable experiences in Thailand that include exploring
                landmarks and engaging in must-see activities
              </p>
            </Link>
          </div>
          <div className="col-lg-4 md-6 sm-12 welcomecards">
            <Link
              to={
                `/booking/` +
                selectedcity.trim() +
                "/" +
                defaultValuecheckin +
                "/" +
                defaultValuecheckout +
                "/" +
                room
              }
              state={{
                tabBar: TabBarLabels.Vehicles,
              }}
            >
              <div className="welcome-card-img-cont">
                <img src="/assets/img/Rectangle4.png" className="img-fluid" />
              </div>

              <h5>V.I.P Limo</h5>
              <p>
                Luxury Car Services in Thailand: Airport, City, and <br /> Land
                Transfers with Professional Chauffeurs
              </p>
            </Link>
          </div>
          <div className="col-lg-4 md-6 sm-12 welcomecards">
            <Link
              to={
                `/booking/` +
                selectedcity.trim() +
                "/" +
                defaultValuecheckin +
                "/" +
                defaultValuecheckout +
                "/" +
                room
              }
              state={{
                tabBar: TabBarLabels.Packages,
              }}
            >
              <div className="welcome-card-img-cont">
                <img src="/assets/img/Rectangle5.png" className="img-fluid" />
              </div>

              <h5>Best selling packages</h5>
              <p>
                Discover Thailand's Best Seller Holiday Packages & Exciting
                Tours
              </p>
            </Link>
          </div> */}
        </Row>
      </div>
      {/* Blogs section */}
      <div className="blogs-wrapper">
        <div className="container blogs-section">
          <div className="blog-text">
            <h2>The world of Indian preferred hospitality</h2>
            <p className="blog-intro">
              Comprehensive Travel Insights Await: Embark on a Captivating
              Journey through <br /> Thailand with Our Expertly Crafted Travel
              Blogs, Unveiling Must-Visit Destinations <br /> for Your
              Once-in-a-Lifetime Experience.
            </p>
          </div>
          <div className="row d-flex justify-content-start align-items-center specialrow">
            {RecomendedBlog != "" &&
              RecomendedBlog.map((item) => {
                return (
                  <div className="col-lg-4 md-4 sm-12 events-cards">
                    <Link to={item.type}>
                      <div class="img-container-box">
                        <img
                          // src="/assets/img/destination-wed.jpeg"
                          src={BASE_URL_IMAGE + item.image}
                          class="img-fluid"
                        />
                        {/* <div></div> */}
                        <div class="img-container-box-text">
                          <h3>
                            <a style={{ color: "white" }} href="">
                              {item.title}
                            </a>
                          </h3>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}

            {/* <div className="col-lg-3 md-3 sm-12 events-cards">
              <Link to={"wedding"}>
                <div class="img-container-box">
                  <div>
                    <img
                      src="/assets/img/destination-wed.jpeg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="img-container-box-text">
                    <h3>
                      <a style={{ color: "white" }} href="">
                        Destination Wedding
                      </a>
                    </h3>
                    <p>
                      Exquisite Indian Weddings in Thailand with Unforgettable
                      Moments and Luxury Services
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 md-3 sm-12 events-cards">
              <Link to={"events"}>
                <div class="img-container-box">
                  <div>
                    <img src="/assets/img/mice-events.jpeg" class="img-fluid" />
                  </div>

                  <div class="img-container-box-text">
                    <h3>
                      <a style={{ color: "white" }} href="">
                        Local Events
                      </a>
                    </h3>
                    <p>
                      Exciting Events and Festivals in Thailand: Concerts,
                      Parties, and Bollywood Nights
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 md-3 sm-12 events-cards">
              <Link to={"mice-events"}>
                <div class="img-container-box">
                  <div>
                    <img
                      src="/assets/img/special-events-1.jpeg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="img-container-box-text">
                    <h3>
                      <a style={{ color: "white" }} href="">
                        M.I.C.E Business
                      </a>
                    </h3>
                    <p>
                      Exceptional M.I.C.E Services in Thailand: Meetings,
                      Incentives & More
                    </p>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
          <div className="row"></div>
        </div>
      </div>

      {/* Special Events Section */}
      <div className="events-wrapper">
        <div className="container events-section">
          <div className="blog-text">
            <h2>Special events in Thailand</h2>
            <p className="events-intro">
              Unveiling Events in Thailand: Immerse Yourself in a World of
              Captivating <br /> Experiences Curated by Industry Experts to
              Delight and Astonish You.
            </p>
          </div>
          <div className="row d-flex justify-content-evenly align-items-center specialrow">
            {SectionForth != "" &&
              SectionForth.map((item) => {
                return (
                  <div className="col-lg-4 md-4 sm-12 events-cards">
                    <Link to={item.type}>
                      <div class="img-container-box">
                        <div>
                          <img
                            // src="/assets/img/destination-wed.jpeg"
                            src={BASE_URL_IMAGE + item.image}
                            class="img-fluid"
                          />
                        </div>
                        <div class="img-container-box-text">
                          <h3>
                            <a style={{ color: "white" }} href="">
                              {item.title}
                            </a>
                          </h3>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            {/* <div className="col-lg-3 md-3 sm-12 events-cards">
              <Link to={"wedding"}>
                <div class="img-container-box">
                  <div>
                    <img
                      src="/assets/img/destination-wed.jpeg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="img-container-box-text">
                    <h3>
                      <a style={{ color: "white" }} href="">
                        Destination Wedding
                      </a>
                    </h3>
                    <p>
                      Exquisite Indian Weddings in Thailand with Unforgettable
                      Moments and Luxury Services
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 md-3 sm-12 events-cards">
              <Link to={"events"}>
                <div class="img-container-box">
                  <div>
                    <img src="/assets/img/mice-events.jpeg" class="img-fluid" />
                  </div>

                  <div class="img-container-box-text">
                    <h3>
                      <a style={{ color: "white" }} href="">
                        Local Events
                      </a>
                    </h3>
                    <p>
                      Exciting Events and Festivals in Thailand: Concerts,
                      Parties, and Bollywood Nights
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 md-3 sm-12 events-cards">
              <Link to={"mice-events"}>
                <div class="img-container-box">
                  <div>
                    <img
                      src="/assets/img/special-events-1.jpeg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="img-container-box-text">
                    <h3>
                      <a style={{ color: "white" }} href="">
                        M.I.C.E Business
                      </a>
                    </h3>
                    <p>
                      Exceptional M.I.C.E Services in Thailand: Meetings,
                      Incentives & More
                    </p>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      {/* Experiences Section */}
      <Testimonial type={"Home"} />
      {/* PartnerShip Section */}
      <PartnersCarousel />
      {/* CTA Section */}
      <Subscribe
        title={"Stay Connected: Get Exclusive Updates with Our Newsletter"}
      />
      {/* Footer */}
      {/* <Footer /> */}
    </div>
  );
};
