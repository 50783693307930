import React from "react";
import { Button, Row, Col, Container, Form, Modal } from "react-bootstrap";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  BASE_URL_IMAGE,
  END_POINT,
  METHODS,
  NumberSeprator,
  TabBarLabels,
  numberToEnglish,
} from "../Helper/helper";
function BottomPriceStripe({
  dateFrom,
  dateTo,
  noofGuest,
  totalAmount,
  ButtonPress,
}) {
  const { country, currency } = useSelector((store) => store.currencyReducer);
  //   console.log(new Date(dateFrom).toDateString().split(" "));
  const date_from = new Date(dateFrom).toDateString().split(" ");
  const date_to =
    dateTo != undefined ? new Date(dateTo).toDateString().split(" ") : "";

  const message = "Welcome to BharatChoice";
  const phoneNumber = "+66810908071";
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <nav class="sticky-bottom-navbar2">
      <Row className="d-flex justify-content-between">
        <Col sm={10}>
          <span className="bottom-book-details">
            {date_from[1] + " " + date_from[2]}
            {date_to != ""
              ? date_to[1] == date_from[1]
                ? -date_to[2]
                : -date_to[1] + " " + date_to[2]
              : ""}
            , {noofGuest + " Guest"}
            {/* July 9-16, {noofGuest + "Guest"} */}
          </span>
        </Col>
        <Col sm={1} className=""></Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-between">
        <Col>
          <div>
            <span
              style={{
                color: "#383838",
                fontSize: "24px",
                fontWeight: "600",
              }}
            >
              {NumberSeprator(Math.floor(totalAmount * currency[country]))}{" "}
            </span>
            &nbsp;
            <span
              style={{
                color: "#383838",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {country}
            </span>
          </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <button type="button" onClick={ButtonPress} class="btn sub-btn-form">
            Book now
          </button>

          {/* <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <Button
              type="button"
              className="sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
            >
              <b> Whatsapp Us </b>
            </Button>
          </a> */}
        </Col>
      </Row>
    </nav>
  );
}

export default BottomPriceStripe;
