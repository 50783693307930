import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { END_POINT } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import Swal from "sweetalert2";

import "../../dashscreen/DashCss.css";

import { useSelector, useDispatch } from "react-redux";
import TableHead from "../../components/TableHead";

const HotelListingScreen = () => {
  const [hotel, setHotelData] = useState([]);

  useEffect(() => {
    hotelListHandler();
  }, []);

  const hotelListHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetHotelList);
      if (result.success == true) {
        setHotelData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const hoteldeleteHandler = async (id) => {
    try {
      const result = await fetchData(END_POINT.DeleteHotels + id);
      if (result.success == true) {
        hotelListHandler(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Link style={{ textDecoration: "none" }} to={"../add-hotel-listing"}>
        <button
          className="btn btn-primary mt-4"
        // onClick={() => {
        //   Navigate("add-hotel-listing");
        // }}
        >
          Add Hotel&nbsp;<i class="fa-solid fa-plus"></i>
        </button>
      </Link>

      <div className="mt-4">
        <div
          id="example_wrapper"
          class="dataTables_wrapper no-footer table-overflow"
        >
          <div id="example_filter" class="dataTables_filter mb-4">
            <label>
              Search:
              <input
                type="search"
                class=""
                placeholder=""
                aria-controls="example"
              />
            </label>
          </div>
          <table
            id="example"
            class="table table-striped table-bordered dataTable no-footer"
            style={{ width: "100%", whiteSpace: "nowrap" }}
            role="grid"
            aria-describedby="example_info"
          >
            <thead>
              <tr role="row">
                {[
                  "S.N",
                  "Name",
                  "Code",
                  "Type / Catergory",
                  "City",
                  "Promo",
                  "Share",
                  "Edit Hotel",
                  "Edit Room",
                  "Edit Date",
                  "Summary",
                ].map((item) => {
                  return <TableHead title={item} />;
                })}
              </tr>
            </thead>

            <tbody>
              {hotel.length > 0 ? (
                hotel.map((item, index) => (
                  <tr role="row" class="odd" key={index}>
                    <td class="sorting_1"> {index + 1} </td>
                    <td> {item.name} </td>
                    <td>Hhba41</td>
                    <td> {item.type} </td>
                    <td> {item.city} </td>
                    <td> {item.promoType} </td>
                    <td>
                      <Link
                        to={"/hotels/" + item._id}
                        class="btn btn-success"
                      >
                        <i class="fa fa-share"></i>Share
                      </Link>
                    </td>
                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../add-hotel-listing",
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{ id: item._id }}
                      >
                        <i class="fa fa-edit"></i>
                      </Link>

                      <a
                        // href="#"
                        type="button"
                        class="btn btn-danger m-1"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: `You won't to delete ${item.name}!`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              hoteldeleteHandler(item._id);

                              Swal.fire(
                                "Deleted!",
                                `${item.name} has been deleted.`,
                                "success"
                              );
                            }
                          });
                          //  handleDelete(item._id)
                        }}
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>
                    <td>
                      <Link
                        to={"../room-type/" + item._id + "/" + item.name}
                        class="btn btn-success"
                      >
                        <i class="fa fa-plus"></i>Room Type
                      </Link>
                    </td>
                    <td>{item.updatedAt.split("T")[0]}</td>
                    <td>
                      <Link
                        to={"../hotel-summary/" + item._id}
                        class="btn btn-info"
                      >
                        <i class="fa fa-eye"></i>View Summary
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <p>Hotel List Not Available</p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HotelListingScreen;
