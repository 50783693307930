import React, { useState, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios'; // Import axios

const AddEditAddress = () => {
    const [address, setAddress] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const ref = useRef();

    // Function to fetch coordinates based on address
    const fetchCoordinates = async (address) => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json`,
                {
                    params: {
                        address: address,
                        key: 'YOUR_GOOGLE_MAPS_API_KEY', // Replace with your API key
                    },
                }
            );
            if (response.data.results.length > 0) {
                const location = response.data.results[0].geometry.location;
                setLatitude(location.lat);
                setLongitude(location.lng);
            }
        } catch (error) {
            console.error('Error fetching coordinates:', error);
        }
    };

    return (
        <div className="col-lg-6 md-6 sm-12">
            <div>
                <span className="label-style">Address:</span> <br />
                <Autocomplete
                    apiKey="YOUR_GOOGLE_MAPS_API_KEY" // Replace with your API key
                    ref={ref}
                    name="address"
                    value={address}
                    onPlaceSelected={(place) => {
                        if (place && place.geometry) {
                            console.log('Place selected:', place); // Debugging log
                            setAddress(place.formatted_address);
                            setLatitude(place.geometry.location.lat());
                            setLongitude(place.geometry.location.lng());
                        } else {
                            console.error('Place selection error or no geometry data');
                        }
                    }}
                    className="input-fields-style"
                    options={{
                        types: [], // Allow all types to be more flexible
                    }}
                    onChange={(e) => {
                        const newAddress = e.target.value;
                        setAddress(newAddress);
                        fetchCoordinates(newAddress); // Fetch lat/lng when typing
                    }}
                />
                <div>
                    <p>Latitude: {latitude}</p>
                    <p>Longitude: {longitude}</p>
                </div>
            </div>
        </div>
    );
};

export default AddEditAddress;
