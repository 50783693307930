import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { END_POINT, BASE_URL_IMAGE } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import Swal from "sweetalert2";

import "../../dashscreen/DashCss.css";

import { useSelector, useDispatch } from "react-redux";
import TableHead from "../../components/TableHead";

const MiceList = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    handler();
  }, []);

  const handler = async () => {
    try {
      const result = await fetchData(END_POINT.GetMice);

      if (result.success == true) {
        setData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const deleteHandler = async (id) => {
    try {
      const result = await fetchData(END_POINT.DeleteMice + id);
      if (result.success == true) {
        handler(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Link style={{ textDecoration: "none" }} to={"../add-mice"}>
        <button
          className="btn btn-primary mt-4"
          // onClick={() => {
          //   Navigate("add-hotel-listing");
          // }}
        >
          Add Mice&nbsp;<i class="fa-solid fa-plus"></i>
        </button>
      </Link>

      <div className="mt-4">
        <div
          id="example_wrapper"
          class="dataTables_wrapper no-footer table-overflow"
        >
          <div id="example_filter" class="dataTables_filter mb-4">
            <label>
              Search:
              <input
                type="search"
                class=""
                placeholder=""
                aria-controls="example"
              />
            </label>
          </div>
          <table
            id="example"
            class="table table-striped table-bordered dataTable no-footer"
            style={{ width: "100%", whiteSpace: "nowrap" }}
            role="grid"
            aria-describedby="example_info"
          >
            <thead>
              <tr role="row">
                {[
                  "S.N",
                  "Banner",
                  "Title",
                  // "Galore Title",
                  // "Description",
                  //   "Wedding Title",
                  //   "Description",
                  //   "Inspiration Title",
                  //   "Description",
                  //   "Venue Title",
                  //   "Description",
                  "Action",
                ].map((item) => {
                  return <TableHead title={item} />;
                })}
              </tr>
            </thead>

            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr role="row" class="odd" key={index}>
                    <td class="sorting_1"> {index + 1} </td>
                    <td>
                      <img
                        src={BASE_URL_IMAGE + item.main_banner}
                        style={{ width: 100, height: 100 }}
                      />
                    </td>
                    <td> {item?.title} </td>
                    {/* <td> {item?.galore_title} </td> */}
                    {/* <td> {item?.desc} </td> */}
                    {/* <td> {item?.wedding_title} </td> */}
                    {/* <td> {item?.wedding_desc} </td> */}
                    {/* <td> {item?.inspiration_title} </td> */}
                    {/* <td> {item?.inspiration_desc} </td> */}
                    {/* <td> {item?.venue_title} </td> */}
                    {/* <td> {item?.venue_desc} </td> */}
                    {/* <td> {item?.service_offer} </td> */}
                    {/* <td> {item?.request_url} </td> */}

                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../add-mice",
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{ id: item._id }}
                      >
                        <i class="fa fa-edit"></i>
                      </Link>

                      <a
                        // href="#"
                        type="button"
                        class="btn btn-danger m-1"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: `You won't to delete ${item.name}!`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteHandler(item._id);

                              Swal.fire(
                                "Deleted!",
                                `${item.name} has been deleted.`,
                                "success"
                              );
                            }
                          });
                          //  handleDelete(item._id)
                        }}
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>

                    {/* <td>{item.updatedAt}</td> */}
                  </tr>
                ))
              ) : (
                <p>Dining List Not Available</p>
              )}
            </tbody>
          </table>
          <div
            class="dataTables_info"
            id="example_info"
            role="status"
            aria-live="polite"
          >
            Showing 1 to 6 of 6 entries
          </div>
          <div
            class="dataTables_paginate paging_simple_numbers"
            id="example_paginate"
          >
            <a
              class="paginate_button previous disabled"
              aria-controls="example"
              data-dt-idx="0"
              tabindex="0"
              id="example_previous"
            >
              Previous
            </a>
            <span>
              <a
                class="paginate_button current"
                aria-controls="example"
                data-dt-idx="1"
                tabindex="0"
              >
                1
              </a>
            </span>
            <a
              class="paginate_button next disabled"
              aria-controls="example"
              data-dt-idx="2"
              tabindex="0"
              id="example_next"
            >
              Next
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiceList;
