import React from "react";
import { useSwiper } from "swiper/react";

export const SwiperNavYellow = () => {
  const swiper = useSwiper();

  return (
    <div className="swiper-nav-btns">
      <button className="btn" onClick={() => swiper.slidePrev()}>
        <img src="/assets/img/arrbrwnlt.svg" className="img-fluid mb-2" />
      </button>
      <button className="btn" onClick={() => swiper.slideNext()}>
        <img src="/assets/img/arrbrwnrt.svg" className="img-fluid mb-2" />
      </button>
    </div>
  );
};
