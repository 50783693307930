import React, { useEffect, useState } from "react";
import {
  END_POINT,
  BASE_URL_IMAGE,
  METHODS,
  NumberSeprator,
  numberToEnglish,
  TabBarLabels,
} from "../../Helper/helper";
import { Header } from "../../components/Header";
import { Button, Col, Container, Row, Accordion, Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchData } from "../../Helper/ApiCall";
import Swal from "sweetalert2";
import ChekoutCreditCard from "../../components/checkout/checkoutForm/omise-prebuilt-form/CheckoutCreditCard";
import CheckoutInternetBanking from "../../components/checkout/checkoutForm/omise-prebuilt-form/CheckoutInternetBanking";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../../components/LoginModal";
import CheckoutQRCode from "../../components/checkout/checkoutForm/omise-prebuilt-form/CheckoutQRCode";
import ActivityLoader from "../../components/ActivityLoader";
const BookNowScreen = () => {
  const { id } = useParams();
  const { country, currency } = useSelector((store) => store.currencyReducer);
  const userData = useSelector((store) => store.userReducer.userData);
  console.log(userData, "userData");
  const navigate = useNavigate();
  let location = useLocation();
  const { data, totalAmount, dates, guest, customerMeals, selectedtab } =
    location?.state;
  const [hoteldata, setHotelData] = useState();
  const [roomAndStockIds, setRoomAndStockIds] = useState([]);
  const [LoginButtonVisible, setLoginButtonVisible] = useState(false);
  const [SignUpButtonVisible, setSignUpButtonVisible] = useState(false);
  const [vatcharge, setvatcharge] = useState(0);
  const [servicecharge, setservicecharge] = useState(0);
  const [finaltotal, setfinaltotal] = useState(0);
  const [checkradio, setcheckradio] = useState("credit/debit");
  const [isLoading, setisLoading] = useState(false);

  let totalExtraBeds = 0;
  let totalRooms = 0;

  if (data) {
    data.forEach((item) => {
      totalExtraBeds += item.extrbed;
      totalRooms += item.room;
    });
  }

  let childNumber = 0;
  let adultNumber = 0;

  if (data) {
    data.forEach((item) => {
      adultNumber += item.adultNumber;
      childNumber += item.childNumber;
    });
  }
  const [showModal, setShowModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedPaymentMethod(null);
  };

  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
    closeModal();
  };
  useEffect(() => {
    const vatchargeAmount = (totalAmount * 7) / 100;
    const servicechargeAmount = (totalAmount * 10) / 100;

    setvatcharge(vatchargeAmount);
    setservicecharge(servicechargeAmount);
    setfinaltotal(totalAmount + vatchargeAmount + servicechargeAmount);

    hoteldetailbyidHandler();
  }, []);
  const hoteldetailbyidHandler = async () => {
    try {
      const finalEndpoint =
        selectedtab == TabBarLabels.Hotel
          ? END_POINT.GetHotelByid
          : selectedtab == TabBarLabels.Dinning
          ? END_POINT.GetDiningByid
          : selectedtab == TabBarLabels.Activities
          ? END_POINT.GetActivityByid
          : selectedtab == TabBarLabels.Vehicles
          ? END_POINT.GetVehiclesByid
          : selectedtab == TabBarLabels.Packages
          ? END_POINT.GetPackageByid
          : null;

      const result = await fetchData(finalEndpoint + id);

      if (result.success == true) {
        setHotelData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (token) => {
    var raw = JSON.stringify({
      type: selectedtab,
      checkin: dates[0],
      checkout: dates[dates.length - 1],
      noofGuest: guest,
      hotel_id: id,
      user_id: userData._id,
      user_email: userData.email,
      amount: Math.floor(totalAmount),
      room_detail: data,
      totalExtraBeds: totalExtraBeds,
      totalRooms: totalRooms,
      dates: dates,
      order_id: token,
      mealDetail: customerMeals,

      //dinning special

      childNumber: childNumber,
      adultNumber: adultNumber,
    });

    try {
      const finalEndpoint =
        selectedtab == TabBarLabels.Hotel
          ? END_POINT.HotelBooking
          : selectedtab == TabBarLabels.Dinning
          ? END_POINT.DinningPostBooking
          : selectedtab == TabBarLabels.Activities
          ? END_POINT.PostActivityBooking
          : selectedtab == TabBarLabels.Vehicles
          ? END_POINT.PostVehiclesBooking
          : selectedtab == TabBarLabels.Packages
          ? END_POINT.PostPackagesBooking
          : null;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      setisLoading(false);
      if (result.success == true) {
        navigate("/thank-you");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createCreditCardCharge = async (token) => {
    try {
      setisLoading(true);
      const raw = JSON.stringify({
        email: userData.email,
        name: userData.fullName,
        amount: totalAmount * 100,
        token: token,
      });

      const res = await fetchData(
        END_POINT.omiseCheckoutCreditCard,
        METHODS.POST,
        raw
      );
      console.log(res);

      if (res.success == true && res.data) {
        setShowModal(false);
        handleSubmit(token);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      console.log(err);
      setisLoading(false);
    }
  };

  const createInternetBankingCharge = async (token) => {
    try {
      const raw = JSON.stringify({
        email: "akashtomar@quloe.com",
        name: "Akash",
        amount: totalAmount,
        token: token,
      });

      const res = await fetchData(
        END_POINT.omiseCheckoutInternetBanking,
        METHODS.POST,
        raw
      );
      console.log(res);
      if (res.success == true && res.authorize_uri != undefined) {
        // setShowModal(false);
        handleSubmit(token);
        // window.location.href = res.authorize_uri;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Header
      // LoginButtonVisible={LoginButtonVisible}
      // SignUpButtonVisible={SignUpButtonVisible}
      />
      <ActivityLoader showModal={isLoading} />
      <LoginModal
        LoginButtonVisible={LoginButtonVisible}
        LoginButton={() => {
          setLoginButtonVisible(false);
        }}
      />
      <Container className="mt-4">
        <Row className="d-flex justify-content-between">
          <Col md={6} lg={6} sm={12}>
            <Row>
              <Col md={2} lg={2}>
                <img
                  // src="/assets/img/book-now-preview-img.svg"
                  src={BASE_URL_IMAGE + hoteldata?.images}
                  alt="Logo"
                  className="img-fluid"
                />
              </Col>

              <Col md={10} lg={10}>
                <h3>
                  <b style={{ textTransform: "capitalize" }}>
                    {hoteldata?.name}, {hoteldata?.city}
                  </b>
                </h3>
                <p>{hoteldata?.address}</p>
              </Col>
            </Row>

            <h4 className="my-4">
              <b>Your trip summary</b>
            </h4>
            <Row className="d-flex justify-content-between">
              <Col md={3} lg={3}>
                <h5>
                  <b>Dates</b>
                </h5>
                {/* <p>Aug 5-6, 2023</p> */}
                {selectedtab == TabBarLabels.Hotel ? (
                  <p>{dates[0] + " to " + dates[dates.length - 1]}</p>
                ) : (
                  <p>{dates[0]}</p>
                )}
              </Col>
              <Col md={1} lg={1}>
                {/* <Button
                  className=""
                  style={{
                    color: "#FFCC00",
                    textDecoration: "underline",
                    background: "none",
                    border: "none",
                  }}
                >
                  Edit
                </Button> */}
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              {selectedtab == TabBarLabels.Hotel ? (
                <Col md={3} lg={3}>
                  <h5>
                    <b>Guest(s)</b>
                  </h5>
                  <p>{guest}</p>
                </Col>
              ) : (
                <>
                  <Col md={3} lg={3}>
                    <h5>
                      <b>Guest(s)</b>
                    </h5>
                  </Col>
                  <Row className="d-flex justify-content-between py-2">
                    <Col md={3} lg={3}>
                      <p>Adult</p>
                    </Col>
                    <Col md={3} lg={3}>
                      <p>{adultNumber}</p>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between py-2">
                    <Col md={3} lg={3}>
                      <p>Child</p>
                    </Col>
                    <Col md={3} lg={3}>
                      <p>{childNumber}</p>
                    </Col>
                  </Row>
                </>
              )}
            </Row>
            {/* <h3 className="my-4">
              <b>Pay with</b>
            </h3> */}
            {/* <Accordion>
              <Accordion.Item
                eventKey="0"
                style={{
                  borderBottom: "1px solid #ccc",
                  borderTop: "none", //
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <Accordion.Header>Credit/Debit card</Accordion.Header>
                <Accordion.Body>
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col lg={10} md={10}>
                      <a href="#">
                        <img
                          src="/assets/img/visa-sm-icon.svg"
                          alt="Logo"
                          className="img-fluid m-2 "
                        />
                      </a>
                      <a href="#">
                        <img
                          src="/assets/img/mastercard-sm-icon.svg"
                          alt="Logo"
                          className="img-fluid m-2"
                        />
                      </a>

                      <a href="#">
                        <img
                          src="/assets/img/jcb-sm-icon.svg"
                          alt="Logo"
                          className="img-fluid m-2"
                        />
                      </a>

                      <a href="#">
                        <img
                          src="/assets/img/american-sm-icon.svg"
                          alt="Logo"
                          className="img-fluid m-2"
                        />
                      </a>
                    </Col>
                    <Col lg={1} md={1}>
                      <input
                        type="radio"
                        style={{ color: "#FFCC00" }}
                        checked={checkradio == "credit/debit" ? true : false}
                        onChange={(e) => {
                          setcheckradio("credit/debit");
                        }}
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="1"
                style={{
                  borderBottom: "1px solid #ccc",
                  borderTop: "none", //
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <Accordion.Header>QR Code</Accordion.Header>
                <Accordion.Body>
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col lg={10} md={10}>
                      <a href="#">
                        <img
                          src="/assets/img/thai-sm-icon.svg"
                          alt="Logo"
                          className="img-fluid m-2 "
                        />
                      </a>
                      <span>QR Code</span>
                    </Col>
                    <Col lg={1} md={1}>
                      <input
                        type="radio"
                        style={{ color: "#FFCC00" }}
                        checked={checkradio == "qrcode" ? true : false}
                        onChange={(e) => {
                          setcheckradio("qrcode");
                        }}
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="2"
                style={{
                  borderBottom: "1px solid #ccc",
                  borderTop: "none", //
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <Accordion.Header>Google Pay</Accordion.Header>
                <Accordion.Body>
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col lg={10} md={10}>
                      <a href="#">
                        <img
                          src="/assets/img/gpay-sm-icon.svg"
                          alt="Logo"
                          className="img-fluid m-2 "
                        />
                      </a>
                      <span>Google pay</span>
                    </Col>
                    <Col lg={1} md={1}>
                      <input
                        type="radio"
                        style={{ color: "#FFCC00" }}
                        checked={checkradio == "googlepay" ? true : false}
                        onChange={(e) => {
                          setcheckradio("googlepay");
                        }}
                      />{" "}
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
          </Col>

          <Col md={5} lg={5} sm={12}>
            <div className="book-now-card">
              <h5 className="my-4">
                <b>Price details</b>
              </h5>
              {selectedtab == TabBarLabels.Hotel
                ? data.map((item, index) => {
                    return (
                      <Row className="d-flex justify-content-between mt-4">
                        <Col>
                          <h6>{item.name}</h6>
                          <span>{item.campaign_name}</span> <br />
                          {/* <span>5 Nights</span> <br /> */}
                          {item.extrbed > 0 && (
                            <span>{item.extrbed} Extra Bed added</span>
                          )}
                        </Col>
                        <Col
                          md={3}
                          lg={3}
                          style={{
                            alignSelf: "flex-end",
                            alignContent: "flex-end",
                          }}
                        >
                          <span>
                            {NumberSeprator(
                              Math.floor(
                                (parseInt(item.weekdaycost) *
                                  parseInt(item.room) +
                                  parseInt(item.extra_bedcost) *
                                    parseInt(item.extrbed)) *
                                  currency[country]
                              )
                            )}
                          </span>
                        </Col>
                      </Row>
                    );
                  })
                : data.map((item, index) => {
                    return (
                      <Row className="d-flex justify-content-between py-2">
                        <Col md={6} lg={6}>
                          <h6>{item.name}</h6>
                          {/* <p>
                      {item.room} nights
                      <br />
                      {item.extrbed > 0 && (
                        <span>{item.extrbed} Extra Bed added</span>
                      )}
                    </p> */}
                        </Col>
                        <Col md={3} lg={3}>
                          <p>
                            {NumberSeprator(
                              Math.floor(
                                adultNumber *
                                  item.adultcost *
                                  currency[country] +
                                  childNumber *
                                    item.childcost *
                                    currency[country]
                              )
                            )}
                          </p>
                        </Col>
                      </Row>
                    );
                  })}
              <Row className="d-flex justify-content-between py-2">
                <Col md={6} lg={6}>
                  <h5 className="mt-4">
                    <b>Price summary</b>
                  </h5>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between py-2">
                <Col>
                  <h5>Net Rate</h5>
                </Col>
                <Col md={3} lg={3}>
                  <span>
                    {NumberSeprator(
                      Math.floor(totalAmount * currency[country])
                    )}
                  </span>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between py-2">
                <Col>
                  <h5>7% Vat</h5>
                </Col>
                <Col md={3} lg={3}>
                  <span>
                    {NumberSeprator(Math.floor(vatcharge * currency[country]))}
                  </span>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between py-2">
                <Col>
                  <h5>10% Service Charge</h5>
                </Col>
                <Col md={3} lg={3}>
                  <span>
                    {NumberSeprator(
                      Math.floor(servicecharge * currency[country])
                    )}
                  </span>
                </Col>
              </Row>

              {/* <Row className="d-flex justify-content-between py-2">
                <Col md={6} lg={6}>
            
                  <div className="pay-now">
                    <h5>Price summary</h5>

                    <div className="amount-price">
                      <h4>
                        Net Room Rate
                        <span>฿ 7,562</span>
                      </h4>
                      <hr />

                      <h4>
                        7% Vat
                        <a
                          type="button"
                          className="btn btn-secondary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Tooltip on top"
                        >
                          <i className="fas fa-info-circle"></i>
                        </a>
                        <span>฿ 529</span>
                      </h4>
                      <hr />
                      <h4>
                        10% Service Charge
                        <a
                          type="button"
                          className="btn btn-secondary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Tooltip on top"
                        >
                          <i className="fas fa-info-circle"></i>
                        </a>
                        <span>฿ 809</span>
                      </h4>

                      <h4 className="wp-cus-price">
                        Total Amount to be paid
                        <span>฿ 8,900</span>
                      </h4>
                    </div>
                  </div>
                </Col>
              </Row> */}

              <Row
                className="d-flex justify-content-between mt-4 mb-4 align-items-center"
                style={{}}
              >
                <Col>
                  <h4>Total</h4>
                </Col>
                <Col md={3} lg={3}>
                  <h5>
                    <b>
                      {NumberSeprator(
                        Math.floor(totalAmount * currency[country])
                      )}{" "}
                      {country}
                    </b>
                  </h5>
                </Col>
              </Row>
              <span
                style={{
                  marginTop: "1rem",
                  display: "block",
                  textAlign: "center",

                  textTransform: "capitalize",
                }}
              >
                {numberToEnglish(Math.floor(totalAmount * currency[country]))}
              </span>
              <p
                style={{
                  color: "#797979",
                  fontSize: "small",
                  textAlign: "center",
                }}
              >
                By clicking “Pay”, I have read and agree to the Terms &
                Conditions
              </p>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  {/* <CheckoutInternetBanking
                    totalAmount={totalAmount}
                    createInternetBankingCharge={createInternetBankingCharge}
                  />
                  <ChekoutCreditCard
                    totalAmount={totalAmount}
                    createCreditCardCharge={createCreditCardCharge}
                  /> */}
                  {/* <Button onClick={handleSubmit} className="book-now-paybutton">
                    <b>Pay</b>
                  </Button> */}
                  {/* {checkradio == "credit/debit" ? (
                    <ChekoutCreditCard
                      totalAmount={totalAmount}
                      currency={country}
                      createCreditCardCharge={createCreditCardCharge}
                      handleSubmit={handleSubmit}
                      data={data}
                    />
                  ) : checkradio == "googlepay" ? ( */}

                  {/* ) : checkradio == "qrcode" ? (
                    <CheckoutQRCode
                      totalAmount={totalAmount}
                      currency={country}
                      createInternetBankingCharge={createInternetBankingCharge}
                      handleSubmit={handleSubmit}
                      data={data}
                    />
                  ) : null} */}
                  {userData == "" ? (
                    <Button
                      type="button"
                      className="book-now-paybutton"
                      onClick={() => {
                        // handleSubmit("tokn_test_5x21ntclsadbhmtu5ks");
                        setLoginButtonVisible(true);
                      }}
                    >
                      <b>Pay</b>
                    </Button>
                  ) : (
                    <ChekoutCreditCard
                      totalAmount={Math.floor(totalAmount * currency[country])}
                      currency={country}
                      createCreditCardCharge={createCreditCardCharge}
                      handleSubmit={handleSubmit}
                      data={data}
                    />
                    // <Button
                    //   type="button"
                    //   className="book-now-paybutton"
                    //   onClick={() => {
                    //     handleSubmit("tokn_test_5x21ntclsadbhmtu5ks");
                    //   }}
                    // >
                    //   <b>Pay</b>
                    // </Button>
                  )}
                </Col>

                <div>
                  {/* ... other JSX ... */}

                  <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Select Payment Method</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Pay with Internet Banking
                      <CheckoutInternetBanking
                        totalAmount={totalAmount}
                        currency={country}
                        createInternetBankingCharge={
                          createInternetBankingCharge
                        }
                        handleSubmit={handleSubmit}
                        data={data}
                      />
                      Pay with Credit/Debit Card
                      <ChekoutCreditCard
                        totalAmount={totalAmount}
                        currency={country}
                        createCreditCardCharge={createCreditCardCharge}
                        handleSubmit={handleSubmit}
                        data={data}
                      />
                    </Modal.Body>
                  </Modal>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BookNowScreen;
