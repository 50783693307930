import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "../reducers/userReducer";
import currencyReducer from "./currencyReducer";
import activityReducer from "./activityReducer";
import homeReducer from "./homeReducer";
import wishlistReducer from "./wishlistReducer";
const rootreducer = combineReducers({
  userReducer: userReducer,
  currencyReducer: currencyReducer,
  activityReducer: activityReducer,
  homeReducer: homeReducer,
  wishlistReducer: wishlistReducer,
});
export default rootreducer;
