import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { fetchData } from "../../../Helper/ApiCall";
import TableHead from "../../components/TableHead";
import { END_POINT } from "../../../Helper/helper";

const AcceptedBookingList = () => {
  const [hotelbooking, sethotelbookingData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const [user, setUserData] = useState(null);

  useEffect(() => {
    Handler();
  }, []);

  const Handler = async () => {
    try {
      const result = await fetchData(END_POINT.GetHotelBooking);
      if (result.success) {
        sethotelbookingData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserDetails = async (userId) => {
    try {
      const result = await fetchData(END_POINT.GetUser + userId);
      if (result.success) {
        setUserData(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalOpen = (item) => {
    setClickedItem(item);
    fetchUserDetails(item.user_id); // Make sure to use the correct user ID field
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        style={{ maxWidth: "none" }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Booking Summary </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {clickedItem && (
            <div>
              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> User Name: </b> {clickedItem.mealDetail.firstName} {clickedItem.mealDetail.lastName}
              </span>

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Email: </b> {clickedItem.mealDetail.email}
              </span>

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Mobile Number: </b> {clickedItem.mealDetail.mobile}
              </span>



              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Veg Food: </b> {clickedItem.mealDetail.veg?.map((itemtype, index) => (
                  <span className="amenties" key={index}>
                    {index + 1}. {itemtype}
                  </span>
                ))}
              </span>


              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Non Veg Food: </b> {clickedItem.mealDetail.non_veg?.map((itemtype, index) => (
                  <span className="amenties" key={index}>
                    {index + 1}. {itemtype}
                  </span>
                ))}
              </span>

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Beds Avilable: </b> {clickedItem.mealDetail.bedsAvilable?.map((itemtype, index) => (
                  <span className="amenties" key={index}>
                    {index + 1}. {itemtype}
                  </span>
                ))}
              </span>

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Estimated Arrivval time: </b> {clickedItem.mealDetail.estimatedArrivvaltime}
              </span>

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Special Request: </b> {clickedItem.mealDetail.specialRequest}
              </span>

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Looking For: </b> {clickedItem.mealDetail.lookingFor}
              </span>

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Guest Details: </b><br />
                1. Title: {clickedItem.mealDetail.GuestDetail.title} <br />
                2. Name: {clickedItem.mealDetail.GuestDetail.firstName} {clickedItem.mealDetail.GuestDetail.lastName} <br />
                3. Email: {clickedItem.mealDetail.GuestDetail.email} <br />
                4. Mobile Code: {clickedItem.mealDetail.GuestDetail.mobileCode} <br />
                5. Mobile Number: {clickedItem.mealDetail.GuestDetail.mobile} <br />
              </span>


            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <button className="btn btn-primary mt-4">Booking List</button> */}
      <h3>Booking List</h3>


      <div className="mt-4">
        <div
          id="example_wrapper"
          className="dataTables_wrapper no-footer table-overflow"
        >
          <div id="example_filter" className="dataTables_filter mb-4">
            <label>
              Search:
              <input
                type="search"
                className=""
                placeholder=""
                aria-controls="example"
              />
            </label>
          </div>
          <table
            id="example"
            className="table table-striped table-bordered dataTable no-footer"
            style={{ width: "100%", whiteSpace: "nowrap" }}
            role="grid"
            aria-describedby="example_info"
          >
            <thead>
              <tr role="row">
                <TableHead title={"S.N"} />
                <TableHead title={"Booking Type"} />
                <TableHead title={"Name"} />
                <TableHead title={"Ordering City"} />
                <TableHead title={"Check In Date"} />
                <TableHead title={"Check Out Date"} />
                <TableHead title={"Number of Guest"} />
                <TableHead title={"Total Rooms"} />
                <TableHead title={"Extra Beds"} />
                <TableHead title={"Total Amount"} />
                <TableHead title={"Booking Summary"} />
              </tr>
            </thead>

            <tbody>
              {hotelbooking.length > 0 ? (
                hotelbooking.map((item, index) => (
                  <tr role="row" className="odd" key={index}>
                    <td className="sorting_1"> {index + 1} </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {item.type_booking} {" Booking"}
                    </td>
                    <td> {item.hotel_details.name} </td>
                    <td> {item.hotel_details.city} </td>
                    <td> {item.checkin} </td>
                    <td> {item.checkout} </td>
                    <td> {item.noofGuest} </td>
                    <td> {item.totalRooms} </td>
                    <td> {item.totalExtraBeds} </td>

                    <td> {item.amount} </td>
                    <td>
                      <button className="btn" onClick={() => handleModalOpen(item)}>
                        View
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <p>No Booking Available</p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AcceptedBookingList;
