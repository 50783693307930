import React, { useState, useEffect } from "react";
import {
  END_POINT,
  METHODS,
  BASE_URL_IMAGE,
  pagenameOptions,
  productpageNames,
} from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AddJoinNetwork = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = location?.state?.id;

  const [formData, setformData] = useState({
    image: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");

  useEffect(() => {
    if (cityid != undefined) {
      cityHandler();
    }
  }, []);
  const cityHandler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetJoinNetworkDetailById + cityid
      );
      console.log("bkbkk", result);
      if (result.success == true) {
        setformData({
          image: result.data.image,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (uploadedImagebanner) => {
    var raw = JSON.stringify({
      image: uploadedImagebanner,
    });
    console.log("raw", raw);
    try {
      const finalEndpoint =
        cityid != undefined
          ? END_POINT.UpdateJoinNetwork + cityid
          : END_POINT.UploadJoinNetwork;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../join-network-list");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        const uploadedImagebanner = values[0].image;

        handleSubmit(uploadedImagebanner);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const AllFileUpdate = async () => {
    Promise.all([image != "" && (await ImageUpload(image))])
      .then((values) => {
        const uploadedImagebanner =
          values[0].image == false ? formData.image : values[0].image;

        handleSubmit(uploadedImagebanner);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add Join Network</b>
        </h3>
      </div>

      <div className="row mb-4">
        <div className="col-lg-10 md-5 sm-12">
          <div>
            <span className="label-style">Image *</span> <br />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
            {formData.image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.image}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-5"
              >
                view image
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            if (cityid != undefined) {
              if (image != "") {
                AllFileUpdate();
              } else {
                handleSubmit(formData.image);
              }
            } else {
              AllFileUpload();
            }
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../product-blog-list");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddJoinNetwork;
