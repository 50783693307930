import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from "react-facebook-login";
import { END_POINT, METHODS } from "../Helper/helper";
import { fetchData } from "../Helper/ApiCall";
import { userdataUpdate } from "../redux/reducers/userReducer";
import { jwtDecode } from 'jwt-decode';


function LoginModal(props) {
  const {
    LoginButtonVisible,
    LoginButton = () => { },
    SignUpButtonVisible,
    SignUpButton = () => { },
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show1, setShow1] = useState(false);

  const [show, setShow] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [mobile, setmobile] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");

  const responseFacebook = async (response) => {
    if (response.status !== "unknown") {
      const { email, name, userID, accessToken } = response;
      const [firstName, lastName] = name.split(" ");

      try {
        // Check if user exists
        const checkUser = await fetchData(END_POINT.FacebookLogin, METHODS.POST, JSON.stringify({ email }));
        if (checkUser.success) {
          // User exists, update state and login
          dispatch(userdataUpdate(checkUser.data));
          setShow(false);
          LoginButton();

        } else {
          // User does not exist, create new user
          const createUser = await fetchData(
            END_POINT.PostUser,
            METHODS.POST,
            JSON.stringify({
              firstName: firstName,
              lastName: lastName,
              email: email,
              mobile: "",
              type: "user",
              status: true,
              facebookId: userID,
              accessToken: accessToken,
            })
          );

          if (createUser.success) {
            dispatch(userdataUpdate(createUser.data));
            setShow(false);
            LoginButton();
          } else {
            Swal.fire({
              title: "Error",
              text: createUser.message,
              icon: "error",
              confirmButtonColor: "#3085d6",
            });
          }
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "An error occurred while logging in with Facebook. Please try again.",
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Facebook login failed. Please try again.",
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
    }
  };


  const responseGoogle = async (response) => {
    const { credential } = response;
    try {
      const decodedToken = jwtDecode(credential);
      const { email, given_name: firstName, family_name: lastName } = decodedToken;

      const result = await fetchData(
        END_POINT.GoogleLogin,
        METHODS.POST,
        JSON.stringify({ email, firstName, lastName, googleId: decodedToken.sub, accessToken: credential })
      );

      if (result.success) {
        dispatch(userdataUpdate(result.data));
        setShow(false);
        LoginButton();
      } else {
        Swal.fire({
          title: "Error",
          text: result.message,
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "An error occurred while logging in with Google. Please try again.",
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
    }
  };


  const LoginHandler = async () => {
    try {
      const raw = JSON.stringify({
        email,
        password,
      });
      const result = await fetchData(END_POINT.LoginUser, METHODS.POST, raw);
      if (result.success) {
        dispatch(userdataUpdate(result.data));
        setShow(false);
        LoginButton();
        if (result.data.type === "admin") {
          navigate("/admin");
        }
      } else {
        Swal.fire({
          title: "Error",
          text: result.message,
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const SignUpHandler = async () => {
    try {
      const raw = JSON.stringify({
        firstName,
        lastName,
        email,
        password,
        mobile,
        type: "user",
        status: true,
      });
      const result = await fetchData(END_POINT.PostUser, METHODS.POST, raw);
      if (result.success) {
        dispatch(userdataUpdate(result.data));
        setShow1(false);
        SignUpButton();
      } else {
        Swal.fire({
          title: "Error",
          text: result.message,
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        show={LoginButtonVisible || show}
        onHide={() => {
          LoginButton();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src="/assets/img/output.png"
              alt="Logo"
              className="bharat-logo img-fluid"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span
              style={{ color: "#000", fontWeight: "bolder", fontSize: "large" }}
            >
              Welcome Back
            </span>
            <Row>
              <Col>
                <div style={{ marginRight: 5 }} className="form-element-each">
                  <span>Email</span>
                  <div className="d-flex align-items-center">
                    <input
                      style={{ border: "none" }}
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ marginRight: 5 }} className="form-element-each">
                  <span>Password</span>
                  <div className="d-flex align-items-center">
                    <input
                      style={{ border: "none" }}
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => {
                        setpassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center d-block">
          <Row className="d-flex align-items-center justify-content-center">
            <Col sm={12} md={12} lg={12}>
              <Button
                className="search-button w-100 align-self-center"
                style={{ border: "none", width: "100%" }}
                type="button"
                onClick={LoginHandler}
              >
                Login
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <img
              src="/assets/img/or-line.svg"
              alt="Logo"
              className="img-fluid"
            />
          </Row>
          <Row className="d-flex justify-content-between align-items-center mx-auto">
            <Col className="d-flex justify-content-center align-items-center">
              <GoogleOAuthProvider clientId="891251370274-5cib3f6dcoafv48qs38pi979flm3j99j.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={responseGoogle}
                  onError={() => {
                    Swal.fire({
                      title: "Error",
                      text: "Google login failed. Please try again.",
                      icon: "error",
                      confirmButtonColor: "#3085d6",
                    });
                  }}
                />
              </GoogleOAuthProvider>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <FacebookLogin
                appId="1676026129518263"
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                render={(renderProps) => (
                  <button onClick={renderProps.onClick} className="custom-facebook-button">
                    <img
                      src="/assets/img/FacebookLogo.svg"
                      alt="Facebook Logo"
                      className="img-fluid"
                    />
                    <span>Sign in with Facebook</span>
                  </button>
                )}
              />
            </Col>
          </Row>
          <Row>
            <p
              style={{
                color: "#000",
                fontWeight: "bolder",
                fontSize: "small",
                textAlign: "center",
                marginTop: 10,
              }}
            >
              Don't have an account?{" "}
              <span
                className="modal-login-toggle"
                style={{ cursor: "pointer", color: "#000" }}
                onClick={() => {
                  setShow1(true);
                  setShow(false);
                }}
              >
                Sign Up
              </span>
            </p>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={SignUpButtonVisible || show1}
        onHide={() => {
          SignUpButton();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src="/assets/img/output.png"
              alt="Logo"
              className="bharat-logo img-fluid"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span
              style={{ color: "#000", fontWeight: "bolder", fontSize: "large" }}
            >
              Create an Account
            </span>
            <Row>
              <Col>
                <div style={{ marginRight: 5 }} className="form-element-each">
                  <span>First Name</span>
                  <div className="d-flex align-items-center">
                    <input
                      style={{ border: "none" }}
                      placeholder="Enter First Name"
                      value={firstName}
                      onChange={(e) => {
                        setfirstName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div style={{ marginRight: 5 }} className="form-element-each">
                  <span>Last Name</span>
                  <div className="d-flex align-items-center">
                    <input
                      style={{ border: "none" }}
                      placeholder="Enter Last Name"
                      value={lastName}
                      onChange={(e) => {
                        setlastName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ marginRight: 5 }} className="form-element-each">
                  <span>Email</span>
                  <div className="d-flex align-items-center">
                    <input
                      style={{ border: "none" }}
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div style={{ marginRight: 5 }} className="form-element-each">
                  <span>Mobile</span>
                  <div className="d-flex align-items-center">
                    <input
                      style={{ border: "none" }}
                      placeholder="Enter Mobile"
                      value={mobile}
                      onChange={(e) => {
                        setmobile(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ marginRight: 5 }} className="form-element-each">
                  <span>Password</span>
                  <div className="d-flex align-items-center">
                    <input
                      style={{ border: "none" }}
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => {
                        setpassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div style={{ marginRight: 5 }} className="form-element-each">
                  <span>Confirm Password</span>
                  <div className="d-flex align-items-center">
                    <input
                      style={{ border: "none" }}
                      placeholder="Enter Password"
                      value={confirmpassword}
                      onChange={(e) => {
                        setconfirmpassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center d-block">
          <Row className="d-flex align-items-center justify-content-center">
            <Col sm={12} md={12} lg={12}>
              <Button
                className="search-button w-100 align-self-center"
                style={{ border: "none", width: "100%" }}
                type="button"
                onClick={SignUpHandler}
              >
                Sign Up
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <img
              src="/assets/img/or-line.svg"
              alt="Logo"
              className="img-fluid"
            />
          </Row>
          <Row className="d-flex justify-content-between align-items-center mx-auto">
            <Col className="d-flex justify-content-center align-items-center">
              <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
                <GoogleLogin
                  onSuccess={responseGoogle}
                  onError={() => {
                    Swal.fire({
                      title: "Error",
                      text: "Google login failed. Please try again.",
                      icon: "error",
                      confirmButtonColor: "#3085d6",
                    });
                  }}
                />
              </GoogleOAuthProvider>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <FacebookLogin
                appId="1676026129518263"
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                render={(renderProps) => (
                  <button onClick={renderProps.onClick} className="custom-facebook-button">
                    <img
                      src="/assets/img/FacebookLogo.svg"
                      alt="Facebook Logo"
                      className="img-fluid"
                    />
                    <span>Sign in with Facebook</span>
                  </button>
                )}
              />
            </Col>
          </Row>
          <Row>
            <p
              style={{
                color: "#000",
                fontWeight: "bolder",
                fontSize: "small",
                textAlign: "center",
                marginTop: 10,
              }}
            >
              Already have an account?{" "}
              <span
                className="modal-login-toggle"
                style={{ cursor: "pointer", color: "#000" }}
                onClick={() => {
                  setShow1(false);
                  setShow(true);
                }}
              >
                Login
              </span>
            </p>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginModal;
