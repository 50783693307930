import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { END_POINT } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import Swal from "sweetalert2";

import "../../dashscreen/DashCss.css";

import { useSelector, useDispatch } from "react-redux";

const VehiclesScreen = () => {
  const [dining, setDiningData] = useState([]);

  useEffect(() => {
    diningListHandler();
  }, []);

  const diningListHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetVehicles);
      if (result.success == true) {
        setDiningData(result.data);
        console.log(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const deleteHandler = async (id) => {
    try {
      const result = await fetchData(END_POINT.DeleteVehicles + id);
      if (result.success == true) {
        diningListHandler(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Link style={{ textDecoration: "none" }} to={"../add-vehicles"}>
        <button
          className="btn btn-primary mt-4"
        // onClick={() => {
        //   Navigate("add-hotel-listing");
        // }}
        >
          Add Vehicles&nbsp;<i class="fa-solid fa-plus"></i>
        </button>
      </Link>

      <div className="mt-4">
        <div
          id="example_wrapper"
          class="dataTables_wrapper no-footer table-overflow"
        >
          {/* <div
            class="dataTables_length"
            id="example_length"
            style={{ padding: 5 }}
          >
            <label>
              Show
              <select
                name="example_length"
                aria-controls="example"
                class="mb-4"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>
          </div>
          <div id="example_filter" class="dataTables_filter mb-4">
            <label>
              Search:
              <input
                type="search"
                class=""
                placeholder=""
                aria-controls="example"
              />
            </label>
          </div> */}
          <table
            id="example"
            class="table table-striped table-bordered dataTable no-footer"
            style={{ width: "100%", whiteSpace: "nowrap" }}
            role="grid"
            aria-describedby="example_info"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="S.N: activate to sort column descending"
                  style={{ width: "20px" }}
                >
                  S.N
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label=" Name: activate to sort column ascending"
                  style={{ width: "146px" }}
                >
                  Name
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Type / Catergory: activate to sort column ascending"
                  style={{ width: "96px" }}
                >
                  Type
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="City: activate to sort column ascending"
                  style={{ width: "50px" }}
                >
                  City
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Promo: activate to sort column ascending"
                  style={{ width: "61px" }}
                >
                  Promo
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Promo: activate to sort column ascending"
                  style={{ width: "61px" }}
                >
                  Share
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Hotel: activate to sort column ascending"
                  style={{ width: "56px" }}
                >
                  Edit Dining
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Room: activate to sort column ascending"
                  style={{ width: "73px" }}
                >
                  Edit Selling
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Date: activate to sort column ascending"
                  style={{ width: "118px" }}
                >
                  Edit Date
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Summary: activate to sort column ascending"
                  style={{ width: "92px" }}
                >
                  Summary
                </th>
              </tr>
            </thead>

            <tbody>
              {dining.length > 0 ? (
                dining.map((item, index) => (
                  <tr role="row" class="odd" key={index}>
                    <td class="sorting_1"> {index + 1} </td>
                    <td> {item.name} </td>

                    <td> {item.type} </td>
                    <td> {item.city} </td>
                    <td> {item.promoType} </td>

                    <td>
                      <Link
                        to={"/vehicles/" + item._id}
                        class="btn btn-success"
                      >
                        <i class="fa fa-share"></i>Share
                      </Link>
                    </td>

                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../add-vehicles",
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{ id: item._id }}
                      >
                        <i class="fa fa-edit"></i>
                      </Link>

                      <a
                        // href="#"
                        type="button"
                        class="btn btn-danger m-1"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: `You won't to delete ${item.name}!`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteHandler(item._id);

                              Swal.fire(
                                "Deleted!",
                                `${item.name} has been deleted.`,
                                "success"
                              );
                            }
                          });
                          //  handleDelete(item._id)
                        }}
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>
                    <td>
                      <Link
                        to={
                          "../vehicles-item-type/" + item._id + "/" + item.name
                        }
                        state={{ dinningname: item.name }}
                        class="btn btn-success"
                      >
                        <i class="fa fa-plus"></i>Item Type
                      </Link>
                    </td>
                    <td>{item.updatedAt.split("T")[0]}</td>
                    <td>
                      <Link
                        to={"../vehicles-summary/" + item._id}
                        class="btn btn-info"
                      >
                        <i class="fa fa-eye"></i>View Summary
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <p>Vehicles List Not Available</p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VehiclesScreen;
