import React, { useState, useEffect } from "react";
import {
  END_POINT,
  METHODS,
  BASE_URL_IMAGE,
  pagenameOptions,
} from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";

const AddRecomended = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = location?.state?.id;

  const [formData, setformData] = useState({
    type: "",
    title: "",
    description: "",
    image: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const [catData, setcatData] = useState([]);
  const [image, setimage] = useState("");

  useEffect(() => {
    categoryHandler();

    if (cityid != undefined) {
      cityHandler();
    }
  }, []);
  const categoryHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetCategoryDataBlog);
      if (result.success == true) {
        setcatData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const cityHandler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetRecomendedBlogDetailById + cityid
      );
      console.log("bkbkk", result);
      if (result.success == true) {
        setformData({
          type: result.data.type,
          title: result.data.title,
          description: result.data.description,
          image: result.data.image,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (uploadedImagebanner) => {
    var raw = JSON.stringify({
      type: formData.type,
      title: formData.title,
      description: formData.description,
      image: uploadedImagebanner,
    });
    console.log("raw", raw);
    try {
      const finalEndpoint =
        cityid != undefined
          ? END_POINT.UpdateRecomendedBlog + cityid
          : END_POINT.UploadRecomendedBlog;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../recomended-list");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        const uploadedImagebanner = values[0].image;

        handleSubmit(uploadedImagebanner);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const AllFileUpdate = async () => {
    Promise.all([image != "" && (await ImageUpload(image))])
      .then((values) => {
        const uploadedImagebanner =
          values[0].image == false ? formData.image : values[0].image;

        handleSubmit(uploadedImagebanner);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add Recomended Blog</b>
        </h3>
      </div>
      <div className="row mb-4">
        <div className="col-lg-10 md-10 sm-12">
          <div>
            <span className="label-style">Page Name:</span> <br />
            <div className="col-lg-6 md-6 sm-12">
              <div>
                <span className="label-style">Select Category:</span> <br />
                <Select
                  value={catData.filter(
                    (item) => item.categoryname == formData.type
                  )}
                  options={catData}
                  className="input-fields-style"
                  // onChange={hotelTypeHandleChange}
                  placeholder="Select an option"
                  isSearchable
                  getOptionLabel={(option) => option.categoryname}
                  getOptionValue={(option) => `${option}`}
                  isOptionSelected={(option) =>
                    formData.type === option.categoryname ? true : false
                  }
                  onChange={(e) => {
                    setformData({
                      ...formData,
                      type: e.categoryname,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Title:</span> <br />
            <input
              type="text"
              id="title"
              name="title"
              className="input-fields-style"
              value={formData.title}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Description:</span> <br />
          <input
            type="text"
            id="description"
            name="description"
            className="input-fields-style"
            value={formData.description}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-10 md-5 sm-12">
          <div>
            <span className="label-style">Banner *</span> <br />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
            {formData.image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.image}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-5"
              >
                view image
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            if (cityid != undefined) {
              if (image != "") {
                AllFileUpdate();
              } else {
                handleSubmit(formData.image);
              }
            } else {
              AllFileUpload();
            }
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../recomended-list");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddRecomended;
