import React, { useState, useEffect } from "react";
import { END_POINT, METHODS } from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Ticketupcoming from "../../assets/svg/profile/Ticketupcoming";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/reducers/userReducer";
import { userdataUpdate } from "../../redux/reducers/userReducer";

import Swal from "sweetalert2";

const PasswordScreen = () => {
  const navigate = useNavigate();

  const { userData } = useSelector((store) => store.userReducer);
  const dispatch = useDispatch();

  const [currentPassword, setcurrentPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");

  const HandelChangePassword = async () => {
    try {
      const raw = JSON.stringify({
        currentPassword: currentPassword,
        confirmPassword: confirmPassword,
        newPassword: newPassword,
      });
      const result = await fetchData(
        END_POINT.UserChangePassword + userData._id,
        METHODS.POST,
        raw
      );
      console.log(result);
      if (result.success == true) {
        // dispatch(userdataUpdate(result.data));
        dispatch(logout());
        navigate("/");

        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        });
      } else {
        Swal.fire({
          title: "Wrong",
          text: result.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Cancel",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Container>
        <h3
          style={{
            color: "#0A3874",
            marginTop: "10px",
            marginBottom: "30px",
          }}
        >
          Change password
        </h3>

        <Row className="d-flex justify-content-between mb-4">
          <span>
            Keep your account safe pleast verify your indentity by entering your
            password.
          </span>
          <Col xs={6} md={6}>
            <div
              style={{ marginRight: 5, padding: 10 }}
              className="form-element-each"
            >
              <span style={{ display: "block", marginLeft: 1 }}>
                Current password
              </span>
              <div className="d-flex align-items-center">
                <input
                  style={{ border: "none" }}
                  placeholder="Enter Current password"
                  value={currentPassword}
                  onChange={(e) => {
                    setcurrentPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between mb-4">
          <h5>Please enter your new password</h5>
          <span>Minimum 8 characters with a number and a letter.</span>
          <Col xs={6} md={6}>
            <div
              style={{ marginRight: 5, padding: 10 }}
              className="form-element-each"
            >
              <span style={{ display: "block", marginLeft: 1 }}>
                New password
              </span>
              <div className="d-flex align-items-center">
                <input
                  style={{ border: "none" }}
                  placeholder="Enter New password"
                  value={newPassword}
                  onChange={(e) => {
                    setnewPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} md={6}>
            <div
              style={{ marginRight: 5, padding: 10 }}
              className="form-element-each"
            >
              <span style={{ display: "block", marginLeft: 1 }}>
                Re-enter new password
              </span>
              <div className="d-flex align-items-center">
                <input
                  style={{ border: "none" }}
                  placeholder="Re-enter new password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setconfirmPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end mt-4">
          <Col className="d-flex justify-content-end align">
            <button className="btn prsnl-dtl-btn ">
              <span className="canc-txt">Cancel</span>
            </button>
            <Button
              className="btn prsnl-dtl-btn sav-btn"
              onClick={HandelChangePassword}
            >
              <span className="sav-txt">Save</span>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PasswordScreen;
