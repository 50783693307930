import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";

import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { fetchData } from "../../Helper/ApiCall";
import {
  END_POINT,
  BASE_URL_IMAGE,
  METHODS,
  NumberSeprator,
  numberToEnglish,
  TabBarLabels,
} from "../../Helper/helper";
import EditorRead from "../../components/EditorRead";
import Testimonial from "../../components/Testimonial";
import PartnersCarousel from "../../components/PartnersCarousel";
import Subscribe from "../../components/Subscribe";
export const MiceScreen = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState({
    MiceDetail: [],
    Mice: [],
    MiceBanner: [],
    MiceReal: [],
    MiceConcept: [],
  });

  useEffect(() => {
    handlerData();
  }, []);
  const handlerData = async () => {
    try {
      const result = await fetchData(END_POINT.GetMicePageData);
      if (result.success == true) {
        setdata({
          MiceDetail: result.data.MiceDetail,
          Mice: result.data.Mice,
          MiceBanner: result.data.MiceBanner,
          MiceReal: result.data.MiceReal,
          MiceConcept: result.data.MiceConcept,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}

      {/* Hero Banner */}
      <div class="mice-outer">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          // modules={[Autoplay]}
          // autoplay={{
          //   delay: 3500,
          //   disableOnInteraction: true,
          // }}
          // breakpoints={{
          //   "@0.00": {
          //     slidesPerView: 2,
          //     spaceBetween: 5,
          //   },
          //   "@0.75": {
          //     slidesPerView: 3,
          //     spaceBetween: 10,
          //   },
          //   "@1.00": {
          //     slidesPerView: 4,
          //     spaceBetween: 30,
          //   },
          //   "@1.50": {
          //     slidesPerView: 5,
          //     spaceBetween: 40,
          //   },
          // }}
          className="micecarousel"
        >
          {data.MiceBanner.map((item) => {
            return (
              <SwiperSlide>
                <div
                  className="mice-banner"
                  style={{
                    backgroundImage: `url(${BASE_URL_IMAGE + item.image})`,
                  }}
                >
                  <div class="overlay"></div>
                  <div className="row">
                    <div className="col-9 mice-banner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                      {/* <h1>
                        Our <span className="yellow-span">M.I.C.E </span>
                        program
                        
                      </h1> */}

                      <h1>
                        {item.title.split(" ").map((word, index, array) =>
                          index === Math.floor(array.length / 2) ? (
                            <span key={index} className="yellow-span">
                              {" "}
                              {word}{" "}
                            </span>
                          ) : (
                            <span key={index}>{word}</span>
                          )
                        )}
                      </h1>

                      <p>{item.description}</p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center btn-container-mice align-items-center">
                    <div className="col-lg-6 md-6 sm-12 d-flex">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"/service-offer"}
                        state={{ title: "Mice" }}
                      >
                        <button className="btn service-offer-btn ">
                          Service offer
                        </button>
                      </Link>
                    </div>
                    <div className="col-lg-6 md-6 sm-12 d-flex ">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"/enquiry"}
                        state={{ title: "Mice" }}
                      >
                        <button className="btn mice-get-in-btn ">
                          Get in touch
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          {/* <SwiperSlide>
            <div className="mice-banner">
              <div className="row">
                <div className="col-9 mice-banner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                  <h1>
                    Our <span className="yellow-span">M.I.C.E </span> program
                  </h1>

                  <p>
                    Destination Siam M.I.C.E is One Stop Service solution for
                    your ideal event. From Mega Event, Product Launching or
                    normal Incentive Fam Trip, you need a trustable partner who
                    derive the outcome with Intelligence and Innovation and can
                    be your strong back support for the success solution
                  </p>
                </div>
              </div>
              <div className="row w-50 d-flex justify-content-center">
                <div className="col-lg-5 md-5 sm-10 d-flex justify-content-evenly">
                  <button className="btn service-offer-btn ">
                    Service offer
                  </button>
                </div>
                <div className="col-lg-5 md-5 sm-10 d-flex ">
                  <Link style={{ textDecoration: "none" }} to={"/enquiry"}>
                    <button className="btn mice-get-in-btn ">
                      Get in touch
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
      {/* Welcome Section */}
      <div className="lifelong-carousel-wrapper">
        <div className="row carouselcontainer">
          <div className="col-lg-4 md-4 sm-12 mx-auto">
            <h2 className="lifelong-text">What is M.I.C.E</h2>
            <p className="lifelong-text-p">
              Let us whisk you away to the dreamy weddings of these couples who
              tied the knot in grandeur.
            </p>
          </div>
          <div className="col-lg-8 md-8 sm-12 mx-auto slider-container">
            <Swiper
              // navigation={true}

              slidesPerView={2}
              spaceBetween={5}
              loop={true}
              className="why-caro-swiper"
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                "@0.75": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@1.00": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                "@1.50": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
              }}
            >
              {data.MiceReal != undefined &&
                data.MiceReal.map((item) => {
                  return (
                    <SwiperSlide>
                      <div className="mx-4 rounded lifelong-slide">
                        <a href={item.mice_venue_link}>
                          <div className="welcome-card-img-cont">
                            <img
                              src="/assets/img/lifelong-slide1.svg"
                              className="img-fluid mb-2"
                              style={{ borderRadius: "1rem" }}
                            />
                          </div>

                          <div className="lifelong-content">
                            <h5 style={{ color: "#fff" }}>{item.title}</h5>
                            <p style={{ color: "#fff" }}>
                              {/* Choice of hotels promising the best deals in town */}
                              <EditorRead
                                description={item.description.slice(0, 70)}
                              />
                            </p>
                          </div>
                        </a>
                      </div>
                    </SwiperSlide>
                  );
                })}
              {/* <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#fff" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#fff" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#fff" }}>
                      Simi & Ketur, Event at Dusit Thani Pattaya
                    </h5>
                    <p style={{ color: "#fff" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#fff" }}>
                      Ketki & Shrey, Event at Anantra Riverside Bangkok
                    </h5>
                    <p style={{ color: "#fff" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#fff" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#fff" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide> */}
              <div className="button-cont">
                <SwiperNavButtons />
              </div>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="container welcome-section">
        {/* <img
          src="/assets/img/mid-left-leaf.svg"
          className="img-fluid mid-left-leaf"
        /> */}
        <h2>M.I.C.E in Thailand</h2>
        <p>
          Focusing and providing the correct platforms is beacon of success
          story. Our list of Thailand many award-winning and globally accredited
          convention centres.
        </p>
        <div className="row">
          {data.Mice.map((item) => {
            return (
              <div className="col-lg-4 md-5 sm-12 venue-cards">
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/enquiry"}
                  state={{ title: "Mice" }}
                >
                  <div className="welcome-card-img-cont" style={{}}>
                    <img
                      src={BASE_URL_IMAGE + item.main_banner}
                      className="img-fluid venue-img"
                    />
                  </div>

                  <div className="venue-card-text">
                    <h4>{item.title}</h4>

                    <EditorRead description={item.description.slice(0, 70)} />
                  </div>
                </Link>
              </div>
            );
          })}
          {/* <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/venue1.svg" className="img-fluid venue-img" />
            <div className="venue-card-text">
              <h4>M.I.C.E event title</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div>
          <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/venue2.svg" className="img-fluid venue-img" />
            <div className="venue-card-text">
              <h4>M.I.C.E event title</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div>
          <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/venue3.svg" className="img-fluid venue-img" />
            <div className="venue-card-text">
              <h4>M.I.C.E event title</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div>
          <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/venue3.svg" className="img-fluid venue-img" />
            <div className="venue-card-text">
              <h4>M.I.C.E event title</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div> */}
        </div>
        {/* <img
          src="/assets/img/bottom-right-leaf.svg"
          className="img-fluid bottom-right-leaf"
        /> */}
      </div>

      {/* Special Events Section */}
      <div className="unique-carousel-wrapper">
        <div className="row carouselcontainer ">
          <div
            className="col-lg-8 md-8 sm-12 mx-auto slider-container "
            id="unique-slider"
          >
            <Swiper
              // navigation={true}
              modules={[Navigation]}
              slidesPerView={2}
              spaceBetween={5}
              loop={true}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                "@0.75": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@1.00": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                "@1.50": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
              }}
              className="why-caro-swiper"
            >
              {data.MiceConcept != undefined &&
                data.MiceConcept.map((item) => {
                  return (
                    <SwiperSlide>
                      <div className="rounded unique-slide">
                        <a href={item.link}>
                          <div className="welcome-card-img-cont">
                            {/* <img
                              src="/assets/img/lifelong-slide1.svg"
                              className="img-fluid mb-2"
                            /> */}
                            <img
                              src={BASE_URL_IMAGE + item.image}
                              className="img-fluid venue-img"
                            />
                          </div>

                          <div className="lifelong-content">
                            <h5 style={{ color: "#333333" }}>{item.title}</h5>
                            <p style={{ color: "#333333" }}>
                              {item.description && (
                                <EditorRead
                                  description={item.description.slice(0, 70)}
                                />
                              )}
                              {/* Event by Wedding Galore <br /> Venue: Sheraton Hua
                            Hin Resort & Spa */}
                            </p>
                          </div>
                        </a>
                      </div>
                    </SwiperSlide>
                  );
                })}
              {/* <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>M.I.C.E event title</h5>
                    <p style={{ color: "#333333" }}>
                      Event by Wedding Galore <br /> Venue: Sheraton Hua Hin
                      Resort & Spa
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>M.I.C.E event title</h5>
                    <p style={{ color: "#333333" }}>
                      Event by Wedding Galore <br /> Venue: Sheraton Hua Hin
                      Resort & Spa
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>M.I.C.E event title</h5>
                    <p style={{ color: "#333333" }}>
                      Event by Wedding Galore <br /> Venue: Sheraton Hua Hin
                      Resort & Spa
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>M.I.C.E event title</h5>
                    <p style={{ color: "#333333" }}>
                      Event by Wedding Galore <br /> Venue: Sheraton Hua Hin
                      Resort & Spa
                    </p>
                  </div>
                </div>
              </SwiperSlide> */}
              <div className="button-cont">
                <SwiperNavYellow />
              </div>
            </Swiper>
          </div>
          <div className="col-lg-4 md-4 sm-12 mx-auto" id="unique-cont">
            <h2 className="unique-text">Exclusive M.I.C.E films</h2>
            <p className="unique-text-p">
              Experience our recent M.I.C.E events through our exclusive films
            </p>
          </div>
        </div>
      </div>
      {/* Experiences Section */}
      {/* CTA events */}
      <div className="cta-events-wrapper">
        <div className="container cta-events-cont">
          <div className="row">
            <div className="col-lg-8 md-10 sm-12 mx-auto cta-events-text">
              <h3>
                Let our experts organize <br /> your next M.I.C.E eventlife
              </h3>
            </div>
          </div>
          <div className="row d-flex justify-content-evenly">
            <div className="col-lg-5 md-5 sm-10 d-flex justify-content-end">
              <Link
                style={{ textDecoration: "none" }}
                to={"/service-offer"}
                state={{ title: "Mice" }}
              >
                <button className="btn service-offer-btn">
                  Our service offerings
                </button>
              </Link>
            </div>
            <div className="col-lg-5 md-5 sm-10 d-flex ">
              <Link
                style={{ textDecoration: "none" }}
                to={"/enquiry"}
                state={{ title: "Mice" }}
              >
                <button className="btn mice-get-in-btn">Send in inquiry</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Testimonial type={"Mice"} />

      {/* PartnerShip Section */}
      <PartnersCarousel />
      {/* CTA Section */}

      <Subscribe
        title={`Get the scoop on deals and content with our newsletter, don't miss out!`}
      />
      {/* Footer */}

      {/* <Footer /> */}
    </div>
  );
};
