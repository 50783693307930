import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabBar: "Hotel",
};

export const activityReducer = createSlice({
  name: "activityReducer",
  initialState,
  reducers: {
    tabbarSelectUpdate: (state, actions) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.tabBar = actions.payload;
    },
    // currencyUpdate: (state, actions) => {

    // },
  },
});

// Action creators are generated for each case reducer function
export const { tabbarSelectUpdate } = activityReducer.actions;

export default activityReducer.reducer;
