import React, { useState } from "react";
import { END_POINT, METHODS } from "../Helper/helper";
import { fetchData } from "../Helper/ApiCall";
import Swal from "sweetalert2";

function Subscribe({ title }) {
  const [email, setemail] = useState("");
  const subscribeHandler = async () => {
    const validate = validateEmail(email);
    console.log(validate);
    if (validate == true) {
      try {
        const raw = JSON.stringify({
          email: email,
        });
        const result = await fetchData(
          END_POINT.UploadSubscribe,
          METHODS.POST,
          raw
        );
        console.log(result);
        if (result.success == true) {
          Swal.fire({
            title: "Confirmed",
            text: result.message,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          });
        } else {
          Swal.fire({
            title: "Wrong",
            text: result.message,
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Cancel",
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      Swal.fire({
        title: "Wrong",
        text: "Email address is not valid",
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cancel",
      });
    }
  };
  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  return (
    <div className="cta-wrapper">
      <div className="container cta">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-6 md-6 sm-12 stay-connect">
            <span>{title}</span>
          </div>
          <div className="col-lg-6 md-6 sm-12 d-flex align-items-center justify-content-center">
            <div className="row d-flex align-items-center sub-row-cont">
              <div className="col-8">
                <input
                  type="email"
                  class="form-control email-input"
                  placeholder="Your email address"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                />
              </div>
              <div className="col-4 ">
                <a
                  class="nav-link btn subscribe-btn"
                  type="button"
                  onClick={subscribeHandler}
                >
                  Subscribe
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
