import React, { useRef, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import Select from "react-select";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";
import {
  BASE_URL_IMAGE,
  END_POINT,
  METHODS,
  NumberSeprator,
  TabBarLabels,
  numberToEnglish,
} from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Button, Container, Form, Modal, Row, Col } from "react-bootstrap";
import Sticky from "react-sticky-el";
import VideoSwiper from "../../components/VideoSwiper";
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import PartnersCarousel from "../../components/PartnersCarousel";
import Testimonial from "../../components/Testimonial";

import ProductPageHeader from "../../components/ProductPageHeader";
import ProductPageBlogSection from "../../components/ProductPageBlogSection";
import ProductPageDeatils from "../../components/ProductPageDeatils";
import ProductPagePolicySection from "../../components/ProductPagePolicySection";
import BottomPriceStripe from "../../components/BottomPriceStripe";
import { addwishlistUpdate } from "../../redux/reducers/wishlistReducer";

const PackagesDetailScreen = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { id } = useParams();
  let location = useLocation();
  const { checkin, checkout, room, selectedtab } = location?.state;
  const wishlistData = useSelector((store) =>
    store.wishlistReducer.wishlistData.find((item) => {
      return item.hotel_id == id;
    })
  );
  const userData = useSelector((store) => store.userReducer.userData);

  const { country, currency } = useSelector((store) => store.currencyReducer);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

  const [dateFrom, setdateFrom] = useState();
  const [dateTo, setdateTo] = useState();
  const [totalAmount, settotalAmount] = useState(0);
  const [hotel, setHotelData] = useState();
  const [selectedroomtype, setSelectedroomtype] = useState([]);
  const [hotepolicy, sethotepolicy] = useState();
  const sharedurl = 'packages';

  const message = "Welcome to BharatChoice";
  const phoneNumber = "+66810908071";
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  const [showModal, setShowModal] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const [hotelRooms, sethotelRooms] = useState([]);

  const handleModalOpen = (item) => {
    setClickedItem(item);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  var getDateArray = function (start, end) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(dt.toISOString().split("T")[0]);

      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };
  const [formData, setformData] = useState({
    checkin: "",
    checkout: "",
    noofGuest: 1,
    hotel_id: id,
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    hotelHandler();
    setdateFrom(checkin);
    setdateTo(checkout);
  }, []);
  useEffect(() => {
    if (dateFrom != undefined) {
      settotalAmount(0);
      setSelectedroomtype([]);
    }
  }, [dateFrom]);

  useEffect(() => {
    if (selectedroomtype.length > 0) {
      const totalScores = selectedroomtype.reduce(
        (previousScore, currentScore, index) => {
          if (currentScore.adultNumber != 0 || currentScore.childNumber != 0) {
            return (
              previousScore +
              currentScore.selected_No_Pax.number_adult *
              currentScore.adultNumber +
              currentScore.selected_No_Pax.number_child *
              currentScore.childNumber +
              currentScore.hotel_room_price *
              currentScore.selected_No_Pax.number_room
            );
          } else {
            return 0;
          }
        },
        0
      );

      settotalAmount(totalScores);
    }
  }, [selectedroomtype]);

  const GetHotelStockhandler = async (hotel_room_type_id) => {
    try {
      const raw = JSON.stringify({
        room_type_id: hotel_room_type_id,
      });
      console.log(END_POINT.GetMultipleHotelStock, raw);

      const result = await fetchData(
        END_POINT.GetMultipleHotelStock,
        METHODS.POST,
        raw
      );

      // console.log(result);
      if (result.success == true) {
        sethotelRooms(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const hotelHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetPackageStockByPackageid + id);
      console.log(END_POINT.GetPackageStockByPackageid + id);
      if (result.success == true) {
        setHotelData(result.data[0]);
        const editorSta = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(result.data[0].policyExclusion)
          )
        );
        sethotepolicy(editorSta);

        const checkData = [];
        result.data[0].package_stock_data.map((item) => {
          if (
            item.package_type == "Hotel" &&
            item.package_stock_data != undefined
          ) {
            checkData.push(item.package_stock_data.hotel_room_type_id);
          }
        });
        console.log("checkData", checkData);
        if (checkData.length > 0) {
          GetHotelStockhandler(checkData);
        }

        // if (
        //   result.data[0].package_stock_data[0].package_stock_data
        //     .package_type == "Hotel"
        // ) {
        //   const hotel_id =
        //     result.data[0].package_stock_data[0].package_stock_data.hotel_id;
        //   const hotel_room_type_id =
        //     result.data[0].package_stock_data[0].package_stock_data
        //       .hotel_room_type_id;
        //   GetHotelStockhandler(hotel_id, hotel_room_type_id);
        // }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const adultHandler = async (item, filterPax, min) => {
    const newArray = [...selectedroomtype];

    const findindex = newArray.findIndex(
      (x) => x.package_item_type_id === item._id
    );
    if (findindex == -1) {
      const hotelRoomPrice = hotelRooms.find((itemRoom) => {
        return item?.package_stock_data?.hotel_id == itemRoom.hotel_id;
      });
      newArray.push({
        selected_No_Pax: filterPax,
        childNumber: 0,
        adultNumber: 0,
        package_id: item.package_id,
        package_item_type_id: item._id,
        package_stock_id: item.package_stock_data._id,
        name: item.name,
        content: item.content,
        package_type: item.package_type,
        item_inclusion: item.item_inclusion,
        image: item.image,
        payment_mode: item?.package_stock_data?.payment_mode,
        booking_type: item?.package_stock_data?.booking_type,
        date_from: item?.package_stock_data?.date_from,
        campaign_name: item?.package_stock_data?.campaign_name,
        payment_option: item?.package_stock_data?.payment_option,
        cost: item?.package_stock_data?.cost,
        sell: item?.package_stock_data?.sell,

        hotel_name: item?.package_stock_data?.hotel_name,
        hotel_id: item?.package_stock_data?.hotel_id,
        hotel_room_type_id: item?.package_stock_data?.hotel_room_type_id,
        hotel_room_type: item?.package_stock_data?.hotel_room_type,
        hotel_room_price: hotelRoomPrice?.campaign_names[0]?.weekdaysell,
        // No_Pax: item?.package_stock_data?.No_Pax,
      });
    } else {
      newArray[findindex].selected_No_Pax = filterPax;
    }

    // const totalScores = newArray.reduce(
    //   (previousScore, currentScore, index) =>
    //     previousScore +
    //     currentScore.selected_No_Pax.number_room * currentScore.sell,

    //   0
    // );
    const roomCount = newArray
      .filter(
        (selectedroomtypeItem) =>
          selectedroomtypeItem.hotel_room_type_id ==
          item.package_stock_data.hotel_room_type_id
      )
      .reduce((acc, obj) => {
        return acc + obj.selected_No_Pax.number_room;
      }, 0);

    // console.log(" totalScores", totalScores, min, roomCount);

    if (min >= roomCount) {
      // settotalAmount(totalScores);
      // if (totalScores == 0) {
      //   setSelectedroomtype([]);
      // } else {
      setSelectedroomtype(newArray);
      // }
    } else {
      Swal.fire({
        title: "Rooms not available for these dates",
        text: `You can only select total ${min} Rooms for these dates`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        showConfirmButton: false,
      });
    }
  };

  const adultValueselector = (item, value, keyName) => {
    const newArray = [...selectedroomtype];

    const findindex = newArray.findIndex(
      (x) => x.package_item_type_id === item._id
    );

    newArray[findindex][keyName] = value;
    setSelectedroomtype(newArray);
  };

  const childHandler = (item, filterPax) => {
    const newArray = [...selectedroomtype];

    const findindex = newArray.findIndex(
      (x) => x.package_item_type_id === item._id
    );
    if (findindex == -1) {
      newArray.push({
        selected_No_Pax: filterPax,
        package_id: item.package_id,
        package_item_type_id: item._id,
        package_stock_id: item.package_stock_data._id,
        name: item.name,
        content: item.content,
        package_type: item.package_type,
        item_inclusion: item.item_inclusion,
        image: item.image,
        payment_mode: item?.package_stock_data?.payment_mode,
        booking_type: item?.package_stock_data?.booking_type,
        date_from: item?.package_stock_data?.date_from,
        campaign_name: item?.package_stock_data?.campaign_name,
        payment_option: item?.package_stock_data?.payment_option,
        cost: item?.package_stock_data?.cost,
        sell: item?.package_stock_data?.sell,

        hotel_name: item?.package_stock_data?.hotel_name,
        hotel_id: item?.package_stock_data?.hotel_id,
        hotel_room_type_id: item?.package_stock_data?.hotel_room_type_id,
        hotel_room_type: item?.package_stock_data?.hotel_room_type,

        // No_Pax: item?.package_stock_data?.No_Pax,
        // No_Pax_Child: item?.package_stock_data?.No_Pax_Child,
        // No_Pax_Adult: item?.package_stock_data?.No_Pax_Adult,
      });
    } else {
      newArray[findindex].selected_No_Pax = filterPax;
    }

    const totalScores = newArray.reduce(
      (previousScore, currentScore, index) =>
        previousScore +
        currentScore.selected_No_Pax.numberPax * currentScore.sell,

      0
    );

    settotalAmount(totalScores);
    if (totalScores == 0) {
      setSelectedroomtype([]);
    } else {
      setSelectedroomtype(newArray);
    }
  };

  const findTotalAdultnum = (id) => {
    const TotalAdultnum = selectedroomtype.find((itemselect) => {
      return itemselect.package_item_type_id == id;
    });
    // console.log("TotalAdultnum", TotalAdultnum);

    return TotalAdultnum;
  };
  const minTotalByName = (data) => {
    if (data.some((slot) => slot.date === dateFrom) == true) {
      const totals = data
        .filter((x) => [dateFrom].includes(x.date))
        .map((x) => x.livestock);
      return totals.length > 0 ? Math.min(...totals) : 0;
    } else {
      return 0;
    }
  };
  const minCheckinDate = new Date().toISOString().split("T")[0];
  const addWishlistHandler = async () => {
    try {
      const raw = JSON.stringify({
        type: TabBarLabels.Packages,
        hotel_id: id,
        user_id: userData._id,
      });

      const result = await fetchData(
        END_POINT.Uploadwishlist,
        METHODS.POST,
        raw
      );
      // console.log(raw);
      if (result.success == true) {
        dispatch(addwishlistUpdate(result.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}
      <BottomPriceStripe
        dateFrom={dateFrom}
        // dateTo={dateTo}
        noofGuest={formData.noofGuest}
        totalAmount={totalAmount}
        ButtonPress={() => {
          if (totalAmount == 0) {
            return Swal.fire({
              title: "Select adults and childs",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: "Ok",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
              showConfirmButton: false,
            });
          }
          var startDate = new Date(dateFrom); //YYYY-MM-DD
          var endDate = new Date(dateTo); //YYYY-MM-DD
          const dates = getDateArray(startDate, endDate);

          navigate("/booking/" + selectedtab + "/" + id, {
            state: {
              selectedtab: selectedtab,
              data: selectedroomtype,
              totalAmount: totalAmount,
              dates: dates,
              guest: formData.noofGuest,
              // package_type:hotel?.
            },
          });
        }}
      />
      <ProductPageHeader hotel={hotel} sharedurl={sharedurl} />

      <div className="container">
        <div className="row">
          <div className="col-lg-7 md-7 sm-12">
            <p className="" style={{ fontSize: 20 }}>
              {hotel?.about}
            </p>
            <h3 style={{ color: "black", marginTop: "15px" }}>
              What this place offers
            </h3>
            <div className="row">
              {hotel?.placeoffer?.map((item, index) => {
                return (
                  <div className="col-6">
                    <span className="amenties">
                      <img src="/assets/img/star.svg" className="img-fluid" />
                      {item}
                    </span>
                  </div>
                );
              })}
            </div>
            {/* <ProductPageDeatils
              keyName={"package_stock_data"}
              keyNameSearch={"package_item_type_id"}
              data={hotel?.package_stock_data}
              selectedroomtype={selectedroomtype}
              adultHandler={(item, value) => {
                adultHandler(item, value);
              }}
              childHandler={(item, value) => {
                childHandler(item, value);
              }}
              productId={id}
              type={TabBarLabels.Packages}
            /> */}
            <div>
              {hotel?.package_stock_data != undefined &&
                hotel?.package_stock_data.map((item, index) => {
                  if (item["package_stock_data"] == undefined) {
                    return null;
                  }
                  const TotalAdultnum = findTotalAdultnum(item._id);
                  // console.log("dsvdvdsv", TotalAdultnum?.adultNumber);
                  // const roomCount = selectedroomtype
                  //   .filter(
                  //     (selectedroomtypeItem) =>
                  //       selectedroomtypeItem.hotel_room_type_id ==
                  //       item.package_stock_data.hotel_room_type_id
                  //   )
                  //   .reduce((acc, obj) => {
                  //     return acc + obj.selected_No_Pax.numberPax;
                  //   }, 0);

                  return (
                    <div className="services-box">
                      <Row className="">
                        <div class="col-md-4 sm-12 text-center hotel-text1 hotel-text2 p-2 border-add">
                          <div class="cus-dinner-img p-4">
                            <img
                              src={BASE_URL_IMAGE + item.image}
                              class="img-fluid "
                            />

                            <button
                              className="btn wish-list-btn"
                              onClick={() => handleModalOpen(item)}
                            >
                              MORE DETAILS <i class="fas fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                        <div class="col-md-8 sm-12 hotel-text1 hotel-text3 ">
                          <div class="cus-dinner-text desktop">
                            <h3>{item.name}</h3>
                          </div>

                          <div class="row ful-height">
                            <div class="col-md-4 sm-12">
                              <div class="cus-dinner-box desktop">
                                {item.package_stock_data?.benifits?.map(
                                  (itemBenefit, indexBenefit) => {
                                    return (
                                      <p class="free free1">
                                        <i class="fas fa-check"></i>{" "}
                                        {itemBenefit.value}
                                        {indexBenefit == 0 && <span></span>}
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            </div>

                            <div class="col-md-8 sm-12 hotel-text1">
                              <div className="p-3">
                                <h5>Best Price for Today's</h5>
                                <div class="prices">
                                  <h6 class="mr-2 font-fix">
                                    <span>
                                      <span>
                                        {NumberSeprator(
                                          Math.floor(
                                            item?.package_stock_data?.sell *
                                            currency[country]
                                          )
                                        )}
                                        {" " + country}
                                      </span>
                                    </span>
                                  </h6>

                                  <input
                                    type="hidden"
                                    value="20"
                                    id="adultPrice46"
                                    name="adultPrice"
                                  />

                                  <div id="field2" class="qty">
                                    <div className="d-flex align-items-center form-element-select">
                                      <select
                                        className="form-control form-element-style select-style-fix"
                                        onChange={(e) => {
                                          console.log(e.target.value);

                                          const filterPax =
                                            item.package_stock_data?.No_Pax.find(
                                              (itemNof_pax) => {
                                                return (
                                                  itemNof_pax.numberPax ==
                                                  e.target.value
                                                );
                                              }
                                            );
                                          if (item.package_type == "Hotel") {
                                            const extraBedfind =
                                              selectedroomtype.find(
                                                (selectedroomtypeItem) => {
                                                  return (
                                                    selectedroomtypeItem.hotel_room_type_id ==
                                                    item.package_stock_data
                                                      .hotel_room_type_id
                                                  );
                                                }
                                              );
                                            const roomCount = selectedroomtype
                                              .filter(
                                                (selectedroomtypeItem) =>
                                                  selectedroomtypeItem.hotel_room_type_id ==
                                                  item.package_stock_data
                                                    .hotel_room_type_id
                                              )
                                              .reduce((acc, obj) => {
                                                return (
                                                  acc +
                                                  obj.selected_No_Pax
                                                    .number_room
                                                );
                                              }, 0);
                                            const filter = hotelRooms.find(
                                              (itemFilter) => {
                                                return (
                                                  item.package_stock_data
                                                    .hotel_room_type_id ==
                                                  itemFilter.room_type_id
                                                );
                                              }
                                            );
                                            const min = minTotalByName(
                                              filter?.campaign_rooms
                                            );
                                            if (
                                              roomCount == 0 ||
                                              extraBedfind?.room >
                                              e.target.value
                                            ) {
                                              adultHandler(
                                                item,
                                                filterPax,
                                                min
                                              );
                                            } else if (
                                              min >= filterPax.number_room
                                            ) {
                                              adultHandler(
                                                item,
                                                filterPax,
                                                min
                                              );
                                            } else {
                                              Swal.fire({
                                                title: "Rooms not available",
                                                text: `You can only select total ${min} Rooms for this date`,
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Yes",
                                                cancelButtonText: "OK",
                                                showConfirmButton: false,
                                              });
                                            }
                                          } else {
                                            childHandler(
                                              item,

                                              filterPax
                                            );
                                          }
                                        }}
                                      >
                                        <option value={0}>0</option>
                                        {item.package_stock_data?.No_Pax.map(
                                          (itemNof_pax) => {
                                            return (
                                              <option
                                                value={itemNof_pax.numberPax}
                                                key={itemNof_pax.numberPax}
                                              >
                                                {itemNof_pax.numberPax}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>

                                      <i
                                        class="fa-solid fa-angle-down"
                                        style={{ color: "#858585" }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                {item.package_type == "Hotel" &&
                                  TotalAdultnum != undefined && (
                                    <>
                                      <div class="prices mt-2">
                                        <h6 class="mr-2">
                                          ADULT
                                          <span>
                                            <span>{" " + country}</span>
                                          </span>
                                        </h6>

                                        <input
                                          type="hidden"
                                          value="20"
                                          id="adultPrice46"
                                          name="adultPrice"
                                        />

                                        <div id="field2" class="qty">
                                          <button
                                            type="button"
                                            id="sub2"
                                            class="sub2 set_size priceIncrAdult"
                                            onClick={(e) => {
                                              if (
                                                item.package_type == "Hotel" &&
                                                TotalAdultnum?.adultNumber !=
                                                undefined &&
                                                TotalAdultnum?.adultNumber != 0
                                              ) {
                                                const newValue =
                                                  TotalAdultnum?.adultNumber -
                                                  1;

                                                adultValueselector(
                                                  item,
                                                  newValue,
                                                  "adultNumber"
                                                );
                                              }
                                            }}
                                          >
                                            -
                                          </button>

                                          <input
                                            type="text"
                                            id="adult_value46"
                                            name="adult_value"
                                            class="adult_value"
                                            value={
                                              TotalAdultnum?.adultNumber !=
                                                undefined
                                                ? TotalAdultnum?.adultNumber
                                                : 0
                                            }
                                            readonly=""
                                          />

                                          <button
                                            type="button"
                                            id="add2"
                                            class="set_size priceDescAdult"
                                            onClick={(e) => {
                                              if (
                                                item.package_type == "Hotel"
                                              ) {
                                                const newValue =
                                                  TotalAdultnum?.adultNumber +
                                                  1;
                                                adultValueselector(
                                                  item,
                                                  newValue,
                                                  "adultNumber"
                                                );
                                              }
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                      <div class="prices mt-2">
                                        <h6 class="mr-2">
                                          CHILD
                                          <span>
                                            <span>{" " + country}</span>
                                          </span>
                                        </h6>

                                        <input
                                          type="hidden"
                                          value="20"
                                          id="adultPrice46"
                                          name="adultPrice"
                                        />

                                        <div id="field2" class="qty">
                                          <button
                                            type="button"
                                            id="sub2"
                                            class="sub2 set_size priceIncrAdult"
                                            onClick={(e) => {
                                              if (
                                                item.package_type == "Hotel" &&
                                                TotalAdultnum?.childNumber !=
                                                undefined &&
                                                TotalAdultnum?.childNumber != 0
                                              ) {
                                                const newValue =
                                                  TotalAdultnum?.childNumber -
                                                  1;
                                                adultValueselector(
                                                  item,
                                                  newValue,
                                                  "childNumber"
                                                );
                                              }
                                            }}
                                          >
                                            -
                                          </button>

                                          <input
                                            type="text"
                                            id="adult_value46"
                                            name="adult_value"
                                            class="adult_value"
                                            value={
                                              TotalAdultnum?.childNumber !=
                                                undefined
                                                ? TotalAdultnum?.childNumber
                                                : 0
                                            }
                                            readonly=""
                                          />

                                          <button
                                            type="button"
                                            id="add2"
                                            class="set_size priceDescAdult"
                                            onClick={(e) => {
                                              if (
                                                item.package_type == "Hotel" &&
                                                TotalAdultnum?.childNumber !=
                                                undefined
                                              ) {
                                                const newValue =
                                                  TotalAdultnum?.childNumber +
                                                  1;
                                                adultValueselector(
                                                  item,
                                                  newValue,
                                                  "childNumber"
                                                );
                                              }
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                <p class="text">{item.content}</p>
                                {wishlistData == undefined && (
                                  <button
                                    className="btn wish-list-btn"
                                    type="button"
                                    onClick={() => {
                                      addWishlistHandler();
                                    }}
                                  >
                                    Save to wish list
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  );
                })}
              <Modal
                show={showModal}
                onHide={handleModalClose}
                style={{ maxWidth: "none" }}
              >
                <Modal.Header closeButton>
                  <Modal.Title> Details </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {clickedItem && (
                    <div>
                      {/* <img src={BASE_URL_IMAGE + clickedItem.gallery_image} alt="Gallery" className="img-fluid" />
              <img src={BASE_URL_IMAGE + clickedItem.room_image} alt="Image" className="img-fluid" /> */}

                      <span className="amenties">
                        <img
                          src="/assets/img/icon-room-size.svg"
                          className="img-fluid"
                          alt="Icon"
                        />
                        <b> Dinning description: </b> {clickedItem.content}
                      </span>

                      {/* <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b>Dinning Inclusion: </b> {clickedItem.item_inclusion}
              </span> */}

                      <span className="amenties">
                        <img
                          src="/assets/img/icon-room-size.svg"
                          className="img-fluid"
                          alt="Icon"
                        />
                        <b> Dinning Type: </b> {clickedItem.type}
                      </span>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleModalClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <Sticky className="col-lg-4 md-4 sm-12 don">
            <div className=" booking-dialog-box">
              <div class="form-group row justify-content-center mx-auto">
                <div className="form-element-each check-in col-12">
                  <span>Select Date</span>
                  <input
                    // type="date"
                    // class="form-control form-element-style"
                    // id="inputCheckIn"
                    type="date"
                    id="checkin"
                    name="checkin"
                    className="form-control form-element-style"
                    min={minCheckinDate}
                    value={dateFrom}
                    onChange={(e) => setdateFrom(e.target.value)}
                  />
                </div>
              </div>

              <button
                type="button"
                class="btn sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                onClick={() => {
                  if (totalAmount == 0) {
                    return Swal.fire({
                      title: "Select adults and childs",
                      icon: "warning",
                      showCancelButton: true,
                      cancelButtonText: "Ok",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                      showConfirmButton: false,
                    });
                  }
                  var startDate = new Date(dateFrom); //YYYY-MM-DD
                  var endDate = new Date(dateTo); //YYYY-MM-DD
                  const dates = getDateArray(startDate, endDate);

                  navigate("/booking/" + selectedtab + "/" + id, {
                    state: {
                      selectedtab: selectedtab,
                      data: selectedroomtype,
                      totalAmount: totalAmount,
                      dates: dates,
                      guest: formData.noofGuest,
                    },
                  });
                }}
              >
                Book now
              </button>

              {/* <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                <Button
                  type="button"
                  className="sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                >
                  <b> Whatsapp Us </b>
                </Button>
              </a> */}
              <div className="d-flex justify-content-between align-items-center totalrow">
                <span style={{ color: "#797979", fontSize: "1.5rem" }}>
                  Total
                </span>
                <span
                  style={{
                    fontSize: "2rem",
                    fontWeight: "550",
                  }}
                >
                  {NumberSeprator(Math.floor(totalAmount * currency[country]))}{" "}
                  {country}
                </span>
              </div>
              <span
                style={{
                  marginTop: "1rem",
                  display: "block",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {numberToEnglish(Math.floor(totalAmount * currency[country]))}
              </span>
              <span
                style={{
                  marginTop: "1rem",
                  display: "block",
                  textAlign: "center",
                }}
              >
                Tax and Service charge included
              </span>
              <div className="mt-2">
                <div class="booking-text1 desktop">
                  <div className="mt-2 d-flex justify-content-center">
                    <div class="booking-text1 desktop">
                      <ul className="ul-list">
                        <li className="ul-list-li">
                          <i class="far fa-clock blue-colr ul-li-i"></i> It only
                          takes 2 minutes
                        </li>
                        <li className="ul-list-li">
                          <i class="fas fa-percent blue-colr ul-li-i"></i>{" "}
                          Include all Taxes &amp; Service charge
                        </li>
                        <li className="ul-list-li">
                          <i class="fas fa-eye-slash blue-colr ul-li-i"></i> No
                          Hidden Cost
                        </li>
                        <li className="ul-list-li">
                          <i class="far fa-credit-card blue-colr ul-li-i"></i>{" "}
                          No Booking or Credit card fees!!
                        </li>
                        <li className="ul-list-li">
                          <i class="fas fa-mobile-alt blue-colr ul-li-i"></i>
                          Show mobile or printed voucher
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
        </div>
      </div>

      {/*Yellow Carousel  */}
      {/* <ProductPageBlogSection
        title={"Your holiday, our adventure"}
        description={`Let us guide you make your holiday the most fun and memorable one yet. We'll take you to all the best spots, plan all the activities, and make sure you have everything you need to relax and enjoy yourself`}
        data={[]}
        pageType={TabBarLabels.Packages}
      /> */}

      {/* Location Details */}

      {/* <ProductPagePolicySection
        location={{
          longitude: hotel != undefined ? hotel?.longitude : "",
          latitude: hotel != undefined ? hotel?.latitude : "",
        }}
        hotepolicy={hotel?.policyExclusion}
        heading={"Package"}
      /> */}

      {/* <Testimonial type={"Packages"} /> */}

      {/* <PartnersCarousel /> */}
    </div>
  );
};

export default PackagesDetailScreen;
