import React from "react";

function TableHead({ title }) {
  return (
    <th
      class="sorting"
      tabindex="0"
      aria-controls="example"
      rowspan="1"
      colspan="1"
      aria-label="Edit Date: activate to sort column ascending"
      style={{ width: "118px" }}
    >
      {title}
    </th>
  );
}

export default TableHead;
