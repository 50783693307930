import React from "react";

function Ticketupcoming(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1955_13743)">
        <path
          d="M2.25 15.9953C2.25018 15.8226 2.30998 15.6552 2.4193 15.5214C2.52862 15.3877 2.68075 15.2958 2.85 15.2612C3.52817 15.1235 4.13788 14.7556 4.57583 14.2198C5.01377 13.684 5.25301 13.0132 5.25301 12.3212C5.25301 11.6292 5.01377 10.9585 4.57583 10.4227C4.13788 9.88687 3.52817 9.51894 2.85 9.38123C2.68075 9.34668 2.52862 9.25477 2.4193 9.12103C2.30998 8.98728 2.25018 8.8199 2.25 8.64717V6.32123C2.25 6.12232 2.32902 5.93155 2.46967 5.7909C2.61032 5.65025 2.80109 5.57123 3 5.57123H21C21.1989 5.57123 21.3897 5.65025 21.5303 5.7909C21.671 5.93155 21.75 6.12232 21.75 6.32123V8.64717C21.7498 8.8199 21.69 8.98728 21.5807 9.12103C21.4714 9.25477 21.3192 9.34668 21.15 9.38123C20.4718 9.51894 19.8621 9.88687 19.4242 10.4227C18.9862 10.9585 18.747 11.6292 18.747 12.3212C18.747 13.0132 18.9862 13.684 19.4242 14.2198C19.8621 14.7556 20.4718 15.1235 21.15 15.2612C21.3192 15.2958 21.4714 15.3877 21.5807 15.5214C21.69 15.6552 21.7498 15.8226 21.75 15.9953V18.3212C21.75 18.5201 21.671 18.7109 21.5303 18.8516C21.3897 18.9922 21.1989 19.0712 21 19.0712H3C2.80109 19.0712 2.61032 18.9922 2.46967 18.8516C2.32902 18.7109 2.25 18.5201 2.25 18.3212V15.9953Z"
          stroke={props.color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.1523 14.2697L15.3485 11.2697"
          stroke={props.color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.5254 14.3433L15.3489 11.2698L12.2754 10.4462"
          stroke={props.color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.5 5.57123V19.0712"
          stroke={props.color}
          stroke-dasharray="2 2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1955_13743">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.321228)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Ticketupcoming;
