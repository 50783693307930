import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishlistData: [],
  error: "",
  loading: false,
};

export const wishlistReducer = createSlice({
  name: "wishlistReducer",
  initialState,
  reducers: {
    wishlistUpdate: (state, actions) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.wishlistData = actions.payload;
      state.error = "";
      state.loading = "";
      state.token = "";
    },
    addwishlistUpdate: (state, actions) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.wishlistData = [...state.wishlistData, actions.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { wishlistUpdate, addwishlistUpdate } = wishlistReducer.actions;

export default wishlistReducer.reducer;
