import React from "react";
import { Navigation, Pagination } from "swiper/modules";
import { BASE_URL_IMAGE } from "../Helper/helper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ImageSwiper.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function ImageSwiper({ hotel }) {
  const imageStyle = {
    width: "600px", // Set the desired width
    height: "500px", // Set the desired height
    objectFit: "cover", // Maintain aspect ratio and cover the container
    borderRadius: "10px", // Optional: Add border-radius for rounded corners
  };

  return (
    <div>
      <Swiper
        navigation={true}
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true }}
        loop={true}
        centeredSlides={true}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 100,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 240,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 150,
          },
        }}
        className="mySwiper2"
      >
        <SwiperSlide>
          <div
            className="image-box-more-car d-flex justify-content-center align-items-center"
            style={{ overflow: "hidden" }}
          >
            <img
              src={BASE_URL_IMAGE + hotel.images}
              className="hotel-more-img"
              style={imageStyle}
            />
          </div>
        </SwiperSlide>

        {hotel?.moreImages?.map(
          (item, index) =>
            index <= 3 && (
              <SwiperSlide key={index}>
                <div
                  className="image-box-more-car d-flex justify-content-center align-items-center"
                  style={{ overflow: "hidden" }}
                >
                  <img
                    src={BASE_URL_IMAGE + item}
                    className="hotel-more-img"
                    style={imageStyle}
                  />
                </div>
              </SwiperSlide>
            )
        )}
      </Swiper>
    </div>
  );
}

export default ImageSwiper;
