import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";
import { useSwiper } from "swiper/react";
import { Navigation } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { END_POINT } from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";

export const PartnerScreen = () => {
  // const [swiperRef, setSwiperRef] = useState(null);
  const swiperRef = useSwiper();
  const prevHandler = () => {
    swiperRef.slidePrev();
  };

  const nextHandler = () => {
    swiperRef.slideNext();
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    handler();
  }, []);

  const handler = async () => {
    try {
      const result = await fetchData(END_POINT.GetDataWhyChooseUs);

      if (result.success == true) {
        setData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const programSectionRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const scrollToProgramSection = () => {
    // Scroll to the program section
    programSectionRef.current.scrollIntoView({ behavior: 'smooth' });

    // Toggle the dropdown
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      {/* Navbar */}
      {/* <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light p-4 header-nav">
        <a class="navbar-brand" href="#">
          <img
            src="/assets/img/output.png"
            alt="Logo"
            className="bharat-logo img-fluid"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav align-items-center d-flex">
            <li class="nav-item">
              <a class="nav-link" href="#">
                Why us?
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                Our Services
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                Contact us
              </a>
            </li>
          </ul>
        </div>
      </nav> */}
      {/* <Header /> */}

      {/*Partner Banner*/}
      {/* <div class="container partner-banner">
        <div class="row">
          <div class="col-lg-12 md-12 sm-12 align-items-center partner-banner-content p-0">
            <h2 class="partner-banner-title">
              Welcome to <span>Bharatchoice.world</span>
            </h2>
            <h3>The world of Indian preferred hospitality services</h3>
            <p>
              Bharatchoice.world is an OTA platform that provides tourism and
              hospitality services by <br /> understanding the needs of Indian
              tourists traveling around the world.
            </p>
          </div>

          <div className="row d-flex justify-content-center button-row-partner">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <button className="btn partner-banner-btn" onClick={scrollToProgramSection}>
                Become a partner
              </button>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <a href="#learn-more">
                <button className="btn learn-more-btn">Learn More</button>
              </a>
            </div>
          </div>


        </div>
      </div> */}

      <div className="container partner-banner">
        <div className="row">
          <div className="col-lg-12 md-12 sm-12 align-items-center partner-banner-content p-0">
            <h2 className="partner-banner-title">
              Welcome to <span>Bharatchoice.world</span>
            </h2>
            <h3>The world of Indian preferred hospitality services</h3>
            <p>
              Bharatchoice.world is an OTA platform that provides tourism and
              hospitality services by
              <br /> understanding the needs of Indian tourists traveling around the
              world.
            </p>
          </div>
          <div className="row mx-auto button-row-partner width44">
            <div className="col-lg-6 md-6 sm-12">
              <button className="btn partner-banner-btn" onClick={scrollToProgramSection}>
                Become a partner
              </button>
            </div>
            <div className="col-lg-6 md-6 sm-12">
              <a href="#learn-more">
                <button className="btn learn-more-btn">Learn More</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Vision Section */}
      <div className="container vision">
        {/* <div className="row visionrow justify-content-center">
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center logodiv p-4">
            <img src="/assets/img/vision-logo.png" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 vision-text">
            <h2 className="vision-text-head">Our vision</h2>
            <p className="vision-text-p">
              <span>Your journey is our journey.</span> The vision of
              bharatchoice.world is to fulfill the needs of Indian traveling the
              world with our new certified standards at one touch of the screen.
            </p>
          </div>
        </div> */}
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-4">
            <img src="/assets/img/vision-logo.png" className="img-fluid" style={{ width: '300px' }} />
          </div>
          <div className="col-lg-7 col-md-6 col-sm-12 vision-text">
            <h2 className="vision-text-head">Our vision</h2>
            <p className="vision-text-p">
              <span>Your journey is our journey.</span> The vision of bharatchoice.world is to fulfill the needs of Indian traveling the world with our new certified standards at one touch of the screen.
            </p>
          </div>
        </div>

      </div>


      <div className="why-carousel-wrapper">
        <div className="row carouselcontainer">
          <div className="col-lg-4 md-4 sm-12 mx-auto">
            <h2 className="why-bharat-choice">Why Bharatchoice?</h2>
            <p className="why-bharat-choice-p">
              Our platform aims to support our partners via the instructions,
              marketing and sales for consistent revenue and sustainable growth
              for the world fastest growing market.
            </p>
          </div>
          <div className="col-lg-8 md-8 sm-12 mx-auto slider-container">
            <Swiper
              // navigation={true}
              modules={[Navigation]}
              slidesPerView={2}
              spaceBetween={5}
              loop={true}
              className="mySwiper"
            >
              {data.length > 0 &&
                data.map((item, index) => {
                  return (
                    <SwiperSlide>
                      <div className="bg-white p-4 m-2 rounded why-bharat-slide">
                        <img
                          src="/assets/img/iconslide1.svg"
                          className="img-fluid mb-2"
                        />
                        <h3> {item.title}</h3>
                        <p>{item.description}</p>
                      </div>
                    </SwiperSlide>
                  );
                })}

              <SwiperSlide>
                <div className="bg-white p-4 m-2 rounded why-bharat-slide">
                  <img
                    src="/assets/img/iconslide1.svg"
                    className="img-fluid mb-2"
                  />
                  <h3>Enhancing your traveling experience</h3>
                  <p>
                    Bharat Choice will upscale the potential of service
                    providers to various establishments and be ready according
                    to the needs of Indian Worldwide consumers.
                  </p>
                </div>
              </SwiperSlide>


              {/* <SwiperSlide>
                <div className="bg-white p-4 m-2 rounded why-bharat-slide">
                  <img
                    src="/assets/img/iconslide1.svg"
                    className="img-fluid mb-2"
                  />
                  <h3>Enhancing your traveling experience</h3>
                  <p>
                    Bharat Choice will upscale the potential of service
                    providers to various establishments and be ready according
                    to the needs of Indian Worldwide consumers.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white p-4 m-2 rounded why-bharat-slide">
                  <img
                    src="/assets/img/iconslide2.svg"
                    className="img-fluid mb-2"
                  />
                  <h3>Building and shaping trends</h3>
                  <p>
                    Bharat Choice will build confidence and awaken tourism
                    trends for Indian tourists around the world, who have
                    special restrictions on traveling to new destinations.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white p-4 m-2 rounded why-bharat-slide">
                  <img
                    src="/assets/img/iconslide1.svg"
                    className="img-fluid mb-2"
                  />
                  <h3>Enhancing your traveling experience</h3>
                  <p>
                    Bharat Choice will upscale the potential of service
                    providers to various establishments and be ready according
                    to the needs of Indian Worldwide consumers.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white p-4 m-2 rounded why-bharat-slide">
                  <img
                    src="/assets/img/iconslide1.svg"
                    className="img-fluid mb-2"
                  />
                  <h3>Enhancing your traveling experience</h3>
                  <p>
                    Bharat Choice will upscale the potential of service
                    providers to various establishments and be ready according
                    to the needs of Indian Worldwide consumers.
                  </p>
                </div>
              </SwiperSlide> */}
              <SwiperNavButtons />
            </Swiper>
          </div>

        </div>
      </div>

      {/* Blogs section */}
      <div className="services-wrapper">
        <div className="container services-section">
          <div className="row">
            <h2>Our services</h2>
            <div className="col-lg-3 md-3 sm-5 services-cards">
              <img src="/assets/img/servicecard1.png" className="img-fluid" />
            </div>
            <div className="col-lg-3 md-3 sm-5 services-cards">
              <img src="/assets/img/servicecard2.png" className="img-fluid" />
            </div>
            <div className="col-lg-3 md-3 sm-5 services-cards">
              <img src="/assets/img/servicecard3.png" className="img-fluid" />
            </div>
            <div className="col-lg-3 md-3 sm-5 services-cards">
              <img src="/assets/img/servicecard4.png" className="img-fluid" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 md-3 sm-5 services-cards">
              <img src="/assets/img/servicecard5.png" className="img-fluid" />
            </div>
            <div className="col-lg-3 md-3 sm-5 services-cards">
              <img src="/assets/img/servicecard6.png" className="img-fluid" />
            </div>
            <div className="col-lg-3 md-3 sm-5 services-cards">
              <img src="/assets/img/servicecard7.png" className="img-fluid" />
            </div>
            <div className="col-lg-3 md-3 sm-5 services-cards">
              <img src="/assets/img/servicecard8.png" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      {/* Certificate section */}
      <div className="container certfied-container mt-4 mb-4" id="learn-more">
        <div className="certfied-head">
          <h4>Bharatchoice is the perfect solution for</h4>
          <p className="certified-text">
            Bharat Choice is the perfect solution for solving the pain points
            for our vegetarian, <br /> jain, and vegan tourists by fulfilling
            the needs and requirements of Indian <br /> travellers worldwide by
            granting world-class certified standards
          </p>
          <img
            src="/assets/img/graphic_airplane.svg"
            className="img-fluid airplane"
          />
        </div>
        <div className="row">
          <div className="col-lg-6 md-6 sm-12">
            <img src="/assets/img/Certficate.svg" className="img-fluid" />
          </div>
          <div className="col-lg-6 md-6 sm-12">
            <div className="checklist-cont">
              <img
                src="/assets/img/iconchart-lineup.svg"
                className="img-fluid"
              />
              <h4 className="checklist-head">The Standard Role</h4>
              <ul className="custom-bullet">
                <li className="checklist-item">
                  To building confidence for the Indian tourists
                </li>
                <li className="checklist-item">
                  To awaken the tourism trends to new destinations
                </li>
                <li className="checklist-item">
                  To being the preferred choice for Indian worldwide
                </li>
              </ul>
            </div>
            <div className="checklist-cont mt-4 mb-4">
              <img src="/assets/img/iconstand-icon.svg" className="img-fluid" />
              <h4 className="checklist-head">The Standard to</h4>
              <ul className="custom-bullet">
                <li className="checklist-item">
                  Indian friendly hotel & resort in Thailand
                </li>
                <li className="checklist-item">
                  Indian friendly restaurants in Thailand
                </li>
                <li className="checklist-item">
                  Attractive places in Thailand
                </li>
              </ul>
            </div>
            <div className="checklist-cont">
              <img src="/assets/img/iconworld-icon.svg" className="img-fluid" />
              <h4 className="checklist-head">The Standard for</h4>
              <ul className="custom-bullet">
                <li className="checklist-item">Indian tourists worldwide</li>
                <li className="checklist-item">Veggie tourists worldwide</li>
                <li className="checklist-item">
                  Jainism - Vegan tourists worldwide
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Special Events Section */}
      <div className="program-wrapper" id="program-section" >
        <div className="container program-section">
          <div className="row">
            <div className="col-lg-6 md-6 sm-12">
              <div>
                <img src="/assets/img/program1.svg" className="img-fluid" />
                <img src="/assets/img/program2.svg" className="img-fluid" />
              </div>
              <div>
                <img src="/assets/img/program3.svg" className="img-fluid" />
                <img src="/assets/img/program4.svg" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 md-6 sm-12 program-text" ref={programSectionRef}>
              <h1>What is Bharatchoice program?</h1>
              <p>
                This program is offered to only selected potential partners, who
                are keen to invest in our journey to attract from Indian
                tourists worldwide.
              </p>
              <div class="dropdown">
                <button
                  class="btn partner-btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Partner with us{" "}
                  <i
                    class="fa-solid fa-angle-down"
                    style={{ color: "#0A3874" }}
                  ></i>
                </button>
                <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="https://docs.google.com/forms/d/e/1FAIpQLSf269orndthnyDbMbJM3Q29lUczj4x9IC_mpSrlfk4NaJahFg/viewform" target="_blank">
                    Hotel
                  </a>
                  <a class="dropdown-item" href="https://docs.google.com/forms/d/e/1FAIpQLSfR0bdERTbAbdg9y6szHauWI1zJWaMPZ26-wrCR0Va8O3zicQ/viewform" target="_blank">
                    Dining
                  </a>
                  <a class="dropdown-item" href="https://docs.google.com/forms/d/e/1FAIpQLScM-Zp92_0tzZ6bUqhPLPC3gyAYF69HbIY0bDlyO1EyHsJEvg/viewform" target="_blank">
                    Attraction
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      {/* <Footer /> */}
    </div >
  );
};
