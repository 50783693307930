import React, { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import VideoSwiper from "./VideoSwiper";
import { BASE_URL_IMAGE, END_POINT } from "../Helper/helper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavYellow } from "./SwiperNavYellow";
import "swiper/css";
import "swiper/css/pagination";
import { fetchData } from "../Helper/ApiCall";
function ProductPageBlogSection({ title, description, data, pageType }) {
  const [dataSwiper, setdataSwiper] = useState([]);

  useEffect(() => {
    Handler();
  }, []);
  const Handler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetDetailByTypeDataProductBlog + pageType
      );
      if (result.success == true) {
        setdataSwiper(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    dataSwiper.length > 0 && (
      <div>
        <div className="unique-carousel-wrapper">
          <div className="row carouselcontainer ">
            <div
              className="col-lg-8 md-8 sm-12 mx-auto slider-container "
              id="unique-slider"
            >
              <Swiper
                // navigation={true}
                modules={[Navigation]}
                slidesPerView={2}
                spaceBetween={5}
                loop={true}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                    spaceBetween: 5,
                  },
                  "@0.75": {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  "@1.00": {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  "@1.50": {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                }}
                className="why-caro-swiper"
              >
                {dataSwiper.map((item) => {
                  return (
                    <SwiperSlide>
                      <div className="rounded unique-slide">
                        <div className="welcome-card-img-cont">
                          <img
                            src={BASE_URL_IMAGE + item.image}
                            className="img-fluid mb-2"
                          />
                        </div>

                        <div className="lifelong-content">
                          <h5 style={{ color: "#333333" }}>{item.title}</h5>
                          <p style={{ color: "#333333" }}>{item.description}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
                {/* <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>Pattaya dining place</h5>
                    <p style={{ color: "#333333" }}>
                      Visit one of the most beautiful island Thailand has to
                      offer with us
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>
                      Guide to coral Island at Pattaya
                    </h5>
                    <p style={{ color: "#333333" }}>
                      Visit one of the most beautiful island Thailand has to
                      offer with us
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>M.I.C.E event title</h5>
                    <p style={{ color: "#333333" }}>
                      Visit one of the most beautiful island Thailand has to
                      offer with us
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>M.I.C.E event title</h5>
                    <p style={{ color: "#333333" }}>
                      Visit one of the most beautiful island Thailand has to
                      offer with us
                    </p>
                  </div>
                </div>
              </SwiperSlide> */}
                <div className="button-cont">
                  <SwiperNavYellow />
                </div>
              </Swiper>
            </div>

            <div className="col-lg-4 md-4 sm-12 mx-auto" id="unique-cont">
              <h2 className="unique-text">{title}</h2>
              <p className="unique-text-p">{description}</p>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default ProductPageBlogSection;
