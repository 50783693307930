import React, { useState, useEffect } from "react";
import { END_POINT, METHODS, BASE_URL_IMAGE } from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const AddMiceConcept = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = location?.state?.id;

  const [formData, setformData] = useState({
    title: "",
    description: "",
    link: "",
    image: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");
  const [banner, setbanner] = useState("");
  const [catData, setcatData] = useState([]);

  useEffect(() => {
    // categoryHandler();
    if (cityid != undefined) {
      handler();
    }
  }, []);

  const handler = async () => {
    try {
      const result = await fetchData(END_POINT.GetMiceConceptByid + cityid);

      if (result.success == true) {
        setformData({
          title: result.data.title,
          description: result.data.description,
          link: result.data.link,
          image: result.data.image,
        });
        setdesc(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.description)
            )
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (uploadedImage) => {
    var raw = JSON.stringify({
      title: formData.title,
      description: formData.description,
      link: formData.link,
      image: uploadedImage,
    });
    console.log("raw", raw);
    try {
      const finalEndpoint =
        cityid != undefined
          ? END_POINT.UpdateMiceConcept + cityid
          : END_POINT.AddMiceConcept;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../mice-concept-list");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        const uploadedImagebanner = values[0].image;

        handleSubmit(uploadedImagebanner);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const AllFileUpdate = async () => {
    Promise.all([image != "" && (await ImageUpload(image))])
      .then((values) => {
        const uploadedImagedininglist =
          values[0].image == false ? formData.image : values[0].image;

        handleSubmit(uploadedImagedininglist);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [desc, setdesc] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState, key) => {
    setdesc(editorState);

    setformData({
      ...formData,
      [key]: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };
  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add Mice Concept</b>
        </h3>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Title:</span> <br />
            <input
              type="text"
              id="title"
              name="title"
              className="input-fields-style"
              value={formData.title}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Description:</span> <br />
          <Editor
            editorState={desc}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(e) => {
              onEditorStateChange(e, "description");
            }}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Link:</span> <br />
            <input
              type="text"
              id="link"
              name="link"
              className="input-fields-style"
              value={formData.link}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Image *</span> <br />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
            {formData.image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.image}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-5"
              >
                view image
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            if (cityid != undefined) {
              if (image != "") {
                AllFileUpdate();
              } else {
                handleSubmit(formData.image);
              }
            } else {
              AllFileUpload();
            }
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../mice-concept-list");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddMiceConcept;
