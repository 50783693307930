import React, { useRef, useState, useEffect } from "react";

import {
  BASE_URL_IMAGE,
  END_POINT,
  METHODS,
  NumberSeprator,
  TabBarLabels,
} from "../../Helper/helper";
import { FileUpload, fetchData } from "../../Helper/ApiCall";
import Swal from "sweetalert2";

import "swiper/css";
import "swiper/css/pagination";
import "../../../src/style.css";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Accordion1 from "../../components/Accordion1";

function Contact() {
  const [key, setKey] = useState("Hotel");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    bharatchoicepnr: "",
    bharatchoicecategory: "",
    bharatchoicecategory_2: "",
    file: "",
    comment: "",
    type: "",
    event_date: "",
    event_name: "",
    event_type: "",
    estimate_guest: "",
    estimate_room: "",
  });
  const [image, setimage] = useState("");
  const [faqData, setfaqData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const Validation = () => {
    if (formData.name == "") {
      return "Enter name";
    } else if (formData.email == "") {
      return "Enter email";
    } else if (formData.mobile == "") {
      return "Enter mobile";
    } else if (formData.comment == "") {
      return "Enter comment";
    } else {
      return true;
    }
  };

  const AllFileUpload = async () => {
    const validate = Validation();
    if (validate == true) {
      Promise.all([await FileUpload(image)])
        .then((values) => {
          const uploadedImagebanner = values[0].image;
          handleSubmit(uploadedImagebanner);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      alert(validate);
    }
  };
  const handleSubmit = async (uploadedImagebanner) => {
    var raw = JSON.stringify({
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      bharatchoicepnr: formData.bharatchoicepnr,
      bharatchoicecategory: formData.bharatchoicecategory,
      bharatchoicecategory_2: formData.bharatchoicecategory_2,
      file: uploadedImagebanner,
      comment: formData.comment,

      event_date: formData.event_date,
      event_name: formData.event_name,
      event_type: formData.event_type,
      estimate_guest: formData.estimate_guest,
      estimate_room: formData.estimate_room,
    });
    console.log("raw", raw);
    try {
      const result = await fetchData(
        END_POINT.UploadCustomerHelp,
        METHODS.POST,
        raw
      );
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hotelCategory = [
    "Other",
    "Postpone or Cancel booking",
    "Help & Support",
    "Complains",
  ];
  const dinningCategory = ["Car", "Hotel", "Wedding", "Tour"];
  const listCategory = [
    "Hotel",
    "Dining",
    "Activities",
    "Package",
    "Vehicles",
    "Serenade",
    "Golf",
    "Wellness",
    "MICE",
    "Wedding",
  ];
  useEffect(() => {
    faqHandler();
  }, []);
  const faqHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetDataFAQ);
      if (result.success == true) {
        setfaqData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Container>
        {/* <TabComp /> */}
        <div className="mt-4 mb-4">
          <Accordion defaultActiveKey="0">
            {faqData.length > 0 &&
              faqData.map((item, index) => {
                console.log("faqData", faqData);

                return (
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header> {item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </div>

        {/* {faqData.length > 0 &&
          faqData.map((item, index) => {
            console.log("faqData", faqData);

            return (
              <>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                >
                  {item.question}
                </button>

                <div
                  id={`collapse${index}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionMenu"
                >
                  <div className="accordion-body">{item.answer}</div>
                </div>
              </>
            );
          })} */}
        {/* <Container className="mt-4 mb-4">
          <Accordion1 />
        </Container> */}

        <div className="d-flex flex-column justify-content-center align-items-center">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              setFormData({
                ...formData,
                bharatchoicepnr: "",
                bharatchoicecategory: "",
                bharatchoicecategory_2: "",
              });
            }}
            className="mb-3 d-flex flex-row"
          >
            {listCategory.map((item) => {
              return <Tab eventKey={item} title={item} />;
            })}
          </Tabs>
        </div>
        <div className="py-4">
          <form class="new-added-form">
            <div class="form-row row justify-content-around">
              <div class="col-md-5 contact-form-col1">
                <div class="form-group instyle">
                  <label>Full Name* </label>

                  <input
                    type="text"
                    class="form-control"
                    placeholder="Full Name"
                    name="name"
                    required=""
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div class="form-group instyle">
                  <label>Mobile Number* (Whatsapp)</label>

                  <input
                    type="text"
                    class="form-control"
                    placeholder="Mobile Number"
                    name="mobile"
                    required=""
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                </div>

                <div class="form-group instyle">
                  <label>Email Address*</label>

                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email ID"
                    name="email"
                    required=""
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                {key == "MICE" || key == "Wedding" ? (
                  <>
                    <div class="form-group instyle">
                      <label>EVENT DATE</label>
                      <input
                        type={"date"}
                        class="form-control"
                        placeholder="EVENT DATE"
                        name="event_date"
                        value={formData.event_date}
                        onChange={handleChange}
                      />
                    </div>
                    <div class="form-group instyle">
                      <label>EVENT NAME (COPORATE NAME)</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="EVENT NAME"
                        name="event_name"
                        value={formData.event_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div class="form-group instyle">
                      <label>EVENT TYPE</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="EVENT TYPE"
                        name="event_type"
                        value={formData.event_type}
                        onChange={handleChange}
                      />
                    </div>
                    <div class="form-group instyle">
                      <label>ESTIMATE NO. OF GUEST</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="ESTIMATE NO. OF GUEST"
                        name="estimate_guest"
                        value={formData.estimate_guest}
                        onChange={handleChange}
                      />
                    </div>
                    <div class="form-group instyle">
                      <label>ESTIMATE NO. OF ROOM</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="ESTIMATE NO. OF ROOM"
                        name="estimate_room"
                        value={formData.estimate_room}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div class="form-group instyle">
                      <label>Bharat Choice PNR</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Bharat Choice PNR"
                        name="bharatchoicepnr"
                        value={formData.bharatchoicepnr}
                        onChange={handleChange}
                      />
                    </div>

                    <div class="form-group instyle">
                      <label>Bharat Choice Catergory</label>
                      <select
                        class="custom-select"
                        name="bharatchoicecategory"
                        value={formData.bharatchoicecategory}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {key == "Hotel"
                          ? hotelCategory.map((item) => {
                              return <option value={item}>{item}</option>;
                            })
                          : dinningCategory.map((item) => {
                              return <option value={item}>{item}</option>;
                            })}
                      </select>
                    </div>

                    <div class="form-group instyle">
                      <label>Bharat Choice Catergory</label>
                      <select
                        class="custom-select"
                        name="bharatchoicecategory_2"
                        value={formData.bharatchoicecategory_2}
                        onChange={handleChange}
                      >
                        <option value="">Select Category</option>
                        {listCategory.map((item) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </select>
                    </div>
                    <div class="form-group instyle">
                      <label>Upload a File</label>
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="image"
                          name="image"
                          onChange={(e) => {
                            setimage(e.target.files[0]);
                          }}
                        />
                        <label
                          class="custom-file-label"
                          for="customFileLangHTML"
                          data-browse="Browse"
                        ></label>
                        <small id="emailHelp" class="form-text text-muted">
                          (only doc,jpg,png,gif,tiff,bmp,txt allowed. Max. file
                          size is 500 KB)
                        </small>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div class="col-md-5 contact-form-col2">
                <div class="form-group instyle">
                  <label>Question/Comments</label>
                  <textarea
                    class="form-control"
                    rows="8"
                    name="comment"
                    id="comment"
                    placeholder="Question/Comments"
                    value={formData.comment}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="container text-left p-0 mt-2 button-cont">
              <button
                type="button"
                class="btn btn-primary"
                onClick={AllFileUpload}
              >
                Submit Now
              </button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
}

export default Contact;
