import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { fetchData } from "../../Helper/ApiCall";
import {
  END_POINT,
  BASE_URL_IMAGE,
  METHODS,
  NumberSeprator,
  numberToEnglish,
  TabBarLabels,
  BASE_URL,
} from "../../Helper/helper";
import EditorRead from "../../components/EditorRead";
import Testimonial from "../../components/Testimonial";
import PartnersCarousel from "../../components/PartnersCarousel";
import Subscribe from "../../components/Subscribe";
import { useSelector, useDispatch } from "react-redux";
import { homedataUpdate } from "../../redux/reducers/homeReducer";
import ActivityLoader from "../../components/ActivityLoader";

export const WeddingScreen = () => {
  const dispatch = useDispatch();

  const { HomeBanner, SectionSecond, RecomendedBlog, SectionForth, loading } =
    useSelector((store) => store.homeReducer);

  const navigate = useNavigate();
  const [data, setdata] = useState({
    WeddingDetail: [],
    Wedding: [],
    WeddingBanner: [],
    WeddingReal: [],
    WeddingInspiration: [],
  });
  const [TestimonialData, setTestimonialData] = useState([]);

  useEffect(() => {
    handlerData();
    if (SectionForth == "") {
      homeHandler();
    }
    // handler();
    // handlerInspiration();
  }, []);
  const handlerData = async () => {
    try {
      const result = await fetchData(END_POINT.GetWeddingPageData);
      if (result.success == true) {
        setdata({
          WeddingDetail: result.data.WeddingDetail,
          Wedding: result.data.Wedding,
          WeddingBanner: result.data.WeddingBanner,
          WeddingReal: result.data.WeddingReal,
          WeddingInspiration: result.data.WeddingInspiration,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const homeHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetWebHomeAllData);
      console.log(result);
      if (result.success == true) {
        dispatch(homedataUpdate(result.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetDetailByPageDataTestimonial + "Wedding"
      );
      if (result.success == true) {
        setTestimonialData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}

      {/* Hero Banner */}
      <div class="hero-banner">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          modules={[Autoplay]}
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
          }}
          //   breakpoints={{
          //     "@0.00": {
          //       slidesPerView: 2,
          //       spaceBetween: 5,
          //     },
          //     "@0.75": {
          //       slidesPerView: 3,
          //       spaceBetween: 10,
          //     },
          //     "@1.00": {
          //       slidesPerView: 4,
          //       spaceBetween: 30,
          //     },
          //     "@1.50": {
          //       slidesPerView: 5,
          //       spaceBetween: 40,
          //     },
          //   }}

          className="herocarousel"
        >
          {data.WeddingBanner.map((item) => {
            return (
              <SwiperSlide>
                <div
                  className="herocarouselslide"
                  style={{
                    backgroundImage: `url(${BASE_URL_IMAGE + item.image})`,
                  }}
                >
                  <div className="col-9 herobanner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                    {/* <h1>
                      <span>The </span>Wedding Galore
                    </h1> */}

                    <h1>
                      <span>{item.title.split(" ")[0]}</span>{" "}
                      {item.title.split(" ").slice(1).join(" ")}
                    </h1>

                    {/* <h3>
                      Special Events & Wedding Planner Service in Thailand
                    </h3> */}
                    <p>{item.description}</p>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={"/enquiry"}
                      state={{ title: "Wedding" }}
                    >
                      <button
                        className="btn getintouchbtn"
                        style={{ marginTop: "24px" }}
                      >
                        Get in touch
                      </button>
                    </Link>
                  </div>
                  <div class="overlay"></div>
                  <div className="row"></div>
                </div>
              </SwiperSlide>
            );
          })}

          {/* <SwiperSlide>
            <div className="herocarouselslide">
              <div className="row">
                <div className="col-9 herobanner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                  <h1>
                    <span>The </span>Wedding Galore
                  </h1>
                  <h3>Special Events & Wedding Planner Service in Thailand</h3>
                  <p>
                    From sandy beaches to city streets, we're committed to
                    creating the perfect wedding for every couple. Our focus is
                    on understanding the needs of both families and paying
                    attention to every detail to make their dream wedding a
                    reality.
                  </p>
                  <button className="btn getintouchbtn">Get in touch</button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="herocarouselslide">
              <div className="row">
                <div className="col-9 herobanner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                  <h1>
                    <span>The </span>Wedding Galore
                  </h1>
                  <h3>Special Events & Wedding Planner Service in Thailand</h3>
                  <p>
                    From sandy beaches to city streets, we're committed to
                    creating the perfect wedding for every couple. Our focus is
                    on understanding the needs of both families and paying
                    attention to every detail to make their dream wedding a
                    reality.
                  </p>
                  <button className="btn getintouchbtn">Get in touch</button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="herocarouselslide">
              <div className="row">
                <div className="col-9 herobanner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                  <h1>
                    <span>The </span>Wedding Galore
                  </h1>
                  <h3>Special Events & Wedding Planner Service in Thailand</h3>
                  <p>
                    From sandy beaches to city streets, we're committed to
                    creating the perfect wedding for every couple. Our focus is
                    on understanding the needs of both families and paying
                    attention to every detail to make their dream wedding a
                    reality.
                  </p>
                  <button className="btn getintouchbtn">Get in touch</button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="herocarouselslide">
              <div className="row">
                <div className="col-9 herobanner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                  <h1>
                    <span>The </span>Wedding Galore
                  </h1>
                  <h3>Special Events & Wedding Planner Service in Thailand</h3>
                  <p>
                    From sandy beaches to city streets, we're committed to
                    creating the perfect wedding for every couple. Our focus is
                    on understanding the needs of both families and paying
                    attention to every detail to make their dream wedding a
                    reality.
                  </p>
                  <button className="btn getintouchbtn">Get in touch</button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="herocarouselslide">
              <div className="row">
                <div className="col-9 herobanner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                  <h1>
                    <span>The </span>Wedding Galore
                  </h1>
                  <h3>Special Events & Wedding Planner Service in Thailand</h3>
                  <p>
                    From sandy beaches to city streets, we're committed to
                    creating the perfect wedding for every couple. Our focus is
                    on understanding the needs of both families and paying
                    attention to every detail to make their dream wedding a
                    reality.
                  </p>
                  <button className="btn getintouchbtn">Get in touch</button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="herocarouselslide">
              <div className="row">
                <div className="col-9 herobanner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                  <h1>
                    <span>The </span>Wedding Galore
                  </h1>
                  <h3>Special Events & Wedding Planner Service in Thailand</h3>
                  <p>
                    From sandy beaches to city streets, we're committed to
                    creating the perfect wedding for every couple. Our focus is
                    on understanding the needs of both families and paying
                    attention to every detail to make their dream wedding a
                    reality.
                  </p>
                  <button className="btn getintouchbtn">Get in touch</button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="herocarouselslide">
              <div className="row">
                <div className="col-9 herobanner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                  <h1>
                    <span>The </span>Wedding Galore
                  </h1>
                  <h3>Special Events & Wedding Planner Service in Thailand</h3>
                  <p>
                    From sandy beaches to city streets, we're committed to
                    creating the perfect wedding for every couple. Our focus is
                    on understanding the needs of both families and paying
                    attention to every detail to make their dream wedding a
                    reality.
                  </p>
                  <button className="btn getintouchbtn">Get in touch</button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="herocarouselslide">
              <div className="row">
                <div className="col-9 herobanner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                  <h1>
                    <span>The </span>Wedding Galore
                  </h1>
                  <h3>Special Events & Wedding Planner Service in Thailand</h3>
                  <p>
                    From sandy beaches to city streets, we're committed to
                    creating the perfect wedding for every couple. Our focus is
                    on understanding the needs of both families and paying
                    attention to every detail to make their dream wedding a
                    reality.
                  </p>
                  <button className="btn getintouchbtn">Get in touch</button>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
      {/* Welcome Section */}
      <div className="lifelong-carousel-wrapper">
        <div className="row carouselcontainer">
          <div className="col-lg-4 md-4 sm-12 mx-auto">
            <h2 className="lifelong-text">A Lifelong Connection</h2>
            <p className="lifelong-text-p">
              Let us whisk you away to the dreamy weddings of these couples who
              tied the knot in grandeur.
            </p>
          </div>
          <div className="col-lg-8 md-8 sm-12 mx-auto slider-container">
            <Swiper
              // navigation={true}

              slidesPerView={2}
              spaceBetween={5}
              loop={true}
              className="why-caro-swiper"
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                "@0.75": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@1.00": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                "@1.50": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
              }}
            >
              {data.WeddingReal != undefined &&
                data.WeddingReal.map((item) => {
                  return (
                    item.wedding_type == "Venue" && (
                      <SwiperSlide>
                        <div className="rounded lifelong-slide">
                          <a href={item.wedding_link}>
                            <div className="welcome-card-img-cont">
                              <img
                                src="/assets/img/lifelong-slide1.svg"
                                className="img-fluid mb-2"
                              />
                            </div>

                            <div className="lifelong-content">
                              <h5 style={{ color: "#fff" }}>{item.title}</h5>
                              <p style={{ color: "#fff" }}>
                                {/* Choice of hotels promising the best deals in town */}
                                <EditorRead
                                  description={item.desc.slice(0, 70)}
                                />
                              </p>
                            </div>
                          </a>
                        </div>
                      </SwiperSlide>
                    )
                  );
                })}
              {/* <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#fff" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#fff" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#fff" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#fff" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#fff" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#fff" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#fff" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#fff" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide> */}
              <div className="button-cont">
                <SwiperNavButtons />
              </div>
            </Swiper>
          </div>
        </div>
      </div>
      <div className="container welcome-section">
        {/* <img
          src="/assets/img/top-right-leaf.svg"
          className="img-fluid top-right-leaf"
        /> */}
        <h2>Find your dream wedding!</h2>
        <p className="intro">
          Experience the elegance and inspiration of Thailand's top wedding
          venues in this series.
        </p>
        <div className="row d-flex justify-content-start align-items-center specialrow">
          {data.Wedding != "" &&
            data.Wedding.map((item) => {
              return (
                <div className="col-lg-3 md-3 sm-12 events-cards">
                  {/* <Link to={item.type == "wedding" ? "" : item.type}> */}
                  <a href={item.request_url}>
                    <div class="img-container-box">
                      <div>
                        <img
                          // src="/assets/img/destination-wed.jpeg"
                          src={BASE_URL_IMAGE + item.image}
                          class="img-fluid"
                        />
                      </div>
                      <div class="img-container-box-text">
                        <h3>
                          <a style={{ color: "white" }} href="">
                            {item.title}
                          </a>
                        </h3>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </a>

                  {/* </Link> */}
                </div>
              );
            })}
          {/* <div className="col-lg-3 md-3 sm-12 events-cards">
            <Link to={"wedding"}>
              <div class="img-container-box">
                <div>
                  <img src="/assets/img/Rectangle1.png" class="img-fluid" />
                </div>
                <div class="img-container-box-text">
                  <h3>
                    <a style={{ color: "white" }} href="">
                      Destination Wedding
                    </a>
                  </h3>
                  <p>
                    Exquisite Indian Weddings in Thailand with Unforgettable
                    Moments and Luxury Services
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 md-3 sm-12 events-cards">
            <Link to={"events"}>
              <div class="img-container-box">
                <div>
                  <img src="/assets/img/mice-events.jpeg" class="img-fluid" />
                </div>

                <div class="img-container-box-text">
                  <h3>
                    <a style={{ color: "white" }} href="">
                      Local Events
                    </a>
                  </h3>
                  <p>
                    Exciting Events and Festivals in Thailand: Concerts,
                    Parties, and Bollywood Nights
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 md-3 sm-12 events-cards">
            <Link to={"mice-events"}>
              <div class="img-container-box">
                <div>
                  <img
                    src="/assets/img/special-events-1.jpeg"
                    class="img-fluid"
                  />
                </div>
                <div class="img-container-box-text">
                  <h3>
                    <a style={{ color: "white" }} href="">
                      M.I.C.E Business
                    </a>
                  </h3>
                  <p>
                    Exceptional M.I.C.E Services in Thailand: Meetings,
                    Incentives & More
                  </p>
                </div>
              </div>
            </Link>
          </div> */}
        </div>
        <div className="row">
          {/* {data.WeddingInspiration.map((item) => {
            return (
              <div className="col-lg-4 md-6 sm-12 welcomecards">
                <div className="welcome-card-img-cont">
                  <img
                    src="/assets/img/dreamwedding-card.svg"
                    className="img-fluid"
                  />
                </div>
              </div>
            );
          })} */}

          {/* <div className="col-lg-4 md-6 sm-12 welcomecards">
            <img
              src="/assets/img/dreamwedding-card2.svg"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-4 md-6 sm-12 welcomecards">
            <img
              src="/assets/img/dreamwedding-card3.svg"
              className="img-fluid"
            />
          </div> */}
        </div>
      </div>
      <div className="container welcome-section">
        {/* <img
          src="/assets/img/mid-left-leaf.svg"
          className="img-fluid mid-left-leaf"
        /> */}
        <h2>Wedding venues</h2>
        <p className="intro">
          These stunning locations are overflowing with charm, opulence, and
          breathtaking scenery, making them ideal for your dream wedding
        </p>
        <div className="row">
          {data.WeddingReal != undefined &&
            data.WeddingReal.map((item) => {
              return (
                item.type == "Venue" && (
                  <div className="col-lg-4 md-5 sm-12 venue-cards">
                    <a href={item.request_url}>
                      <div className="welcome-card-img-cont">
                        <img
                          // src="/assets/img/venue1.svg"
                          src={BASE_URL_IMAGE + item.image}
                          className="img-fluid venue-img"
                          style={{ borderRadius: "2rem" }}
                        />
                      </div>

                      <div className="venue-card-text">
                        <h4>{item.title}</h4>
                        <EditorRead description={item.desc} />
                      </div>
                    </a>
                  </div>
                )
              );
            })}
          {/*          
          <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/venue2.svg" className="img-fluid venue-img" />
            <div className="venue-card-text">
              <h4>Amari Watergate, Bangkok</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div>
          <div className="col-lg-4 md-5 sm-12 venue-cards">
            <img src="/assets/img/venue3.svg" className="img-fluid venue-img" />
            <div className="venue-card-text">
              <h4>Anantra Riverside, Bangkok</h4>
              <p>Choice of hotels promising the best deals in town</p>
            </div>
          </div> */}
        </div>
        {/* <img
          src="/assets/img/bottom-right-leaf.svg"
          className="img-fluid bottom-right-leaf"
        /> */}
      </div>

      {/* Special Events Section */}
      <div className="unique-carousel-wrapper">
        <div className="row carouselcontainer ">
          <div
            className="col-lg-8 md-8 sm-12 mx-auto slider-container "
            id="unique-slider"
          >
            <Swiper
              // navigation={true}
              modules={[Navigation]}
              slidesPerView={2}
              spaceBetween={5}
              loop={true}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                "@0.75": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@1.00": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                "@1.50": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
              }}
              className="why-caro-swiper"
            >
              {data.WeddingReal != undefined &&
                data.WeddingReal.map((item) => {
                  return (
                    item.wedding_type == "Real" && (
                      <SwiperSlide>
                        <div className="rounded unique-slide">
                          <a href={item.wedding_link}>
                            <div className="welcome-card-img-cont">
                              <img
                                src="/assets/img/lifelong-slide1.svg"
                                className="img-fluid mb-2"
                              />
                            </div>

                            <div className="lifelong-content">
                              <h5 style={{ color: "#333333" }}>{item.title}</h5>
                              <p style={{ color: "#333333" }}>
                                <EditorRead description={item.desc} />
                              </p>
                            </div>
                          </a>
                        </div>
                      </SwiperSlide>
                    )
                  );
                })}
              {/* <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#333333" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#333333" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#333333" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#333333" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide> */}
              <div className="button-cont">
                <SwiperNavYellow />
              </div>
            </Swiper>
          </div>
          <div className="col-lg-4 md-4 sm-12 mx-auto" id="unique-cont">
            <h2 className="unique-text">Unique wedding films</h2>
            <p className="unique-text-p">
              Experience our recent weddings through our exclusive films.
            </p>
          </div>
        </div>
      </div>
      {/* Experiences Section */}
      {/* CTA events */}
      <div className="cta-events-wrapper">
        <div className="container cta-events-cont">
          <div className="row">
            <div className="col-lg-8 md-10 sm-12 mx-auto cta-events-text">
              <h3>
                Let our wedding planners help you bring the wedding of your
                dreams to life
              </h3>
            </div>
          </div>
          <div className="row btnrow cta-events">
            <div className="col-lg-4 md-5 sm-12 mx-auto d-flex justify-content-evenly">
              <Link
                style={{ textDecoration: "none" }}
                to={"/service-offer"}
                state={{ title: "Wedding" }}
              >
                <button
                  className="btn offering"
                  // type="button"
                  // onClick={() => {
                  //   navigate("/service-offer");
                  // }}
                >
                  Our service offerings
                </button>
              </Link>
            </div>
            <div className="col-lg-4 md-5 sm-12 mx-auto d-flex justify-content-evenly">
              <Link
                style={{ textDecoration: "none" }}
                to={"/enquiry"}
                state={{ title: "Wedding" }}
              >
                <button
                  className="btn appointment"
                  type="button"
                  onClick={() => {
                    navigate("/enquiry");
                  }}
                >
                  {/* <Link to="/enquiry"> */}
                  Online appointment request
                  {/* </Link> */}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Testimonial type={"Wedding"} />

      {/* PartnerShip Section */}
      <PartnersCarousel />
      {/* CTA Section */}

      <Subscribe
        title={`Get the scoop on deals and content with our newsletter, don't miss out!`}
      />
      {/* Footer */}

      {/* <Footer /> */}
    </div>
  );
};
