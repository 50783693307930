import React, { useRef, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import Select from "react-select";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";

import {
  BASE_URL_IMAGE,
  END_POINT,
  METHODS,
  NumberSeprator,
  TabBarLabels,
  numberToEnglish,
} from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Button, Row, Col, Container, Form, Modal } from "react-bootstrap";
import Sticky from "react-sticky-el";
import VideoSwiper from "../../components/VideoSwiper";
import { useSelector, useDispatch } from "react-redux";
import PartnersCarousel from "../../components/PartnersCarousel";
import Testimonial from "../../components/Testimonial";
import ProductPageHeader from "../../components/ProductPageHeader";
import ProductPageBlogSection from "../../components/ProductPageBlogSection";
import ProductPageDeatils from "../../components/ProductPageDeatils";
import ProductPagePolicySection from "../../components/ProductPagePolicySection";
import { addwishlistUpdate } from "../../redux/reducers/wishlistReducer";
import BottomPriceStripe from "../../components/BottomPriceStripe";
import PreviewSwiper from "../../components/PreviewSwiper/PreviewSwiper";
const HotelDetailScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  let location = useLocation();

  const message = "Welcome to BharatChoice";
  const phoneNumber = "+66810908071";
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;


  const { checkin, checkout, room, selectedtab } = location?.state;
  const { country, currency } = useSelector((store) => store.currencyReducer);
  const userData = useSelector((store) => store.userReducer.userData);

  const wishlistData = useSelector((store) =>
    store.wishlistReducer.wishlistData.find((item) => {
      return item.hotel_id == id;
    })
  );

  const [show, setShow] = useState(false);
  const sharedurl = 'hotels';

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

  const [dateFrom, setdateFrom] = useState();
  const [dateTo, setdateTo] = useState();

  const [totalAmount, settotalAmount] = useState(0);
  const [hotel, setHotelData] = useState();
  const [selectedroomtype, setSelectedroomtype] = useState([]);
  const [dates, setdates] = useState([]);

  const [formData, setformData] = useState({
    checkin: "",
    checkout: "",
    noofGuest: 1,
    hotel_id: id,
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    hotelHandler();
    setdateFrom(checkin);
    setdateTo(checkout);
  }, []);

  useEffect(() => {
    if (dateFrom != undefined && dateTo != undefined) {
      console.log("indsbjdsb");
      settotalAmount(0);
      setSelectedroomtype([]);
      var startDate = new Date(dateFrom); //YYYY-MM-DD
      var endDate = new Date(dateTo); //YYYY-MM-DD
      const dates = getDateArray(startDate, endDate);
      setdates(dates);
    }
  }, [dateFrom, dateTo]);
  const addWishlistHandler = async () => {
    try {
      const raw = JSON.stringify({
        type: TabBarLabels.Hotel,
        hotel_id: id,
        user_id: userData._id,
      });

      const result = await fetchData(
        END_POINT.Uploadwishlist,
        METHODS.POST,
        raw
      );
      // console.log(result);
      if (result.success == true) {
        dispatch(addwishlistUpdate(result.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hotelHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetHotelStockByHotelid + id);
      console.log(END_POINT.GetHotelStockByHotelid + id);
      console.log("test");
      console.log(selectedroomtype);
      if (result.success == true) {
        setHotelData(result.data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // console.log("selectedroomtype", selectedroomtype);

  const roomselectHandler = (e, itemroomtype, item, roomsDate, min) => {
    const selectedRooms = parseInt(e.target.value); // Get the selected number of rooms

    // var startDate = new Date(dateFrom); //YYYY-MM-DD
    // var endDate = new Date(dateTo); //YYYY-MM-DD
    // const dates = getDateArray(startDate, endDate);

    let findStockavailable = true;
    const newDatearray = [];

    dates.map((stocks) => {
      const finddate = roomsDate.campaign_rooms.find((itemroomtypeFind) => {
        // console.log("finddate", itemroomtypeFind.date, stocks);

        return itemroomtypeFind.date == stocks;
      });
      // console.log("finddate", finddate);

      if (
        finddate == undefined &&
        finddate?.date == stocks &&
        finddate?.livestock < selectedRooms
      ) {
        findStockavailable = false;
        newDatearray.push(new Date(stocks).toDateString());
      } else {
        // console.log("finddate", finddate?.livestock);
      }
    });

    if (findStockavailable == false) {
      Swal.fire({
        title: "Rooms not available for these dates",
        text: newDatearray.toString(),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        showConfirmButton: false,
      });
    } else {
      const newArray = [...selectedroomtype];

      const findindex = newArray.findIndex(
        (x) => x.hotel_stock_id === itemroomtype._id
      );
      if (findindex != -1) {
        if (selectedRooms == 0) {
          newArray.splice(findindex, 1);
        } else {
          newArray[findindex].room = parseInt(e.target.value);
          newArray[findindex].extrbed =
            newArray[findindex].extrbed == undefined
              ? 0
              : newArray[findindex].extrbed;
        }
      } else {
        newArray.push({
          extrbed: 0,
          room: parseInt(e.target.value),
          hotel_id: roomsDate.hotel_id,
          room_type_id: roomsDate.room_type_id,
          hotel_stock_id: itemroomtype._id,

          name: item.name,
          type: item.type,
          size: item.size,
          sleep: item.sleep,
          room_image: item.room_image,
          gallery_image: item.gallery_image,

          payment_mode: roomsDate.payment_mode,
          booking_type: roomsDate.booking_type,
          stock: roomsDate.stock,
          // date_from: itemroomtype.date_from,
          // date_to: itemroomtype.date_to,
          campaign_name: itemroomtype.campaign_name,
          benifits: itemroomtype.benifits,
          payment_option: itemroomtype.payment_option,
          weekdaycost: itemroomtype.weekdaycost,
          weekdaysell: itemroomtype.weekdaysell,
          weekendcost: itemroomtype.weekendcost,
          weekendsell: itemroomtype.weekendsell,
          extra_bedcost: itemroomtype.extra_bedcost,
          extra_bedsell: itemroomtype.extra_bedsell,
          // item,
        });
      }

      const roomCount = newArray
        .filter(
          (selectedroomtypeItem) =>
            selectedroomtypeItem.room_type_id == roomsDate.room_type_id
        )
        .reduce((acc, obj) => {
          return acc + obj.room;
        }, 0);

      // const findindexForCheckroom = newArray.findIndex(
      //   (x) => x.hotel_stock_id === itemroomtype._id
      // );
      console.log("roomCount", newArray, roomCount, min);
      if (min >= roomCount) {
        const totalScores = newArray.reduce(
          (previousScore, currentScore, index) =>
            previousScore +
            parseInt(currentScore.weekdaysell) * parseInt(currentScore.room) +
            parseInt(currentScore.extra_bedsell) *
            parseInt(currentScore.extrbed),

          0
        );
        settotalAmount(totalScores);
        if (totalScores == 0) {
          setSelectedroomtype([]);
        } else {
          setSelectedroomtype(newArray);
        }
      } else {
        Swal.fire({
          title: "Rooms not available for these dates",
          text: `You can only select total ${min} Rooms for these dates`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          showConfirmButton: false,
        });
      }
    }
  };

  const extrabedselectHandler = (e, itemroomtype, roomsDate) => {
    const newArray = [...selectedroomtype];

    const check = newArray.find((item) => {
      return item.hotel_stock_id == itemroomtype._id;
    });

    if (check != undefined) {
      const findindex = newArray.findIndex(
        (x) => x.hotel_stock_id === itemroomtype._id
      );

      newArray[findindex].extrbed = parseInt(e.target.value);

      const totalScores = newArray.reduce(
        (previousScore, currentScore, index) =>
          previousScore +
          parseInt(currentScore.weekdaysell) * parseInt(currentScore.room) +
          parseInt(currentScore.extra_bedsell) * parseInt(currentScore.extrbed),
        0
      );
      settotalAmount(totalScores);
      setSelectedroomtype(newArray);
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);

  const handleModalOpen = (item) => {
    setClickedItem(item);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  var getDateArray = function (start, end) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(dt.toISOString().split("T")[0]);

      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  const minTotalByName = (data) => {
    // console.log(
    //   data.some((slot) => slot.date === dates[0]),
    //   data.some((slot) => slot.date === dates[dates.length - 1])
    //   // data.includes({ date: dates[0] })
    //   // data.includes({ date: dates[dates.length - 1] })
    // );

    if (
      data.some((slot) => slot.date === dates[0]) == true &&
      data.some((slot) => slot.date === dates[dates.length - 1]) == true
    ) {
      const totals = data
        .filter((x) => dates.includes(x.date))
        .map((x) => x.livestock);

      return totals.length > 0 ? Math.min(...totals) : 0;
    } else {
      return 0;
    }
  };
  const handleCheckinChange = (e) => {
    const selectedCheckinDate = new Date(e.target.value);
    const nextDay = new Date(selectedCheckinDate);
    nextDay.setDate(selectedCheckinDate.getDate() + 1);
    const formattedNextDay = nextDay.toISOString().split("T")[0];
    setdateFrom(e.target.value);
    setdateTo(formattedNextDay);
  };
  const minCheckinDate = new Date().toISOString().split("T")[0];

  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}

      <ProductPageHeader hotel={hotel} sharedurl={sharedurl} />

      <div className="container">
        <div className="row d-flex justify-content-between">
          <div className="col-lg-7 md-7 sm-12 left-col-det">
            <p className="" style={{ fontSize: 20, marginBottom: "30px" }}>
              {hotel?.about}
            </p>

            <h3 style={{ color: "black" }}>What this place offers</h3>
            <div className="row">
              {hotel?.placeoffer.map((item, index) => {
                return (
                  <div className="col-6">
                    <span className="amenties">
                      <img src="/assets/img/star.svg" className="img-fluid" />
                      {item}
                    </span>
                  </div>
                );
              })}
            </div>
            {/* Booking cards */}
            {hotel?.roomtypedata.map((item, index) => {
              const shouldRenderCard = item.hotelstocks.some(
                (itemroomtype) => itemroomtype.campaign_names
              );

              if (!shouldRenderCard) {
                return null;
              }
              const min =
                item?.hotelstocks.length > 0
                  ? minTotalByName(item?.hotelstocks[0].campaign_rooms)
                  : 0; // 1000
              return (
                <div className="booking-card-cont my-5">
                  <div className="row">
                    <div className="col-lg-4 md-4 sm-12 firstcol d-flex flex-column">
                      <h3
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {item.name}
                      </h3>
                      <PreviewSwiper hotel={item} />
                      {/* <img
                        src={BASE_URL_IMAGE + item.gallery_image}
                        className="img-fluid"
                      />
                       */}
                      <div>
                        <span className="amenties">
                          <img
                            src="/assets/img/icon-room-size.svg"
                            className="img-fluid"
                          />
                          Room size: {item.size}
                        </span>
                        <span className="amenties">
                          <img
                            src="/assets/img/icon-room-size.svg"
                            className="img-fluid"
                          />
                          Sleep: {item.sleep}
                        </span>
                      </div>
                      {min < 5 && (
                        <div class="wp-available">
                          <h6>
                            {min == 0
                              ? " Rooms not available"
                              : `Last ${min} Rooms Available !!!`}
                          </h6>
                        </div>
                      )}
                      <button
                        className="btn wish-list-btn"
                        onClick={() => handleModalOpen(item)}
                      >
                        View More
                      </button>
                    </div>
                    <div className="col-lg-8 md-8 sm-12">
                      <div className="row dont">
                        <div className="col-6">
                          <span className="column-head">Room Details</span>
                        </div>
                        <div className="col-6">
                          <span className="column-head2">Pricing</span>
                        </div>
                      </div>

                      {item.hotelstocks.length > 0 &&
                        item?.hotelstocks[0].campaign_names.length > 0 &&
                        item?.hotelstocks[0].campaign_names.map(
                          (itemroomtype) => {
                            const basePrice = isWeekend
                              ? itemroomtype.weekendsell
                              : itemroomtype.weekdaysell;

                            const extraBedfind = selectedroomtype.find(
                              (selectedroomtypeItem) => {
                                return (
                                  selectedroomtypeItem.hotel_stock_id ==
                                  itemroomtype._id
                                );
                              }
                            );
                            // const min = minTotalByName(
                            //   item?.hotelstocks[0].campaign_rooms
                            // ); // 1000
                            const roomCount = selectedroomtype
                              .filter(
                                (selectedroomtypeItem) =>
                                  selectedroomtypeItem.room_type_id == item._id
                              )
                              .reduce((acc, obj) => {
                                return acc + obj.room;
                              }, 0);
                            return (
                              <div className="row mobile-bg-col">
                                <div className="col-lg-6 md-6 sm-12 roomdetails-box">
                                  <span className="room-type-head">
                                    {itemroomtype.campaign_name}
                                  </span>
                                  {/* {min < 5 && (
                                    <div class="wp-available">
                                      <h6>
                                        {min == 0
                                          ? " Rooms not available"
                                          : `Last ${min} Rooms Available !!!`}
                                      </h6>
                                    </div>
                                  )} */}

                                  {Array.isArray(itemroomtype?.benifits) ==
                                    true ? (
                                    itemroomtype?.benifits?.length > 0 &&
                                    itemroomtype.benifits?.map(
                                      (benifitItem, indexbenifit) => {
                                        return (
                                          <span
                                            className="amenties"
                                            key={indexbenifit}
                                          >
                                            <img
                                              src="/assets/img/ForkKnife.svg"
                                              className="img-fluid"
                                              alt="Icon"
                                            />
                                            {benifitItem.label}
                                          </span>
                                        );
                                      }
                                    )
                                  ) : (
                                    <span className="amenties">
                                      <img
                                        src="/assets/img/ForkKnife.svg"
                                        className="img-fluid"
                                        alt="Icon"
                                      />
                                      {itemroomtype.benifits}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6 md-6 sm-12 roompricing-box">
                                  <span
                                    style={{
                                      fontSize: 28,
                                      textAlign: "end",
                                      display: "block",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    {NumberSeprator(
                                      Math.floor(basePrice * currency[country])
                                    )}{" "}
                                    <span
                                      style={{
                                        fontSize: "small",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {country}/night
                                    </span>
                                  </span>

                                  <div className="d-flex flex-column mt-2 mobile-realign-ul">
                                    <div className="d-flex justify-content-between mobile-realign-ul-mrgin mb-4">
                                      <span>Rooms (s)</span>
                                      <div className="d-flex align-items-center form-element-select">
                                        <select
                                          className="form-control form-element-style1"
                                          value={
                                            extraBedfind == undefined
                                              ? 0
                                              : extraBedfind.room
                                          }
                                          onChange={(e) => {
                                            // console.log(
                                            //   "roomCount",
                                            //   roomCount,
                                            //   min
                                            // );
                                            if (
                                              roomCount == 0 ||
                                              extraBedfind?.room >
                                              e.target.value
                                            ) {
                                              roomselectHandler(
                                                e,
                                                itemroomtype,
                                                item,
                                                item?.hotelstocks[0],
                                                min
                                              );
                                            } else if (
                                              // min - roomCount >=
                                              // e.target.value
                                              min >= Number(e.target.value)
                                            ) {
                                              roomselectHandler(
                                                e,
                                                itemroomtype,
                                                item,
                                                item?.hotelstocks[0],
                                                min
                                              );
                                            } else {
                                              Swal.fire({
                                                title: "Rooms not available",
                                                text: `You can only select total ${min} Rooms for these dates`,
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Yes",
                                                cancelButtonText: "OK",
                                                showConfirmButton: false,
                                              });
                                            }
                                          }}
                                        >
                                          {[
                                            ...Array(Number(min) + 1).keys(),
                                          ].map((itemRoomvalue) => {
                                            return (
                                              <option value={itemRoomvalue}>
                                                {itemRoomvalue}
                                              </option>
                                            );
                                          })}
                                        </select>

                                        <i
                                          class="fa-solid fa-angle-down"
                                          style={{ color: "#858585" }}
                                        ></i>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between mobile-realign-ul-mrgin extra-bed">
                                      {/* <span>Extra bed</span> */}

                                      <div className="d-flex align-items-center form-element-select">
                                        <select
                                          className="form-control form-element-style1"
                                          onChange={(e) =>
                                            extrabedselectHandler(
                                              e,
                                              itemroomtype,
                                              item?.hotelstocks[0]
                                            )
                                          }
                                        >
                                          {extraBedfind == undefined ? (
                                            <option value={"0"} key={0}>
                                              Extra bed
                                            </option>
                                          ) : (
                                            <>
                                              {[
                                                ...Array(
                                                  extraBedfind?.room + 1
                                                ).keys(),
                                              ].map((optionValue) => (
                                                <option
                                                  value={optionValue}
                                                  key={optionValue}
                                                >
                                                  {optionValue}
                                                </option>
                                              ))}
                                            </>
                                          )}
                                        </select>

                                        <i
                                          class="fa-solid fa-angle-down"
                                          style={{ color: "#858585" }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                  {wishlistData == undefined && (
                                    <div className="d-flex justify-content-center align-items-center">
                                      <button
                                        className="btn wish-list-btn mx-auto"
                                        type="button"
                                        onClick={() => {
                                          addWishlistHandler(id);
                                        }}
                                      >
                                        Save to wish list
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-lg-4 md-4 sm-12">
            <Sticky>
              <div className=" booking-dialog-box">
                <div class="form-group row justify-content-center mx-auto">
                  <div className="form-element-each check-in col-6">
                    <span>Check-in</span>
                    <input
                      // type="date"
                      // class="form-control form-element-style"
                      // id="inputCheckIn"
                      type="date"
                      id="checkin"
                      name="checkin"
                      className="form-control form-element-style"
                      min={minCheckinDate}
                      value={dateFrom}
                      onChange={handleCheckinChange}
                    // readOnly
                    />
                  </div>
                  <div className="form-element-each check-out col-6">
                    <span>Check-out</span>
                    <input
                      // type="date"
                      // placeholder="Add Date"
                      // class="form-control form-element-style"
                      // id="inputCheckOut"
                      type="date"
                      id="checkout"
                      name="checkout"
                      className="form-control form-element-style"
                      value={dateTo}
                      onChange={(e) => setdateTo(e.target.value)}
                    // readOnly
                    />
                  </div>
                </div>
                <div className="form-element-each">
                  <span>Guest (s)</span>
                  <div className="d-flex align-items-center">
                    <select
                      className="form-control form-element-style"
                      name="noofGuest"
                      value={formData.noofGuest}
                      id="noofGuest"
                      placeholder="Select Guest"
                      isSearchable
                      onChange={(e) => {
                        setformData({
                          ...formData,
                          noofGuest: e.target.value,
                        });
                      }}
                    >
                      <option value={0}>0</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </select>

                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "#858585" }}
                    ></i>
                  </div>
                </div>

                <button
                  type="button"
                  class="btn sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                  onClick={() => {
                    if (totalAmount == 0) {
                      return Swal.fire({
                        title: "Select rooms",
                        icon: "warning",
                        showCancelButton: true,
                        cancelButtonText: "Ok",
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes",
                        showConfirmButton: false,
                      });
                    }

                    navigate("/booking/" + selectedtab + "/" + id, {
                      state: {
                        selectedtab: selectedtab,
                        data: selectedroomtype,
                        totalAmount: totalAmount,
                        dates: dates,
                        guest: formData.noofGuest,
                      },
                    });
                  }}
                >
                  Book now
                </button>

                {/* <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                  <Button
                    type="button"
                    className="sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                  >
                    <b> Whatsapp Us </b>
                  </Button>
                </a> */}

                <div className="d-flex justify-content-between align-items-center totalrow">
                  <span style={{ color: "#797979", fontSize: "1.5rem" }}>
                    Total
                  </span>
                  <span
                    style={{
                      fontSize: "2rem",
                      fontWeight: "550",
                    }}
                  >
                    {NumberSeprator(
                      Math.floor(totalAmount * currency[country])
                    )}{" "}
                    {country}
                  </span>
                </div>
                <span
                  style={{
                    marginTop: "1rem",
                    display: "block",
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                >
                  {numberToEnglish(Math.floor(totalAmount * currency[country]))}
                </span>
                <span
                  style={{
                    marginTop: "1rem",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  Tax and Service charge included
                </span>
                <div className="mt-2 d-flex justify-content-center">
                  <div class="booking-text1 desktop">
                    <ul className="ul-list">
                      <li className="ul-list-li">
                        <i class="far fa-clock blue-colr ul-li-i"></i> It only
                        takes 2 minutes
                      </li>
                      <li className="ul-list-li">
                        <i class="fas fa-percent blue-colr ul-li-i"></i> Include
                        all Taxes &amp; Service charge
                      </li>
                      <li className="ul-list-li">
                        <i class="fas fa-eye-slash blue-colr ul-li-i"></i> No
                        Hidden Cost
                      </li>
                      <li className="ul-list-li">
                        <i class="far fa-credit-card blue-colr ul-li-i"></i> No
                        Booking or Credit card fees!!
                      </li>
                      <li className="ul-list-li">
                        <i class="fas fa-mobile-alt blue-colr ul-li-i"></i>
                        Show mobile or printed voucher
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Sticky>
          </div>
        </div>
      </div>
      {/* Navbar phone and tablet bottom */}
      <BottomPriceStripe
        dateFrom={dateFrom}
        dateTo={dateTo}
        noofGuest={formData.noofGuest}
        totalAmount={totalAmount}
        ButtonPress={() => {
          if (totalAmount == 0) {
            return Swal.fire({
              title: "Select rooms",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: "Ok",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
              showConfirmButton: false,
            });
          }

          navigate("/booking/" + selectedtab + "/" + id, {
            state: {
              selectedtab: selectedtab,
              data: selectedroomtype,
              totalAmount: totalAmount,
              dates: dates,
              guest: formData.noofGuest,
            },
          });
        }}
      />
      {/* <nav class="sticky-bottom-navbar2">
        <Row className="d-flex justify-content-between">
          <Col sm={10}>
            <Link>
              <span className="bottom-book-details">
                July 9-16, {formData.noofGuest + "Guest"}
              </span>
            </Link>
          </Col>
          <Col sm={1} className=""></Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-between">
          <Col>
            <div>
              <span
                style={{
                  color: "#383838",
                  fontSize: "24px",
                  fontWeight: "600",
                }}
              >
                {NumberSeprator(Math.floor(totalAmount * currency[country]))}{" "}
              </span>
              &nbsp;
              <span
                style={{
                  color: "#383838",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                {country}
              </span>
            </div>
          </Col>
          <Col className="d-flex justify-content-end">
            <button type="button" class="btn sub-btn-form">
              Book now
            </button>
          </Col>
        </Row>
      </nav> */}
      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleModalClose}
        style={{ maxWidth: "none" }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {clickedItem && (
            <div>
              {/* <img src={BASE_URL_IMAGE + clickedItem.gallery_image} alt="Gallery" className="img-fluid" />
              <img src={BASE_URL_IMAGE + clickedItem.room_image} alt="Image" className="img-fluid" /> */}

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Room description: </b> {clickedItem.desc}
              </span>

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Room size: </b> {clickedItem.size}
              </span>

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Sleep: </b> {clickedItem.sleep}
              </span>

              {clickedItem.type?.map((itemtype, index) => (
                <span className="amenties" key={index}>
                  <img
                    src="/assets/img/icon-room-size.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {itemtype}
                </span>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/*Yellow Carousel  */}

      <ProductPageBlogSection
        title={"Your holiday, our adventure"}
        description={`Let us guide you make your holiday the most fun and memorable one yet. We'll take you to all the best spots, plan all the activities, and make sure you have everything you need to relax and enjoy yourself`}
        data={[]}
        pageType={TabBarLabels.Hotel}
      />

      {/* Location Details */}
      <ProductPagePolicySection
        location={{
          longitude: hotel != undefined ? hotel?.longitude : "",
          latitude: hotel != undefined ? hotel?.latitude : "",
        }}
        hotepolicy={hotel?.policyExclusion}
        heading={"Hotel"}
      />

      {/* Testimonials Section */}
      <Testimonial type={"Hotel"} />
      {/* PartnerShip Section */}
      <PartnersCarousel />
      {/* Footer */}
      {/* <Footer /> */}
    </div>
  );
};
export default HotelDetailScreen;
