import React, { useState } from "react";
import { Link } from "react-router-dom";

import SideMenu from "../components/SideMenu";
import "../dashscreen/DashCss.css";
import TableComp from "../components/TableComp";
function DashBoardScreen() {
  return (
    <div>
      {/* <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex ">
        <a class="navbar-brand" href="#">
          <img src="/assets/img/bharat-logo.svg" style={{ padding: 5 }} />
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <a href="" className="float-right">
          <i class="fa-solid fa-gear"></i>
        </a>
      </nav>
      <div className="row d-flex justify-content-around">
        
        <div className="col-8 dash-content-section mt-4">
          <div className="row d-flex justify-content-evenly mt-4">
            <div className="col-lg-3 md-3 sm-12 dash-card">
              <h5>Revenue</h5>
              <div className="d-flex align-items-center justify-content-evenly">
                <h2>$2,112</h2>
                <span style={{ color: "red" }}>
                  -11.4%<i class="fa-solid fa-arrow-down"></i>
                </span>
              </div>
              <span style={{ fontSize: "small", color: "gray" }}>
                Compared to last year
              </span>
            </div>
            <div className="col-lg-3 md-3 sm-12 dash-card">
              <h5>Sales</h5>
              <div className="d-flex align-items-center justify-content-evenly">
                <h2>$2,112</h2>
                <span style={{ color: "green" }}>
                  11.4%<i class="fa-solid fa-arrow-up"></i>
                </span>
              </div>
              <span style={{ fontSize: "small", color: "gray" }}>
                Compared to last year
              </span>
            </div>
            <div className="col-lg-3 md-3 sm-12 dash-card">
              <h5>Costs</h5>
              <div className="d-flex align-items-center justify-content-evenly">
                <h2>$2,112</h2>
                <span style={{ color: "red" }}>
                  -11.4%
                  <i class="fa-solid fa-arrow-down"></i>
                </span>
              </div>
              <span style={{ fontSize: "small", color: "gray" }}>
                Compared to last year
              </span>
            </div>
          </div>
       
        </div>
      </div> */}
      <div className="row d-flex justify-content-evenly mt-4">
        <div className="col-lg-3 md-3 sm-12 dash-card">
          <h5>Revenue</h5>
          <div className="d-flex align-items-center justify-content-evenly">
            <h2>$2,112</h2>
            <span style={{ color: "red" }}>
              -11.4%<i class="fa-solid fa-arrow-down"></i>
            </span>
          </div>
          <span style={{ fontSize: "small", color: "gray" }}>
            Compared to last year
          </span>
        </div>
        <div className="col-lg-3 md-3 sm-12 dash-card">
          <h5>Sales</h5>
          <div className="d-flex align-items-center justify-content-evenly">
            <h2>$2,112</h2>
            <span style={{ color: "green" }}>
              11.4%<i class="fa-solid fa-arrow-up"></i>
            </span>
          </div>
          <span style={{ fontSize: "small", color: "gray" }}>
            Compared to last year
          </span>
        </div>
        <div className="col-lg-3 md-3 sm-12 dash-card">
          <h5>Costs</h5>
          <div className="d-flex align-items-center justify-content-evenly">
            <h2>$2,112</h2>
            <span style={{ color: "red" }}>
              -11.4%
              <i class="fa-solid fa-arrow-down"></i>
            </span>
          </div>
          <span style={{ fontSize: "small", color: "gray" }}>
            Compared to last year
          </span>
        </div>
      </div>
    </div>
  );
}

export default DashBoardScreen;
