import React from "react";
import { useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";

function LoginPage(props) {
  useEffect(() => {
    props.funcNav(false);
    props.funcFoot(false);
    return () => {
      props.funcFoot(true);
      props.funcNav(true);
    };
  });
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Container className="login-cont-">
        <Row className="d-flex flex-column justify-content-center align-items-center">
          <span
            style={{
              color: "#000",
              fontWeight: "bolder",
              fontSize: "large",
              textAlign: "center",
            }}
          >
            Welcome Back
          </span>
          <Col sm={12} lg={4} md={4}>
            <div style={{ marginRight: 5 }} className="form-element-each">
              <span>Email</span>
              <div className="d-flex align-items-center">
                <input
                  style={{ border: "none" }}
                  placeholder="Enter Email"
                  //   value={email}
                  //   onChange={(e) => {
                  //     setemail(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} lg={4} md={4}>
            <div style={{ marginRight: 5 }} className="form-element-each">
              <span>Password</span>
              <div className="d-flex align-items-center">
                <input
                  style={{ border: "none" }}
                  placeholder="Enter Password"
                  //   value={password}
                  //   onChange={(e) => {
                  //     setpassword(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center">
          <Col sm={12} lg={4} md={4}>
            <Button
              className="search-button align-self-center"
              style={{ border: "none", width: "100%" }}
              type="button"
              //   onClick={LoginHandler}
            >
              Login
            </Button>
          </Col>
        </Row>
        <Row className="mt-2 mb-2 d-flex justify-content-center align-items-center">
          <Col sm={12} lg={4} md={4}>
            <img
              src="/assets/img/or-line.svg"
              alt="Logo"
              className="img-fluid"
            />
          </Col>
        </Row>
        <Row className="d-flex flex-column justify-content-center align-items-center">
          <Col sm={12} lg={4} md={4} className="mb-2">
            <Button className="d-flex social-sign-in justify-content-center align-items-center ">
              <span>
                <img
                  src="/assets/img/GoogleLogo.svg"
                  alt="Logo"
                  className="img-fluid"
                />
              </span>
              <span style={{ color: "#6B7280" }}>Google</span>
            </Button>
          </Col>
          <Col sm={12} lg={4} md={4} className="mb-2">
            <Button className="d-flex social-sign-in justify-content-center align-items-center ">
              <span>
                <img
                  src="/assets/img/FacebookLogo.svg"
                  alt="Logo"
                  className="img-fluid"
                />
              </span>
              <span style={{ color: "#6B7280" }}>Facebook</span>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginPage;
