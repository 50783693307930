import React, { useRef, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import Select from "react-select";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";
import {
    BASE_URL_IMAGE,
    END_POINT,
    METHODS,
    NumberSeprator,
    TabBarLabels,
    numberToEnglish,
} from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Button, Container, Form, Modal, Row, Col } from "react-bootstrap";
import Sticky from "react-sticky-el";
import VideoSwiper from "../../components/VideoSwiper";
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromRaw,
    convertFromHTML,
} from "draft-js";
import PartnersCarousel from "../../components/PartnersCarousel";
import Testimonial from "../../components/Testimonial";

import ProductPageHeader from "../../components/ProductPageHeader";
import ProductPageBlogSection from "../../components/ProductPageBlogSection";
import ProductPageDeatils from "../../components/ProductPageDeatils";
import ProductPagePolicySection from "../../components/ProductPagePolicySection";
import BottomPriceStripe from "../../components/BottomPriceStripe";
import SharedProductPageHeader from "../../components/SharedProductPageHeader";
const SharedDinningDetail = () => {
    const { country, currency } = useSelector((store) => store.currencyReducer);
    const navigate = useNavigate();
    const { id } = useParams();
    let location = useLocation();
    const { selectedtab } = "dinning"
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const sharedurl = 'dinning';

    const message = "Welcome to BharatChoice";
    const phoneNumber = "+66810908071";
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

    const [dateFrom, setdateFrom] = useState();
    const [dateTo, setdateTo] = useState();
    const [totalAmount, settotalAmount] = useState(0);
    const [hotel, setHotelData] = useState();
    const [selectedroomtype, setSelectedroomtype] = useState([]);
    const [hotepolicy, sethotepolicy] = useState();

    const [showModal, setShowModal] = useState(false);
    const [clickedItem, setClickedItem] = useState(null);

    const handleModalOpen = (item) => {
        setClickedItem(item);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    var getDateArray = function (start, end) {
        var arr = new Array();
        var dt = new Date(start);
        while (dt <= end) {
            arr.push(dt.toISOString().split("T")[0]);

            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    };
    const [formData, setformData] = useState({
        checkin: "",
        checkout: "",
        noofGuest: 1,
        hotel_id: id,
    });
    const handleChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Function to get today's date in the desired format
    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    // Function to get the next date from today in the desired format
    const getNextDate = () => {
        const today = new Date();
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + 1);
        return nextDate.toISOString().split('T')[0];
    };

    const todayDate = getTodayDate();
    const todayNextDate = getNextDate();

    useEffect(() => {
        hotelHandler();

        setdateFrom(todayDate);
        setdateTo(todayNextDate);

        // setdateFrom(checkin);
        // setdateTo(checkout);
    }, []);
    useEffect(() => {
        if (dateFrom != undefined) {
            console.log("indsbjdsb");
            settotalAmount(0);
            setSelectedroomtype([]);
        }
    }, [dateFrom]);
    const hotelHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetDinningStockByDinningid + id);

            if (result.success == true) {
                setHotelData(result.data[0]);
                const editorSta = EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(result.data[0].policyExclusion)
                    )
                );
                sethotepolicy(editorSta);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const adultHandler = async (item, value) => {
        const plus = value; // Get the selected number of rooms
        const newArray = [...selectedroomtype];
        const findindex = newArray.findIndex(
            (x) => x.dinning_item_type_id === item._id
        );
        if (findindex == -1) {
            newArray.push({
                childNumber: 0,
                adultNumber: plus,
                dinning_id: item.dinning_id,
                dinning_item_type_id: item._id,
                dinning_stock_id: item.dinning_stock_data._id,
                name: item.name,
                content: item.content,
                type: item.type,
                item_inclusion: item.item_inclusion,
                image: item.image,
                payment_mode: item?.dinning_stock_data?.payment_mode,
                booking_type: item?.dinning_stock_data?.booking_type,
                date_from: item?.dinning_stock_data?.date_from,
                campaign_name: item?.dinning_stock_data?.campaign_name,
                payment_option: item?.dinning_stock_data?.payment_option,
                adultcost: item?.dinning_stock_data?.adultcost,
                adultsell: item?.dinning_stock_data?.adultsell,
                childcost: item?.dinning_stock_data?.childcost,
                childsell: item?.dinning_stock_data?.childsell,
            });
        } else {
            if (plus == 0 && newArray[findindex].childNumber == 0) {
                newArray.splice(findindex, 1);
            } else {
                newArray[findindex].adultNumber = plus;
            }
        }

        const totalScores = newArray.reduce(
            (previousScore, currentScore, index) =>
                previousScore +
                currentScore.adultNumber * currentScore.adultsell +
                currentScore.childsell * currentScore.childNumber,

            0
        );

        console.log("totalScores", totalScores);

        settotalAmount(totalScores);
        if (totalScores == 0) {
            setSelectedroomtype([]);
        } else {
            setSelectedroomtype(newArray);
        }

        const totalNumberchild = newArray.reduce(
            (previousScore, currentScore, index) =>
                previousScore + currentScore.adultNumber + currentScore.childNumber,

            0
        );

        setformData({
            ...formData,
            noofGuest: totalNumberchild,
        });
    };
    const childHandler = (item, value) => {
        const plus = value; // Get the selected number of rooms
        const newArray = [...selectedroomtype];
        const findindex = newArray.findIndex(
            (x) => x.dinning_item_type_id === item._id
        );
        if (findindex == -1) {
            newArray.push({
                childNumber: plus,
                adultNumber: 0,
                dinning_id: item.dinning_id,
                dinning_item_type_id: item._id,
                dinning_stock_id: item.dinning_stock_data._id,
                name: item.name,
                content: item.content,
                type: item.type,
                item_inclusion: item.item_inclusion,
                image: item.image,
                payment_mode: item?.dinning_stock_data?.payment_mode,
                booking_type: item?.dinning_stock_data?.booking_type,
                date_from: item?.dinning_stock_data?.date_from,
                campaign_name: item?.dinning_stock_data?.campaign_name,
                payment_option: item?.dinning_stock_data?.payment_option,
                adultcost: item?.dinning_stock_data?.adultcost,
                adultsell: item?.dinning_stock_data?.adultsell,
                childcost: item?.dinning_stock_data?.childcost,
                childsell: item?.dinning_stock_data?.childsell,
            });
        } else {
            if (plus == 0 && newArray[findindex].adultNumber == 0) {
                newArray.splice(findindex, 1);
            } else {
                newArray[findindex].childNumber = plus;
            }
        }

        const totalScores = newArray.reduce(
            (previousScore, currentScore, index) =>
                previousScore +
                currentScore.adultNumber * currentScore.adultsell +
                currentScore.childsell * currentScore.childNumber,

            0
        );

        console.log("totalScores", totalScores);

        settotalAmount(totalScores);
        if (totalScores == 0) {
            setSelectedroomtype([]);
        } else {
            setSelectedroomtype(newArray);
        }

        const totalNumberchild = newArray.reduce(
            (previousScore, currentScore, index) =>
                previousScore + currentScore.adultNumber + currentScore.childNumber,

            0
        );

        setformData({
            ...formData,
            noofGuest: totalNumberchild,
        });
    };
    const findTotalAdultnum = (id) => {
        const TotalAdultnum = selectedroomtype.find((itemselect) => {
            return itemselect.activity_item_type_id == id;
        });

        return TotalAdultnum == undefined ? 0 : TotalAdultnum;
    };
    const minCheckinDate = new Date().toISOString().split("T")[0];

    return (
        <div>
            {/* Navbar */}
            {/* <Header /> */}
            <BottomPriceStripe
                dateFrom={dateFrom}
                // dateTo={dateTo}
                noofGuest={formData.noofGuest}
                totalAmount={totalAmount}
                ButtonPress={() => {
                    if (totalAmount == 0) {
                        return Swal.fire({
                            title: "Select adults and childs",
                            icon: "warning",
                            showCancelButton: true,
                            cancelButtonText: "Ok",
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes",
                            showConfirmButton: false,
                        });
                    }
                    var startDate = new Date(dateFrom); //YYYY-MM-DD
                    var endDate = new Date(dateTo); //YYYY-MM-DD
                    const dates = getDateArray(startDate, endDate);

                    navigate("/booking/" + selectedtab + "/" + id, {
                        state: {
                            selectedtab: selectedtab,
                            data: selectedroomtype,
                            totalAmount: totalAmount,
                            dates: dates,
                            guest: formData.noofGuest,
                        },
                    });
                }}
            />
            <SharedProductPageHeader hotel={hotel} sharedurl={sharedurl} />

            <div className="container table-contt">
                <div className="row content-column">
                    <div className="col-lg-7 md-7 sm-12 ">
                        <p className="" style={{ fontSize: 20 }}>
                            {hotel?.about}
                        </p>
                        <h3 style={{ color: "black", marginTop: "15px" }}>
                            What this place offers
                        </h3>
                        <div className="row">
                            {hotel?.placeoffer?.map((item, index) => {
                                return (
                                    <div className="col-6">
                                        <span className="amenties">
                                            <img src="/assets/img/star.svg" className="img-fluid" />
                                            {item}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                        <ProductPageDeatils
                            keyName={"dinning_stock_data"}
                            keyNameSearch={"dinning_item_type_id"}
                            data={hotel?.dinning_stock_data}
                            selectedroomtype={selectedroomtype}
                            adultHandler={(item, value) => {
                                adultHandler(item, value);
                            }}
                            childHandler={(item, value) => {
                                childHandler(item, value);
                            }}
                            productId={id}
                            type={TabBarLabels.Dinning}
                        />
                    </div>

                    <Sticky className="col-lg-4 md-4 sm-12 don">
                        <div className=" booking-dialog-box sticky-contt">
                            <div class="form-group row justify-content-center mx-auto">
                                <div className="form-element-each check-in col-12">
                                    <span>Select Date</span>
                                    <input
                                        // type="date"
                                        // class="form-control form-element-style"
                                        // id="inputCheckIn"
                                        type="date"
                                        id="checkin"
                                        name="checkin"
                                        className="form-control form-element-style"
                                        min={minCheckinDate}
                                        value={dateFrom}
                                        onChange={(e) => setdateFrom(e.target.value)}
                                    />
                                </div>
                            </div>

                            <button
                                type="button"
                                class="btn sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                                onClick={() => {
                                    if (totalAmount == 0) {
                                        return Swal.fire({
                                            title: "Select adults and childs",
                                            icon: "warning",
                                            showCancelButton: true,
                                            cancelButtonText: "Ok",
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Yes",
                                            showConfirmButton: false,
                                        });
                                    }
                                    var startDate = new Date(dateFrom); //YYYY-MM-DD
                                    var endDate = new Date(dateTo); //YYYY-MM-DD
                                    const dates = getDateArray(startDate, endDate);

                                    navigate("/booking/" + selectedtab + "/" + id, {
                                        state: {
                                            selectedtab: selectedtab,
                                            data: selectedroomtype,
                                            totalAmount: totalAmount,
                                            dates: dates,
                                            guest: formData.noofGuest,
                                        },
                                    });
                                }}
                            >
                                Book now
                            </button>

                            {/* <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                                <Button
                                    type="button"
                                    className="sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                                >
                                    <b> Whatsapp Us </b>
                                </Button>
                            </a> */}

                            <div className="d-flex justify-content-between align-items-center totalrow">
                                <span style={{ color: "#797979", fontSize: "1.5rem" }}>
                                    Total
                                </span>
                                <span
                                    style={{
                                        fontSize: "2rem",
                                        fontWeight: "550",
                                    }}
                                >
                                    {NumberSeprator(Math.floor(totalAmount * currency[country]))}{" "}
                                    {country}
                                </span>
                            </div>
                            <span
                                style={{
                                    marginTop: "1rem",
                                    display: "block",
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                }}
                            >
                                {numberToEnglish(Math.floor(totalAmount * currency[country]))}
                            </span>
                            <span
                                style={{
                                    marginTop: "1rem",
                                    display: "block",
                                    textAlign: "center",
                                }}
                            >
                                Tax and Service charge included
                            </span>
                            <div className="mt-2">
                                <div class="booking-text1 desktop">
                                    <div className="mt-2 d-flex justify-content-center">
                                        <div class="booking-text1 desktop">
                                            <ul className="ul-list">
                                                <li className="ul-list-li">
                                                    <i class="far fa-clock blue-colr ul-li-i"></i> It only
                                                    takes 2 minutes
                                                </li>
                                                <li className="ul-list-li">
                                                    <i class="fas fa-percent blue-colr ul-li-i"></i>{" "}
                                                    Include all Taxes &amp; Service charge
                                                </li>
                                                <li className="ul-list-li">
                                                    <i class="fas fa-eye-slash blue-colr ul-li-i"></i> No
                                                    Hidden Cost
                                                </li>
                                                <li className="ul-list-li">
                                                    <i class="far fa-credit-card blue-colr ul-li-i"></i>{" "}
                                                    No Booking or Credit card fees!!
                                                </li>
                                                <li className="ul-list-li">
                                                    <i class="fas fa-mobile-alt blue-colr ul-li-i"></i>
                                                    Show mobile or printed voucher
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Sticky>
                </div>
            </div>

            {/*Yellow Carousel  */}
            <ProductPageBlogSection
                title={"Your holiday, our adventure"}
                description={`Let us guide you make your holiday the most fun and memorable one yet. We'll take you to all the best spots, plan all the activities, and make sure you have everything you need to relax and enjoy yourself`}
                data={[]}
                pageType={TabBarLabels.Dinning}
            />

            {/* Location Details */}

            {hotel != undefined && (
                <ProductPagePolicySection
                    location={{
                        longitude: hotel?.longitude,
                        latitude: hotel?.latitude,
                    }}
                    hotepolicy={hotel?.policyExclusion}
                    heading={"Dining"}
                />
            )}

            {/* Testimonials Section */}
            <Testimonial type={"Dinning"} />

            {/* PartnerShip Section */}
            <PartnersCarousel />
            {/* Footer */}
            {/* <Footer /> */}
        </div>
    );
};

export default SharedDinningDetail;
