import React, { useState, useEffect } from "react";
import { END_POINT, METHODS, wedding_view_type } from "../../../Helper/helper";
import { ImageUpload, fetchData } from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";

const AddMiceBanner = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const bannerid = location?.state?.id;

  const [formData, setformData] = useState({
    view_type: "",
    image: "",
    title: "",
    description: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [city, setHotelCityData] = useState([]);

  const [image, setimage] = useState("");

  useEffect(() => {
    if (bannerid != undefined) {
      detailsHandler();
    }
  }, []);
  const detailsHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetMiceBannerByid + bannerid);
      if (result.success == true) {
        setformData({
          view_type: result.data.view_type,
          image: result.data.image,
          title: result.data.title,
          description: result.data.description,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (uploadedImagecity = null) => {
    var raw = JSON.stringify({
      view_type: formData.view_type,
      image: uploadedImagecity,
      title: formData.title,
      description: formData.description,
    });
    console.log(raw);
    try {
      const finalEndpoint =
        bannerid != undefined
          ? END_POINT.UpdateMiceBanner + bannerid
          : END_POINT.AddMiceBanner;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../mice-banner");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        console.log(values);
        const uploadedImagehotellist = values[0].image;
        handleSubmit(uploadedImagehotellist);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const AllFileUpdate = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        const uploadedImagehotellist =
          values[0].image == undefined ? formData.image : values[0].image;

        handleSubmit(uploadedImagehotellist);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add Mice Banner</b>
        </h3>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Title:</span> <br />
            <input
              type="text"
              id="title"
              name="title"
              className="input-fields-style"
              value={formData.title}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Description:</span> <br />
            <input
              type="text"
              id="description"
              name="description"
              className="input-fields-style"
              value={formData.description}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">View Type</span> <br />
            <Select
              id="view_type"
              name="view_type"
              value={wedding_view_type.filter(
                (item) => item.value == formData.view_type
              )}
              options={wedding_view_type} // Use wedding_view_type instead of view_type
              className="input-fields-style"
              placeholder="Select View type"
              isSearchable
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) =>
                formData.view_type === option.value ? true : false
              }
              onChange={(e) => {
                setformData({
                  ...formData,
                  view_type: e.value,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Upload Banner Image *</span> <br />
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            if (bannerid != undefined) {
              if (image != "") {
                AllFileUpdate();
              } else {
                handleSubmit(formData.image);
              }
            } else {
              AllFileUpload();
            }
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../wedding-banner");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddMiceBanner;
