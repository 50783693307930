import "./App.css";
import { register } from "swiper/element/bundle";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
  withRouter,
  Redirect,
  useHistory,
  useLocation,
  Navigate,
} from "react-router-dom";
import React, { useRef, useEffect, useState } from "react";
import ScrollToTop from "./components/ScrollToTop";
import { useSelector, useDispatch } from "react-redux";
import { Provider } from "react-redux";
import { store, Persistor } from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { HomeScreen } from "./screens/HomeScreen/HomeScreen";
import { PartnerScreen } from "./screens/Partner/PartnerScreen";
import { ProductLevel1 } from "./screens/ProductLevel1/ProductLevel1";
import { WeddingScreen } from "./screens/Wedding/WeddingScreen";
import EventsScreen from "./screens/Events/EventsScreen";
import { MiceScreen } from "./screens/MiceScreen/MiceScreen";
import { BlogsScreen } from "./screens/BlogsScreen/BlogsScreen";

import { WeddingEnquiryForm } from "./screens/EnquiryForm/WeddingEnquiryForm";
import WeddingServiceForm from "./screens/EnquiryForm/WeddingServiceForm";
import HotelDetailScreen from "./screens/Hotel/HotelDetailScreen";
import BookNowScreen from "./screens/BookNow/BookNowScreen";
import FoodPreference from "./screens/BookNow/FoodPreference";
import ProfileScreen from "./screens/Profile/ProfileScreen";
import PasswordScreen from "./screens/Profile/PasswordScreen";
import PersonalDetails from "./screens/Profile/PersonalDetails";
import PreviousBookingScreen from "./screens/OrderHistory/PreviousBookingScreen";
import UpcomingBookingScreen from "./screens/OrderHistory/UpcomingBookingScreen";
import DinningDetailScreen from "./screens/DinningScreen/DinningDetailScreen";
import ActivityDetailScreen from "./screens/Activity/ActivityDetailScreen";
import VehiclesDetailScreen from "./screens/Vehicles/VehiclesDetailScreen";
import PackagesDetailScreen from "./screens/Packages/PackagesDetailScreen";
//Dahsboard
import SideBarScreen from "./dashboard/dashscreen/sidebar/SideBarScreen";
import DashBoardScreen from "./dashboard/dashscreen/DashBoardScreen";
import HotelListingScreen from "./dashboard/dashscreen/hotel/HotelListingScreen";
import AddHotelScreen from "./dashboard/dashscreen/hotel/AddHotelScreen";
import HotelCityScreen from "./dashboard/dashscreen/hotel/HotelCityScreen";
import AddHotelCityScreen from "./dashboard/dashscreen/hotel/AddHotelCityScreen";
import AddRoomFacilityScreen from "./dashboard/dashscreen/hotel/AddRoomFacility";
import RoomFacilityScreen from "./dashboard/dashscreen/hotel/RoomFacilityScreen";
import EditHotel from "./dashboard/dashscreen/hotel/HotelRoomList";
import HotelRoomList from "./dashboard/dashscreen/hotel/HotelRoomList";
import RoomTypeScreen from "./dashboard/dashscreen/hotel/RoomTypeScreen";
import AddRoomtypeScreen from "./dashboard/dashscreen/hotel/AddRoomTypeScreen";
import HotelStock from "./dashboard/dashscreen/hotel/HotelStock";
import CartCheckoutPage from "./screens/Payment/CartCheckoutPage";
import HotelBookingScreen from "./dashboard/dashscreen/hotel/HotelBookingScreen";
import HotelSummaryScreen from "./dashboard/dashscreen/hotel/HotelSummaryScreen";
import ThankYou from "./screens/ThankYou/ThankYou";

//wedding
import WeedingList from "./dashboard/dashscreen/wedding/WeedingList";
import AddWedding from "./dashboard/dashscreen/wedding/AddWedding";
import WeddingBannerScreen from "./dashboard/dashscreen/wedding/WeddingBannerScreen";
import AddWeddingBannerScreen from "./dashboard/dashscreen/wedding/AddWeddingBanner";
import WeddingRealVenue from "./dashboard/dashscreen/wedding/WeddingRealVenue";
import AddWeddingRealVenue from "./dashboard/dashscreen/wedding/AddWeddingRealVenue";
import WeddingInspiration from "./dashboard/dashscreen/wedding/WeddingInspiration";
import AddWeddingInspiration from "./dashboard/dashscreen/wedding/AddWeddingInspiration";
import WeddingDetailScreen from "./dashboard/dashscreen/wedding/WeddingDetailScreem";

//MICE

import MiceDetailScreem from "./dashboard/dashscreen/mice/MiceDetailScreem";
import MiceList from "./dashboard/dashscreen/mice/MiceList";
import AddMice from "./dashboard/dashscreen/mice/AddMice";
import MiceBannerScreen from "./dashboard/dashscreen/mice/MiceBannerScreen";
import AddMiceBanner from "./dashboard/dashscreen/mice/AddMiceBanner";
import MiceVenue from "./dashboard/dashscreen/mice/MiceVenue";
import AddMiceVenue from "./dashboard/dashscreen/mice/AddMiceVenue";
import MiceConcept from "./dashboard/dashscreen/mice/MiceConcept";
import AddMiceConcept from "./dashboard/dashscreen/mice/AddMiceConcept";
//Dinning
import DiningCityScreen from "./dashboard/dashscreen/dinning/DiningCityScreen";
import AddDiningCityScreen from "./dashboard/dashscreen/dinning/AddDiningCityScreen";
import DiningScreen from "./dashboard/dashscreen/dinning/DiningScreen";
import AddDiningScreen from "./dashboard/dashscreen/dinning/AddDiningScreen";
import AddItemtypeScreen from "./dashboard/dashscreen/dinning/AddItemTypeScreen";
import ItemTypeScreen from "./dashboard/dashscreen/dinning/ItemTypeScreen";
import DinningPriceStock from "./dashboard/dashscreen/dinning/DinningPriceStock";
//activity
import ActivityCityScreen from "./dashboard/dashscreen/activity/ActivityCityScreen";
import ActivityScreen from "./dashboard/dashscreen/activity/ActivityScreen";
import AddActivityScreen from "./dashboard/dashscreen/activity/AddActivityScreen";
import AddActivityCityScreen from "./dashboard/dashscreen/activity/AddActivityCityScreen";
import AddActivityItemTypeScreen from "./dashboard/dashscreen/activity/AddActivityItemTypeScreen";
import ActivityPriceStock from "./dashboard/dashscreen/activity/ActivityPriceStock";
import ActivityItemTypeScreen from "./dashboard/dashscreen/activity/ActivityItemTypeScreen";

//package

import PackageCityScreen from "./dashboard/dashscreen/package/PackageCityScreen";
import PackageScreen from "./dashboard/dashscreen/package/PackageScreen";
import AddPackageScreen from "./dashboard/dashscreen/package/AddPackageScreen";
import AddPackageCityScreen from "./dashboard/dashscreen/package/AddPackageCityScreen";
import AddPackageItemTypeScreen from "./dashboard/dashscreen/package/AddPackageItemTypeScreen";
import PackagePriceStock from "./dashboard/dashscreen/package/PackagePriceStock";
import PackageItemTypeScreen from "./dashboard/dashscreen/package/PackageItemTypeScreen";

//vehicles

import VehiclesCityScreen from "./dashboard/dashscreen/vehicles/VehiclesCityScreen";
import VehiclesScreen from "./dashboard/dashscreen/vehicles/VehiclesScreen";
import AddVehiclesScreen from "./dashboard/dashscreen/vehicles/AddVehiclesScreen";
import AddVehiclesCityScreen from "./dashboard/dashscreen/vehicles/AddVehiclesCityScreen";
import AddVehiclesItemTypeScreen from "./dashboard/dashscreen/vehicles/AddVehiclesItemTypeScreen";
import VehiclesPriceStock from "./dashboard/dashscreen/vehicles/VehiclesPriceStock";
import VehiclesItemTypeScreen from "./dashboard/dashscreen/vehicles/VehiclesItemTypeScreen";

//pages
import PagesList from "./dashboard/dashscreen/Pages/PagesList";
import AddPage from "./dashboard/dashscreen/Pages/AddPage";

//Blogs
import BlogsList from "./dashboard/dashscreen/Blogs/BlogsList";
import AddBlog from "./dashboard/dashscreen/Blogs/AddBlog";
import CategoryList from "./dashboard/dashscreen/Blogs/CategoryList";
import BlogBannerScreem from "./dashboard/dashscreen/Blogs/BlogBannerScreen";
import AddCategory from "./dashboard/dashscreen/Blogs/AddCategory";
import SubCategoryList from "./dashboard/dashscreen/Blogs/SubCategoryList";
import AddSubCategory from "./dashboard/dashscreen/Blogs/AddSubCategory";

//testimonial
import AddTestimonial from "./dashboard/dashscreen/Testimonial/AddTestimonial";
import TestimonialList from "./dashboard/dashscreen/Testimonial/TestimonialList";

import AcceptedBookingList from "./dashboard/dashscreen/BookingsDetail/AcceptedBookingList";
import RejectedBookingList from "./dashboard/dashscreen/BookingsDetail/RejectedBookingList";

//SocialLinkDetail
import SocialLinkDetail from "./dashboard/dashscreen/SocialLink/SocialLinkDetail";

//faq
import FAQList from "./dashboard/dashscreen/HelpAndSupport/FAQList";
import AddFAQ from "./dashboard/dashscreen/HelpAndSupport/AddFAQ";

//ProductBlogList
import ProductBlogList from "./dashboard/dashscreen/ProductBlog/ProductBlogList";
import AddProductBlog from "./dashboard/dashscreen/ProductBlog/AddProductBlog";

//why-choose-us
import WhyChooseUsList from "./dashboard/dashscreen/PartnerWithUs/WhyChooseUsList";
import AddWhyChooseUs from "./dashboard/dashscreen/PartnerWithUs/AddWhyChooseUs";
//Subscribe
import SubscribeList from "./dashboard/dashscreen/Subscribe/SubscribeList";
//Enquiry
import EnquiryList from "./dashboard/dashscreen/Enquiry/EnquiryList";

import EventBannerScreen from "./dashboard/dashscreen/events/EventBannerScreen";
import AddEventBannerScreen from "./dashboard/dashscreen/events/AddEventBannerScreen";

import EventScreen from "./dashboard/dashscreen/events/EventScreen";
import AddEventScreen from "./dashboard/dashscreen/events/AddEventScreen";

import EventBlogScreen from "./dashboard/dashscreen/events/EventBlogScreen";
import AddEventBlogScreen from "./dashboard/dashscreen/events/AddEventBlogScreen";

//homeDashboard
import AddHomeBanner from "./dashboard/dashscreen/HomeDetails/AddHomeBanner";
import HomeBannerList from "./dashboard/dashscreen/HomeDetails/HomeBannerList";
import RecomendedList from "./dashboard/dashscreen/HomeDetails/RecomendedList";
import AddRecomended from "./dashboard/dashscreen/HomeDetails/AddRecomended";
import JoinNetworkList from "./dashboard/dashscreen/HomeDetails/JoinNetworkList";
import AddJoinNetwork from "./dashboard/dashscreen/HomeDetails/AddJoinNetwork";
import AddSectionFourth from "./dashboard/dashscreen/HomeDetails/AddSectionFourth";
import SectionFourthList from "./dashboard/dashscreen/HomeDetails/SectionFourthList";
import AddSectionSecond from "./dashboard/dashscreen/HomeDetails/AddSectionSecond";
import SectionSecondList from "./dashboard/dashscreen/HomeDetails/SectionSecondList";

import PagesScreen from "./screens/Pages/PagesScreen";

import { CityBlogsScreen } from "./screens/BlogsScreen/CityBlogsScreen";

import { WeddingBlogs } from "./screens/BlogsScreen/WeddingBlogs";
import { BlogDetails } from "./screens/BlogsScreen/BlogDetails";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import WishList from "./screens/Wishlist/WishList";
import Contact from "./screens/ContactUs/Contact";
import LoginPage from "./screens/LoginPage/LoginPage";
import Map from "./screens/BlogsScreen/Map";
import ShareHotelDetail from "./screens/Hotel/ShareHotelDetails";
import SharedDinningDetail from "./screens/DinningScreen/ShareDinningDetail";
import ShareActivityDetail from "./screens/Activity/ShareActivityDetail";
import SharePackagesDetail from "./screens/Packages/SharePackageDetail";
import SharedVehiclesDetailScreen from "./screens/Vehicles/SharedVehicleDetail";
import ActivityFaqScreen from "./dashboard/dashscreen/activity/ActivityFaq";
import AddActivityFaq from "./dashboard/dashscreen/activity/AddActivityFaq";
import ActivityTicket from "./screens/Pages/ActivityTicket";
import ActivityTicketScreen from "./dashboard/dashscreen/activity/ShowActivityTicket";
import ShowActivityTicketScreen from "./dashboard/dashscreen/activity/ShowActivityTicket";
import AddActivityScreenDummy from "./dashboard/dashscreen/activity/dummy";
import { NewWeddingScreen } from "./screens/Wedding/NewWeddingScreen";

export default function App() {
  const PrivateAllRoutes = ({ children, path, access, accessName }) => {
    const { userData } = useSelector((store) => store.userReducer);

    const auth_2 = userData?.type == "admin" ? true : false;

    return (
      <>
        {/* {type == "Admin" ? (
          <>{!auth_2 ? <Navigate to={"/login"} /> : children}</>
        ) : (
          <>
            {location.pathname != "/" &&
            (isWordPresent == false || isWordPresent == null) ? (
              <Navigate to={"/login"} />
            ) : (
              children
            )}
          </>
        )} */}
        {auth_2 == false || auth_2 == null ? (
          <Navigate to={"/login"} />
        ) : (
          children
        )}
      </>
    );
  };

  const [showNav, setShowNav] = useState(true);
  const [showfooter, setShowfooter] = useState(true);

  return (
    // <CartCheckoutPage />
    <Provider store={store}>
      <PersistGate loading={null} persistor={Persistor}>
        <Router>
          {showNav && (
            <nav>
              <Header />
            </nav>
          )}
          <ScrollToTop />

          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/partner-with-us" element={<PartnerScreen />} />
            <Route
              path="/login"
              element={
                <LoginPage funcNav={setShowNav} funcFoot={setShowfooter} />
              }
            />

            <Route
              path="/booking/:city/:checkin/:checkout/:room"
              element={<ProductLevel1 />}
            />

            <Route
              path="/hotel-booking-details/:id"
              element={<HotelDetailScreen />}
            />

            <Route
              path="/hotels/:id"
              element={<ShareHotelDetail />}
            />

            <Route
              path="/activity-ticket"
              element={<ActivityTicket />}
            />

            <Route
              path="/dinning-booking-details/:id"
              element={<DinningDetailScreen />}
            />

            <Route
              path="/dinning/:id"
              element={<SharedDinningDetail />}
            />

            <Route
              path="/activities-booking-details/:id"
              element={<ActivityDetailScreen />}
            />

            <Route
              path="/activities/:id"
              element={<ShareActivityDetail />}
            />

            <Route
              path="/vehicles-booking-details/:id"
              element={<VehiclesDetailScreen />}
            />

            <Route
              path="/vehicles/:id"
              element={<SharedVehiclesDetailScreen />}
            />

            <Route
              path="/packages-booking-details/:id"
              element={<PackagesDetailScreen />}
            />

            <Route
              path="/packages/:id"
              element={<SharePackagesDetail />}
            />

            <Route path="/book-hotel/:id" element={<BookNowScreen />} />
            <Route
              path="/booking/:tab/:id"
              element={<FoodPreference funcFoot={setShowfooter} />}
            />

            {/* <Route path="order-details" element={<OrderDetails />} /> */}

            <Route path="/thank-you" element={<ThankYou />} />

            <Route path="/wedding" element={<WeddingScreen />} />
            <Route path="/new-wedding" element={<NewWeddingScreen />} />
            <Route path="/events" element={<EventsScreen />} />
            <Route path="/mice-events" element={<MiceScreen />} />
            <Route path="/wishlist" element={<WishList />} />
            <Route path="/contact-us" element={<Contact />} />

            <Route path="/blogs" element={<BlogsScreen />} />
            {/* <Route path="/map" element={<Map />} /> */}
            <Route path="/blog-detail/:id" element={<BlogDetails />} />

            {/* <Route path="/about-us" element={<AboutUsScreen />} /> */}
            {/* <Route path="/privacy-plocy" element={<PrivacyPlolicyScreen />} /> */}
            {/* <Route path="/terms-condition" element={<TermsConditionScreen />} /> */}
            <Route path="/terms-and-condition" element={<PagesScreen />} />
            <Route path="/privacy-plocy" element={<PagesScreen />} />
            <Route path="/about-us" element={<PagesScreen />} />
            <Route path="/cookie-policy" element={<PagesScreen />} />
            <Route path="/refund-policy" element={<PagesScreen />} />

            <Route
              path="/blogs/:category/:name"
              element={<CityBlogsScreen />}
            />
            <Route path="/blogs/:category" element={<CityBlogsScreen />} />

            <Route path="/enquiry" element={<WeddingEnquiryForm />} />
            <Route path="/service-offer" element={<WeddingServiceForm />} />

            {/* <Route path="/user-profile/*" element={<ProfileScreen />}>
              <Route
                path="previous-trips"
                element={<PreviousBookingScreen />}
              />
              <Route
                path="upcoming-trips"
                element={<UpcomingBookingScreen />}
              />
            </Route> */}

            <Route path="/user-profile/*" element={<ProfileScreen />}>
              <Route
                index={true}
                // path="user-info"
                element={<PersonalDetails />}
              />

              <Route
                path="previous-trips"
                element={<PreviousBookingScreen />}
              />
              <Route
                path="upcoming-trips"
                element={<UpcomingBookingScreen />}
              />
              <Route path="password-details" element={<PasswordScreen />} />
            </Route>

            {/* dinning */}
            {/* <Route
              path="/dinning-booking/:city/:checkin/:checkout/:room"
              element={<DinningListingScreen />}
            /> */}

            <Route
              path="/admin/*"
              element={
                <PrivateAllRoutes>
                  <SideBarScreen funcNav={setShowNav} funcFoot={setShowfooter} />
                </PrivateAllRoutes>
              }
            >
              <Route index={true} element={<DashBoardScreen />} />
              <Route path="hotel-listing" element={<HotelListingScreen />} />
              <Route
                path="hotel-summary/:id"
                element={<HotelSummaryScreen />}
              />
              <Route path="add-hotel-listing" element={<AddHotelScreen />} />
              <Route path="hotel-city" element={<HotelCityScreen />} />
              <Route path="add-hotel-city" element={<AddHotelCityScreen />} />
              <Route
                path="add-room-facility"
                element={<AddRoomFacilityScreen />}
              />
              <Route path="room-facility" element={<RoomFacilityScreen />} />
              <Route path="hotel-room-list/:id" element={<HotelRoomList />} />
              <Route path="room-type/:id/:name" element={<RoomTypeScreen />} />
              <Route path="add-room-type/:id" element={<AddRoomtypeScreen />} />
              <Route path="price-stock/:id/:name" element={<HotelStock />} />
              <Route
                path="hotel-booking-list"
                element={<HotelBookingScreen />}
              />
              {/* wedding */}
              <Route path="wedding-list" element={<WeedingList />} />
              <Route path="add-wedding" element={<AddWedding />} />
              <Route path="wedding-details" element={<WeddingDetailScreen />} />
              <Route path="wedding-banner" element={<WeddingBannerScreen />} />
              <Route
                path="add-wedding-banner"
                element={<AddWeddingBannerScreen />}
              />
              <Route
                path="wedding-real-venue-list"
                element={<WeddingRealVenue />}
              />
              <Route
                path="add-wedding-real-venue"
                element={<AddWeddingRealVenue />}
              />
              <Route
                path="wedding-inspiration-list"
                element={<WeddingInspiration />}
              />
              <Route
                path="add-wedding-inspiration"
                element={<AddWeddingInspiration />}
              />

              {/* MICE */}

              <Route path="mice-list" element={<MiceList />} />
              <Route path="add-mice" element={<AddMice />} />
              <Route path="mice-details" element={<MiceDetailScreem />} />
              <Route path="mice-banner" element={<MiceBannerScreen />} />
              <Route path="add-mice-banner" element={<AddMiceBanner />} />
              <Route path="mice-venue-list" element={<MiceVenue />} />
              <Route path="add-mice-venue" element={<AddMiceVenue />} />
              <Route path="mice-concept-list" element={<MiceConcept />} />
              <Route path="add-mice-concept" element={<AddMiceConcept />} />

              {/* dinning list */}
              <Route path="dining-city" element={<DiningCityScreen />} />
              <Route path="add-dining-city" element={<AddDiningCityScreen />} />

              <Route path="dining-list" element={<DiningScreen />} />
              <Route path="add-dining" element={<AddDiningScreen />} />
              <Route
                path="item-type/:id/:dinningname"
                element={<ItemTypeScreen />}
              />
              <Route path="add-item-type/:id" element={<AddItemtypeScreen />} />
              <Route
                path="dinning-price-stock/:id"
                element={<DinningPriceStock />}
              />

              {/* Activity */}
              <Route path="activity-city" element={<ActivityCityScreen />} />
              <Route
                path="add-activity-city"
                element={<AddActivityCityScreen />}
              />

              <Route path="activity-list" element={<ActivityScreen />} />
              <Route path="add-activity" element={<AddActivityScreen />} />
              <Route path="add-activity-dummy" element={<AddActivityScreenDummy />} />

              <Route
                path="add-activity-item-type/:id"
                element={<AddActivityItemTypeScreen />}
              />

              <Route
                path="add-activity-faq/:id"
                element={<AddActivityFaq />}
              />

              <Route
                path="activity-price-stock/:id"
                element={<ActivityPriceStock />}
              />
              <Route
                path="activity-item-type/:id/:dinningname"
                element={<ActivityItemTypeScreen />}
              />

              <Route
                path="activity-faq/:id/:dinningname"
                element={<ActivityFaqScreen />}
              />
              {/* Package */}
              <Route path="package-city" element={<PackageCityScreen />} />
              <Route
                path="add-package-city"
                element={<AddPackageCityScreen />}
              />

              <Route path="package-list" element={<PackageScreen />} />
              <Route path="add-package" element={<AddPackageScreen />} />

              <Route
                path="add-package-item-type/:id"
                element={<AddPackageItemTypeScreen />}
              />

              <Route
                path="package-price-stock/:id"
                element={<PackagePriceStock />}
              />
              <Route
                path="package-item-type/:id/:dinningname"
                element={<PackageItemTypeScreen />}
              />
              {/* Vehicles */}
              <Route path="vehicles-city" element={<VehiclesCityScreen />} />
              <Route
                path="add-vehicles-city"
                element={<AddVehiclesCityScreen />}
              />

              <Route path="vehicles-list" element={<VehiclesScreen />} />
              <Route path="add-vehicles" element={<AddVehiclesScreen />} />

              <Route
                path="add-vehicles-item-type/:id"
                element={<AddVehiclesItemTypeScreen />}
              />

              <Route
                path="vehicles-price-stock/:id"
                element={<VehiclesPriceStock />}
              />
              <Route
                path="vehicles-item-type/:id/:dinningname"
                element={<VehiclesItemTypeScreen />}
              />
              {/* Pages */}
              <Route path="pages" element={<PagesList />} />
              <Route path="add-page" element={<AddPage />} />

              {/* Blogs */}
              <Route path="blogs" element={<BlogsList />} />
              <Route path="add-blog" element={<AddBlog />} />
              <Route path="category" element={<CategoryList />} />
              <Route path="blog-banner" element={<BlogBannerScreem />} />
              <Route path="add-category" element={<AddCategory />} />
              <Route path="sub-category" element={<SubCategoryList />} />
              <Route path="add-sub-category" element={<AddSubCategory />} />
              {/* Bookings */}
              <Route
                path="booking-list"
                element={<AcceptedBookingList />}
              />
              <Route
                path="rejected-booking-list"
                element={<RejectedBookingList />}
              />

              {/* Testimonial */}
              <Route path="testimonial-list" element={<TestimonialList />} />
              <Route path="add-testimonial" element={<AddTestimonial />} />

              {/* Enquiry */}
              <Route path="enquiry-list" element={<EnquiryList />} />

              {/* Event */}

              <Route path="event-banner" element={<EventBannerScreen />} />

              <Route
                path="add-event-banner"
                element={<AddEventBannerScreen />}
              />

              <Route path="add-event" element={<AddEventScreen />} />
              <Route path="event-list" element={<EventScreen />} />

              <Route path="add-event-blog" element={<AddEventBlogScreen />} />
              <Route path="event-blog-list" element={<EventBlogScreen />} />

              {/* Subscribe */}
              <Route path="subscribe-list" element={<SubscribeList />} />

              {/* Social Link */}
              <Route path="social-link-detail" element={<SocialLinkDetail />} />

              {/* FAQ */}
              <Route path="faq-list" element={<FAQList />} />
              <Route path="add-faq" element={<AddFAQ />} />

              {/* WhyChooseUs */}
              <Route path="why-choose-us-list" element={<WhyChooseUsList />} />
              <Route path="add-why-choose-us" element={<AddWhyChooseUs />} />

              {/* ProductBlog */}
              <Route path="product-blog-list" element={<ProductBlogList />} />
              <Route path="add-product-blog" element={<AddProductBlog />} />

              {/* HoemScreenData */}
              <Route path="home-banner-list" element={<HomeBannerList />} />
              <Route path="add-home-banner" element={<AddHomeBanner />} />

              <Route
                path="section-second-list"
                element={<SectionSecondList />}
              />
              <Route path="add-section-second" element={<AddSectionSecond />} />

              <Route path="recomended-list" element={<RecomendedList />} />
              <Route path="add-recomended" element={<AddRecomended />} />

              <Route
                path="section-fourth-list"
                element={<SectionFourthList />}
              />
              <Route path="add-section-fourth" element={<AddSectionFourth />} />

              <Route path="join-network-list" element={<JoinNetworkList />} />
              <Route path="add-join-network" element={<AddJoinNetwork />} />
              <Route path="show-activity-ticket" element={<ShowActivityTicketScreen />} />


            </Route>
          </Routes>
          {showfooter && (
            <footer>
              <Footer />
            </footer>
          )}
        </Router>
      </PersistGate>
    </Provider>
  );
}
