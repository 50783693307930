import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { END_POINT, METHODS } from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

const ActivityTicket = () => {
    const [activitie, setActivityData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        diningListHandler();
    }, []);

    const diningListHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetActivity);
            if (result.success === true) {
                setActivityData(result.data);
                console.log(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const [formData, setformData] = useState({
        name: "",
        email: "",
        phone: "",
        numberOfPeople: "",
        activity: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Check if the field being changed is numberOfPeople and validate the value
        if (name === "numberOfPeople") {
            const numberOfPeople = parseInt(value, 10);
            if (numberOfPeople < 1 || numberOfPeople > 10) {
                return; // Exit the function if the value is out of range
            }
        }

        setformData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const raw = JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            numberOfPeople: formData.numberOfPeople,
            activity: formData.activity,
        });
        console.log(raw);

        try {
            const finalEndpoint = END_POINT.AddActivityTicket;
            const result = await fetchData(finalEndpoint, METHODS.POST, raw);
            console.log("API response:", result);
            if (result.success === true) {
                Swal.fire({
                    title: "Confirmed",
                    text: result.message,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/activity-ticket");
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            {/* Navbar */}

            {/* About Us Content */}
            <div className="container about-us-section">
                <br></br>
                <br></br>
                <form onSubmit={handleSubmit} className="activity-form">
                    <h1>Activity Ticket</h1>

                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="numberOfPeople">Number of People</label>
                        <input
                            type="number"
                            min={1}
                            max={10}
                            className="form-control"
                            id="numberOfPeople"
                            name="numberOfPeople"
                            value={formData.numberOfPeople}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="activity">Activity</label>
                        <select
                            className="form-control"
                            id="activity"
                            name="activity"
                            value={formData.activity}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select an activity</option>
                            {activitie.map((activity) => (
                                <option key={activity.id} value={activity.name}>
                                    {activity.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <br></br>
                    <center>
                        <Button
                            type="submit"
                            className="btn btn-primary m-2"
                        >
                            Submit
                        </Button>
                    </center>
                </form>
                <br></br>
                <br></br>
            </div>

            {/* Footer */}
        </div>
    );
};

export default ActivityTicket;
