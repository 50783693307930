import React, { useRef, useState, useEffect } from "react";
import { BASE_URL_IMAGE, END_POINT } from "../Helper/helper";
import EditorRead from "./EditorRead";
import { fetchData } from "../Helper/ApiCall";

function Testimonial({ type }) {
  const [TestimonialData, setTestimonialData] = useState([]);

  useEffect(() => {
    handler();
    // handlerInspiration();
  }, []);
  const handler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetDetailByPageDataTestimonial + type
      );
      // console.log(result);
      if (result.success == true) {
        setTestimonialData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    TestimonialData.length > 0 && (
      <div className="experience-wrapper">
        <div className="container experience-section">
          <h2>Client Testimonials: Discover Their Experience</h2>

          <div className="row experince-cards-wrapper">
            {TestimonialData.length > 0 &&
              TestimonialData.map((item) => {
                return (
                  <div className="col-lg-3 md-6 sm-12 experience-cards m-2">
                    <div className="cards-inner-row">
                      <div className="tesitmonial-image-user">
                        <img
                          src={BASE_URL_IMAGE + item.image}
                          className="img-fluid"
                        />
                      </div>
                      <div className="">
                        <img
                          src="/assets/img/Buildingicon.png"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <p>
                      <EditorRead description={item.comments} />
                    </p>
                    <p>{item.designation}</p>
                  </div>
                );
              })}
          </div>
          <div className="col-12 text-center mt-3">
            <button class="btn see-more-btn">See More</button>
          </div>
        </div>
      </div>
    )
  );
}

export default Testimonial;
