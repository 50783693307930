import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { END_POINT } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import Swal from "sweetalert2";
import EditorRead from "./EditorRead";
import "../../dashscreen/DashCss.css";

const ShowActivityTicketScreen = () => {
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        ticketListHandler();
    }, []);

    const ticketListHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetActivityTicket);
            if (result.success === true) {
                setTickets(result.data);
                console.log(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <div>
            <Link
                style={{ textDecoration: "none" }}
                to="../add-activity-ticket"
            >

            </Link>

            <div className="mt-4">
                <div
                    id="example_wrapper"
                    className="dataTables_wrapper no-footer table-overflow"
                >
                    <div
                        className="dataTables_length"
                        id="example_length"
                        style={{ padding: 5 }}
                    >
                        <label>
                            Show
                            <select
                                name="example_length"
                                aria-controls="example"
                                className="mb-4"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entries
                        </label>
                    </div>
                    <div id="example_filter" className="dataTables_filter mb-4">
                        <label>
                            Search:
                            <input
                                type="search"
                                className=""
                                placeholder=""
                                aria-controls="example"
                            />
                        </label>
                    </div>
                    <table
                        id="example"
                        className="table table-striped table-bordered dataTable no-footer"
                        style={{ width: "100%", whiteSpace: "nowrap" }}
                        role="grid"
                        aria-describedby="example_info"
                    >
                        <thead>
                            <tr role="row">
                                <th
                                    className="sorting_asc"
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    aria-label="S.N: activate to sort column descending"
                                    style={{ width: "20px" }}
                                >
                                    S.N
                                </th>
                                <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Name: activate to sort column ascending"
                                    style={{ width: "146px" }}
                                >
                                    Name
                                </th>
                                <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Email: activate to sort column ascending"
                                    style={{ width: "146px" }}
                                >
                                    Email
                                </th>
                                <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Phone: activate to sort column ascending"
                                    style={{ width: "96px" }}
                                >
                                    Phone
                                </th>
                                <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Number of People: activate to sort column ascending"
                                    style={{ width: "96px" }}
                                >
                                    Number of People
                                </th>
                                <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Activity: activate to sort column ascending"
                                    style={{ width: "96px" }}
                                >
                                    Activity
                                </th>
                                <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Edit Ticket: activate to sort column ascending"
                                    style={{ width: "56px" }}
                                >
                                    Date
                                </th>

                            </tr>
                        </thead>

                        <tbody>
                            {tickets.length > 0 ? (
                                tickets.map((ticket, index) => (
                                    <tr role="row" className="odd" key={index}>
                                        <td className="sorting_1"> {index + 1} </td>
                                        <td> {ticket.name} </td>
                                        <td> {ticket.email} </td>
                                        <td> {ticket.phone} </td>
                                        <td> {ticket.numberOfPeople} </td>
                                        <td> {ticket.activity} </td>

                                        <td>{new Date(ticket.updatedAt).toLocaleDateString()}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8">No tickets available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div
                        className="dataTables_info"
                        id="example_info"
                        role="status"
                        aria-live="polite"
                    >
                        Showing {tickets.length > 0 ? 1 : 0} to {tickets.length} of {tickets.length} entries
                    </div>
                    <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="example_paginate"
                    >
                        <a
                            className="paginate_button previous disabled"
                            aria-controls="example"
                            data-dt-idx="0"
                            tabIndex="0"
                            id="example_previous"
                        >
                            Previous
                        </a>
                        <span>
                            <a
                                className="paginate_button current"
                                aria-controls="example"
                                data-dt-idx="1"
                                tabIndex="0"
                            >
                                1
                            </a>
                        </span>
                        <a
                            className="paginate_button next disabled"
                            aria-controls="example"
                            data-dt-idx="2"
                            tabIndex="0"
                            id="example_next"
                        >
                            Next
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowActivityTicketScreen;
