import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { END_POINT } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import "../../dashscreen/DashCss.css";
import TableHead from "../../components/TableHead";

const PackageItemTypeScreen = () => {
  let location = useLocation();

  const [itemType, setItemTypeData] = useState([]);
  const { id, dinningname } = useParams();
  // const dinningname = location?.state?.dinningname;

  useEffect(() => {
    itemTypeHandler();
  }, []);

  const itemTypeHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetPackageItemType + id);
      console.log(result);
      if (result.success == true) {
        setItemTypeData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const deleteHandler = async (id) => {
    try {
      const result = await fetchData(END_POINT.DeletePackageItemType + id);
      if (result.success == true) {
        itemTypeHandler();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Link
        style={{ textDecoration: "none" }}
        to={"../add-package-item-type/" + id}
      >
        <button
          className="btn btn-primary mt-4"
        // onClick={() => {
        //   Navigate("add-hotel-listing");
        // }}
        >
          Add Item Type&nbsp;<i class="fa-solid fa-plus"></i>
        </button>
      </Link>

      <div className="mt-4">
        <div
          id="example_wrapper"
          class="dataTables_wrapper no-footer table-overflow"
        >
          <div
            class="dataTables_length"
            id="example_length"
            style={{ padding: 5 }}
          >
            <label>
              Show
              <select
                name="example_length"
                aria-controls="example"
                class="mb-4"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>
          </div>
          <div id="example_filter" class="dataTables_filter mb-4">
            <label>
              Search:
              <input
                type="search"
                class=""
                placeholder=""
                aria-controls="example"
              />
            </label>
          </div>
          <table
            id="example"
            class="table table-striped table-bordered dataTable no-footer"
            style={{ width: "100%", whiteSpace: "nowrap" }}
            role="grid"
            aria-describedby="example_info"
          >
            <thead>
              <tr role="row">
                {[
                  "S.N",
                  "Item Name",
                  "Package Type",

                  // "Payment Option",
                  // "Price Start From",
                  // "Booking Period",
                  // "Service Day",
                  "Edit",
                  "Price Stock",
                  "Edit Date",
                  "Summary",
                ].map((item) => {
                  return <TableHead title={item} />;
                })}
              </tr>
            </thead>

            <tbody>
              {itemType.length > 0 ? (
                itemType.map((item, index) => (
                  <tr role="row" class="odd" key={index}>
                    <td class="sorting_1"> {index + 1} </td>
                    <td> {item.name} </td>
                    <td> {item.package_type} </td>

                    {/* <td>0</td> */}
                    {/* <td>0</td> */}
                    {/* <td>0</td> */}
                    {/* <td>0</td> */}

                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../add-package-item-type/" + id,
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{ id: item._id, dinningname: dinningname }}
                      >
                        <i class="fa fa-edit"></i>
                      </Link>
                      <a
                        // href="#"
                        type="button"
                        class="btn btn-danger m-1"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: `You won't to delete ${item.name}!`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteHandler(item._id);

                              Swal.fire(
                                "Deleted!",
                                `${item.name} has been deleted.`,
                                "success"
                              );
                            }
                          });
                          //  handleDelete(item._id)
                        }}
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>
                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../package-price-stock/" + id,
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{
                          id: item._id,
                          name: item.name,
                          dinningname: dinningname,
                          package_type: item.package_type,
                        }}
                      >
                        <i class="fa fa-plus"></i>Price Stock
                      </Link>
                    </td>
                    <td>{item.updatedAt.split("T")[0]}</td>
                    <td>
                      <a href="#" class="btn btn-info">
                        <i class="fa fa-eye"></i>View Summary
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <p>package List Not Available</p>
              )}
            </tbody>
          </table>
          <div
            class="dataTables_info"
            id="example_info"
            role="status"
            aria-live="polite"
          >
            Showing 1 to 6 of 6 entries
          </div>
          <div
            class="dataTables_paginate paging_simple_numbers"
            id="example_paginate"
          >
            <a
              class="paginate_button previous disabled"
              aria-controls="example"
              data-dt-idx="0"
              tabindex="0"
              id="example_previous"
            >
              Previous
            </a>
            <span>
              <a
                class="paginate_button current"
                aria-controls="example"
                data-dt-idx="1"
                tabindex="0"
              >
                1
              </a>
            </span>
            <a
              class="paginate_button next disabled"
              aria-controls="example"
              data-dt-idx="2"
              tabindex="0"
              id="example_next"
            >
              Next
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageItemTypeScreen;
