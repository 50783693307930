import React, { useState } from "react";
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import VideoSwiper from "./VideoSwiper";
import {
  BASE_URL_IMAGE,
  END_POINT,
  METHODS,
  NumberSeprator,
  TabBarLabels,
} from "../Helper/helper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavYellow } from "./SwiperNavYellow";
import "swiper/css";
import "swiper/css/pagination";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../Helper/ApiCall";
import { addwishlistUpdate } from "../redux/reducers/wishlistReducer";

function ProductPageDeatils({
  productId,
  type,
  keyName,
  keyNameSearch,
  data,
  selectedroomtype,
  adultHandler,
  childHandler,
}) {
  const dispatch = useDispatch();
  const wishlistData = useSelector((store) =>
    store.wishlistReducer.wishlistData.find((item) => {
      return item.hotel_id == productId;
    })
  );
  const { country, currency } = useSelector((store) => store.currencyReducer);
  const userData = useSelector((store) => store.userReducer.userData);

  const [showModal, setShowModal] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);

  const handleModalOpen = (item) => {
    setClickedItem(item);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const findTotalAdultnum = (id) => {
    const TotalAdultnum = selectedroomtype.find((itemselect) => {
      return itemselect[keyNameSearch] == id;
    });

    return TotalAdultnum == undefined ? 0 : TotalAdultnum;
  };

  const addWishlistHandler = async () => {
    try {
      const raw = JSON.stringify({
        type: type,
        hotel_id: productId,
        user_id: userData._id,
      });

      const result = await fetchData(
        END_POINT.Uploadwishlist,
        METHODS.POST,
        raw
      );
      console.log(raw);
      if (result.success == true) {
        dispatch(addwishlistUpdate(result.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      {data != undefined &&
        data.map((item, index) => {
          if (item[keyName] == undefined || item?.[keyName]?.booking_type === "Instant Delete") {
            return null;
          }
          const TotalAdultnum = findTotalAdultnum(item._id);

          return (
            <div className="services-box">
              <Row className="">
                <div class="col-md-4 sm-12 text-center hotel-text1 hotel-text2 p-2 border-add">
                  <div class="cus-dinner-img p-4">
                    <img src={BASE_URL_IMAGE + item.image} class="img-fluid " />

                    <button
                      className="btn wish-list-btn"
                      onClick={() => handleModalOpen(item)}
                      style={{ fontSize: "17px" }}
                    >
                      MORE DETAILS <i class="fas fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
                <div class="col-md-8 sm-12 hotel-text1 hotel-text3 ">
                  <div class="cus-dinner-text desktop">
                    <h3>{item.name} </h3>
                  </div>

                  <div class="row ful-height">
                    {/* <div class="col-md-4 sm-12">
                      <div class="cus-dinner-box desktop">
                        {item.dinning_stock_data?.benifits?.map(
                          (itemBenefit, indexBenefit) => {
                            return (
                              <p class="free free1">
                                <i class="fas fa-check"></i> {itemBenefit.value}
                                {indexBenefit == 0 && (
                                  <span>

                                  </span>
                                )}
                              </p>
                            );
                          }
                        )}

                      </div>
                    </div> */}

                    <div class="col-md-8 sm-12 hotel-text1">
                      <div className="p-3">
                        <h5>Best Price for Today's </h5>
                        <div class="prices">
                          <h6 class="mr-2">

                            {type !== TabBarLabels.Vehicles &&
                              type !== TabBarLabels.Packages &&
                              item.type !== TabBarLabels.Vehicles &&
                              (item.type === "Vehicle" ? "VEHICLE" : "ADULT")}



                            <span>
                              {type == TabBarLabels.Packages ? (
                                <span>
                                  {NumberSeprator(
                                    Math.floor(
                                      (item?.[keyName]?.No_Pax_Adult *
                                        item?.[keyName]?.adultsell +
                                        item?.[keyName]?.No_Pax_Child *
                                        item?.[keyName]?.childsell) *
                                      currency[country]
                                    )
                                  )}
                                  {" " + country}
                                </span>
                              ) : (
                                <span>
                                  {NumberSeprator(
                                    Math.floor(
                                      item?.[keyName]?.adultsell *
                                      currency[country]
                                    )
                                  )}
                                  {" " + country}

                                </span>
                              )}
                            </span>
                          </h6>

                          <input
                            type="hidden"
                            value="20"
                            id="adultPrice46"
                            name="adultPrice"
                          />

                          <div id="field2" class="qty">
                            <button
                              type="button"
                              id="sub2"
                              value="46"
                              class="sub2 set_size priceIncrAdult"
                              onClick={(e) => {
                                const value =
                                  TotalAdultnum == undefined ||
                                    TotalAdultnum == false
                                    ? 0
                                    : TotalAdultnum.adultNumber;
                                if (value != 0) {
                                  adultHandler(item, value - 1);
                                }
                              }}
                            >
                              -
                            </button>

                            <input
                              type="text"
                              id="adult_value46"
                              name="adult_value"
                              class="adult_value"
                              value={
                                TotalAdultnum == undefined ||
                                  TotalAdultnum == false
                                  ? 0
                                  : TotalAdultnum?.adultNumber
                              }
                              readonly=""
                            />

                            <button
                              type="button"
                              id="add2"
                              value="46"
                              class="set_size priceDescAdult"
                              onClick={(e) => {
                                const value =
                                  TotalAdultnum == undefined ||
                                    TotalAdultnum == false
                                    ? 0
                                    : TotalAdultnum.adultNumber;
                                adultHandler(item, value + 1);
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        {type != TabBarLabels.Vehicles && item.type != "Vehicle" &&
                          // type != TabBarLabels.Packages &&
                          item.type != TabBarLabels.Vehicles && (
                            <div class="prices mt-2">
                              <h6 class="mr-2">

                                {(item.type === "Vehicle" ? "VEHICLE" : "CHILD")}
                                <span>
                                  {NumberSeprator(
                                    Math.floor(
                                      item?.[keyName]?.childsell *
                                      currency[country]
                                    )
                                  )}
                                  {" " + country}
                                </span>
                              </h6>
                              <input
                                type="hidden"
                                name="child_sell"
                                id="child_sell"
                                value="12"
                              />
                              <div id="field2" class="qty">
                                <button
                                  type="button"
                                  id="sub2"
                                  value="46"
                                  class="sub2 set_size priceIncrAdult"
                                  onClick={(e) => {
                                    const value =
                                      TotalAdultnum == undefined ||
                                        TotalAdultnum == false
                                        ? 0
                                        : TotalAdultnum.childNumber;
                                    if (value != 0) {
                                      childHandler(item, value - 1);
                                    }
                                  }}
                                >
                                  -
                                </button>

                                <input
                                  type="text"
                                  id="adult_value46"
                                  name="adult_value"
                                  class="adult_value"
                                  value={
                                    TotalAdultnum == undefined ||
                                      TotalAdultnum == false
                                      ? 0
                                      : TotalAdultnum?.childNumber
                                  }
                                  readonly=""
                                />

                                <button
                                  type="button"
                                  id="add2"
                                  value="46"
                                  class="set_size priceDescAdult"
                                  onClick={(e) => {
                                    const value =
                                      TotalAdultnum == undefined ||
                                        TotalAdultnum == false
                                        ? 0
                                        : TotalAdultnum.childNumber;
                                    childHandler(item, value + 1);
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          )}

                        <p class="text">{item.content}</p>
                        {wishlistData == undefined && (
                          <button
                            className="btn wish-list-btn"
                            type="button"
                            onClick={() => {
                              addWishlistHandler();
                            }}
                          >
                            Save to wish list
                          </button>
                        )}
                      </div>

                      {/* <div
                        class="spinner-border text-danger itemLoader46"
                        role="status"
                        style={{ display: "none" }}
                      >
                        <span class="sr-only">Loading...</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Row>
            </div >
          );
        })}
      <Modal
        show={showModal}
        onHide={handleModalClose}
        style={{ maxWidth: "none" }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {clickedItem && (
            <div>
              {/* <img src={BASE_URL_IMAGE + clickedItem.gallery_image} alt="Gallery" className="img-fluid" />
              <img src={BASE_URL_IMAGE + clickedItem.room_image} alt="Image" className="img-fluid" /> */}

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b>  Description : </b> {clickedItem.content}
              </span>

              {/* <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b>Dinning Inclusion: </b> {clickedItem.item_inclusion}
              </span> */}

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b>  Type : </b> {clickedItem.type}
              </span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default ProductPageDeatils;
