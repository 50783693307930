import React, { useState, useEffect } from "react";
import { useSwiper } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  END_POINT,
  METHODS,
  ExchangeApi,
  CountryCheckApi,
  BASE_URL_IMAGE,
} from "../Helper/helper";
import { fetchData } from "../Helper/ApiCall";
import { userdataUpdate } from "../redux/reducers/userReducer";
import {
  countryUpdate,
  currencyUpdate,
} from "../redux/reducers/currencyReducer";
import { wishlistUpdate } from "../redux/reducers/wishlistReducer";
import LoginModal from "./LoginModal";
export const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((store) => store.userReducer);
  const { country, currency } = useSelector((store) => store.currencyReducer);
  const { wishlistData } = useSelector((store) => store.wishlistReducer);
  const swiper = useSwiper();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [firdtName, setfirdtName] = useState("");
  const [lastName, setlastName] = useState("");
  const [mobile, setmobile] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setShow1(false);
  };
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
    setShow(false);
  };
  console.log("userData", country);

  const LoginHandler = async () => {
    try {
      const raw = JSON.stringify({
        email: email,
        password: password,
      });
      const result = await fetchData(END_POINT.LoginUser, METHODS.POST, raw);
      if (result.success == true) {
        console.log(result);
        handleClose1();
        dispatch(userdataUpdate(result.data));
        if (result.data.type == "admin") {
          navigate("/admin");
        }
      } else {
        Swal.fire({
          title: "Wrong",
          text: result.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Cancel",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const SingUpandler = async () => {
    try {
      const raw = JSON.stringify({
        firstName: firdtName,
        lastName: lastName,
        email: email,
        password: password,
        mobile: mobile,
        type: "user",
        status: true,
      });
      const result = await fetchData(END_POINT.PostUser, METHODS.POST, raw);
      if (result.success == true) {
        console.log(result);
        handleClose();
        dispatch(userdataUpdate(result.data));
      } else {
        Swal.fire({
          title: "Wrong",
          text: result.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Cancel",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (country == "") {
      CurrencyHandler();
    }
    if (currency == "") {
      CurrencyPriceHnadler();
    }
    if (wishlistData.length == 0) {
      addWishlistHandler();
    }
  }, []);
  const CurrencyHandler = async () => {
    try {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      const res = await fetch(CountryCheckApi, requestOptions);
      const result = await res.json();
      // console.log("result", result);

      if (result.country_name == "India") {
        dispatch(countryUpdate(result.currency));

        // setcurrency(result.currency);
      } else if (result.country_name == "Thailand") {
        // setcurrency(result.currency);
        dispatch(countryUpdate(result.currency));
      } else {
        dispatch(countryUpdate("USD"));
        // setcurrency("USD");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const CurrencyPriceHnadler = async () => {
    try {
      const result = await fetchData(ExchangeApi, METHODS.GET);
      // console.log("result", result);
      if (result.result == "success") {
        dispatch(
          currencyUpdate({
            THB: result.conversion_rates.THB,
            USD: result.conversion_rates.USD,
            INR: result.conversion_rates.INR,
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const addWishlistHandler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetwishlistbyUseridData + userData._id
      );
      console.log("dsdvsdvdd", result);
      if (result.success == true) {
        dispatch(wishlistUpdate(result.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light p-4 header-nav">
        {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img
                src="/assets/img/output.png"
                alt="Logo"
                className="bharat-logo img-fluid"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bolder",
                  fontSize: "large",
                }}
              >
                Welcome to Bharatchoice
              </span>
              <Row className="d-flex justify-content-between">
                <Col xs={6} md={6}>
                  <div className="form-element-each">
                    <span>First name</span>
                    <div className="d-flex align-items-center">
                      <input
                        style={{ border: "none", paddingLeft: "0.7rem" }}
                        placeholder="Enter First name"
                        value={firdtName}
                        onChange={(e) => {
                          setfirdtName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={6}>
                  <div className="form-element-each">
                    <span>Last name</span>
                    <div className="d-flex align-items-center">
                      <input
                        style={{ border: "none", paddingLeft: "0.7rem" }}
                        placeholder="Enter Last name"
                        value={lastName}
                        onChange={(e) => {
                          setlastName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-element-each">
                    <span>Mobile number</span>
                    <div className="d-flex align-items-center">
                      <input
                        style={{ border: "none", paddingLeft: "0.7rem" }}
                        placeholder="Add number"
                        value={mobile}
                        onChange={(e) => {
                          setmobile(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-element-each">
                    <span>Email</span>
                    <div className="d-flex align-items-center">
                      <input
                        style={{ border: "none", paddingLeft: "0.7rem" }}
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-element-each">
                    <span>Password</span>
                    <div className="d-flex align-items-center">
                      <input
                        style={{ border: "none", paddingLeft: "0.7rem" }}
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-element-each">
                    <span>Confirm password</span>
                    <div className="d-flex align-items-center">
                      <input
                        style={{ border: "none", paddingLeft: "0.7rem" }}
                        placeholder="Confirm password"
                        value={confirmpassword}
                        onChange={(e) => {
                          setconfirmpassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center d-block">
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm={12} md={12} lg={12} style={{}}>
                <Button
                  className="modal-login-button align-self-center"
                  style={{ border: "none", paddingLeft: "0.7rem" }}
                  type="button"
                  onClick={SingUpandler}
                >
                  Sign Up
                </Button>
              </Col>
            </Row>
            <Row className="mt-2">
              <img
                src="/assets/img/or-line.svg"
                alt="Logo"
                className="img-fluid"
              />
            </Row>
            <Row className="d-flex justify-content-between align-items-center mx-auto">
              <Col className="d-flex justify-content-center align-items-center">
                <Button className="d-flex social-sign-in justify-content-center align-items-center col-11 ">
                  <span>
                    <img
                      src="/assets/img/GoogleLogo.svg"
                      alt="Logo"
                      className="img-fluid"
                    />
                  </span>
                  <span style={{ color: "#6B7280" }}>Google</span>
                </Button>
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <Button className="d-flex social-sign-in justify-content-center align-items-center col-11 ">
                  <span>
                    <img
                      src="/assets/img/FacebookLogo.svg"
                      alt="Logo"
                      className="img-fluid"
                    />
                  </span>
                  <span style={{ color: "#6B7280" }}>Facebook</span>
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <span>
                Already on Bharatchoice?
                <a
                  style={{ color: "#FFCC00", marginLeft: 5 }}
                  onClick={handleShow1}
                >
                  Log in
                </a>
              </span>
            </Row>
            <Row>
              <span style={{ fontSize: "small", color: "#6B7280" }}>
                By signing up to create an account I accept BharatChoice's Terms
                of Use and Privacy Policy.
              </span>
            </Row>
          </Modal.Footer>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img
                src="/assets/img/output.png"
                alt="Logo"
                className="bharat-logo img-fluid"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bolder",
                  fontSize: "large",
                }}
              >
                Welcome Back
              </span>
              <Row>
                <Col>
                  <div className="form-element-each">
                    <span>Email</span>
                    <div className="d-flex align-items-center">
                      <input
                        style={{ border: "none", paddingLeft: "0.7rem" }}
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-element-each">
                    <span>Password</span>
                    <div className="d-flex align-items-center">
                      <input
                        style={{ border: "none", paddingLeft: "0.7rem" }}
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center d-block">
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm={12} md={12} lg={12}>
                <Button
                  className="search-button w-100 align-self-center"
                  style={{ border: "none", width: "100%" }}
                  type="button"
                  onClick={LoginHandler}
                >
                  Login
                </Button>
              </Col>
            </Row>
            <Row className="mt-2">
              <img
                src="/assets/img/or-line.svg"
                alt="Logo"
                className="img-fluid"
              />
            </Row>
            <Row className="d-flex justify-content-between align-items-center mx-auto">
              <Col className="d-flex justify-content-center align-items-center">
                <Button className="d-flex social-sign-in justify-content-center align-items-center col-11 ">
                  <span>
                    <img
                      src="/assets/img/GoogleLogo.svg"
                      alt="Logo"
                      className="img-fluid"
                    />
                  </span>
                  <span style={{ color: "#6B7280" }}>Google</span>
                </Button>
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <Button className="d-flex social-sign-in justify-content-center align-items-center col-11 ">
                  <span>
                    <img
                      src="/assets/img/FacebookLogo.svg"
                      alt="Logo"
                      className="img-fluid"
                    />
                  </span>
                  <span style={{ color: "#6B7280" }}>Facebook</span>
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <span>
                New to Bharatchoice?
                <a
                  style={{ color: "#FFCC00", marginLeft: 5 }}
                  onClick={handleShow}
                >
                  Sign in
                </a>
              </span>
            </Row>
          </Modal.Footer>
        </Modal> */}
        <LoginModal
          LoginButtonVisible={show1}
          LoginButton={() => {
            setShow1(false);
          }}
          SignUpButtonVisible={show}
          SignUpButton={() => {
            setShow(false);
          }}
        />
        <a class="navbar-brand">
          <Link style={{ textDecoration: "none" }} to={"/"}>
            <img
              src="/assets/img/output.png"
              alt="Logo"
              className="bharat-logo img-fluid"
            />
          </Link>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav align-items-start d-flex">
            <li class="nav-item">
              <a class="nav-link">
                <i
                  class={
                    country == "INR"
                      ? "fa-solid fa-indian-rupee-sign fa-lg"
                      : country == "THB"
                        ? "fa-solid fa-baht-sign fa-lg"
                        : "fa-solid fa-dollar-sign fa-lg"
                  }
                  style={{ color: "#ffcc00", padding: 5 }}
                ></i>
                {country}
              </a>
            </li>
            <li class="nav-item">
              <Link to={"/partner-with-us"}>
                <p class="nav-link">
                  <img
                    src="/assets/img/ChatsCircle.svg"
                    style={{ padding: 5 }}
                  />
                  Support
                </p>
              </Link>
            </li>
            <li class="nav-item">
              <Link to={"/wishlist"}>
                <p class="nav-link">
                  <i
                    class="fa-solid fa-bookmark fa-1x"
                    style={{ color: "#ffcc00", padding: 5 }}
                  ></i>
                  Saved {wishlistData.length > 0 && wishlistData.length}
                </p>
              </Link>
            </li>

            {userData == "" ? (
              <>
                <li class="nav-item" onClick={handleShow1}>
                  <a
                    class="nav-link"
                    style={{
                      color: "#FFCC00",
                      fontWeight: "700",
                      paddingLeft: 10,
                      paddingRight: 10,
                      cursor: "pointer",
                    }}
                  >
                    Login
                  </a>
                </li>

                <li class="nav-item" onClick={handleShow}>
                  <a class="nav-link btn sign-up-btn" style={{}}>
                    Sign Up
                  </a>
                </li>
              </>
            ) : (
              <li class="nav-item">
                <a
                  class="nav-link"
                  // href="#"
                  style={{
                    color: "#383838",

                    // paddingLeft: 10,
                    // paddingRight: 10,
                  }}
                  type="button"
                  onClick={() => {
                    navigate("/user-profile");
                  }}
                >
                  <img
                    // src="/assets/img/UserCircleyellowsmall.svg"
                    className="img-fluid p-2"
                    src={
                      userData?.user_image != undefined
                        ? BASE_URL_IMAGE + userData?.user_image
                        : "/assets/img/UserCircle.svg"
                    }
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 50 / 2,
                    }}
                  />
                  {userData?.fullName}
                </a>
              </li>
            )}
          </ul>
        </div>
      </nav>

      <nav class="sticky-bottom-navbar">
        <Link className="nav-link mob-nav-item" to={"/partner-with-us"}>
          <i
            className={
              country == "INR"
                ? "fa-solid fa-indian-rupee-sign fa-lg"
                : country == "THB"
                  ? "fa-solid fa-baht-sign fa-lg"
                  : "fa-solid fa-dollar-sign fa-lg"
            }
            style={{ color: "#ffcc00", padding: 5 }}
          ></i>
          {country}
        </Link>

        <Link className="nav-link mob-nav-item" to={"/wishlist"}>
          <i
            class="fa-solid fa-bookmark fa-1x"
            style={{ color: "#ffcc00", padding: 5 }}
          ></i>
          Saved
        </Link>
        <Link className="nav-link mob-nav-item" to={"/partner-with-us"}>
          <img
            src="/assets/img/ChatsCircle.svg"
            style={{ padding: 5 }}
          // width="50"
          // height="50"
          />
          Support
        </Link>

        <a
          className="nav-link mob-nav-item"
          type="button"
          onClick={() => {
            handleShow();
          }}
        >
          <i
            class="fa-regular fa-user"
            style={{ color: "#ffcc00", fontSize: 20, padding: 5 }}
          ></i>
          Sign Up
        </a>
      </nav>
    </>
  );
};
