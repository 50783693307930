import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { fetchData } from "../../../Helper/ApiCall";
import {
  END_POINT,
  METHODS,
  pricestockbenefitOptions,
  pricestockpaymentmethod,
  pricestockbookingtype,
  pricestockcampaignname,
  getDateNow,
} from "../../../Helper/helper";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Select from "react-select";

const HotelStock = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  let location = useLocation();
  const roomfacilityid = location?.state?.id;
  const { name, hotel_name } = location?.state;
  const [data, setdata] = useState([]);
  const [formData, setformData] = useState({
    room_type_id: "",
    hotel_id: "",
    payment_mode: "",
    booking_type: "",
    stock: "",
    date_from: "",
    date_to: "",
    status: "",
    campaign_name: "",
    benifits: [],
    payment_option: "",
    weekdaycost: "",
    weekdaysell: "",
    weekendcost: "",
    weekendsell: "",
    extra_bedcost: "",
    extra_bedsell: "",
    campaign_rooms: [],
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    var startDate = new Date(formData.date_from); //YYYY-MM-DD
    var endDate = new Date(formData.date_to); //YYYY-MM-DD

    const campaign_rooms = getDateArray(startDate, endDate);

    const raw = JSON.stringify({
      room_type_id: roomfacilityid,
      hotel_id: id,
      payment_mode: formData.payment_mode,
      booking_type: formData.booking_type,
      campaign_rooms: campaign_rooms,
    });
    console.log(raw);

    try {
      // const finalEndpoint =
      //   Hotelid != undefined
      //     ? END_POINT.UpdateHotel + Hotelid
      //     : END_POINT.HotelCreate;
      const result = await fetchData(
        END_POINT.PostHotelStock,
        METHODS.POST,
        raw
      );

      console.log(result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            GetHotelStockhandler();
            // navigate("../hotel-listing");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmitCampign = async () => {
    const dataCheck = data[0].campaign_names.find((item) => {
      return item.campaign_name == formData.campaign_name;
    });
    console.log("dataCheck", dataCheck);

    const raw = JSON.stringify({
      hotel_stock_id: data[0]._id,
      hotel_stock_campign_id: dataCheck?._id,

      campaign_names: {
        status: formData.status,
        campaign_name: formData.campaign_name,
        benifits: formData.benifits,
        payment_option: formData.payment_option,
        weekdaycost: formData.weekdaycost,
        weekdaysell: formData.weekdaysell,
        weekendcost: formData.weekendcost,
        weekendsell: formData.weekendsell,
        extra_bedcost: formData.extra_bedcost,
        extra_bedsell: formData.extra_bedsell,
      },
    });
    console.log(raw);

    try {
      const result = await fetchData(
        END_POINT.PostHotelCampaign,
        METHODS.POST,
        raw
      );

      console.log(result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            GetHotelStockhandler();
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  var getDateArray = function (start, end) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
      arr.push({
        date: dt.toISOString().split("T")[0],
        status: "Active",
        roomstock: parseInt(formData.stock),
        liveroomsell: 0,
        liveextrabedsell: 0,
        bookingnumber: 0,
        livestock: parseInt(formData.stock),
      });

      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  useEffect(() => {
    GetHotelStockhandler();
  }, []);

  const GetHotelStockhandler = async () => {
    try {
      const raw = JSON.stringify({
        room_type_id: roomfacilityid,
        hotel_id: id,
      });
      const result = await fetchData(
        END_POINT.GetHotelStockByid,
        METHODS.POST,
        raw
      );
      console.log(raw);

      console.log(result);
      if (result.success == true) {
        setdata(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const UpdateHotelStockStatusHandler = async (campaign_name, date, status) => {
    try {
      const raw = JSON.stringify({
        room_type_id: roomfacilityid,
        hotel_id: id,
        campaign_name: campaign_name,
        date: date,
        status: status == "Active" ? "Inactive" : "Active",
      });
      const result = await fetchData(
        END_POINT.UpdateHotelStockStatus,
        METHODS.POST,
        raw
      );

      console.log(result);
      if (result.success == true) {
        GetHotelStockhandler(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCheckinChange = (e) => {
    const selectedCheckinDate = new Date(e.target.value);
    const nextDay = new Date(selectedCheckinDate);

    nextDay.setDate(selectedCheckinDate.getDate() + 1);
    console.log(nextDay);

    const formattedNextDay = nextDay.toISOString().split("T")[0];

    setformData({
      ...formData,
      date_from: e.target.value,
      date_to: formattedNextDay,
    });
  };
  const minCheckinDate = getDateNow();
  // console.log(new Date().toISOString());
  return (
    <div>
      <h2 style={{ color: "#000", fontWeight: "bold", marginBottom: 20 }}>
        Hotel Name - {hotel_name}
      </h2>
      <h6>Price Stock</h6>
      <div className="formwraptable mx-auto">
        <div className="row mb-4 d-flex justify-content-between">
          <div className="col-4">
            <h5 style={{ textDecoration: "underline" }}>Room Name : {name}</h5>
          </div>
          <div className="col-6">
            <button
              className="btn btn-success m-1"
              type="button"
              onClick={() => {
                navigate("../hotel-listing");
              }}
            >
              <i class="fa-solid fa-eye p-1"></i> Hotel
            </button>
            <button
              className="btn btn-success m-1"
              type="button"
              onClick={() => {
                navigate("../room-type/" + id + "/" + hotel_name);
              }}
            >
              <i class="fa-solid fa-eye p-1"></i>View Room List
            </button>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-3 md-3 sm-12">
            <div>
              <span className="label-style">Payment Mode *</span> <br />
              <Select
                id="type"
                name="type"
                value={pricestockpaymentmethod.filter(
                  (item) => item.value == formData.payment_mode
                )}
                options={pricestockpaymentmethod}
                className="input-fields-style"
                // onChange={hotelTypeHandleChange}
                placeholder="Select an option"
                isSearchable
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) =>
                  formData.payment_mode === option.value ? true : false
                }
                onChange={(e) => {
                  console.log(e);
                  setformData({
                    ...formData,
                    payment_mode: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 md-3 sm-12">
            <div>
              <span className="label-style">Booking Type *</span> <br />
              <Select
                id="type"
                name="type"
                value={pricestockbookingtype.filter(
                  (item) => item.value == formData.booking_type
                )}
                options={pricestockbookingtype}
                className="input-fields-style"
                // onChange={hotelTypeHandleChange}
                placeholder="Select an option"
                isSearchable
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) =>
                  formData.booking_type === option.value ? true : false
                }
                onChange={(e) => {
                  console.log(e);
                  setformData({
                    ...formData,
                    booking_type: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-lg-1 md-1 sm-12">
            <div>
              <span className="label-style">Stock *</span> <br />
              <input
                type="number"
                className="input-fields-style"
                name="stock"
                value={formData.stock}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="col-lg-2 md-2 sm-12">
            <div>
              <span className="label-style">Date From *</span> <br />
              <input
                type="date"
                className="input-fields-style"
                name="date_from"
                min={minCheckinDate}
                value={formData.date_from}
                onChange={(e) => handleCheckinChange(e)}
              />
            </div>
          </div>
          <div className="col-lg-2 md-2 sm-12">
            <div>
              <span className="label-style">Date To *</span> <br />
              <input
                type="date"
                className="input-fields-style"
                name="date_to"
                min={formData.date_from}
                value={formData.date_to}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-4 md-4 sm-10 ">
            <button
              className="btn btn-warning m-2"
              type="button"
              onClick={handleSubmit}
            >
              Add Stocks
            </button>
          </div>
        </div>
        {data.length > 0 && (
          <>
            <div className="row mb-4">
              <div className="col-12">
                <table>
                  <thead>
                    <td className="table-elements head">Active campaign</td>
                    <td className="table-elements head">Campaign name</td>
                    <td className="table-elements head">Benefit</td>
                    <td className="table-elements head">Payment option</td>
                    <td className="table-elements head">Week Day</td>
                    <td className="table-elements head">Weekend</td>
                    <td className="table-elements head">Extra Bed</td>
                  </thead>
                  <tbody>
                    <td className="table-elements">
                      <select
                        className=""
                        name="status"
                        value={formData.status}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select an option</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </td>
                    <td className="table-elements">
                      <select
                        className=""
                        name="campaign_name"
                        value={formData.campaign_name}
                        onChange={(e) => {
                          const value = e.target.value;

                          setformData({
                            ...formData,
                            benifits:
                              value == "Room Only"
                                ? "No meals Included"
                                : value == "Room with Breakfast"
                                ? "Breakfast Included"
                                : value == "Room with Half Board"
                                ? "Breakfast & Dinner"
                                : value == "Room with Full Board"
                                ? "ABF, Lunch,Dinner"
                                : "",
                            campaign_name: value,
                          });
                        }}
                      >
                        <option value="">Select an option</option>
                        {pricestockcampaignname.map((item) => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </select>
                    </td>
                    <td className="table-elements">
                      {/* {formData.benifits} */}
                      <Select
                        options={pricestockbenefitOptions}
                        className="input-fields-style"
                        // onChange={hotelTypeHandleChange}
                        placeholder="Select an option"
                        isSearchable
                        // getOptionLabel={(option) => option.value}
                        // getOptionValue={(option) => `${option}`}
                        isMulti
                        value={formData.benifits}
                        onChange={(e) => {
                          console.log(e);
                          setformData({
                            ...formData,
                            benifits: e,
                          });
                        }}
                      />
                    </td>

                    <td className="table-elements">
                      <input
                        type="number"
                        className=""
                        name="payment_option"
                        value={formData.payment_option}
                        onChange={(e) => handleChange(e)}
                      />
                      {/* <select
                    className=""
                    name="payment_option"
                    value={formData.payment_option}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select an option</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="1">2</option>
                    <option value="1">3</option>
                    <option value="1">7</option>
                  </select> */}
                    </td>
                    <td className="table-elements">
                      <span>Cost</span>
                      <input
                        type="number"
                        className=""
                        name="weekdaycost"
                        value={formData.weekdaycost}
                        onChange={(e) => handleChange(e)}
                      />
                      <span>Sell</span>
                      <input
                        type="number"
                        className=""
                        name="weekdaysell"
                        value={formData.weekdaysell}
                        onChange={(e) => handleChange(e)}
                      />
                    </td>
                    <td className="table-elements">
                      <span>Cost</span>
                      <input
                        type="number"
                        className=""
                        name="weekendcost"
                        value={formData.weekendcost}
                        onChange={(e) => handleChange(e)}
                      />
                      <span>Sell</span>
                      <input
                        type="number"
                        className=""
                        name="weekendsell"
                        value={formData.weekendsell}
                        onChange={(e) => handleChange(e)}
                      />
                    </td>
                    <td className="table-elements">
                      <span>Cost</span>
                      <input
                        type="number"
                        className=""
                        name="extra_bedcost"
                        value={formData.extra_bedcost}
                        onChange={(e) => handleChange(e)}
                      />
                      <span>Sell</span>
                      <input
                        type="number"
                        className=""
                        name="extra_bedsell"
                        value={formData.extra_bedsell}
                        onChange={(e) => handleChange(e)}
                      />
                    </td>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4 md-4 sm-10 ">
                <button
                  className="btn btn-warning m-2"
                  type="button"
                  onClick={handleSubmitCampign}
                >
                  Add campaign
                </button>
              </div>
            </div>
          </>
        )}
        <div className="row mb-4 d-flex justify-content-between">
          <div className="col-5">
            <h5 style={{}}>Campaign Review : {name}</h5>
          </div>
          <div className="col-5 d-flex">
            <div>
              <span className="label-style">Date To *</span>
              <input type="date" className="m-1" />
            </div>
            <div>
              <span className="label-style">Date From *</span>
              <input type="date" className="m-1" />
            </div>
          </div>
        </div>

        {data.length > 0 &&
          data.map((item, index) => {
            return (
              <>
                {item.campaign_names.map((campignitem, campignindex) => {
                  return (
                    <>
                      <h5 style={{ textDecoration: "underline" }}>
                        Campaign name : {campignitem.campaign_name}
                      </h5>
                      <div className="row mb-4">
                        <div className="col-12">
                          <table>
                            <thead>
                              <td className="table-elements head">Date</td>
                              <td className="table-elements head">Status</td>
                              <td className="table-elements head">
                                Payment Option
                              </td>
                              <td className="table-elements head">Cost</td>
                              <td className="table-elements head">Sell</td>
                              <td className="table-elements head">
                                Extra Bed Cost
                              </td>
                              <td className="table-elements head">
                                Extra Bed Sell
                              </td>
                              <td className="table-elements head">
                                Room Stock{" "}
                              </td>

                              <td className="table-elements head">
                                Live Room Sell{" "}
                              </td>

                              <td className="table-elements head">
                                Live Extra Bed Sell{" "}
                              </td>

                              <td className="table-elements head">
                                Booking No.{" "}
                              </td>

                              <td className="table-elements head">
                                Live stock
                              </td>
                            </thead>
                            {item.campaign_rooms.length > 0 &&
                              item.campaign_rooms.map(
                                (itemCampaign, indexCampaign) => {
                                  return (
                                    <tbody>
                                      <td className="table-elements">
                                        {itemCampaign.date}
                                      </td>
                                      <td className="table-elements">
                                        <button
                                          className={`btn ${
                                            itemCampaign.status == "Active"
                                              ? "btn-success"
                                              : "btn-danger"
                                          }`}
                                          type="button"
                                          onClick={() => {
                                            UpdateHotelStockStatusHandler(
                                              item.campaign_name,
                                              itemCampaign.date,
                                              itemCampaign.status
                                            );
                                          }}
                                        >
                                          {itemCampaign.status}
                                        </button>
                                      </td>
                                      <td className="table-elements">
                                        {campignitem.payment_option}
                                      </td>
                                      <td className="table-elements">
                                        {campignitem.weekdaycost}
                                      </td>
                                      <td className="table-elements">
                                        {campignitem.weekdaysell}
                                      </td>
                                      <td className="table-elements">
                                        {campignitem.extra_bedcost}
                                      </td>
                                      <td className="table-elements">
                                        {campignitem.extra_bedsell}
                                      </td>
                                      <td className="table-elements">
                                        {itemCampaign.roomstock}
                                      </td>
                                      <td className="table-elements">
                                        {itemCampaign.liveroomsell}
                                      </td>
                                      <td className="table-elements">
                                        {itemCampaign.liveextrabedsell}
                                      </td>
                                      <td className="table-elements">
                                        {itemCampaign.bookingnumber}
                                      </td>
                                      <td className="table-elements">
                                        {itemCampaign.livestock}
                                      </td>
                                    </tbody>
                                  );
                                }
                              )}
                          </table>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            );
          })}
      </div>
    </div>
  );
};

export default HotelStock;
