import React, { useState, useRef, useEffect } from "react";
import {
  END_POINT,
  METHODS,
  packageTypeOptions,
  hotelpromo_type,
  hotelstarratingOptions,
  GOOGLE_MAPS_APIKEY,
  hotelplaceoffers,
  BASE_URL_IMAGE,
} from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
  MoreImageUpload,
} from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import GoogleMapReact from "google-map-react";
import Swal from "sweetalert2";
import axios from "axios";

import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import AutoCompleteAddress from "../../components/AutoCompleteAddress";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ActivityLoader from "../../../components/ActivityLoader";

const AddPackageScreen = () => {
  const navigate = useNavigate();

  let location = useLocation();
  const diningid = location?.state?.id;
  const [formData, setformData] = useState({
    type: "",
    name: "",
    city: "",
    location: "",
    startFrom: "",
    starRating: "",
    productType: "",
    whattoexpect: "",
    policyExclusion: "",
    address: "",
    map_url: "",
    // longitude: 100.992538,
    // latitude: 15.870032,
    salesTeamname: "",
    salesTeamemail: "",
    salesTeammobile: "",
    reservationTeamname: "",
    reservationTeamemail: "",
    reservationTeammobile: "",
    accounTeamname: "",
    accounTeamemail: "",
    accounTeammobile: "",
    promoType: "",
    // facility: "",
    about: "",
    placeoffer: "",
    video: "",
    banner: "",
    images: "",
    moreImages: [],
  });
  const [address, setaddress] = useState("");
  const [longitude, setlongitude] = useState("");
  const [latitude, setlatitude] = useState("");


  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    // Update the formData with the new latitude and longitude
    setformData((prevState) => ({
      ...prevState,
      latitude: lat,
      longitude: lng,
    }));

    // Reverse geocode to get the new address after moving marker
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        setformData((prevState) => ({
          ...prevState,
          address: results[0].formatted_address,
        }));
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  };

  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [city, setDiningCityData] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const [video, setvideo] = useState("");
  const [hotellistimage, sethotellistimage] = useState("");
  const [moreimage, setmoreimage] = useState("");
  const [bannerimage, setbannerimage] = useState("");

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_APIKEY,
    onPlaceSelected: (place) => {
      console.log(place);
      LatLong_Api(place.formatted_address);
      // setformData({
      //   ...formData,
      //   // location: responseJson.results[0].formatted_address,
      //   address: place.formatted_address,
      // });
    },
  });
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position);
      const { latitude, longitude } = position.coords;
      if (diningid == undefined) {
        // setformData({
        //   ...formData,
        //   longitude: longitude,
        //   latitude: latitude,
        // });
        setlatitude(latitude);
        setlongitude(longitude);
      }
    });
  }, []);
  useEffect(() => {
    cityHandler();
    if (diningid != undefined) {
      diningdetailbyidHandler();
    }
  }, []);
  const cityHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetPackageCity);
      if (result.success == true) {
        setDiningCityData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const diningdetailbyidHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetPackageByid + diningid);
      console.log("dsbkjsb", result);
      if (result.success == true) {
        // const placeoffer = result.data.placeoffer.map((item) => {
        //   return item.value;
        // });

        setformData({
          type: result.data.type,
          name: result.data.name,
          city: result.data.city,
          location: result.data.location,
          starRating: result.data.starRating,
          startFrom: result.data.startFrom,

          productType: result.data.productType,
          whattoexpect: result.data.whattoexpect,
          policyExclusion: result.data.policyExclusion,
          // address: result.data.address,
          // longitude: result.data.longitude,
          // latitude: result.data.latitude,
          salesTeamname: result.data.salesTeam.name,
          salesTeamemail: result.data.salesTeam.email,
          salesTeammobile: result.data.salesTeam.mobile,
          reservationTeamname: result.data.reservationTeam.name,
          reservationTeamemail: result.data.reservationTeam.email,
          reservationTeammobile: result.data.reservationTeam.mobile,
          accounTeamname: result.data.accounTeam.name,
          accounTeamemail: result.data.accounTeam.email,
          accounTeammobile: result.data.accounTeam.mobile,
          promoType: result.data.promoType,
          // map_url: result.data.map_url,
          placeoffer: manuallyAddedOffers,
          about: result.data.about,
          // video: result.data.video,
          // banner: result.data.banner,
          images: result.data.images,
          moreImages:
            result.data.moreImages == null ? [] : result.data.moreImages,
        });
        setaddress(result.data.address);
        setlatitude(result.data.latitude);
        setlongitude(result.data.longitude);
        setManuallyAddedOffers(result.data.placeoffer);
        seteditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.whattoexpect)
            )
          )
        );
        seteditorState_2(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.policyExclusion)
            )
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (
    // uploadedvideo,
    // uploadedImagebanner,
    uploadedImagehotellist,
    uploadedMoreImage
  ) => {
    // const placeoffer = formData.placeoffer.map((item) => {
    //   return item.value;
    // });
    const placeoffer = manuallyAddedOffers;
    var raw = JSON.stringify({
      type: formData.type,
      name: formData.name,
      city: formData.city,
      location: formData.location,
      starRating: formData.starRating,
      startFrom: formData.startFrom,
      productType: formData.productType,
      whattoexpect: formData.whattoexpect,
      policyExclusion: formData.policyExclusion,
      address: address,
      longitude: longitude,
      latitude: latitude,

      salesTeamname: formData.salesTeamname,
      salesTeamemail: formData.salesTeamemail,
      salesTeammobile: formData.salesTeammobile,

      reservationTeamname: formData.reservationTeamname,
      reservationTeamemail: formData.reservationTeamemail,
      reservationTeammobile: formData.reservationTeammobile,

      accounTeamname: formData.accounTeamname,
      accounTeamemail: formData.accounTeamemail,
      accounTeammobile: formData.accounTeammobile,

      promoType: formData.promoType,
      // map_url: formData.map_url,
      // video: uploadedvideo,
      // banner: uploadedImagebanner,
      images: uploadedImagehotellist,
      moreImages: uploadedMoreImage,
      placeoffer: placeoffer,
      about: formData.about,
    });
    console.log(raw);
    try {
      const finalEndpoint =
        diningid != undefined
          ? END_POINT.UpdatePackage + diningid
          : END_POINT.PostPackage;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);

      console.log(result);
      setisLoading(false);

      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../package-list");
          }
        });
      } else {
        setisLoading(false);
      }
    } catch (err) {
      console.log(err);
      setisLoading(false);
    }
  };
  console.log(formData);

  const AllFileUpload = async () => {
    setisLoading(true);

    Promise.all([
      // await VideoUpload(video),
      // await ImageUpload(bannerimage),
      await ImageUpload(hotellistimage),
      await MoreImageUpload(moreimage),
    ])
      .then((values) => {
        console.log(values);
        // const uploadedvideo = values[0].image;
        // const uploadedImagebanner = values[1].image;
        const uploadedImagehotellist = values[0].image;
        const uploadedMoreImage = values[1].image;
        handleSubmit(
          // uploadedvideo,
          // uploadedImagebanner,
          uploadedImagehotellist,
          uploadedMoreImage
        );
      })
      .catch((err) => {
        console.error(err);
        setisLoading(false);
      });
  };
  const AllFileUpdate = async () => {
    setisLoading(true);

    Promise.all([
      // video != "" && (await VideoUpload(video)),
      // bannerimage != "" && (await ImageUpload(bannerimage)),
      hotellistimage != "" && (await ImageUpload(hotellistimage)),
      moreimage != "" && (await MoreImageUpload(moreimage)),
    ])
      .then((values) => {
        console.log("dsdsv", values);
        // const uploadedvideo =
        //   values[0] == false ? formData.video : values[0].image;
        // const uploadedImagebanner =
        //   values[1] == false ? formData.banner : values[1].image;
        const uploadedImagehotellist =
          values[0] == false ? formData.images : values[0].image;
        const uploadedMoreImage =
          values[1] == false ? formData.moreImages : values[1].image;

        handleSubmit(
          // uploadedvideo,
          // uploadedImagebanner,
          uploadedImagehotellist,
          uploadedMoreImage
        );
      })
      .catch((err) => {
        console.error(err);
        setisLoading(false);
      });
  };
  const loadMap = async (map, maps) => {
    new maps.Marker({
      position: { lat: formData.latitude, lng: formData.longitude },
      map,
      draggable: true,
      places: formData.location,
    });
    // console.log(map, maps);
  };
  const changeMarkerPosition = (e) => {
    console.log(e);
    const { lat, lng } = e;
    // setformData({
    //   ...formData,
    //   longitude: lng,
    //   latitude: lat,
    // });
    Address_Api(lat, lng);
  };
  const Address_Api = (lat, lng) => {
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      lat +
      "," +
      lng +
      "&key=" +
      GOOGLE_MAPS_APIKEY
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("ADDRESS GEOCODE is BACK!! => ", responseJson);
        if (responseJson.status == "OK") {
          // setformData({
          //   ...formData,
          //   // location: responseJson.results[0].formatted_address,
          //   longitude: lng,
          //   latitude: lat,
          //   address: responseJson.results[0].formatted_address,
          // });
          setaddress(responseJson.results[0].formatted_address);
          setlatitude(lat);
          setlongitude(lng);
        }
      });
  };
  const LatLong_Api = (address) => {
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      address +
      "&key=" +
      GOOGLE_MAPS_APIKEY
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.results[0].geometry.location);
        const lat = responseJson.results[0].geometry.location.lat;
        const lng = responseJson.results[0].geometry.location.lng;
        // setformData({
        //   ...formData,
        //   longitude: lng,
        //   latitude: lat,
        //   address: address,
        // });
        setaddress(address);
        setlatitude(lat);
        setlongitude(lng);
        const loc = { lat: lat, lng: lng };
        changeMarkerPosition(loc);
      });
  };
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    seteditorState(editorState);
    setformData({
      ...formData,
      whattoexpect: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };
  const [editorState_2, seteditorState_2] = useState(EditorState.createEmpty());
  const onEditorStateChange_2 = (editorState) => {
    seteditorState_2(editorState);
    setformData({
      ...formData,
      policyExclusion: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
    });
  };

  const [manuallyAddedOffers, setManuallyAddedOffers] = useState([]);

  const handleAddOffer = (newOffer) => {
    setManuallyAddedOffers([...manuallyAddedOffers, newOffer]);
  };

  const handleRemoveOffer = (offerToRemove) => {
    setManuallyAddedOffers(
      manuallyAddedOffers.filter((offer) => offer !== offerToRemove)
    );
  };

  const fetchCoordinates = async (address) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address: address,
            key: GOOGLE_MAPS_APIKEY, // Replace with your API key
          },
        }
      );
      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        setlatitude(location.lat);
        setlongitude(location.lng);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <ActivityLoader showModal={isLoading} />

      <div>
        <h3 className="mb-4">
          <b>Add Package</b>
        </h3>
        <div className="row mb-4">
          <div className="col-lg-6 md-6 sm-12">
            <span className="label-style">Select type</span> <br />
            <Select
              id="type"
              name="type"
              value={packageTypeOptions.filter(
                (item) => item.value == formData.type
              )}
              options={packageTypeOptions}
              className="input-fields-style"
              // onChange={hotelTypeHandleChange}
              placeholder="Select an option"
              isSearchable
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) =>
                formData.type === option.value ? true : false
              }
              onChange={(e) => {
                console.log(e);
                setformData({
                  ...formData,
                  type: e.value,
                });
              }}
            />
          </div>
          <div className="col-lg-6 md-6 sm-12">
            <span className="label-style">Select City</span> <br />
            <Select
              id="city"
              name="city"
              value={city.filter((item) => item.city_name == formData.city)}
              options={city}
              className="input-fields-style"
              // onChange={hotelTypeHandleChange}
              placeholder="Select city"
              isSearchable
              getOptionLabel={(option) => option.city_name}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) =>
                formData.city === option.city_name ? true : false
              }
              onChange={(e) => {
                setformData({
                  ...formData,
                  city: e.city_name,
                });
              }}
            />
          </div>
        </div>
        <div className="row mb-4">
          {/* <div className="col-lg-6 md-6 sm-12">
            <span className="label-style">Select Hotel Product Type</span>
            <br />
            <Select
              options={hotelproductTypeOptions}
              className="input-fields-style"
              placeholder="Select an option"
              isSearchable
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) =>
                formData.productType === option.value ? true : false
              }
              onChange={(e) => {
                console.log(e);
                setformData({
                  ...formData,
                  productType: e.value,
                });
              }}
            />
           
          </div> */}
          <div className="col-lg-6 md-6 sm-12">
            <span className="label-style">Select Ratings</span> <br />
            <Select
              options={hotelstarratingOptions}
              value={hotelstarratingOptions.filter(
                (item) => item.value == formData.starRating
              )}
              className="input-fields-style"
              placeholder="Select an option"
              isSearchable
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) =>
                formData.starRating === option.value ? true : false
              }
              onChange={(e) => {
                console.log(e);
                setformData({
                  ...formData,
                  starRating: e.value,
                });
              }}
            />
          </div>
          <div className="col-lg-6 md-6 sm-12">
            <div>
              <span className="label-style">Price Start From:</span> <br />
              <input
                type="text"
                id="startFrom"
                name="startFrom"
                className="input-fields-style"
                value={formData.startFrom}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Name:</span> <br />
            <input
              type="text"
              id="hotel_name"
              name="name"
              className="input-fields-style"
              value={formData.name}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Address:</span> <br />
            {/* <input
              ref={ref}
              type="text"
              id="autocomplete"
              name="address"
              value={address}
              className="input-fields-style"
              onChange={(e) => setaddress(e.target.value)}
            /> */}

            <Autocomplete
              apiKey={GOOGLE_MAPS_APIKEY}
              ref={ref}
              name="address"
              value={address}
              onPlaceSelected={(place) => {
                if (place && place.geometry) {
                  console.log('Place selected:', place); // Debugging log
                  setformData((prevState) => ({
                    ...prevState,
                    address: place.formatted_address,
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng(),
                  }));
                } else {
                  console.error('Place selection error or no geometry data');
                }
              }}
              className="input-fields-style"
              options={{
                types: [], // Allow all types to be more flexible
              }}
              onChange={(e) => {
                const newAddress = e.target.value;
                setaddress(newAddress);
                fetchCoordinates(newAddress); // Fetch lat/lng when typing
              }}
            />

          </div>
        </div>
      </div>

      {/* {latitude != "" && longitude != "" && (
        <div style={{ height: "50vh", width: "90%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: GOOGLE_MAPS_APIKEY,
              language: "en",
            }}
            // region={{ lat: latitude, lng: longitude }}
            defaultCenter={{ lat: latitude, lng: longitude }}
            // defaultCenter={{ lat: 10.99835602, lng: 77.01502627 }}
            // defaultCenter={{ lat: 40.756795, lng: -73.954298 }}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              console.log("nckdsbvckd");
              loadMap(map, maps);
            }}
            onClick={changeMarkerPosition}
          ></GoogleMapReact>
        </div>
      )} */}

      <div style={{ height: '400px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCIdnH9jJ_mfZXIRDJpTpt_yaCyvhB4aU0' }}
          defaultCenter={{ lat: latitude || 0, lng: longitude || 0 }}
          center={{ lat: latitude || 0, lng: longitude || 0 }}
          defaultZoom={11}
          onClick={({ lat, lng }) => {
            setformData((prevState) => ({
              ...prevState,
              latitude: lat,
              longitude: lng,
            }));
          }}
        >
          {formData.latitude && formData.longitude && (
            <div
              lat={formData.latitude}
              lng={formData.longitude}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
              style={{
                height: '25px',
                width: '25px',
                backgroundColor: 'red',
                borderRadius: '50%',
              }}
            />
          )}
        </GoogleMapReact>
      </div>


      {/* <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Place offers</span> <br />
          <Select
            options={hotelplaceoffers}
            // value={hotelplaceoffers.filter(
            //   (item) => item.value == formData.placeoffer
            // )}
            className="input-fields-style"
            placeholder="Select an option"
            isSearchable
            isMulti={true}
            // getOptionLabel={(option) => option}
            // getOptionValue={(option) => `${option}`}
            // isOptionSelected={(option) =>
            //   formData.placeoffer === option.value ? true : false
            // }
            onChange={(e) => {
              console.log(e);
              setformData({
                ...formData,
                placeoffer: e,
              });
            }}
          />
        </div>
      </div> */}
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">About</span> <br />
          <textarea
            id="about"
            name="about"
            value={formData.about}
            onChange={(e) => handleChange(e)}
            rows={5}
            style={{
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "8px",
              marginBottom: "20px",
            }}
            placeholder="Enter Here..."
          />
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Product Facility</span> <br />
          {/* Input field for manually adding offers */}
          <div style={{ display: "flex", marginBottom: "20px" }}>
            <input
              type="text"
              placeholder="Add an offer"
              value={formData.newOffer || ""}
              onChange={(e) =>
                setformData({ ...formData, newOffer: e.target.value })
              }
              style={{ flex: 1, border: "1px solid #ccc", padding: "8px" }}
            />
            <button
              onClick={() => {
                if (formData.newOffer) {
                  handleAddOffer(formData.newOffer);
                  setformData({ ...formData, newOffer: "" });
                }
              }}
              style={{
                background: "#007BFF",
                color: "white",
                border: "none",
                borderRadius: "4px",
                padding: "8px 16px",
                marginLeft: "8px",
                cursor: "pointer",
              }}
            >
              Add
            </button>
          </div>
          {/* Display manually added offers */}
          {manuallyAddedOffers.map((offer, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "8px",
                borderBottom: "1px solid #ccc",
                padding: "8px 0",
              }}
            >
              <div style={{ flex: 1 }}>{`${index + 1}. ${offer}`}</div>
              <button
                onClick={() => handleRemoveOffer(offer)}
                style={{
                  background: "#DC3545",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  padding: "4px 8px",
                  cursor: "pointer",
                }}
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">What To Expect:</span> <br />
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
          />
          {/* <textarea
            id="whattoexpect"
            name="whattoexpect"
            value={formData.whattoexpect}
            onChange={(e) => handleChange(e)}
            rows={5}
            style={{ width: "100%" }}
            placeholder="Enter Here..."
          /> */}
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Policy & Exclusion:</span> <br />
          <Editor
            editorState={editorState_2}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange_2}
          />
          {/* <textarea
            id="policyExclusion"
            name="policyExclusion"
            value={formData.policyExclusion}
            onChange={(e) => handleChange(e)}
            rows={5}
            style={{ width: "100%" }}
            placeholder="Enter Here..."
          />*/}
        </div>
      </div>
      {/* <div className="row mb-4 d-flex">
        <div className="col-12">
          <img
            className="img-fluid"
            src="/assets/img/mapinput.png"
            style={{ padding: 5 }}
          />
        </div>
      </div> */}

      {/* <div className="row mb-4">
        <div className="col-lg-12 md-12 sm-12 ">
          <div className="d-flex align-items-center ">
            <span style={{ marginRight: 10 }} className="label-style">
              Hotel Product Facility *
            </span>
            <br />
            <input
              style={{ marginRight: 10 }}
              type="text"
              id="inputBox1"
              name="inputBox1"
              placeholder="Facility Name"
            />
            <span style={{ marginRight: 10 }} className="label-style">
              Input Box
            </span>
            <br />
            <input
              type="text"
              id="inputBox1"
              name="inputBox1"
              placeholder="Icon Html Tag"
            />
            <button className="btn btn-primary mx-2">
              Add &nbsp;<i class="fa-solid fa-plus"></i>
            </button>
          </div>
        </div>
      </div> */}

      {/* <div className="row mb-4">
        <div className="col-lg-10 md-6 sm-12">
          <div>
            <span className="label-style">Map URL:</span> <br />
            <input
              type="text"
              id="map_url"
              name="map_url"
              className="input-fields-style"
              value={formData.map_url}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div> */}

      <h2 style={{ color: "black" }}>Sale Team</h2>
      <div className="row mb-4">
        <div className="col-lg-3 md-3 sm-12">
          <div>
            <span className="label-style"> Name:</span> <br />
            <input
              type="text"
              id="salesTeamname"
              name="salesTeamname"
              value={formData.salesTeamname}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-3 md-3 sm-12">
          <div>
            <span className="label-style">Email:</span> <br />
            <input
              type="text"
              id="salesTeamemail"
              name="salesTeamemail"
              value={formData.salesTeamemail}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-3 md-3 sm-12">
          <div>
            <span className="label-style">Mobile:</span> <br />
            <input
              type="text"
              id="salesTeammobile"
              name="salesTeammobile"
              value={formData.salesTeammobile}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <h2 style={{ color: "black" }}>Reservation Team</h2>
      <div className="row mb-4">
        <div className="col-lg-3 md-3 sm-12">
          <div>
            <span className="label-style"> Name:</span> <br />
            <input
              type="text"
              id="reservationTeamname"
              name="reservationTeamname"
              value={formData.reservationTeamname}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-3 md-3 sm-12">
          <div>
            <span className="label-style">Email:</span> <br />
            <input
              type="text"
              id="reservationTeamemail"
              name="reservationTeamemail"
              value={formData.reservationTeamemail}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-3 md-3 sm-12">
          <div>
            <span className="label-style">Mobile:</span> <br />
            <input
              type="text"
              id="reservationTeammobile"
              name="reservationTeammobile"
              value={formData.reservationTeammobile}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <h2 style={{ color: "black" }}>Account Team</h2>
      <div className="row mb-4">
        <div className="col-lg-3 md-3 sm-12">
          <div>
            <span className="label-style">Name:</span> <br />
            <input
              type="text"
              id="accounTeamname"
              name="accounTeamname"
              value={formData.accounTeamname}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-3 md-3 sm-12">
          <div>
            <span className="label-style"> Email:</span> <br />
            <input
              type="text"
              id="accounTeamemail"
              name="accounTeamemail"
              value={formData.accounTeamemail}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-3 md-3 sm-12">
          <div>
            <span className="label-style"> Mobile:</span> <br />
            <input
              type="text"
              id="accounTeammobile"
              name="accounTeammobile"
              value={formData.accounTeammobile}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      {/* <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Virtual Video or Image *</span> <br />
            <input
              type="file"
              id="fileBox1"
              name="fileBox1"
              accept="video/*"
              onChange={(e) => {
                setvideo(e.target.files[0]);
              }}
            />
          </div>
          {formData.video != "" && (
            <a
              href={BASE_URL_IMAGE + formData.video}
              target="_blank"
              rel="noopener noreferrer"
            >
              view video
            </a>
          )}
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Upload Banner Image *</span> <br />
            <input
              type="file"
              id="fileBox1"
              name="fileBox1"
              accept="image/*"
              onChange={(e) => {
                setbannerimage(e.target.files[0]);
              }}
            />
          </div>
          {formData.banner != "" && (
            <a
              href={BASE_URL_IMAGE + formData.banner}
              target="_blank"
              rel="noopener noreferrer"
            >
              view image
            </a>
          )}
        </div>
      </div> */}
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Upload Featured Image *</span> <br />
            <input
              type="file"
              id="fileBox1"
              name="fileBox1"
              accept="image/*"
              onChange={(e) => {
                console.log(e.target.files);

                sethotellistimage(e.target.files[0]);
              }}
            />
          </div>
          {formData.images != "" && (
            <a
              href={BASE_URL_IMAGE + formData.images}
              target="_blank"
              rel="noopener noreferrer"
            >
              view image
            </a>
          )}{" "}
        </div>

        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Upload List More Image *</span> <br />
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => {
                setmoreimage([...e.target.files]);
              }}
            />
          </div>
          {formData.moreImages.length > 0 &&
            formData.moreImages.map((item) => {
              return (
                <div>
                  <a
                    href={BASE_URL_IMAGE + item}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    view image
                  </a>
                </div>
              );
            })}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Select Promo</span> <br />
            <Select
              id="type"
              name="type"
              value={hotelpromo_type.filter(
                (item) => item.value == formData.promoType
              )}
              options={hotelpromo_type}
              className="input-fields-style"
              // onChange={hotelTypeHandleChange}
              placeholder="Select promo type"
              isSearchable
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) =>
                formData.promoType === option.value ? true : false
              }
              onChange={(e) => {
                setformData({
                  ...formData,
                  promoType: e.value,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start">
        <Button
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            if (diningid != undefined) {
              AllFileUpdate();
            } else {
              AllFileUpload();
            }
          }}
        >
          Submit
        </Button>
        <Button
          className="btn btn-danger m-2"
          type="button"
          onClick={() => {
            navigate("../package-list");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddPackageScreen;
