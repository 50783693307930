import React from "react";
import { Container } from "react-bootstrap";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
// import { BASE_URL_IMAGE } from "../Helper/helper";

import { Swiper, SwiperSlide } from "swiper/react";
// import "./ImageSwiper.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { BASE_URL_IMAGE } from "../../Helper/helper";

function PreviewSwiper({ hotel }) {
  return (
    <div>
      <Swiper
        navigation={true}
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true }}
        loop={true}
        className="mySwiper4"
      >
        <SwiperSlide>
          <div className="preview-swiper">
            <img
              src={BASE_URL_IMAGE + hotel.gallery_image}
              // src={BASE_URL_IMAGE + hotel.gallery_image}
              className="img-fluid w-100 mb-2"
            />

          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="preview-swiper">
            <img
              // src="/assets/img/preview-car.png"
              src={BASE_URL_IMAGE + hotel.gallery_image}
              className="img-fluid w-100 mb-2"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="preview-swiper">
            <img
              // src="/assets/img/preview-car.png"
              src={BASE_URL_IMAGE + hotel.gallery_image}
              className="img-fluid w-100 mb-2"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="preview-swiper">
            <img
              // src="/assets/img/texteditor.png"
              src={BASE_URL_IMAGE + hotel.gallery_image}
              className="img-fluid w-100 mb-2"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="preview-swiper">
            <img
              // src="/assets/img/preview-car.png"
              src={BASE_URL_IMAGE + hotel.gallery_image}
              className="img-fluid w-100 mb-2"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default PreviewSwiper;
