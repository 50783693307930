import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../../dashscreen/DashCss.css";

import { useSelector, useDispatch } from "react-redux";
import { END_POINT } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import Swal from "sweetalert2";

const RoomFacilityScreen = () => {
  const [facility, setRoomFacilityData] = useState([]);

  useEffect(() => {
    roomFacilityHandler();
  });

  const roomFacilityHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetRoomFacility);
      if (result.success == true) {
        setRoomFacilityData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const deleteHandler = async (id) => {
    try {
      const result = await fetchData(END_POINT.DeleteRoomFacility + id);
      if (result.success == true) {
        roomFacilityHandler();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Link style={{ textDecoration: "none" }} to={"../add-room-facility"}>
        <button
          className="btn btn-primary mt-4"
          // onClick={() => {
          //   Navigate("add-hotel-listing");
          // }}
        >
          Add Room Facility&nbsp;<i class="fa-solid fa-plus"></i>
        </button>
      </Link>

      <div className="mt-4">
        <div
          id="example_wrapper"
          class="dataTables_wrapper no-footer table-overflow"
        >
          <div
            class="dataTables_length"
            id="example_length"
            style={{ padding: 5 }}
          >
            <label>
              Show
              <select
                name="example_length"
                aria-controls="example"
                class="mb-4"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>
          </div>
          <div id="example_filter" class="dataTables_filter mb-4">
            <label>
              Search:
              <input
                type="search"
                class=""
                placeholder=""
                aria-controls="example"
              />
            </label>
          </div>
          <table
            id="example"
            class="table table-striped table-bordered dataTable no-footer"
            style={{ width: "100%", whiteSpace: "nowrap" }}
            role="grid"
            aria-describedby="example_info"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="S.N: activate to sort column descending"
                  style={{ width: "20px" }}
                >
                  S.N
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label=" Name: activate to sort column ascending"
                  style={{ width: "146px" }}
                >
                  Room Facility
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Hotel: activate to sort column ascending"
                  style={{ width: "56px" }}
                >
                  Edit
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Date: activate to sort column ascending"
                  style={{ width: "118px" }}
                >
                  Date
                </th>
              </tr>
            </thead>

            <tbody>
              {facility.length > 0 ? (
                facility.map((item, index) => (
                  <tr role="row" class="odd" key={index}>
                    <td class="sorting_1"> {index + 1} </td>
                    <td> {item.room_facility} </td>

                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../add-room-facility",
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{ id: item._id }}
                      >
                        <i class="fa fa-edit"></i>
                      </Link>
                      <a
                        // href="#"
                        type="button"
                        class="btn btn-danger m-1"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: `You won't to delete ${item.room_facility}!`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteHandler(item._id);

                              Swal.fire(
                                "Deleted!",
                                `${item.room_facility} has been deleted.`,
                                "success"
                              );
                            }
                          });
                          //  handleDelete(item._id)
                        }}
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>

                    <td> {item.updatedAt.split("T")[0]} </td>
                  </tr>
                ))
              ) : (
                <p>No Facility Available</p>
              )}
            </tbody>
          </table>
          <div
            class="dataTables_info"
            id="example_info"
            role="status"
            aria-live="polite"
          >
            Showing 1 to 6 of 6 entries
          </div>
          <div
            class="dataTables_paginate paging_simple_numbers"
            id="example_paginate"
          >
            <a
              class="paginate_button previous disabled"
              aria-controls="example"
              data-dt-idx="0"
              tabindex="0"
              id="example_previous"
            >
              Previous
            </a>
            <span>
              <a
                class="paginate_button current"
                aria-controls="example"
                data-dt-idx="1"
                tabindex="0"
              >
                1
              </a>
            </span>
            <a
              class="paginate_button next disabled"
              aria-controls="example"
              data-dt-idx="2"
              tabindex="0"
              id="example_next"
            >
              Next
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomFacilityScreen;
