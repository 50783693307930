import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  BASE_URL_IMAGE,
  END_POINT,
  METHODS,
  NumberSeprator,
  TabBarLabels,
} from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import StickySearchBar from "../../components/StickySearchBar";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import ActivitySelector from "../../components/ActivitySelector";
import PartnersCarousel from "../../components/PartnersCarousel";
import Subscribe from "../../components/Subscribe";
import { useSelector, useDispatch } from "react-redux";

function WishList() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  let location = useLocation();
  const { country, currency } = useSelector((store) => store.currencyReducer);
  const { wishlistData } = useSelector((store) => store.wishlistReducer);

  const tabBar = location?.state?.tabBar;

  const { city, checkin, checkout, room } = useParams();
  const [dynamicCity, setHotelCityData] = useState([]);
  const [selectedtab, setselectedtab] = useState();
  const [selectroom, setselectroom] = useState();
  const [selectedcity, setselectedcity] = useState();

  const [defaultValuecheckin, setdefaultValuecheckin] = useState();
  const [defaultValuecheckout, setdefaultValuecheckout] = useState();
  const [hotel, setHotelData] = useState([]);
  const minCheckinDate = new Date().toISOString().split("T")[0];
  const [blogcategory, setblogcategory] = useState([]);

  useEffect(() => {
    // setdefaultValuecheckin(checkin);
    // setdefaultValuecheckout(checkout);
    // setselectedcity(city);
    // setselectroom(room);
    // cityHandler();
    // setselectedtab(tabBar);
    // blogcategoryHandler();
  }, []);
  useEffect(() => {
    if (selectedtab != undefined) {
      // cityHandler();
      // hotelHandler(city, checkin, checkout, room);
    }
  }, [selectedtab]);

  const blogcategoryHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetCategoryDataBlog);
      console.log(result);
      if (result.success == true) {
        setblogcategory(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const cityHandler = async () => {
    try {
      const finalEndpoint =
        selectedtab == TabBarLabels.Hotel
          ? END_POINT.GetHotelCity
          : selectedtab == TabBarLabels.Dinning
            ? END_POINT.GetDiningCity
            : selectedtab == TabBarLabels.Activities
              ? END_POINT.GetActivityCity
              : selectedtab == TabBarLabels.Vehicles
                ? END_POINT.GetVehiclesCity
                : selectedtab == TabBarLabels.Packages
                  ? END_POINT.GetPackageCity
                  : null;
      const result = await fetchData(finalEndpoint);
      console.log(result);
      if (result.success == true) {
        // const newArray = result.data.map((item) => {
        //   return { value: item.city_name, label: item.city_name };
        // });
        setHotelCityData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hotelHandler = async (city, checkin, checkout, room) => {
    try {
      const raw = JSON.stringify({
        city: city,
        checkin: checkin,
        checkout: checkout,
        room: room,
      });
      const finalEndpoint =
        selectedtab == TabBarLabels.Hotel
          ? END_POINT.GetHotelByCity
          : selectedtab == TabBarLabels.Dinning
            ? END_POINT.GetDinningByCity
            : selectedtab == TabBarLabels.Activities
              ? END_POINT.GetActivityByCity
              : selectedtab == TabBarLabels.Vehicles
                ? END_POINT.GetVehiclesByCity
                : selectedtab == TabBarLabels.Packages
                  ? END_POINT.GetPackageByCity
                  : null;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);

      console.log(raw);
      if (result.success == true) {
        setHotelData(result.data);
      } else {
        setHotelData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const ButtonDiv = ({ title, img, imgYellow }) => {
    return (
      <li class="nav-item ">
        <a
          class="nav-link tab-item"
          type="button"
          onClick={() => {
            setselectedtab(title);
            setselectedcity();
          }}
        >
          {selectedtab !== title ? (
            <img src={img} className="img-fluid tab-item-img" id="tab1-img" />
          ) : (
            <img
              src={imgYellow}
              className="img-fluid tab-item-img"
              id="tab1-img"
            />
          )}
          {title}
        </a>
      </li>
    );
  };
  const handleCheckinChange = (e) => {
    const selectedCheckinDate = new Date(e.target.value);
    const nextDay = new Date(selectedCheckinDate);
    nextDay.setDate(selectedCheckinDate.getDate() + 1);
    const formattedNextDay = nextDay.toISOString().split("T")[0];
    setdefaultValuecheckin(e.target.value);
    setdefaultValuecheckout(formattedNextDay);
  };

  const Box = ({ id, name, image, tab, type, city, starRating, startFrom }) => {
    return (
      <div className="col-lg-3 md-5 sm-12 product-cards">
        <Link
          style={{ textDecoration: "none" }}
          to={`/${tab}-booking-details/` + id}
          state={{
            selectedtab: tab,
            checkin: checkin,
            checkout: checkout,
            room: room,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "18rem",
              height: "12rem",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "1rem",
            }}
          >
            <img
              src={BASE_URL_IMAGE + image}
              style={{ objectFit: "cover", maxWidth: "100%" }}
              className="img-fluid product-card-img"
            />
          </div>

          <div className="product-card-content">
            <div className="product-tags">
              <span>{type}</span>
              <span>{city}</span>
            </div>
            <h5 style={{ fontSize: 18 }}>{name}</h5>
            <div className="price d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center ">
                <img src="/assets/img/star.svg" />
                <span>{starRating}</span>
              </div>

              <div className="d-flex">
                <p
                  style={{
                    fontSize: "small",
                    marginTop: 3,
                    marginRight: 5,
                  }}
                >
                  From
                </p>
                <span>
                  {country}{" "}
                  {NumberSeprator(Math.floor(startFrom * currency[country]))}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  };
  return (
    <div>
      <StickySearchBar />
      {/* Banner 1 */}

      {/* Welcome Section */}
      <div className="container welcome-section">
        <h2>Wishlist</h2>
        {/* <p className="intro">
          Explore the finest hotels in Thailand that elevates your holiday
        </p> */}
        {/* <div class="dropdown mt-4">
          <button
            class="btn dropdown-toggle sort-by-title"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Sort by
            <i
              class="fa-solid fa-angle-down"
              style={{ color: "#0A3874", marginLeft: 20 }}
            ></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#">
              Best Match
            </a>
            <a class="dropdown-item" href="#">
              Best Seller
            </a>

            <a class="dropdown-item" href="#">
              Price High to Low
            </a>
            <a class="dropdown-item" href="#">
              Price Low to High
            </a>
            <a class="dropdown-item" href="#">
              Near by
            </a>
          </div>
        </div> */}

        <div className="row product-row">
          {wishlistData.length > 0 ? (
            wishlistData.map((item, index) => {
              console.log(item);
              return (

                <>
                  {item.type == TabBarLabels.Hotel ? (

                    <Box
                      image={item?.hotelData?.images}
                      id={item.hotelData?._id}
                      name={item.hotelData?.name}
                      tab={item.type}
                      type={item.hotelData?.type}
                      city={item.hotelData?.city}
                      starRating={item.hotelData?.starRating}
                      startFrom={item.hotelData?.startFrom}
                    />
                  ) : item.type == TabBarLabels.Dinning ? (
                    <Box
                      image={item?.dinningData?.images}
                      id={item?.dinningData?._id}
                      name={item?.dinningData?.name}
                      tab={item?.type}
                      type={item?.dinningData?.type}
                      city={item?.dinningData?.city}
                      starRating={item?.dinningData?.starRating}
                      startFrom={item?.dinningData?.startFrom}
                    />
                  ) : item.type == TabBarLabels.Activities ? (
                    <Box
                      image={item.activitydata?.images}
                      id={item.activitydata?._id}
                      name={item.activitydata?.name}
                      tab={item.type}
                      type={item.activitydata?.type}
                      city={item.activitydata?.city}
                      starRating={item.activitydata?.starRating}
                      startFrom={item.activitydata?.startFrom}
                    />
                  ) : item.type == TabBarLabels.Vehicles ? (
                    <Box
                      image={item.vehiclesdata?.images}
                      id={item.vehiclesdata?._id}
                      name={item.vehiclesdata?.name}
                      tab={item.type}
                      type={item.vehiclesdata?.type}
                      city={item.vehiclesdata?.city}
                      starRating={item.vehiclesdata?.starRating}
                      startFrom={item.vehiclesdata?.startFrom}
                    />
                  ) : item.type == TabBarLabels.Packages ? (
                    <Box
                      image={item.packagedata?.images}
                      id={item.packagedata?._id}
                      name={item.packagedata?.name}
                      tab={item.type}
                      type={item.packagedata?.type}
                      city={item.packagedata?.city}
                      starRating={item.packagedata?.starRating}
                      startFrom={item.packagedata?.startFrom}
                    />
                  ) : null}
                </>
              );
            })
          ) : (
            <p>Hotels Not Available</p>
          )}
        </div>
      </div>

      {/* PartnerShip Section */}
      <PartnersCarousel />
      {/* CTA Section */}
      <Subscribe
        title={
          "Get the scoop on deals and content with our newsletter, don't miss out!"
        }
      />

      {/* Footer */}

      {/* <Footer /> */}
    </div>
  );
}

export default WishList;
