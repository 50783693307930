import React from "react";
import { TabBarLabels } from "../Helper/helper";
import { useSelector, useDispatch } from "react-redux";
import { tabbarSelectUpdate } from "../redux/reducers/activityReducer";
function ActivitySelector(props) {
  const dispatch = useDispatch();

  const { tabBar } = useSelector((store) => store.activityReducer);
  console.log(props.defaultValuecheckout, "props.defaultValuecheckout");
  const ButtonDiv = ({ title, img, imgYellow }) => {
    return (
      <li class="nav-item bord-btm-yellow">
        <a
          class="nav-link tab-item"
          type="button"
          style={{
            textTransform: "capitalize",
            borderBottom: props.selectedtab == title ? "4px solid #FFCC00" : 0,
          }}
          onClick={() => {
            // dispatch(tabbarSelectUpdate(title));
            // setselectedtab(title);
            props.onChange(title);
          }}
        >
          {props.selectedtab !== title ? (
            <img src={img} className="img-fluid tab-item-img" id="tab1-img" />
          ) : (
            <img
              src={imgYellow}
              className="img-fluid tab-item-img"
              id="tab1-img"
            />
          )}
          {title}
        </a>
      </li>
    );
  };
  return (
    <div class="col-lg-6 md-12 sm-12 mx-0 mt-4">
      <div class="container bg-white p-2 booking-form ">
        <div class="container mx-0 tabscont">
          <ul class="nav nav-tabs tab-heads" id="myTab" role="tablist">
            <ButtonDiv
              title={TabBarLabels.Hotel}
              img={"/assets/img/Buildings.svg"}
              imgYellow={"/assets/img/BuildingsYellow.svg"}
            />
            <ButtonDiv
              title={TabBarLabels.Dinning}
              img={"/assets/img/BowlFood.svg"}
              imgYellow={"/assets/img/BowlFoodYellow.svg"}
            />
            <ButtonDiv
              title={TabBarLabels.Activities}
              img={"/assets/img/Bicycle.svg"}
              imgYellow={"/assets/img/BicycleYellow.svg"}
            />
            <ButtonDiv
              title={TabBarLabels.Vehicles}
              img={"/assets/img/CarProfile.svg"}
              imgYellow={"/assets/img/CarProfileYellow.svg"}
            />
            <ButtonDiv
              title={TabBarLabels.Packages}
              img={"/assets/img/Bag.svg"}
              imgYellow={"/assets/img/BagYellow.svg"}
            />
          </ul>
        </div>
        <form>
          <div class="form-row mt-3">
            <div class="form-group col-md-12">
              <div className="form-element-each">
                <span>Where</span>
                <div className="d-flex align-items-center">
                  <select
                    class="form-control form-element-style"
                    id="inputLocation"
                    value={props.selectedcity}
                    onChange={(e) => props.onChangeselectedcity(e.target.value)}
                  >
                    <option selected>Select</option>
                    {props.cityData.length > 0 &&
                      props.cityData.map((item) => {
                        return (
                          <option value={item.city_name}>
                            {item.city_name}
                          </option>
                        );
                      })}
                  </select>
                  <i
                    class="fa-solid fa-angle-down"
                    style={{ color: "#858585" }}
                  ></i>
                </div>
              </div>
            </div>
            <div class="form-group row justify-content-center mx-auto">
              <div
                className={`form-element-each ${props.selectedtab == TabBarLabels.Hotel
                  ? "check-in col-6"
                  : ""
                  }`}
              >
                <span>
                  {props.selectedtab == TabBarLabels.Hotel
                    ? "Check-in"
                    : "Select Date"}
                </span>
                <input
                  type="date"
                  placeholder="dd/mm/yyy"
                  class="form-control form-element-style"
                  id="inputCheckIn"
                  defaultValue={props.defaultValuecheckin}
                  // onChange={(e) => setdefaultValuecheckin(e.target.value)}
                  min={props.minCheckinDate}
                  onChange={props.handleCheckinChange}
                />
              </div>
              {props.selectedtab == TabBarLabels.Hotel && (
                <div className="form-element-each check-out col-6">
                  <span>Check-out</span>
                  <input
                    type="date"
                    placeholder="dd/mm/yyy"
                    class="form-control form-element-style"
                    id="inputCheckOut"
                    min={props.minCheckoutDate}
                    value={props.defaultValuecheckout}
                    onChange={(e) => props.onChangeCheckoutDate(e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
          {props.selectedtab == TabBarLabels.Hotel && (
            <div class="form-row ">
              <div class="form-group col-md-12 ">
                <div className="form-element-each">
                  <span>Rooms</span>
                  <div className="d-flex align-items-center">
                    <select
                      class="form-control form-element-style"
                      id="inputRooms"
                      value={props.room}
                      onChange={(e) => props.onChangeroom(e.target.value)}
                    >
                      <option selected>Select</option>
                      <option value={1}> 1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                    </select>
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "#858585" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          )}
          <button
            type="button"
            class="btn search-button col-md-12 sm-12 md-12"
            onClick={() => {
              // console.log(
              //   `/${selectedtab.toLowerCase()}-booking/` +
              //     selectedcity.trim() +
              //     "/" +
              //     defaultValuecheckin +
              //     "/" +
              //     defaultValuecheckout +
              //     "/" +
              //     room
              // );
              //   navigate(
              //     `/booking/` +
              //       selectedcity.trim() +
              //       "/" +
              //       defaultValuecheckin +
              //       "/" +
              //       defaultValuecheckout +
              //       "/" +
              //       room,
              //     {
              //       state: {
              //         tabBar: selectedtab,
              //       },
              //     }
              //   );
              props.submitButton();
            }}
          >
            Search
          </button>
        </form>
      </div>
    </div>
  );
}

export default ActivitySelector;
