import React, { useState, useEffect } from "react";
import { BASE_URL_IMAGE, END_POINT, METHODS } from "../../../Helper/helper";
import { ImageUpload, fetchData } from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";

const AddRoomtypeScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const roomtypeid = location?.state?.id;
  const [formData, setformData] = useState({
    type: [],
    name: "",
    size: "",
    sleep: "",
    desc: "",
    room_image: "",
    gallery_image: "",
    hotel_id: id,
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");
  const [gallery, setgallery] = useState("");
  const [facility, setFacilityData] = useState([]);

  useEffect(() => {
    FacilityHandler();
    if (roomtypeid != undefined) {
      dataHandler();
    }
  }, []);
  const FacilityHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetRoomFacility);
      if (result.success == true) {
        const room_facility = result.data.map((item) => {
          return {
            value: item.room_facility,
            label: item.room_facility,
          };
        });
        setFacilityData(room_facility);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const dataHandler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetRoomTypeByRoomId + roomtypeid
      );
      if (result.success == true) {
        const room_facility = result.data.type.map((item) => {
          return {
            value: item,
            label: item,
          };
        });

        setformData({
          type: room_facility,
          name: result.data.name,
          size: result.data.size,
          sleep: result.data.sleep,
          desc: result.data.desc,
          room_image: result.data.room_image,
          gallery_image: result.data.gallery_image,
          hotel_id: result.data.hotel_id,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (uploadImage = null, uploadGallery = null) => {
    const room_facility = formData.type.map((item) => {
      return item.value;
    });
    var raw = JSON.stringify({
      type: room_facility,
      name: formData.name,
      size: formData.size,
      sleep: formData.sleep,
      desc: formData.desc,
      hotel_id: id,

      room_image: uploadImage,
      gallery_image: uploadGallery,
    });
    console.log(raw);

    try {
      const finalEndpoint =
        roomtypeid != undefined
          ? END_POINT.UpdateRoomType + roomtypeid
          : END_POINT.AddRoomType;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        // setFormSubmitted(result.data);
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../room-type/" + id);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(image), await ImageUpload(gallery)])
      .then((values) => {
        console.log(values);
        const uploadImage = values[0].image;
        const uploadGallery = values[1].image;

        handleSubmit(uploadImage, uploadGallery);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add Room Type</b>
        </h3>

        <div className="row mb-4"></div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Room Type Name:</span> <br />
            <input
              type="text"
              id="name"
              name="name"
              className="input-fields-style"
              value={formData.name}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Type:</span> <br />
            <Select
              id="type"
              name="type"
              value={formData.type}
              options={facility}
              className="input-fields-style"
              // onChange={hotelTypeHandleChange}
              placeholder="Select Type"
              isMulti={true}
              isSearchable
              //   getOptionLabel={(option) => option.room_facility}
              //   getOptionValue={(option) => `${option}`}
              //   isOptionSelected={(option) =>
              //     formData.type === option.room_facility ? true : false
              //   }
              onChange={(e) => {
                setformData({
                  ...formData,
                  type: e,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Size:</span> <br />
            <input
              type="text"
              id="size"
              name="size"
              className="input-fields-style"
              value={formData.size}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Sleep:</span> <br />
            <input
              type="text"
              id="sleep"
              name="sleep"
              className="input-fields-style"
              value={formData.sleep}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Desc:</span> <br />
            <input
              type="text"
              id="desc"
              name="desc"
              className="input-fields-style"
              value={formData.desc}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Gallery Image *</span> <br />
            <input
              type="file"
              id="fileBox1"
              name="fileBox1"
              onChange={(e) => {
                setgallery(e.target.files[0]);
              }}
            />
          </div>
          {formData.gallery_image != "" && (
            <a
              href={BASE_URL_IMAGE + formData.gallery_image}
              target="_blank"
              rel="noopener noreferrer"
            >
              view image
            </a>
          )}
        </div>
      </div>

      {/* <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Room Image *</span> <br />
            <input
              type="file"
              id="fileBox1"
              name="fileBox1"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Gallery Image *</span> <br />
            <input
              type="file"
              id="fileBox1"
              name="fileBox1"
              onChange={(e) => {
                setgallery(e.target.files[0]);
              }}
            />
          </div>
        </div>
      </div> */}

      <div className="d-flex justify-content-start">
        <Button
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            AllFileUpload();
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="button"
          onClick={() => {
            navigate("../room-type/" + id);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddRoomtypeScreen;
