import React, { useRef, useState } from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import Swal from "sweetalert2";
import { END_POINT, METHODS, BASE_URL_IMAGE } from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

export const WeddingEnquiryForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const title = location?.state?.title;

  const [formData, setformData] = useState({
    fullName: "",
    mobile: "",
    email: "",
    event_type: "",
    event_name: "",
    event_date: "",
    estimate_guest: "",
    estimate_room: "",
    event_detail: "",
    type: "enquiry",
    category_type: title,
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handlerSubmit = async () => {
    try {
      var raw = JSON.stringify(formData);
      console.log("raw", raw);
      const result = await fetchData(
        END_POINT.CreateWeddingEnquiryForm,
        METHODS.POST,
        raw
      );
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}
      {/* Form */}
      <div
        class="container mt-5 d-flex justify-content-center"
        style={{ width: "100vw" }}
      >
        <Row className="d-flex justify-content-center">
          <Col lg={6} md={6} sm={12}>
            <h1 style={{ color: "#0A3874", fontWeight: "bold" }}>
              *{title}* inquiry form
            </h1>
            <p className="mt-4">
              Focusing and providing the correct platforms is beacon of success
              story. Our list of Thailand many <br /> award-winning and globally
              accredited convention centres.
            </p>
            <div className="formcontainer d-flex justify-content-center align-items-center">
              <form>
                <div class="form-row row">
                  <h3>Personal information</h3>
                  <div class="col-md-5 sm-12 lg-5 m-2">
                    <div className="form-element-each">
                      <span>Full name</span>
                      <div className="d-flex align-items-center">
                        <input
                          class="form-control form-element-style"
                          placeholder="Enter full name"
                          name="fullName"
                          value={formData.fullName}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 sm-12 lg-5 m-2">
                    <div className="form-element-each">
                      <span>Mobile number</span>
                      <div className="d-flex align-items-center">
                        <input
                          class="form-control form-element-style"
                          placeholder="Enter number"
                          name="mobile"
                          value={formData.mobile}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 sm-12 lg-5 m-2">
                    <div className="form-element-each">
                      <span>Email address</span>
                      <div className="d-flex align-items-center">
                        <input
                          class="form-control form-element-style"
                          placeholder="Enter email"
                          name="email"
                          value={formData.email}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row row">
                  <h3>Event information</h3>
                  <div class="col-md-5 sm-12 lg-5 m-2">
                    <div className="form-element-each">
                      <span>Event type</span>
                      <div className="d-flex align-items-center">
                        <select
                          class="form-control form-element-style"
                          id="inputeventtype"
                          name="event_type"
                          value={formData.event_type}
                          onChange={(e) => handleChange(e)}
                        >
                          <option selected>Select</option>
                          <option value={"Pre Wedding"}>Pre Wedding</option>
                          <option value={"Wedding"}>Wedding</option>
                          <option
                            value={`Other(BD, Aniversary Etc)`}
                          >{`Other(BD, Aniversary Etc)`}</option>
                        </select>
                        <i
                          class="fa-solid fa-angle-down"
                          style={{ color: "#858585" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 sm-12 lg-5 m-2">
                    <div className="form-element-each">
                      <span>Event name</span>
                      <div className="d-flex align-items-center">
                        <input
                          class="form-control form-element-style"
                          placeholder="Enter name"
                          name="event_name"
                          value={formData.event_name}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 sm-12 lg-5 m-2">
                    <div className="form-element-each">
                      <span>Event date</span>
                      <input
                        type="date"
                        class="form-control form-element-style"
                        id="inputeventdate"
                        name="event_date"
                        value={formData.event_date}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div class="col-md-5 sm-12 lg-5 m-2">
                    <div className="form-element-each">
                      <span>Estimate no. of guest</span>
                      <div className="d-flex align-items-center">
                        <input
                          class="form-control form-element-style"
                          type="number"
                          placeholder="Enter no. of guest"
                          name="estimate_guest"
                          value={formData.estimate_guest}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 sm-12 lg-5 m-2">
                    <div className="form-element-each">
                      <span>Estimate no. of room</span>
                      <div className="d-flex align-items-center">
                        <input
                          class="form-control form-element-style"
                          type="number"
                          placeholder="Enter no. of room"
                          name="estimate_room"
                          value={formData.estimate_room}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 sm-12 lg-5 m-2">
                    <div className="form-element-each">
                      <span>Event detail(optional)</span>
                      <div className="d-flex align-items-center">
                        <input
                          class="form-control form-element-style"
                          placeholder="Enter detail"
                          name="event_detail"
                          value={formData.event_detail}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formbtn-row row mb-4">
                  <button
                    class="btn sub-btn-form col-md-3 sm-10 lg-3 m-2"
                    type="button"
                    onClick={handlerSubmit}
                  >
                    Send Enquiry
                  </button>
                  <button class="btn canc-btn-form col-md-3 sm-10 lg-3 m-2">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>

      {/* Footer */}
      {/* <Footer /> */}
    </div>
  );
};
