import React, { useState, useEffect } from "react";
import { END_POINT, METHODS, BASE_URL_IMAGE } from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const MiceDetailScreem = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = "650cbc9e3315f424a19859c8";

  const [formData, setformData] = useState({
    name: "",
    text: "",
    short_desc: "",
    map_url: "",
    video: "",
    banner_image: "",
    image: "",
    metatitle: "",
    metadescription: "",
    metakeywords: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");
  const [banner, setbanner] = useState("");
  const [video, setvideo] = useState("");

  useEffect(() => {
    handler();
  }, []);

  const handler = async () => {
    try {
      const result = await fetchData(END_POINT.GetMiceDeatilsByid + cityid);

      if (result.success == true) {
        setformData({
          name: result.data.name,
          text: result.data.text,
          short_desc: result.data.short_desc,
          map_url: result.data.map_url,
          video: result.data.video,
          banner_image: result.data.banner_image,
          image: result.data.image,
          metatitle: result.data.metatitle,
          metadescription: result.data.metadescription,
          metakeywords: result.data.metakeywords,
        });

        setdesc(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.short_desc)
            )
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (
    uploadedvideo,
    uploadedImagebanner,
    uploadedMoreImage
  ) => {
    var raw = JSON.stringify({
      name: formData.name,
      text: formData.text,
      short_desc: formData.short_desc,
      map_url: formData.map_url,
      video: uploadedvideo,
      banner_image: uploadedImagebanner,
      image: uploadedMoreImage,
      metatitle: formData.metatitle,
      metadescription: formData.metadescription,
      metakeywords: formData.metakeywords,
    });
    console.log("raw", raw);
    try {
      const finalEndpoint =
        cityid != undefined
          ? END_POINT.UpdateMiceDetail + cityid
          : END_POINT.AddMiceDetail;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([
      await VideoUpload(video),
      await ImageUpload(banner),
      await ImageUpload(image),
    ])
      .then((values) => {
        console.log(values);
        const uploadedvideo = values[0].image;
        const uploadedImagebanner = values[1].image;
        const uploadedMoreImage = values[2].image;
        handleSubmit(uploadedvideo, uploadedImagebanner, uploadedMoreImage);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const AllFileUpdate = async () => {
    Promise.all([
      video != "" && (await VideoUpload(video)),
      banner != "" && (await ImageUpload(banner)),
      image != "" && (await ImageUpload(image)),
    ])
      .then((values) => {
        console.log("dsdsv", values);

        const uploadedvideo =
          values[0] == false ? formData.video : values[0].image;
        const uploadedImagebanner =
          values[1] == false ? formData.banner_image : values[1].image;
        const uploadedMoreImage =
          values[2] == false ? formData.image : values[2].image;

        handleSubmit(uploadedvideo, uploadedImagebanner, uploadedMoreImage);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [desc, setdesc] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState, key) => {
    setdesc(editorState);
    setformData({
      ...formData,
      [key]: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Wedding Detail</b>
        </h3>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Name:</span> <br />
            <input
              type="text"
              id="name"
              name="name"
              className="input-fields-style"
              value={formData.name}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Meta Title:</span> <br />
            <input
              type="text"
              id="metatitle"
              name="metatitle"
              className="input-fields-style"
              value={formData.metatitle}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Meta Desc:</span> <br />
            <input
              type="text"
              id="metadescription"
              name="metadescription"
              className="input-fields-style"
              value={formData.metadescription}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Meta Keywords:</span> <br />
            <input
              type="text"
              id="metakeywords"
              name="metakeywords"
              className="input-fields-style"
              value={formData.metakeywords}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Short Description:</span> <br />
          <Editor
            editorState={desc}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(e) => {
              onEditorStateChange(e, "short_desc");
            }}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Text:</span> <br />
            <input
              type="text"
              id="text"
              name="text"
              className="input-fields-style"
              value={formData.text}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Map URL:</span> <br />
            <input
              type="text"
              id="map_url"
              name="map_url"
              className="input-fields-style"
              value={formData.map_url}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Video *</span> <br />
            <input
              type="file"
              id="fileBox1"
              name="fileBox1"
              accept="video/*"
              onChange={(e) => {
                setvideo(e.target.files[0]);
              }}
            />
          </div>
          {formData.video != "" && (
            <a
              href={BASE_URL_IMAGE + formData.video}
              target="_blank"
              rel="noopener noreferrer"
            >
              view video
            </a>
          )}
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Banner Image *</span> <br />
            <input
              type="file"
              onChange={(e) => {
                setbanner(e.target.files[0]);
              }}
            />
            {formData.banner_image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.banner_image}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-5"
              >
                view image
              </a>
            )}
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12 mt-5">
          <div>
            <span className="label-style">Image *</span> <br />
            <input
              type="file"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
            {formData.image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.image}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-5"
              >
                view image
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            if (cityid != undefined) {
              if (image != "") {
                AllFileUpdate();
              } else {
                handleSubmit(formData.banner_image, formData.image);
              }
            } else {
              AllFileUpload();
            }
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../wedding-list");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default MiceDetailScreem;
