import React, { useState, useEffect } from "react";
import { END_POINT, METHODS } from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ActivityLoader from "../../../components/ActivityLoader";


const AddHotelCityScreen = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = location?.state?.id;

  const [formData, setformData] = useState({
    city_name: "",
    city_text: "",
    city_desc: "",
    map_url: "",
    city_ordering: "",
    metatitle: "",
    metadescription: "",
    metakeywords: "",
    video: "",
    banner_image: "",
    image: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [city, setHotelCityData] = useState([]);

  const [image, setimage] = useState("");
  const [isLoading, setisLoading] = useState(false);


  useEffect(() => {
    if (cityid != undefined) {
      cityHandler();
    }
  }, []);
  const cityHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetHotelCityByid + cityid);
      if (result.success == true) {
        setformData({
          city_name: result.data.city_name,
          city_text: result.data.city_text,
          city_desc: result.data.city_desc,
          map_url: result.data.map_url,
          city_ordering: result.data.city_ordering,
          image: result.data.image,
          metatitle: "",
          metadescription: "",
          metakeywords: "",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (
    uploadedImagecity = null
  ) => {
    setisLoading(true);
    var raw = JSON.stringify({
      city_name: formData.city_name,
      city_text: formData.city_text,
      city_desc: formData.city_desc,
      map_url: formData.map_url,
      city_ordering: formData.city_ordering,

      image: uploadedImagecity,
      metatitle: "",
      metadescription: "",
      metakeywords: "",
    });
    console.log(raw);
    try {
      const finalEndpoint =
        cityid != undefined
          ? END_POINT.UpdateHotelCity + cityid
          : END_POINT.AddHotelCity;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      setisLoading(false);

      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../hotel-city");
          }
        });
      }
      else {
        setisLoading(false);
      }
    } catch (err) {
      console.log(err);
      setisLoading(false);

    }
  };

  const AllFileUpload = async () => {
    setisLoading(true);
    Promise.all([
      await ImageUpload(image),
    ])
      .then((values) => {
        console.log(values);
        const uploadedImagehotellist = values[0].image;
        handleSubmit(
          uploadedImagehotellist
        );
      })
      .catch((err) => {
        console.error(err);
        setisLoading(false);

      });
  };
  const AllFileUpdate = async () => {
    setisLoading(true);

    Promise.all([
      await ImageUpload(image),
    ])
      .then((values) => {

        const uploadedImagehotellist =
          values[2].image == undefined ? formData.image : values[0].image;

        handleSubmit(
          uploadedImagehotellist
        );
      })
      .catch((err) => {
        console.error(err);
        setisLoading(false);

      });
  };
  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <ActivityLoader showModal={isLoading} />

      <div>
        <h3 className="mb-4">
          <b>Add Hotel City</b>
        </h3>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">City Name:</span> <br />
            <input
              type="text"
              id="city_name"
              name="city_name"
              className="input-fields-style"
              value={formData.city_name}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Text:</span> <br />
            <input
              type="text"
              id="city_text"
              name="city_text"
              value={formData.city_text}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Map Url:</span> <br />
            <input
              type="text"
              id="map_url"
              name="map_url"
              value={formData.map_url}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">City ordering:</span> <br />
            <input
              type="text"
              id="city_ordering"
              name="city_ordering"
              value={formData.city_ordering}
              className="input-fields-style"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">City Desc:</span> <br />
          <textarea
            id="city_desc"
            name="city_desc"
            value={formData.city_desc}
            onChange={(e) => handleChange(e)}
            rows={5}
            style={{ width: "100%" }}
            placeholder="Enter Here..."
          />
        </div>
      </div>

      {/* <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">City Video *</span> <br />
            <input
              type="file"
              id="video"
              name="video"
              accept="video/*"
              onChange={(e) => {
                setvideo(e.target.files[0]);
              }}
            />
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">City Banner Image *</span> <br />
            <input
              type="file"
              id="banner"
              name="banner"
              accept="image/*"
              onChange={(e) => {
                setbanner(e.target.files[0]);
              }}
            />
          </div>
        </div>
      </div> */}
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">City Image *</span> <br />
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            if (cityid != undefined) {
              if (image != "") {
                AllFileUpdate();
              } else {
                handleSubmit(
                  formData.video,
                  formData.banner_image,
                  formData.image
                );
              }
            } else {
              AllFileUpload();
            }
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../hotel-city");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddHotelCityScreen;
