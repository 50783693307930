import React, { useState, useEffect } from "react";
import {
  END_POINT,
  METHODS,
  BASE_URL_IMAGE,
  pagenameOptions,
} from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const AddPage = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = location?.state?.id;

  const [formData, setformData] = useState({
    type: "",
    title: "",
    description: "",
    metatitle: "",
    metadescription: "",
    metakeywords: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");

  useEffect(() => {
    if (cityid != undefined) {
      cityHandler();
    }
  }, []);
  const cityHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetDetailByIdData + cityid);
      console.log("bkbkk", result);
      if (result.success == true) {
        setformData({
          type: result.data.type,
          title: result.data.title,
          description: result.data.description,
          metatitle: result.data.metatitle,
          metadescription: result.data.metadescription,
          metakeywords: result.data.metakeywords,
        });
        seteditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.description)
            )
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async () => {
    var raw = JSON.stringify({
      type: formData.type,
      title: formData.title,
      description: formData.description,
      metatitle: formData.metatitle,
      metadescription: formData.metadescription,
      metakeywords: formData.metakeywords,
    });
    console.log("raw", raw);
    try {
      const finalEndpoint =
        cityid != undefined ? END_POINT.Update + cityid : END_POINT.Upload;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../pages");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    seteditorState(editorState);
    setformData({
      ...formData,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };
  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add Page</b>
        </h3>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Page Name:</span> <br />
            <select
              name="type"
              className="input-fields-style"
              value={formData.type}
              onChange={(e) => handleChange(e)}
            >
              <option selected>Select</option>
              {pagenameOptions.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
            {/* <input
              type="text"
              id="type"
              name="type"
              className="input-fields-style"
              value={formData.type}
              onChange={(e) => handleChange(e)}
            /> */}
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Page Title:</span> <br />
            <input
              type="text"
              id="title"
              name="title"
              className="input-fields-style"
              value={formData.title}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Desc:</span> <br />
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Meta Title:</span> <br />
            <input
              type="text"
              id="metatitle"
              name="metatitle"
              className="input-fields-style"
              value={formData.metatitle}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Meta Desc:</span> <br />
            <input
              type="text"
              id="metadescription"
              name="metadescription"
              className="input-fields-style"
              value={formData.metadescription}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Meta Keywords:</span> <br />
            <input
              type="text"
              id="metakeywords"
              name="metakeywords"
              className="input-fields-style"
              value={formData.metakeywords}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../pages");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddPage;
