import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../../dashscreen/DashCss.css";

import { useSelector, useDispatch } from "react-redux";
import { END_POINT } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import Swal from "sweetalert2";
import ActivityLoader from "../../../components/ActivityLoader";


const ActivityCityScreen = () => {
  const [city, setCityData] = useState([]);
  const [isLoading, setisLoading] = useState(false);


  useEffect(() => {
    cityHandler();
  }, []);

  const cityHandler = async () => {
    setisLoading(true);

    try {
      const result = await fetchData(END_POINT.GetActivityCity);
      if (result.success == true) {
        setCityData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
    finally {
      setisLoading(false);
    }
  };
  const deleteHandler = async (id) => {
    try {
      const result = await fetchData(END_POINT.DeleteActivityCity + id);
      if (result.success == true) {
        cityHandler();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <ActivityLoader showModal={isLoading} />

      <Link style={{ textDecoration: "none" }} to={"../add-activity-city"}>
        <button className="btn btn-primary mt-4">
          Add City&nbsp;<i class="fa-solid fa-plus"></i>
        </button>
      </Link>

      <div className="mt-4">
        <div
          id="example_wrapper"
          class="dataTables_wrapper no-footer table-overflow"
        >
          <div id="example_filter" class="dataTables_filter mb-4">
            <label>
              Search:
              <input
                type="search"
                class=""
                placeholder=""
                aria-controls="example"
              />
            </label>
          </div>
          <table
            id="example"
            class="table table-striped table-bordered dataTable no-footer"
            style={{ width: "100%", whiteSpace: "nowrap" }}
            role="grid"
            aria-describedby="example_info"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="S.N: activate to sort column descending"
                  style={{ width: "20px" }}
                >
                  S.N
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label=" Name: activate to sort column ascending"
                  style={{ width: "146px" }}
                >
                  City Name
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label=" Code: activate to sort column ascending"
                  style={{ width: "45px" }}
                >
                  City Text
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Hotel: activate to sort column ascending"
                  style={{ width: "56px" }}
                >
                  Edit
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Date: activate to sort column ascending"
                  style={{ width: "118px" }}
                >
                  Date
                </th>
              </tr>
            </thead>

            <tbody>
              {city.length > 0 ? (
                city.map((item, index) => (
                  <tr role="row" class="odd" key={index}>
                    <td class="sorting_1"> {index + 1} </td>
                    <td> {item.city_name} </td>
                    <td> {item.city_text} </td>

                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../add-activity-city",
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{ id: item._id }}
                      >
                        <i class="fa fa-edit"></i>
                      </Link>
                      <a
                        // href="#"
                        type="button"
                        class="btn btn-danger m-1"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: `You won't to delete ${item.city_name}!`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteHandler(item._id);

                              Swal.fire(
                                "Deleted!",
                                `${item.city_name} has been deleted.`,
                                "success"
                              );
                            }
                          });
                          //  handleDelete(item._id)
                        }}
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>

                    <td> {item.updatedAt.split("T")[0]} </td>
                  </tr>
                ))
              ) : (
                <p>No Cities Available</p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ActivityCityScreen;
