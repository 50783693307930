import React, { useState, useEffect } from "react";
import {
  BASE_URL_IMAGE,
  END_POINT,
  ItemTypeOptions,
  METHODS,
} from "../../../Helper/helper";
import { ImageUpload, fetchData } from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";

const AddItemtypeScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const typeid = location?.state?.id;
  const dinningname = location?.state?.dinningname;

  const [formData, setformData] = useState({
    type: "",
    name: "",
    content: "",
    item_inclusion: "",
    image: "",
    dining_id: id,
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");
  const [gallery, setgallery] = useState("");

  useEffect(() => {
    if (typeid != undefined) {
      dataHandler();
    }
  }, []);

  const dataHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetItemTypeByRoomId + typeid);
      if (result.success == true) {
        setformData({
          type: result.data.type,
          name: result.data.name,
          content: result.data.content,
          item_inclusion: result.data.item_inclusion,
          image: result.data.room_image,
          dining_id: result.data.dining_id,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (uploadImage = null) => {
    var raw = JSON.stringify({
      type: formData.type,
      name: formData.name,
      content: formData.content,
      item_inclusion: formData.item_inclusion,
      dining_id: id,
      image: uploadImage,
    });
    console.log(raw);

    try {
      const finalEndpoint =
        typeid != undefined
          ? END_POINT.UpdateItemType + typeid
          : END_POINT.AddItemType;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        // setFormSubmitted(result.data);
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../item-type/" + id + "/" + dinningname);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        console.log(values);
        const uploadImage = values[0].image;

        handleSubmit(uploadImage);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add New Item Type</b>
        </h3>

        <div className="row mb-4"></div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Name:</span> <br />
            <input
              type="text"
              id="name"
              name="name"
              className="input-fields-style"
              value={formData.name}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Type:</span> <br />
            <Select
              id="type"
              name="type"
              value={ItemTypeOptions.filter(
                (item) => item.value == formData.type
              )}
              options={ItemTypeOptions}
              className="input-fields-style"
              // onChange={hotelTypeHandleChange}
              placeholder="Select an option"
              isSearchable
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) =>
                formData.type === option.value ? true : false
              }
              onChange={(e) => {
                console.log(e);
                setformData({
                  ...formData,
                  type: e.value,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Content:</span> <br />
            <input
              type="text"
              id="content"
              name="content"
              className="input-fields-style"
              value={formData.content}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Item inclusion:</span> <br />
            <input
              type="text"
              id="item_inclusion"
              name="item_inclusion"
              className="input-fields-style"
              value={formData.item_inclusion}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Image *</span> <br />
            <input
              type="file"
              id="fileBox1"
              name="fileBox1"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
          </div>
          {formData.image != "" && (
            <a
              href={BASE_URL_IMAGE + formData.image}
              target="_blank"
              rel="noopener noreferrer"
            >
              view image
            </a>
          )}{" "}
        </div>
      </div>

      <div className="d-flex justify-content-start">
        <Button
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            AllFileUpload();
          }}
        >
          Submit
        </Button>

        <Button className="btn btn-danger m-2" type="cancel">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddItemtypeScreen;
