import React, { useState, useEffect } from "react";
import { END_POINT, METHODS } from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Ticketupcoming from "../../assets/svg/profile/Ticketupcoming";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/reducers/userReducer";
import ActivityLoader from "../../components/ActivityLoader";

const ThankYou = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const [paymentStatus, setpaymentStatus] = useState("");

  useEffect(() => {
    handler();
  }, []);

  const handler = async () => {
    try {
      const charge_id = localStorage.getItem("charge_id");
      const bookingdata = localStorage.getItem("bookingdata");
      const bookingdataurl = localStorage.getItem("bookingdataurl");
      const raw = JSON.stringify({
        charge_id: charge_id,
      });

      const res = await fetchData(
        END_POINT.omisepaymentcheck,
        METHODS.POST,
        raw
      );
      console.log(res);
      if (res.success == true && res?.data?.status == "successful") {
        setpaymentStatus(res?.data?.status);
        const result = await fetchData(
          bookingdataurl,
          METHODS.POST,
          bookingdata
        );
        if (result.success == true) {
          localStorage.clear();
          setisLoading(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <ActivityLoader showModal={isLoading} />

      <Container>
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <div className="col-md-4">
            <div className="border border-3 border-success"></div>
            <div className="card  bg-white shadow p-5">
              <div className="mb-4 text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-success bi bi-check-circle"
                  width="75"
                  height="75"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <div className="text-center">
                <h1>Thank You !</h1>
                <p>
                  {paymentStatus == "successful"
                    ? `We've send the link to your inbox. Thanks for booking your payment recieved successfully`
                    : "Sorry order failed"}
                </p>
                <Link style={{ textDecoration: "none" }} to={"/"}>
                  <button className="btn btn-outline-success">Back Home</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* <Footer /> */}
    </div>
  );
};

export default ThankYou;
