import React, { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";

import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { Button, Col, Container, Row } from "react-bootstrap";
import { fetchData } from "../../Helper/ApiCall";
import {
  END_POINT,
  BASE_URL_IMAGE,
  METHODS,
  NumberSeprator,
  numberToEnglish,
  TabBarLabels,
} from "../../Helper/helper";
import ProductImageSwiper from "../../components/ProductImageSwiper";
export const BlogDetails = () => {
  const { id } = useParams();

  const [blogdata, setblogdata] = useState();

  useEffect(() => {
    handler();
  }, []);
  const handler = async () => {
    try {
      const result = await fetchData(END_POINT.GetDetailDataBlog + id);

      if (result.success == true) {
        setblogdata(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}
      <Container className="mx-auto" style={{ position: "relative" }}>
        <Row>
          <Col lg={12} md={12} sm={12} className="d-flex flex-column text-center">
            <h1 className="mt-4 mb-4">{blogdata?.title} </h1>
            <div
            // style={{
            //   // height: "60vh",
            //   borderRadius: "1rem",
            //   width: "80%",

            //   objectFit: "contain",
            // }}
            >
              <div className="details-text-box">
                <img
                  src={BASE_URL_IMAGE + blogdata?.image}
                  className="img-fluid"
                  alt="Blog"
                />
              </div>


            </div>
            <div className="details-text-box mt-4 mb-4 text-center">
              <p style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: blogdata?.article }} />
            </div>

          </Col>
          {/* <Col lg={12} md={12} sm={12}>
            <div
              style={{
                height: "60vh",
                borderRadius: "1rem",
                width: "80%",
                objectFit: "contain",
              }}
            >
              <img
                style={{ objectFit: "contain" }}
                src="/assets/img/blog-detail-img.png"
                className="img-fluid"
              />
            </div>
          </Col> */}
        </Row>
        {/* <Row className="mt-4 mb-4">
          <Col lg={12} md={12} sm={12}>
            <div className="details-text-box mt-4 mb-4">
              <h3 className="mt-4 mb-4">This is a title text</h3>
              <p style={{ paddingRight: "20%" }}>
                Sukosol hotel is a perfect blend of heritage and classic
                ambience. It is situated at the downtown Bangkok and has easy
                access to Bangkok’s popular landmark just minutes away; may it
                be shopping, food or sightseeing.
                <br />
                <br />
                There are diverse range of luxurious room-types suitable for
                vacationing, and business. All rooms are uniquely designed to
                accentuate Thailand’s authenticity, providing guests with
                comfort and convenience.
              </p>
            </div>
            <div
              style={{
                height: "60vh",
                borderRadius: "1rem",
                width: "80%",
                objectFit: "contain",
              }}
            >
              <img
                style={{ objectFit: "contain" }}
                src="/assets/img/blog-detail-img.png"
                className="img-fluid"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <div className="d-flex product-slide-cards row">
              <div className="col-5" style={{}}>
                <ProductImageSwiper />
              </div>

              <div className="product-card-content col-6">
                <div className="product-tags">
                  <span>Hotel</span>
                  <span>Pattaya</span>
                </div>
                <h5>Signature Pattaya</h5>
                <div className="price d-flex justify-content-between">
                  <div style={{ display: "flex" }}>
                    <img
                      style={{ objectFit: "contain" }}
                      src="/assets/img/rating-star.svg"
                      className="img-fluid"
                    />
                    <p>3.8</p>
                  </div>
                  <span>THB 2,140.00</span>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className="d-flex product-slide-cards row">
              <div className="col-5" style={{}}>
                <ProductImageSwiper />
              </div>

              <div className="product-card-content col-6">
                <div className="product-tags">
                  <span>Hotel</span>
                  <span>Pattaya</span>
                </div>
                <h5>Signature Pattaya</h5>
                <div className="price d-flex justify-content-between">
                  <div style={{ display: "flex" }}>
                    <img
                      style={{ objectFit: "contain" }}
                      src="/assets/img/rating-star.svg"
                      className="img-fluid"
                    />
                    <p>3.8</p>
                  </div>
                  <span>THB 2,140.00</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4 mb-4">
          <Col lg={12} md={12} sm={12}>
            <div className="details-text-box mt-4 mb-4">
              <h3 className="mt-4 mb-4">Videos</h3>
              <p style={{ paddingRight: "20%" }}>
                Sukosol hotel is a perfect blend of heritage and classic
                ambience. It is situated at the downtown Bangkok and has easy
                access to Bangkok’s popular landmark just minutes away; may it
                be shopping, food or sightseeing.
                <br />
                <br />
                There are diverse range of luxurious room-types suitable for
                vacationing, and business. All rooms are uniquely designed to
                accentuate Thailand’s authenticity, providing guests with
                comfort and convenience.
              </p>
            </div>
          </Col>
        </Row> */}
      </Container>

      <div className="unique-carousel-wrapper">
        <div className="row carouselcontainer ">
          <div
            className="col-lg-8 md-8 sm-12 mx-auto slider-container "
            id="unique-slider"
          >
            <Swiper
              // navigation={true}
              modules={[Navigation]}
              slidesPerView={2}
              spaceBetween={5}
              loop={true}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                "@0.75": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@1.00": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                "@1.50": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
              }}
              className="why-caro-swiper"
            >
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>M.I.C.E event title</h5>
                    <p style={{ color: "#333333" }}>
                      Event by Wedding Galore <br /> Venue: Sheraton Hua Hin
                      Resort & Spa
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>M.I.C.E event title</h5>
                    <p style={{ color: "#333333" }}>
                      Event by Wedding Galore <br /> Venue: Sheraton Hua Hin
                      Resort & Spa
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>M.I.C.E event title</h5>
                    <p style={{ color: "#333333" }}>
                      Event by Wedding Galore <br /> Venue: Sheraton Hua Hin
                      Resort & Spa
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>M.I.C.E event title</h5>
                    <p style={{ color: "#333333" }}>
                      Event by Wedding Galore <br /> Venue: Sheraton Hua Hin
                      Resort & Spa
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <div className="button-cont">
                <SwiperNavYellow />
              </div>
            </Swiper>
          </div>
          <div className="col-lg-4 md-4 sm-12 mx-auto" id="unique-cont">
            <h2 className="unique-text">Blogs related to Pattaya</h2>
            <p className="unique-text-p">
              Let us guide you make your holiday the most fun and memorable one
              yet. We'll take you to all the best spots, plan all the
              activities, and make sure you have everything you need to relax
              and enjoy yourself
            </p>
          </div>
        </div>
      </div>

      {/* Footer */}

      {/* <Footer /> */}
    </div>
  );
};
