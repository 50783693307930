import React from "react";
import { Link } from "react-router-dom";

const HotelRoomList = () => {
  return (
    <div>
      <div>
        <div class="card height-auto">
          <div class="card-body">
            <div class="heading-layout1">
              <div class="item-title">
                <h3 style={{ color: "#dc3545", textDecoration: "underline" }}>
                  Hotel Name: Lebua @ State Tower
                </h3>
                <h3>Edit Selling Detail</h3>
                <div class="form-group" style={{ padding: 10 }}>
                  <input type="date" name="date" />
                </div>
              </div>
              <div class="dropdown">
                <a href="" class="btn-fill-lg bg-blue-dark btn-hover-yellow">
                  <i class="fa fa-eye p-2"></i> View Hotel
                </a>

                <a href="" class="btn-fill-lg bg-blue-dark btn-hover-yellow">
                  <Link style={{}} to={"/admin/add-room-type"}>
                    <i class="fa fa-plus p-2"></i> Add Room Type
                  </Link>
                </a>
              </div>
            </div>
            <div>
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped table-bordered"
                  style={{ width: "100%", whiteSpace: "nowrap" }}
                >
                  <thead>
                    <tr>
                      <th>Priority</th>
                      <th>Room Name</th>
                      <th>Price Start From</th>
                      <th>Stock</th>
                      <th>Live Sell</th>
                      <th>Live Stock</th>
                      <th>Edit Room Detail</th>
                      <th>Price Stock</th>
                      <th>Edit Date</th>
                      <th>Summary</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <div style={{ color: "#f31018" }} class=""></div>
                          1&nbsp;
                          <input
                            type="number"
                            style={{ width: "50px", marginRight: 5 }}
                          />
                          <button type="submit" class="btn btn-info">
                            Update
                          </button>
                        </div>
                      </td>
                      <td>Tower Club Suite City View with Lounge</td>
                      <td>12000</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>
                        <a href="" class="btn btn-primary">
                          <Link
                            style={{ textDecoration: "none", color: "#fff" }}
                            to={"/admin/add-room-type"}
                          >
                            <i class="fa fa-edit"></i>
                          </Link>
                        </a>
                        <a href="" class="btn btn-danger">
                          <i class="fa fa-trash"></i>
                        </a>
                      </td>
                      <td>
                        <a href="" class="btn btn-success">
                          <i class="fa fa-plus"></i> Price stock
                        </a>
                      </td>
                      <td>2023-04-30 12:52:33</td>
                      <td>
                        <a href="" class="btn btn-info">
                          <i class="fa fa-eye"></i> View Summary
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div style={{ color: "#f31018" }} class=""></div>
                          1&nbsp;
                          <input
                            type="number"
                            style={{ width: "50px", marginRight: 5 }}
                          />
                          <button type="submit" class="btn btn-info">
                            Update
                          </button>
                        </div>
                      </td>
                      <td>Tower Club Suite River View with Lounge</td>
                      <td>13000</td>
                      <td>3</td>
                      <td>0</td>
                      <td>3</td>
                      <td>
                        <a href="" class="btn btn-primary">
                          <Link
                            style={{ textDecoration: "none", color: "#fff" }}
                            to={"/add-room-type"}
                          >
                            <i class="fa fa-edit"></i>
                          </Link>
                        </a>
                        <a
                          href=""
                          class="btn btn-danger"
                          onclick="ConfirmDialog()"
                        >
                          <i class="fa fa-trash"></i>
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://bharatchoice.world/admin/hotel/price/stock/a_h_price_stock/79/42"
                          class="btn btn-success"
                        >
                          <i class="fa fa-plus"></i> Price stock
                        </a>
                      </td>
                      <td>2023-04-30 12:55:42</td>
                      <td>
                        <a
                          href="https://bharatchoice.world/admin/hotel/price/stock/summary/42"
                          class="btn btn-info"
                        >
                          <i class="fa fa-eye"></i> View Summary
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelRoomList;
