import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { END_POINT } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import Swal from "sweetalert2";

import "../../dashscreen/DashCss.css";

import { useSelector, useDispatch } from "react-redux";

const HotelSummaryScreen = () => {
  const { id } = useParams();
  const [hotel, setHotelData] = useState([]);

  useEffect(() => {
    hotelHandler();
  }, []);

  const hotelHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetHotelStockByHotelid + id);
      console.log(END_POINT.GetHotelStockByHotelid + id);
      if (result.success == true) {
        setHotelData(result.data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <h1 style={{ textDecoration: "none" }}>Hotel Summary</h1>

      <div className="mt-4">
        <h4 style={{ textDecoration: "none" }}>{hotel?.name}</h4>
        <div
          id="example_wrapper"
          class="dataTables_wrapper no-footer table-overflow"
        >
          <div
            class="dataTables_length"
            id="example_length"
            style={{ padding: 5 }}
          >
            <label>
              Show
              <select
                name="example_length"
                aria-controls="example"
                class="mb-4"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>
          </div>
          <div id="example_filter" class="dataTables_filter mb-4">
            <label>
              Search:
              <input
                type="search"
                class=""
                placeholder=""
                aria-controls="example"
              />
            </label>
          </div>
          <table
            id="example"
            class="table table-striped table-bordered dataTable no-footer"
            style={{ width: "100%", whiteSpace: "nowrap" }}
            role="grid"
            aria-describedby="example_info"
          >
            <thead>
              <tr role="row">
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="example"
                  rowSpan="1"
                  colSpan="1"
                  aria-sort="ascending"
                  aria-label="S.N: activate to sort column descending"
                  style={{ width: "20px" }}
                >
                  S.N
                </th>
                <th
                  className="sorting"
                  tabIndex="0"
                  aria-controls="example"
                  rowSpan="1"
                  colSpan="1"
                  aria-label=" Name: activate to sort column ascending"
                  style={{ width: "146px" }}
                >
                  Room Name
                </th>
                <th
                  className="sorting"
                  tabIndex="0"
                  aria-controls="example"
                  rowSpan="1"
                  colSpan="1"
                  aria-label=" Name: activate to sort column ascending"
                  style={{ width: "146px" }}
                >
                  Room Type
                </th>
                <th
                  className="sorting"
                  tabIndex="0"
                  aria-controls="example"
                  rowSpan="1"
                  colSpan="1"
                  aria-label=" Code: activate to sort column ascending"
                  style={{ width: "45px" }}
                >
                  Booking
                </th>

                <th
                  className="sorting"
                  tabIndex="0"
                  aria-controls="example"
                  rowSpan="1"
                  colSpan="1"
                  aria-label=" Code: activate to sort column ascending"
                  style={{ width: "45px" }}
                >
                  Live Sell (room Night)
                </th>

                <th
                  className="sorting"
                  tabIndex="0"
                  aria-controls="example"
                  rowSpan="1"
                  colSpan="1"
                  aria-label=" Code: activate to sort column ascending"
                  style={{ width: "45px" }}
                >
                  Live Sell (extra Bed)
                </th>

                <th
                  className="sorting"
                  tabIndex="0"
                  aria-controls="example"
                  rowSpan="1"
                  colSpan="1"
                  aria-label=" Code: activate to sort column ascending"
                  style={{ width: "45px" }}
                >
                  No. of Pax
                </th>

                <th
                  className="sorting"
                  tabIndex="0"
                  aria-controls="example"
                  rowSpan="1"
                  colSpan="1"
                  aria-label=" Code: activate to sort column ascending"
                  style={{ width: "45px" }}
                >
                  Total Cost (Amount)
                </th>

                <th
                  className="sorting"
                  tabIndex="0"
                  aria-controls="example"
                  rowSpan="1"
                  colSpan="1"
                  aria-label=" Code: activate to sort column ascending"
                  style={{ width: "45px" }}
                >
                  total Sell (Amount)
                </th>

                {/* <th
                  className="sorting"
                  tabIndex="0"
                  aria-controls="example"
                  rowSpan="1"
                  colSpan="1"
                  aria-label=" Code: activate to sort column ascending"
                  style={{ width: "45px" }}
                >
                  Voucher Number
                </th> */}
              </tr>
            </thead>

            <tbody>
              {hotel?.roomtypedata?.length > 0 ? (
                hotel.roomtypedata.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <tr role="row" className="odd">
                      <td className="sorting_1">{index + 1}</td>
                      <td>{item.name}</td>
                      <td colSpan="1"></td>
                      <td colSpan="1"></td>
                      <td colSpan="1"></td>
                      <td colSpan="1"></td>
                      <td colSpan="1"></td>
                      <td colSpan="1"></td>
                      <td colSpan="1"></td>
                      {/* <td colSpan="1"></td> */}
                    </tr>
                    {item?.hotelstocks.map((itemroomtype, innerIndex) => {
                      const booking =
                        itemroomtype.roomstock - itemroomtype.livestock; // Calculate the booking value
                      return (
                        <tr key={innerIndex}>
                          <td></td>
                          <td></td>
                          <td>{itemroomtype.campaign_name}</td>
                          <td>{booking}</td>
                          <td>{itemroomtype.weekdaysell}</td>
                          <td>{itemroomtype.extra_bedsell}</td>
                          <td> 0 </td>
                          <td> 0 </td>
                          <td> 0 </td>
                          {/* <td> 0 </td> */}
                        </tr>
                      );
                    })}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="10">Hotel Summary Not Available</td>
                </tr>
              )}
            </tbody>
          </table>
          <div
            class="dataTables_info"
            id="example_info"
            role="status"
            aria-live="polite"
          >
            Showing 1 to 6 of 6 entries
          </div>
          <div
            class="dataTables_paginate paging_simple_numbers"
            id="example_paginate"
          >
            <a
              class="paginate_button previous disabled"
              aria-controls="example"
              data-dt-idx="0"
              tabindex="0"
              id="example_previous"
            >
              Previous
            </a>
            <span>
              <a
                class="paginate_button current"
                aria-controls="example"
                data-dt-idx="1"
                tabindex="0"
              >
                1
              </a>
            </span>
            <a
              class="paginate_button next disabled"
              aria-controls="example"
              data-dt-idx="2"
              tabindex="0"
              id="example_next"
            >
              Next
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelSummaryScreen;
