import React, { useState, useEffect } from "react";
import { END_POINT, METHODS, BASE_URL_IMAGE } from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Ticketupcoming from "../../assets/svg/profile/Ticketupcoming";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/reducers/userReducer";

const ProfileScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedtab, setselectedtab] = useState("Personal details");
  const { userData } = useSelector((store) => store.userReducer);

  const ButtonDiv = ({ img, title, press }) => {
    // const Icons = Icon;
    return (
      <div
        className="d-flex align-items-center menu-itm-profile"
        type="button"
        onClick={press}
      >
        <img
          src={img}
          className="img-fluid"
          style={{ width: 40, height: 40 }}
        />

        {/* <Icons color={"#000"} /> */}
        {/* {Icon} */}
        <p style={{ color: title == selectedtab ? "#E8BA00" : "#000" }}>
          {title}
        </p>
      </div>
    );
  };
  return (
    <div>
      {/* <Header /> */}
      <Container>
        <Row className="justify-content-evenly m-4">
          <Col lg={3} md={3} sm={12} className="p-2">
            <div className="d-flex  align-items-center user-name-img">
              <div
                style={{
                  height: "5.5rem",
                  width: "5.5rem",
                  borderRadius: "5.5rem",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  overflow: "hidden",
                }}
              >
                <img
                  src={
                    userData?.user_image != undefined
                      ? BASE_URL_IMAGE + userData?.user_image
                      : "/assets/img/UserCircle.svg"
                  }
                  className="img-fluid"
                  style={{}}
                />
              </div>

              <p>{userData?.fullName}</p>
            </div>
            <div className="profile-menu">
              <h5>My Account</h5>

              <ButtonDiv
                title={"Personal details"}
                img={
                  selectedtab == "Personal details"
                    ? "/assets/img/UserCircle.svg"
                    : "/assets/img/UserCirclesmall.svg"
                }
                press={() => {
                  setselectedtab("Personal details");
                  navigate("");
                }}
              />

              <ButtonDiv
                title={"Change password"}
                img={
                  selectedtab == "Change password"
                    ? "/assets/img/PasswordYellow.svg"
                    : "/assets/img/Password.svg"
                  // "/assets/img/password.svg"
                }
                Icon={<Ticketupcoming color={"#000"} />}
                press={() => {
                  setselectedtab("Change password");
                  navigate("password-details");
                }}
              />
              <h5>My Trips</h5>
              <ButtonDiv
                title={"Upcoming trips"}
                img={
                  selectedtab == "Upcoming trips"
                    ? "/assets/img/Ticket-upcoming.svg"
                    : "/assets/img/Ticket-previous.svg"
                  // "/assets/img/Ticket-upcoming.svg"
                }
                Icon={<Ticketupcoming color={"#000"} />}
                press={() => {
                  setselectedtab("Upcoming trips");
                  navigate("upcoming-trips");
                }}
              />
              <ButtonDiv
                title={"Previous trips"}
                img={
                  selectedtab == "Previous trips"
                    ? "/assets/img/Ticket-upcoming.svg"
                    : "/assets/img/Ticket-previous.svg"
                  // "/assets/img/Ticket-previous.svg"
                }
                Icon={<Ticketupcoming color={"#000"} />}
                press={() => {
                  setselectedtab("Previous trips");
                  navigate("previous-trips");
                }}
              />

              <ButtonDiv
                title={"Logout"}
                img={"/assets/img/Ticket-previous.svg"}
                Icon={<Ticketupcoming color={"#000"} />}
                press={() => {
                  dispatch(logout());
                  navigate("/");
                }}
              />

              {/* <div
                className="d-flex align-items-center menu-itm-profile"
                type="button"
                onClick={() => {
                  setselectedtab("upcoming");
                }}
              >
                <img
                  src="/assets/img/Ticket-upcoming.svg"
                  className="img-fluid"
                />
                <p style={{ color: "#E8BA00" }}>Upcoming trip</p>
              </div>
              <div
                className="d-flex  align-items-center "
                type="button"
                onClick={() => {
                  setselectedtab("previous");
                }}
              >
                <img
                  src="/assets/img/Ticket-previous.svg"
                  className="img-fluid"
                />
                <p>Previous trips</p>
              </div> */}
            </div>
          </Col>
          <Col lg={9} md={9} sm={12} className="p-2">
            <Outlet></Outlet>
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </div>
  );
};

export default ProfileScreen;
