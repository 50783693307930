import React, { useRef, useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import {
  BASE_URL_IMAGE,
  END_POINT,
  METHODS,
  TabBarLabels,
} from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import Select from "react-select";

import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function UpcomingBookingScreen() {
  const dispatch = useDispatch();
  const { _id } = useSelector((store) => store.userReducer.userData);
  const { country, currency } = useSelector((store) => store.currencyReducer);

  const [hotelbooking, sethotelbookingData] = useState([]);

  useEffect(() => {
    Handler();
  }, []);

  const Handler = async () => {
    try {
      const result = await fetchData(END_POINT.GetUpcomingTrips + _id);
      console.log(result.data.length);
      if (result.success == true) {
        sethotelbookingData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <h3
        style={{
          color: "#0A3874",
          marginTop: "10px",
          marginBottom: "30px",
        }}
      >
        Upcoming trips
      </h3>
      {hotelbooking.length > 0 ? (
        hotelbooking.map((item, index) => {
          return (
            <div className="d-flex justify-content-between trip-preview-box mb-3">
              <div className="d-flex justify-content-between">
                <img
                  src={BASE_URL_IMAGE + item.hotel_details.images}
                  width={120}
                  height={60}
                  className="img-fluid "
                />
                <div className="p-2">
                  <h5>
                    {item.hotel_details.name}, {item.hotel_details.city}
                  </h5>
                  <p>
                    {new Date(item.checkin).toDateString()}
                    {item.type_booking == TabBarLabels.Hotel && (
                      <>to {new Date(item.checkout).toDateString()}</>
                    )}
                  </p>
                  {/* <p>
                    Date of Booking : {new Date(item.createdAt).toDateString()}
                  </p> */}
                </div>
              </div>

              <div>
                {/* <p>{item.totalRooms} Rooms</p> */}
                {/* <p>{item.totalExtraBeds} Extrabed</p> */}
                <p>Total cost</p>
                <h5>
                  {Math.floor(parseInt(item.amount) * currency[country])}{" "}
                  {country}
                </h5>
              </div>
            </div>
          );
        })
      ) : (
        <p>No Booking Available</p>
      )}
    </div>
  );
}

export default UpcomingBookingScreen;
