import React, { useState, useEffect } from "react";
import {
  END_POINT,
  METHODS,
  BASE_URL_IMAGE,
  pagenameOptions,
} from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const AddFAQ = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = location?.state?.id;

  const [formData, setformData] = useState({
    question: "",
    answer: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (cityid != undefined) {
      cityHandler();
    }
  }, []);
  const cityHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetDetailByIdFAQ + cityid);
      console.log("bkbkk", result);
      if (result.success == true) {
        setformData({
          question: result.data.question,
          answer: result.data.answer,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async () => {
    var raw = JSON.stringify({
      question: formData.question,
      answer: formData.answer,
    });
    console.log("raw", raw);
    try {
      const finalEndpoint =
        cityid != undefined
          ? END_POINT.UpdateFAQ + cityid
          : END_POINT.UploadFAQ;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../faq-list");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div>
        <h3 className="mb-4">
          <b>Add FAQ</b>
        </h3>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">question:</span> <br />
            <input
              type="text"
              id="question"
              name="question"
              className="input-fields-style"
              value={formData.question}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Answer:</span> <br />
            <input
              type="text"
              id="answer"
              name="answer"
              className="input-fields-style"
              value={formData.answer}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../faq-list");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddFAQ;
