import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../../dashscreen/DashCss.css";

import { useSelector, useDispatch } from "react-redux";
import { BASE_URL_IMAGE, END_POINT } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import Swal from "sweetalert2";

const EventBannerScreen = () => {
  const [banner, setBannerData] = useState([]);

  useEffect(() => {
    EventBannerHandler();
  }, []);

  const EventBannerHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetEventBanner);
      if (result.success == true) {
        setBannerData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <h3>Event Banner</h3>

      <Link style={{ textDecoration: "none" }} to={"../add-event-banner"}>
        <button className="btn btn-primary mt-4">
          Add Event Banner&nbsp;<i class="fa-solid fa-plus"></i>
        </button>
      </Link>

      <div className="mt-4">
        <div
          id="example_wrapper"
          class="dataTables_wrapper no-footer table-overflow"
        >
          <div
            class="dataTables_length"
            id="example_length"
            style={{ padding: 5 }}
          >
            <label>
              Show
              <select
                name="example_length"
                aria-controls="example"
                class="mb-4"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>
          </div>
          <div id="example_filter" class="dataTables_filter mb-4">
            <label>
              Search:
              <input
                type="search"
                class=""
                placeholder=""
                aria-controls="example"
              />
            </label>
          </div>
          <table
            id="example"
            class="table table-striped table-bordered dataTable no-footer"
            style={{ width: "100%", whiteSpace: "nowrap" }}
            role="grid"
            aria-describedby="example_info"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="S.N: activate to sort column descending"
                  style={{ width: "20px" }}
                >
                  S.N
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label=" Name: activate to sort column ascending"
                  style={{ width: "146px" }}
                >
                  banner
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label=" Name: activate to sort column ascending"
                  style={{ width: "146px" }}
                >
                  View Type
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Hotel: activate to sort column ascending"
                  style={{ width: "56px" }}
                >
                  Edit
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Date: activate to sort column ascending"
                  style={{ width: "118px" }}
                >
                  Date
                </th>
              </tr>
            </thead>

            <tbody>
              {banner.length > 0 ? (
                banner.map((item, index) => (
                  <tr role="row" class="odd" key={index}>
                    <td class="sorting_1"> {index + 1} </td>
                    <td>
                      <img
                        src={BASE_URL_IMAGE + item.image}
                        alt={`Event Banner ${index + 1}`}
                        style={{ maxWidth: "100px" }}
                      />
                    </td>
                    <td> {item.view_type} </td>

                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../add-event-banner",
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{ id: item._id }}
                      >
                        <i class="fa fa-edit"></i>
                      </Link>
                    </td>

                    <td> {item.updatedAt.split("T")[0]} </td>
                  </tr>
                ))
              ) : (
                <p>No Event Banner Available</p>
              )}
            </tbody>
          </table>
          <div
            class="dataTables_info"
            id="example_info"
            role="status"
            aria-live="polite"
          >
            Showing 1 to 6 of 6 entries
          </div>
          <div
            class="dataTables_paginate paging_simple_numbers"
            id="example_paginate"
          >
            <a
              class="paginate_button previous disabled"
              aria-controls="example"
              data-dt-idx="0"
              tabindex="0"
              id="example_previous"
            >
              Previous
            </a>
            <span>
              <a
                class="paginate_button current"
                aria-controls="example"
                data-dt-idx="1"
                tabindex="0"
              >
                1
              </a>
            </span>
            <a
              class="paginate_button next disabled"
              aria-controls="example"
              data-dt-idx="2"
              tabindex="0"
              id="example_next"
            >
              Next
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventBannerScreen;
