import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { fetchData } from "../../Helper/ApiCall";
import {
  END_POINT,
  BASE_URL_IMAGE,
  METHODS,
  NumberSeprator,
  numberToEnglish,
  TabBarLabels,
} from "../../Helper/helper";
import EditorRead from "../../components/EditorRead";
import PartnersCarousel from "../../components/PartnersCarousel";
import Testimonial from "../../components/Testimonial";
import Subscribe from "../../components/Subscribe";

export const EventsScreen = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState({
    WeddingDetail: [],
    Event: [],
    EventBanner: [],
    WeddingReal: [],
    WeddingInspiration: [],
  });

  useEffect(() => {
    handlerData();
    // handler();
    // handlerInspiration();
  }, []);
  const handlerData = async () => {
    try {
      const result = await fetchData(END_POINT.GetEventPageData);
      if (result.success == true) {
        setdata({
          Event: result.data.Event,
          EventBanner: result.data.EventBanner,
          EventBlog: result.data.EventBlog,
        });
        // setweddingdetailData(result.data.WeddingDetail);
        // setweddingData(result.data.Wedding);
        // setbannerData(result.data.WeddingBanner);
        // setweddingvenue(result.data.WeddingReal);
        // setinspirationdata(result.data.WeddingInspiration);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}

      {/* Hero Banner */}
      <div class="hero-banner">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          modules={[Autoplay]}
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
          }}
          //   breakpoints={{
          //     "@0.00": {
          //       slidesPerView: 2,
          //       spaceBetween: 5,
          //     },
          //     "@0.75": {
          //       slidesPerView: 3,
          //       spaceBetween: 10,
          //     },
          //     "@1.00": {
          //       slidesPerView: 4,
          //       spaceBetween: 30,
          //     },
          //     "@1.50": {
          //       slidesPerView: 5,
          //       spaceBetween: 40,
          //     },
          //   }}

          className="herocarousel"
        >
          {data.EventBanner && data.EventBanner.length > 0 ? (
            data.EventBanner.map((itemevent) => (
              <SwiperSlide key={itemevent.id}>
                <div class="overlay"></div>
                <div
                  className="herocarouselslide"
                  style={{
                    backgroundImage: `url(${BASE_URL_IMAGE + itemevent.image})`,
                  }}
                >
                  <div className="row">
                    <div className="col-9 herobanner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                      <h1>
                        <span>{itemevent.title} </span>
                      </h1>
                      <h3>{itemevent.short_description}</h3>
                      <p>{itemevent.description}</p>
                      <div className="row w-50 d-flex justify-content-center">
                        <div className="col-lg-5 md-5 sm-12 d-flex justify-content-evenly">
                          <button className="btn service-offer-btn">
                            View Events
                          </button>
                        </div>
                        <div className="col-lg-5 md-5 sm-12 d-flex ">
                          <Link
                            style={{ textDecoration: "none" }}
                            to={"/enquiry"}
                          >
                            <button className="btn mice-get-in-btn">
                              Get in touch
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))
          ) : (
            <p>No event banners available.</p>
          )}
        </Swiper>
      </div>

      <div className="container welcome-section">
        {/* <img
          src="/assets/img/top-right-leaf.svg"
          className="img-fluid top-right-leaf"
        /> */}
        <h2>Upcoming Events!</h2>
        <p>Bharatchoice welcomes you all to our upcoming events</p>
        <div className="row">
          {data.Event !== undefined && data.Event.length > 0 ? (
            data.Event.map((item) => (
              <div className="col-lg-4 md-6 sm-12 welcomecards" key={item.id}>
                <Link to={item.link} className="event-link">
                  <div class="img-container-box">
                    <img
                      src={`${BASE_URL_IMAGE}${item.image}`}
                      className="img-fluid"
                      alt={item.title}
                    />
                  </div>

                  <h4>{item.title}</h4>
                  <p>{item.desc}</p>
                </Link>
              </div>
            ))
          ) : (
            // Handle the case when data.Event is empty or undefined here
            <p>No event data available.</p>
          )}

          {/* <div className="col-lg-4 md-6 sm-12 welcomecards">
            <img
              src="/assets/img/dreamwedding-card2.svg"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-4 md-6 sm-12 welcomecards">
            <img
              src="/assets/img/dreamwedding-card3.svg"
              className="img-fluid"
            />
          </div> */}
        </div>
      </div>

      {/* Special Events Section */}
      <div className="unique-carousel-wrapper">
        <div className="row carouselcontainer ">
          <div
            className="col-lg-8 md-8 sm-12 mx-auto slider-container "
            id="unique-slider"
          >
            <Swiper
              // navigation={true}
              modules={[Navigation]}
              slidesPerView={2}
              spaceBetween={5}
              loop={true}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                "@0.75": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@1.00": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                "@1.50": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
              }}
              className="why-caro-swiper"
            >
              {data.EventBlog !== undefined && data.EventBlog.length > 0 ? (
                data.EventBlog.map((item) => {
                  return (
                    <SwiperSlide>
                      <div className="rounded unique-slide">
                        <Link
                          to={item.link}
                          key={item.id}
                          className="event-link"
                        >
                          <div className="welcome-card-img-cont">
                            <img
                              src={`${BASE_URL_IMAGE}${item.image}`}
                              className="img-fluid mb-2"
                              alt={item.title}
                            />
                          </div>

                          <div className="lifelong-content">
                            <h5 style={{ color: "#333333" }}>{item.title}</h5>
                            <p style={{ color: "#333333" }}>
                              <EditorRead description={item.desc} />
                            </p>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })
              ) : (
                // Handle the case when data.EventBlog is empty or undefined here
                <p>No real wedding data available.</p>
              )}

              {/* <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#333333" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded unique-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#333333" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#333333" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" p-4 m-2 rounded lifelong-slide">
                  <img
                    src="/assets/img/lifelong-slide1.svg"
                    className="img-fluid mb-2"
                  />
                  <div className="lifelong-content">
                    <h5 style={{ color: "#333333" }}>
                      Nandini & Pratik, Event at Mandarin Oriental Bangkok
                    </h5>
                    <p style={{ color: "#333333" }}>
                      Choice of hotels promising the best deals in town
                    </p>
                  </div>
                </div>
              </SwiperSlide> */}
              <div className="button-cont">
                <SwiperNavYellow />
              </div>
            </Swiper>
          </div>
          <div className="col-lg-4 md-4 sm-12 mx-auto" id="unique-cont">
            <h2 className="unique-text">Exclusive events films</h2>
            <p className="unique-text-p">
              Experience our recent events through our exclusive films
            </p>
          </div>
        </div>
      </div>
      {/* Experiences Section */}
      {/* CTA events */}
      <div className="cta-events-wrapper">
        <div className="container cta-events-cont">
          <div className="row">
            <div className="col-lg-8 md-10 sm-12 mx-auto cta-events-text">
              <h3>
                Let our wedding planners help you bring the wedding of your
                dreams to life
              </h3>
            </div>
          </div>
          <div className="row btnrow cta-events">
            <div className="col-lg-4 md-5 sm-12 mx-auto d-flex justify-content-evenly">
              <Link
                style={{ textDecoration: "none" }}
                to={"/service-offer"}
                state={{ title: "Wedding" }}
              >
                <button
                  className="btn offering"
                  // type="button"
                  // onClick={() => {
                  //   navigate("/service-offer");
                  // }}
                >
                  Our service offerings
                </button>
              </Link>
            </div>
            <div className="col-lg-4 md-5 sm-12 mx-auto d-flex justify-content-evenly">
              <Link
                style={{ textDecoration: "none" }}
                to={"/enquiry"}
                state={{ title: "Wedding" }}
              >
                <button
                  className="btn appointment"
                  type="button"
                  onClick={() => {
                    navigate("/enquiry");
                  }}
                >
                  {/* <Link to="/enquiry"> */}
                  Online appointment request
                  {/* </Link> */}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Testimonial type={"Event"} />
      {/* PartnerShip Section */}
      <PartnersCarousel />
      {/* CTA Section */}
      <Subscribe
        title={`Get the scoop on deals and content with our newsletter, don't miss out!`}
      />
      {/* Footer */}

      {/* <Footer /> */}
    </div>
  );
};
export default EventsScreen;
