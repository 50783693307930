import React, { useState, useEffect } from "react";

const StickySearchBar = () => {
  const [scrolledDown, setScrolledDown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolledDown(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`search-sticky ${scrolledDown ? "visible" : ""}`}>
      <div className="form-element-each">
        <span>Where</span>
        <div className="d-flex align-items-center">
          <select class="form-control form-element-style" id="inputLocation">
            <option selected>Select</option>
            <option>Chiangmai</option>
            <option>Huahin</option>
            <option>Bangkok</option>
            <option>Kanchanaburi</option>
          </select>
          <i class="fa-solid fa-angle-down" style={{ color: "#858585" }}></i>
        </div>
      </div>

      <div class="form-group row justify-content-center mx-auto">
        <div className="form-element-each check-in col-6">
          <span>Check-in</span>
          <input
            type="date"
            class="form-control form-element-style"
            id="inputCheckIn"
          />
        </div>
        <div className="form-element-each check-out col-6">
          <span>Check-out</span>
          <input
            type="date"
            placeholder="Add Date"
            class="form-control form-element-style"
            id="inputCheckOut"
          />
        </div>
      </div>

      <div class="form-row ">
        <div class="form-group col-md-12 ">
          <div className="form-element-each">
            <span>Rooms</span>
            <div className="d-flex align-items-center">
              <select class="form-control form-element-style" id="inputRooms">
                <option selected>Select</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
              <i
                class="fa-solid fa-angle-down"
                style={{ color: "#858585" }}
              ></i>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn search-button">
        Search
      </button>
    </nav>
  );
};

export default StickySearchBar;
