import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { fetchData } from "../../../Helper/ApiCall";
import {
  END_POINT,
  METHODS,
  pricestockbenefitOptions,
  pricestockpaymentmethod,
  pricestockbookingtype,
  pricestockcampaignname,
  getDateNow,
  stockbenefitOptions,
} from "../../../Helper/helper";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Select from "react-select";

const VehiclesPriceStock = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  let location = useLocation();
  const roomfacilityid = location?.state?.id;
  const { name, dinningname, type } = location?.state;
  const [data, setdata] = useState([]);
  const [formData, setformData] = useState({
    vehicles_id: "",
    vehicles_item_type_id: "",
    payment_mode: "",
    booking_type: "",
    date_from: "",
    date_to: "",
    status: "",
    campaign_name: "",
    payment_option: "",
    adultcost: "",
    adultsell: "",
    childcost: "",
    childsell: "",
    bookingPeriod: "",
    cutoffTime: "",
    campaign_rooms: [],
    benifits: [],
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    var startDate = new Date(formData.date_from); //YYYY-MM-DD
    var endDate = new Date(formData.date_to); //YYYY-MM-DD

    const campaign_rooms = getDateArray(startDate, endDate);

    const raw = JSON.stringify({
      vehicles_item_type_id: roomfacilityid,
      vehicles_id: id,
      payment_mode: formData.payment_mode,
      booking_type: formData.booking_type,
      date_from: formData.date_from,
      date_to: formData.date_to,
      benifits: formData.benifits,

      campaign_name: dinningname,

      payment_option: formData.payment_option,

      adultcost: formData.adultcost,
      adultsell: formData.adultsell,
      childcost: formData.childcost,
      childsell: formData.childsell,
      bookingPeriod: formData.bookingPeriod,
      cutoffTime: formData.cutoffTime,

      campaign_rooms: campaign_rooms,
    });
    console.log(raw);

    try {
      const result = await fetchData(
        END_POINT.PostVehiclesStock,
        METHODS.POST,
        raw
      );

      console.log(result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            GetStockhandler();
            // navigate("../hotel-listing");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  var getDateArray = function (start, end) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
      arr.push({
        date: dt.toISOString().split("T")[0],
        status: formData.status,
        payment_option: formData.payment_option,
        adultcost: formData.adultcost,
        adultsell: formData.adultsell,
        childcost: formData.childcost,
        childsell: formData.childsell,
        bookingPeriod: formData.bookingPeriod,
        cutoffTime: formData.cutoffTime,
        VoucherNumber: "",
        livesell: 0,
      });

      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  useEffect(() => {
    GetStockhandler();
  }, []);

  const GetStockhandler = async () => {
    try {
      const raw = JSON.stringify({
        vehicles_item_type_id: roomfacilityid,
        vehicles_id: id,
      });
      const result = await fetchData(
        END_POINT.GetVehiclesStockByid,
        METHODS.POST,
        raw
      );

      console.log(result);
      if (result.success == true) {
        setdata(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const UpdateHotelStockStatusHandler = async (campaign_name, date, status) => {
    try {
      const raw = JSON.stringify({
        vehicles_item_type_id: roomfacilityid,
        vehicles_id: id,
        campaign_name: campaign_name,
        date: date,
        status: status == "Active" ? "Inactive" : "Active",
      });
      const result = await fetchData(
        END_POINT.UpdateVehiclesStockStatus,
        METHODS.POST,
        raw
      );

      console.log(result);
      if (result.success == true) {
        GetStockhandler(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCheckinChange = (e) => {
    const selectedCheckinDate = new Date(e.target.value);
    const nextDay = new Date(selectedCheckinDate);

    nextDay.setDate(selectedCheckinDate.getDate() + 1);
    console.log(nextDay);

    const formattedNextDay = nextDay.toISOString().split("T")[0];

    setformData({
      ...formData,
      date_from: e.target.value,
      date_to: formattedNextDay,
    });
  };
  const minCheckinDate = getDateNow();
  // console.log(new Date().toISOString());
  return (
    <div>
      <h2 style={{ color: "#000", fontWeight: "bold", marginBottom: 20 }}>
        Price Stock
      </h2>

      <div className="formwraptable mx-auto">
        <div className="row mb-4 d-flex justify-content-between">
          <div className="col-4">
            <h5 style={{ textDecoration: "underline" }}>
              Activity Name : {dinningname}
            </h5>
            <h7 style={{ textDecoration: "underline" }}>Type : {type}</h7>
            <br />
            <h7 style={{ textDecoration: "underline" }}>Item Name : {name}</h7>
          </div>
          <div className="col-6">
            <button
              className="btn btn-success m-1"
              type="button"
              onClick={() => {
                navigate("../vehicles-list");
              }}
            >
              <i class="fa-solid fa-eye p-1"></i> Vehicle
            </button>
            <button
              className="btn btn-success m-1"
              type="button"
              onClick={() => {
                navigate("../vehicles-item-type/" + id + "/" + dinningname);
              }}
            >
              <i class="fa-solid fa-eye p-1"></i>View Item
            </button>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-3 md-3 sm-12">
            <div>
              <span className="label-style">Payment Mode *</span> <br />
              <Select
                id="type"
                name="type"
                value={pricestockpaymentmethod.filter(
                  (item) => item.value == formData.payment_mode
                )}
                options={pricestockpaymentmethod}
                className="input-fields-style"
                // onChange={hotelTypeHandleChange}
                placeholder="Select an option"
                isSearchable
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) =>
                  formData.payment_mode === option.value ? true : false
                }
                onChange={(e) => {
                  console.log(e);
                  setformData({
                    ...formData,
                    payment_mode: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 md-3 sm-12">
            <div>
              <span className="label-style">Booking Type *</span> <br />
              <Select
                id="type"
                name="type"
                value={pricestockbookingtype.filter(
                  (item) => item.value == formData.booking_type
                )}
                options={pricestockbookingtype}
                className="input-fields-style"
                // onChange={hotelTypeHandleChange}
                placeholder="Select an option"
                isSearchable
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) =>
                  formData.booking_type === option.value ? true : false
                }
                onChange={(e) => {
                  console.log(e);
                  setformData({
                    ...formData,
                    booking_type: e.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="col-lg-2 md-2 sm-12">
            <div>
              <span className="label-style">Date From *</span> <br />
              <input
                type="date"
                className="input-fields-style"
                name="date_from"
                min={minCheckinDate}
                value={formData.date_from}
                onChange={(e) => handleCheckinChange(e)}
              />
            </div>
          </div>
          <div className="col-lg-2 md-2 sm-12">
            <div>
              <span className="label-style">Date To *</span> <br />
              <input
                type="date"
                className="input-fields-style"
                name="date_to"
                min={formData.date_from}
                value={formData.date_to}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <table>
              <thead>
                <td className="table-elements head">Active campaign</td>
                <td className="table-elements head">Campaign name</td>
                <td className="table-elements head">Benefit</td>

                <td className="table-elements head">Payment option</td>
                <td className="table-elements head">Adult</td>
                <td className="table-elements head">Child</td>
                <td className="table-elements head">Booking Period</td>
                <td className="table-elements head">Cutoff Time</td>
              </thead>
              <tbody>
                <td className="table-elements">
                  <select
                    className=""
                    name="status"
                    value={formData.status}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select an option</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </td>
                <td className="table-elements">{name}</td>
                <td className="table-elements">
                  <Select
                    options={stockbenefitOptions}
                    className="input-fields-style"
                    placeholder="Select an option"
                    isSearchable
                    isMulti={true}
                    onChange={(e) => {
                      console.log(e);
                      setformData({
                        ...formData,
                        benifits: e,
                      });
                    }}
                  />
                </td>
                <td className="table-elements">
                  <Select
                    options={stockbenefitOptions}
                    className="input-fields-style"
                    placeholder="Select an option"
                    isSearchable
                    isMulti={true}
                    onChange={(e) => {
                      console.log(e);
                      setformData({
                        ...formData,
                        placeoffer: e,
                      });
                    }}
                  />
                </td>
                <td className="table-elements">
                  <input
                    type="number"
                    className=""
                    name="payment_option"
                    value={formData.payment_option}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                <td className="table-elements">
                  <span>Cost</span>
                  <input
                    type="number"
                    className=""
                    name="adultcost"
                    value={formData.adultcost}
                    onChange={(e) => handleChange(e)}
                  />
                  <span>Sell</span>
                  <input
                    type="number"
                    className=""
                    name="adultsell"
                    value={formData.adultsell}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                <td className="table-elements">
                  <span>Cost</span>
                  <input
                    type="number"
                    className=""
                    name="childcost"
                    value={formData.childcost}
                    onChange={(e) => handleChange(e)}
                  />
                  <span>Sell</span>
                  <input
                    type="number"
                    className=""
                    name="childsell"
                    value={formData.childsell}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                <td className="table-elements">
                  <input
                    type="number"
                    className=""
                    name="bookingPeriod"
                    value={formData.bookingPeriod}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                <td className="table-elements">
                  <input
                    className=""
                    type="time"
                    name="cutoffTime"
                    value={formData.cutoffTime}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-4 md-4 sm-10 ">
            <button
              className="btn btn-warning m-2"
              type="button"
              onClick={handleSubmit}
            >
              Update
            </button>
          </div>
        </div>
        {/* <div className="row mb-4 d-flex justify-content-between">
          <div className="col-5">
            <h5 style={{}}>Campaign Review </h5>
          </div>
          <div className="col-5 d-flex">
            <div>
              <span className="label-style">Date To *</span>
              <input type="date" className="m-1" />
            </div>
            <div>
              <span className="label-style">Date From *</span>
              <input type="date" className="m-1" />
            </div>
          </div>
        </div> */}

        {data.length > 0 &&
          data.map((item, index) => {
            return (
              <div key={index}>
                <h5 style={{ textDecoration: "underline" }}>
                  Campaign Review : {item.campaign_name}
                </h5>
                <div className="row mb-4">
                  <div className="col-12">
                    <table>
                      <thead>
                        <td className="table-elements head">Date</td>
                        <td className="table-elements head">Service Day</td>
                        <td className="table-elements head">Payment Option</td>
                        <td className="table-elements head">Adult Cost</td>
                        <td className="table-elements head">Adult Sell</td>
                        <td className="table-elements head">Child Cost</td>
                        <td className="table-elements head">Child Sell</td>
                        <td className="table-elements head">Booking Period </td>

                        <td className="table-elements head">Live Sell </td>

                        <td className="table-elements head">Voucher Number </td>
                      </thead>
                      {item.campaign_rooms.length > 0 &&
                        item.campaign_rooms.map(
                          (itemCampaign, indexCampaign) => {
                            return (
                              <tbody>
                                <td className="table-elements">
                                  {itemCampaign.date}
                                </td>
                                <td className="table-elements">
                                  <button
                                    className={`btn ${
                                      itemCampaign.status == "Active"
                                        ? "btn-success"
                                        : "btn-danger"
                                    }`}
                                    type="button"
                                    onClick={() => {
                                      UpdateHotelStockStatusHandler(
                                        item.campaign_name,
                                        itemCampaign.date,
                                        itemCampaign.status
                                      );
                                    }}
                                  >
                                    {itemCampaign.status}
                                  </button>
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.payment_option}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.adultcost}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.adultsell}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.childcost}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.childsell}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.bookingPeriod}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.livesell}
                                </td>
                                <td className="table-elements">
                                  {itemCampaign.VoucherNumber}
                                </td>
                              </tbody>
                            );
                          }
                        )}
                    </table>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default VehiclesPriceStock;
