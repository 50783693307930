import React, { useState, useEffect } from "react";
import {
  END_POINT,
  activityItemTypeOptions,
  METHODS,
  BASE_URL_IMAGE,
} from "../../../Helper/helper";
import { ImageUpload, fetchData } from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const AddPackageItemTypeScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const typeid = location?.state?.id;
  const dinningname = location?.state?.dinningname;

  const [formData, setformData] = useState({
    name: "",
    package_type: "",

    content: "",
    item_inclusion: "",
    image: "",
    package_id: id,
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");
  const [gallery, setgallery] = useState("");

  useEffect(() => {
    if (typeid != undefined) {
      dataHandler();
    }
  }, []);

  const dataHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetPackageItemTypeByid + typeid);
      console.log(END_POINT.GetPackageItemTypeByid + typeid);

      if (result.success == true) {
        setformData({
          name: result.data.name,
          content: result.data.content,
          package_type: result.data.package_type,
          item_inclusion: result.data.item_inclusion,
          image: result.data.image,
          package_id: result.data.package_id,
        });
        seteditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.item_inclusion)
            )
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (uploadImage) => {
    var raw = JSON.stringify({
      name: formData.name,
      package_type: formData.package_type,
      content: formData.content,
      item_inclusion: formData.item_inclusion,
      package_id: id,
      image: uploadImage,
    });
    console.log(raw);

    try {
      const finalEndpoint =
        typeid != undefined
          ? END_POINT.UpdatePackageItemType + typeid
          : END_POINT.PostPackageItemType;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        // setFormSubmitted(result.data);
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../package-item-type/" + id + "/" + dinningname);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        console.log(values);
        const uploadImage = values[0].image;

        handleSubmit(uploadImage);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    seteditorState(editorState);
    setformData({
      ...formData,
      item_inclusion: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
    });
  };
  const packageType = [
    { value: "Hotel", label: "Hotel" },
    { value: "Other", label: "Other" },
  ];
  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add New Item Type</b>
        </h3>

        <div className="row mb-4"></div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Name:</span> <br />
            <input
              type="text"
              id="name"
              name="name"
              className="input-fields-style"
              value={formData.name}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Content:</span> <br />
            <input
              type="text"
              id="content"
              name="content"
              className="input-fields-style"
              value={formData.content}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Item inclusion:</span> <br />
            {/* <input
              type="text"
              id="item_inclusion"
              name="item_inclusion"
              className="input-fields-style"
              value={formData.item_inclusion}
              onChange={(e) => handleChange(e)}
            /> */}
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <span className="label-style">Package Type</span> <br />
          <Select
            id="type"
            name="type"
            value={packageType.filter(
              (item) => item.value == formData.package_type
            )}
            options={packageType}
            className="input-fields-style"
            // onChange={hotelTypeHandleChange}
            placeholder="Select an option"
            isSearchable
            getOptionLabel={(option) => option.value}
            getOptionValue={(option) => `${option}`}
            isOptionSelected={(option) =>
              formData.package_type === option.value ? true : false
            }
            onChange={(e) => {
              console.log(e);
              setformData({
                ...formData,
                package_type: e.value,
              });
            }}
          />
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Image *</span> <br />
            <input
              type="file"
              id="fileBox1"
              name="fileBox1"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
            {formData.image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.image}
                target="_blank"
                rel="noopener noreferrer"
              >
                view image
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start">
        <Button
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            AllFileUpload();
          }}
        >
          Submit
        </Button>

        <Button className="btn btn-danger m-2" type="cancel">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddPackageItemTypeScreen;
