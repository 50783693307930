import React, { useState, useEffect } from "react";
import { useSwiper } from "swiper/react";
import { Link } from "react-router-dom";
import { fetchData } from "../Helper/ApiCall";
import { BASE_URL, END_POINT, TabBarLabels } from "../Helper/helper";
import "./sticky.css";


export const Footer = () => {
  const swiper = useSwiper();
  const [soacialData, setsoacialData] = useState();
  const [defaultValuecheckin, setdefaultValuecheckin] = useState("");
  const [defaultValuecheckout, setdefaultValuecheckout] = useState("");
  const [selectedcity, setselectedcity] = useState("");
  const [room, setroom] = useState(1);
  const [city, setHotelCityData] = useState([]);

  const message = "Welcome to BharatChoice";
  const phoneNumber = "+66810908071";
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;


  const today = new Date();
  const date = today.setDate(today.getDate());
  // const defaultValuecheckin = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd
  const nextdate = today.setDate(today.getDate() + 1);
  // const defaultValuecheckout = new Date(nextdate).toISOString().split("T")[0]; // yyyy-mm-dd

  useEffect(() => {
    setdefaultValuecheckin(new Date(date).toISOString().split("T")[0]);
    setdefaultValuecheckout(new Date(nextdate).toISOString().split("T")[0]);
    cityHandler();
    socialHandler();
  }, []);
  const cityHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetHotelCity);

      if (result.success == true) {
        setselectedcity(result.data[0].city_name);
        setHotelCityData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const socialHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetDataSocialLink);

      if (result.success == true) {
        setsoacialData({ ...result.data[0] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (

    <>
      <div className="whatsapp-icon">
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">

          <img src={`${BASE_URL}/assets/img/whatsaap.png`} alt="WhatsApp" />
        </a>
      </div>

      <div className="footer-wrapper">
        <div className="container pt-2 pb-2">
          <div className="row footer-row">
            <div className="col-lg-2 md-2 sm-12">
              <img src="/assets/img/logofooter.png" className="img-fluid" />
              <p className="copright-txt">©2023 Bharatchoice rights reserves</p>
              <div className="row">
                <div className="col">
                  <a href={soacialData?.facebook_link}>
                    <img src="/assets/img/fb.png" className="img-fluid p-1" />
                  </a>
                  <a href={soacialData?.instgram_link}>
                    <img src="/assets/img/insta.png" className="img-fluid p-1" />
                  </a>
                  <a href={soacialData?.youtube_link}>
                    <img
                      src="/assets/img/youtube.png"
                      className="img-fluid p-1"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-2 md-2 sm-12 liststyles">
              <ul>
                <li className="list-head">Top Cities to Visit</li>
                {
                  // [
                  //   "Chiangmai",
                  //   "Huahin",
                  //   "Bangkok",
                  //   "Kanchanaburi",
                  //   "Samui Island",
                  //   "Khao yai",
                  //   "Phuket",
                  //   "Pattaya",
                  // ]
                  city.length > 0 &&
                  city.map((item, index) => {
                    return (
                      <li>
                        <Link
                          style={{ textDecoration: "none", color: "#fff" }}
                          to={
                            `/booking/` +
                            item.city_name +
                            "/" +
                            defaultValuecheckin +
                            "/" +
                            defaultValuecheckout +
                            "/" +
                            room
                          }
                          state={{
                            tabBar: TabBarLabels.Hotel,
                          }}
                        >
                          {item.city_name}
                        </Link>
                      </li>
                    );
                  })
                }
                {/* <li>Chiangmai</li>
              <li>Huahin</li>
              <li>Bangkok</li>
              <li>Kanchanaburi</li>
              <li>Samui Island</li>
              <li>Khao yai</li>
              <li>Phuket</li>
              <li>Pattaya</li> */}
              </ul>
            </div>
            <div className="col-lg-2 md-2 sm-12 liststyles">
              <ul>
                <li className="list-head">Our services</li>
                {["Hotels", "Dining", "Activities", "Packages", "Vehicles"].map(
                  (item) => {
                    return (
                      <li>

                        <Link
                          style={{ textDecoration: "none", color: "#fff" }}
                          to={
                            `/booking/` +
                            selectedcity.trim() +
                            "/" +
                            defaultValuecheckin +
                            "/" +
                            defaultValuecheckout +
                            "/" +
                            room
                          }
                          state={{
                            tabBar:
                              item == "Hotels"
                                ? TabBarLabels.Hotel
                                : item == "Dining"
                                  ? TabBarLabels.Dinning
                                  : item == "Activities"
                                    ? TabBarLabels.Activities
                                    : item == "Packages"
                                      ? TabBarLabels.Packages
                                      : TabBarLabels.Vehicles,
                          }}
                        >
                          {item}
                        </Link>
                      </li>
                    );
                  }
                )}

                {/* <li>
                <Link
                  style={{ textDecoration: "none", color: "#fff" }}
                  // to={
                  //   `/booking/` +
                  //   selectedcity.trim() +
                  //   "/" +
                  //   defaultValuecheckin +
                  //   "/" +
                  //   defaultValuecheckout +
                  //   "/" +
                  //   room
                  // }
                >
                  Hotels
                </Link>
              </li>
              <li>Dining</li>
              <li>Activities</li>
              <li>Packages</li>
              <li>Vehicles</li> */}
                <li>

                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to={"/mice-events"}
                  >
                    MICE
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to={"/wedding"}
                  >
                    Weddings
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to={"/events"}
                  >
                    Events
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 md-2 sm-12 liststyles">
              <ul>
                <li className="list-head">Bharatchoice</li>

                <li>
                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to={"/partner-with-us"}
                  >
                    Partner with us
                  </Link>
                </li>

                <li>
                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to={"/contact-us"}
                  >
                    Help and Support
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to={"/blogs"}
                  >
                    Blogs
                  </Link>
                </li>

                <li>
                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to={"/privacy-plocy"}
                  >
                    Privacy Policy
                  </Link>
                </li>

                <li>
                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to={"/terms-and-condition"}
                  >
                    Terms & Condition
                  </Link>
                </li>

                <li>
                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to={"/about-us"}
                  >
                    About Us
                  </Link>
                </li>

                <li>
                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to={"/cookie-policy"}
                  >
                    Cookie Policy
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to={"/refund-policy"}
                  >
                    Refund Policy
                  </Link>
                  <Link to={"/activity-ticket"}
                    style={{ textDecoration: "none", color: "#fff" }}

                  >
                    Activity Ticket
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 md-3 sm-12 payment">
              <span className="list-head">Payment methods</span>
              <div>
                <img
                  src="/assets/img/master.png"
                  className="img-fluid pay-icon"
                />
                <img src="/assets/img/visa.png" className="img-fluid pay-icon" />
              </div>

              <img src="/assets/img/PayPal.png" className="img-fluid pay-icon" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
