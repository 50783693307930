import React, { useState, useEffect } from "react";
import { END_POINT, METHODS, BASE_URL_IMAGE } from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const AddMice = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = location?.state?.id;

  const [formData, setformData] = useState({
    title: "",
    description: "",
    service_offer_link: "",
    send_inquery_link: "",
    mice_concept: "",
    mice_concept_description: "",
    mice_venue: "",
    mice_venue_description: "",
    main_banner: "",
    ds_logo: "",
    ds_description: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");
  const [banner, setbanner] = useState("");
  const [catData, setcatData] = useState([]);

  useEffect(() => {
    // categoryHandler();
    if (cityid != undefined) {
      handler();
    }
  }, []);

  const handler = async () => {
    try {
      const result = await fetchData(END_POINT.GetMiceByid + cityid);

      if (result.success == true) {
        setformData({
          title: result.data.title,
          description: result.data.description,
          service_offer_link: result.data.service_offer_link,
          send_inquery_link: result.data.send_inquery_link,
          mice_concept: result.data.mice_concept,
          mice_concept_description: result.data.mice_concept_description,
          mice_venue: result.data.mice_venue,
          mice_venue_description: result.data.mice_venue_description,
          main_banner: result.data.main_banner,
          ds_logo: result.data.ds_logo,
          ds_description: result.data.ds_description,
        });

        setds_description(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.ds_description)
            )
          )
        );

        setmice_venue_description(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.mice_venue_description)
            )
          )
        );

        setmice_concept_description(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.mice_concept_description)
            )
          )
        );

        setdescription(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.description)
            )
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (uploadedImagebanner, uploadedImage) => {
    var raw = JSON.stringify({
      title: formData.title,
      description: formData.description,
      service_offer_link: formData.service_offer_link,
      send_inquery_link: formData.send_inquery_link,
      mice_concept: formData.mice_concept,
      mice_concept_description: formData.mice_concept_description,
      mice_venue: formData.mice_venue,
      mice_venue_description: formData.mice_venue_description,
      main_banner: uploadedImagebanner,
      ds_logo: uploadedImage,
      ds_description: formData.ds_description,
    });
    console.log("raw", raw);
    try {
      const finalEndpoint =
        cityid != undefined ? END_POINT.UpdateMice + cityid : END_POINT.AddMice;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../mice-list");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(banner), await ImageUpload(image)])
      .then((values) => {
        const uploadedImagebanner = values[0].image;
        const uploadedImage = values[1].image;
        handleSubmit(uploadedImagebanner, uploadedImage);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const AllFileUpdate = async () => {
    Promise.all([
      banner != "" && (await ImageUpload(banner)),
      image != "" && (await ImageUpload(image)),
    ])
      .then((values) => {
        const uploadedImagedininglist =
          values[0].image == false ? formData.banner_image : values[0].image;
        const uploadedImage =
          values[1].image == false ? formData.image : values[1].image;

        handleSubmit(uploadedImagedininglist, uploadedImage);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [ds_description, setds_description] = useState(
    EditorState.createEmpty()
  );
  const [mice_venue_description, setmice_venue_description] = useState(
    EditorState.createEmpty()
  );
  const [mice_concept_description, setmice_concept_description] = useState(
    EditorState.createEmpty()
  );
  const [description, setdescription] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState, key) => {
    if (key == "ds_description") {
      setds_description(editorState);
    }
    if (key == "mice_venue_description") {
      setmice_venue_description(editorState);
    }
    if (key == "mice_concept_description") {
      setmice_concept_description(editorState);
    }
    if (key == "description") {
      setdescription(editorState);
    }
    setformData({
      ...formData,
      [key]: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add Mice</b>
        </h3>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Title:</span> <br />
            <input
              type="text"
              id="title"
              name="title"
              className="input-fields-style"
              value={formData.title}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Description:</span> <br />
          <Editor
            editorState={description}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(e) => {
              onEditorStateChange(e, "description");
            }}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">M.I.C.E Concept:</span> <br />
            <input
              type="text"
              id="mice_concept"
              name="mice_concept"
              className="input-fields-style"
              value={formData.mice_concept}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Description:</span> <br />
          <Editor
            editorState={mice_concept_description}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(e) => {
              onEditorStateChange(e, "mice_concept_description");
            }}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">M.I.C.E Venue:</span> <br />
            <input
              type="text"
              id="mice_venue"
              name="mice_venue"
              className="input-fields-style"
              value={formData.mice_venue}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Description:</span> <br />
          <Editor
            editorState={mice_venue_description}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(e) => {
              onEditorStateChange(e, "mice_venue_description");
            }}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">DS Description:</span> <br />
            <Editor
              editorState={ds_description}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(e) => {
                onEditorStateChange(e, "ds_description");
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Service Offer Link:</span> <br />
            <input
              type="text"
              id="service_offer_link"
              name="service_offer_link"
              className="input-fields-style"
              value={formData.service_offer_link}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Send Inquiry Link:</span> <br />
          <input
            type="text"
            id="send_inquery_link"
            name="send_inquery_link"
            className="input-fields-style"
            value={formData.send_inquery_link}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Main Banner *</span> <br />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                setbanner(e.target.files[0]);
              }}
            />
            {formData.banner_image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.banner_image}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-5"
              >
                view image
              </a>
            )}
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">DS Logo *</span> <br />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
            {formData.image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.image}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-5"
              >
                view image
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            if (cityid != undefined) {
              if (image != "") {
                AllFileUpdate();
              } else {
                handleSubmit(formData.banner_image, formData.image);
              }
            } else {
              AllFileUpload();
            }
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../wedding-list");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddMice;
