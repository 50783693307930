import React from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { Modal } from "react-bootstrap";

function ActivityLoader(props) {
  return (
    <Modal
      show={props.showModal}
      className="spinner-modal"
      style={{
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Modal.Body>
        <Spinner
          size={100}
          color={"#FFCC00"}
          animating={true}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            marginTop: "40vh",
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

export default ActivityLoader;
