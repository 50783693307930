import React, { useState, useEffect } from "react";
import {
    END_POINT,
    activityItemTypeOptions,
    METHODS,
    BASE_URL_IMAGE,
} from "../../../Helper/helper";
import { ImageUpload, fetchData } from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromRaw,
    convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const AddActivityFaq = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    let location = useLocation();
    const typeid = location?.state?.id;
    const dinningname = location?.state?.dinningname;

    const [formData, setformData] = useState({
        question: "",
        answer: "",

        activity_id: id,
    });
    const handleChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const [image, setimage] = useState("");
    const [gallery, setgallery] = useState("");

    useEffect(() => {
        if (typeid != undefined) {
            dataHandler();
        }
    }, []);

    const dataHandler = async () => {
        try {
            const result = await fetchData(
                END_POINT.GetItemActivityFaqByid + typeid
            );
            console.log(END_POINT.GetItemActivityFaqByid + typeid);

            if (result.success == true) {
                setformData({
                    question: result.data.question,

                    activity_id: result.data.activity_id,
                });
                seteditorState(
                    EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            convertFromHTML(result.data.answer)
                        )
                    )
                );
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmit = async (uploadImage) => {
        var raw = JSON.stringify({
            question: formData.question,
            answer: formData.answer,
            activity_id: id,
        });
        console.log(raw);

        try {
            const finalEndpoint =
                typeid != undefined
                    ? END_POINT.UpdateActivityFaq + typeid
                    : END_POINT.PostActivityFaq;
            const result = await fetchData(finalEndpoint, METHODS.POST, raw);
            console.log("API response:", result);
            if (result.success == true) {
                // setFormSubmitted(result.data);
                Swal.fire({
                    title: "Confirmed",
                    text: result.message,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("../activity-faq/" + id + "/" + dinningname);
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const AllFileUpload = async () => {
        Promise.all([await ImageUpload(image)])
            .then((values) => {
                console.log(values);
                const uploadImage = values[0].image;

                handleSubmit(uploadImage);
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const [editorState, seteditorState] = useState(EditorState.createEmpty());
    const onEditorStateChange = (editorState) => {
        seteditorState(editorState);
        setformData({
            ...formData,
            answer: draftToHtml(
                convertToRaw(editorState.getCurrentContent())
            ),
        });
    };
    return (
        <div
        //  onSubmit={AllFileUpload}
        >
            <div>
                <h3 className="mb-4">
                    <b>Add New FAQ</b>
                </h3>

                <div className="row mb-4"></div>
            </div>
            <div className="row mb-4">
                <div className="col-lg-6 md-6 sm-12">
                    <div>
                        <span className="label-style">Question:</span> <br />
                        <input
                            type="text"
                            id="question"
                            name="question"
                            className="input-fields-style"
                            value={formData.question}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                </div>
            </div>


            <div className="row mb-4">
                <div className="col-lg-6 md-6 sm-12">
                    <div>
                        <span className="label-style">Answer:</span> <br />
                        {/* <input
              type="text"
              id="item_inclusion"
              name="item_inclusion"
              className="input-fields-style"
              value={formData.item_inclusion}
              onChange={(e) => handleChange(e)}
            /> */}
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                        />
                    </div>
                </div>
            </div>



            <div className="d-flex justify-content-start">
                <Button
                    type="button"
                    className="btn btn-primary m-2"
                    // name="submit"
                    onClick={() => {
                        AllFileUpload();
                    }}
                >
                    Submit
                </Button>

                <Button className="btn btn-danger m-2" type="cancel">
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default AddActivityFaq;
