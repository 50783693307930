import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { END_POINT } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import "../../dashscreen/DashCss.css";

const ActivityItemTypeScreen = () => {
  let location = useLocation();

  const [itemType, setItemTypeData] = useState([]);
  const { id, dinningname } = useParams();
  // const dinningname = location?.state?.dinningname;

  useEffect(() => {
    itemTypeHandler();
  }, []);

  const itemTypeHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetActivityItemType + id);
      console.log(result);
      if (result.success == true) {
        setItemTypeData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const deleteHandler = async (id) => {
    try {
      const result = await fetchData(END_POINT.DeleteActivityItemType + id);
      if (result.success == true) {
        itemTypeHandler();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Link
        style={{ textDecoration: "none" }}
        to={"../add-activity-item-type/" + id}
      >
        <button
          className="btn btn-primary mt-4"
        // onClick={() => {
        //   Navigate("add-hotel-listing");
        // }}
        >
          Add Item Type&nbsp;<i class="fa-solid fa-plus"></i>
        </button>
      </Link>

      <div className="mt-4">
        <div
          id="example_wrapper"
          class="dataTables_wrapper no-footer table-overflow"
        >
          <div
            class="dataTables_length"
            id="example_length"
            style={{ padding: 5 }}
          >
            <label>
              Show
              <select
                name="example_length"
                aria-controls="example"
                class="mb-4"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>
          </div>
          <div id="example_filter" class="dataTables_filter mb-4">
            <label>
              Search:
              <input
                type="search"
                class=""
                placeholder=""
                aria-controls="example"
              />
            </label>
          </div>
          <table
            id="example"
            class="table table-striped table-bordered dataTable no-footer"
            style={{ width: "100%", whiteSpace: "nowrap" }}
            role="grid"
            aria-describedby="example_info"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="S.N: activate to sort column descending"
                  style={{ width: "20px" }}
                >
                  S.N
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label=" Name: activate to sort column ascending"
                  style={{ width: "146px" }}
                >
                  Item Name
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Type / Catergory: activate to sort column ascending"
                  style={{ width: "96px" }}
                >
                  Type
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="City: activate to sort column ascending"
                  style={{ width: "50px" }}
                >
                  Payment Option
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Promo: activate to sort column ascending"
                  style={{ width: "61px" }}
                >
                  Price Start From
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Promo: activate to sort column ascending"
                  style={{ width: "61px" }}
                >
                  Booking Period
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Promo: activate to sort column ascending"
                  style={{ width: "61px" }}
                >
                  Service Day
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Hotel: activate to sort column ascending"
                  style={{ width: "56px" }}
                >
                  Edit
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Room: activate to sort column ascending"
                  style={{ width: "73px" }}
                >
                  Price Stock
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Edit Date: activate to sort column ascending"
                  style={{ width: "118px" }}
                >
                  Edit Date
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1"
                  colspan="1"
                  aria-label="Summary: activate to sort column ascending"
                  style={{ width: "92px" }}
                >
                  Summary
                </th>
              </tr>
            </thead>

            <tbody>
              {itemType.length > 0 ? (
                itemType.map((item, index) => (
                  <tr role="row" class="odd" key={index}>
                    <td class="sorting_1"> {index + 1} </td>
                    <td> {item.name} </td>
                    <td>{item.type}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>

                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../add-activity-item-type/" + id,
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{ id: item._id, dinningname: dinningname }}
                      >
                        <i class="fa fa-edit"></i>
                      </Link>
                      <a
                        // href="#"
                        type="button"
                        class="btn btn-danger m-1"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: `You won't to delete ${item.name}!`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteHandler(item._id);

                              Swal.fire(
                                "Deleted!",
                                `${item.name} has been deleted.`,
                                "success"
                              );
                            }
                          });
                          //  handleDelete(item._id)
                        }}
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>
                    <td>
                      <Link
                        class="btn btn-primary m-1"
                        to={{
                          pathname: "../activity-price-stock/" + id,
                          // search: "?sort=name",
                          // hash: "#the-hash",
                        }}
                        state={{
                          id: item._id,
                          name: item.name,
                          dinningname: dinningname,
                          type: item.type,
                        }}
                      >
                        <i class="fa fa-plus"></i>Price Stock
                      </Link>
                    </td>
                    <td>{item.updatedAt.split("T")[0]}</td>
                    <td>
                      <a href="#" class="btn btn-info">
                        <i class="fa fa-eye"></i>View Summary
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <p>Hotel List Not Available</p>
              )}
            </tbody>
          </table>
          <div
            class="dataTables_info"
            id="example_info"
            role="status"
            aria-live="polite"
          >
            Showing 1 to 6 of 6 entries
          </div>
          <div
            class="dataTables_paginate paging_simple_numbers"
            id="example_paginate"
          >
            <a
              class="paginate_button previous disabled"
              aria-controls="example"
              data-dt-idx="0"
              tabindex="0"
              id="example_previous"
            >
              Previous
            </a>
            <span>
              <a
                class="paginate_button current"
                aria-controls="example"
                data-dt-idx="1"
                tabindex="0"
              >
                1
              </a>
            </span>
            <a
              class="paginate_button next disabled"
              aria-controls="example"
              data-dt-idx="2"
              tabindex="0"
              id="example_next"
            >
              Next
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityItemTypeScreen;
