import React, { useState, useEffect } from "react";
import {
  END_POINT,
  vehiclesItemTypeOptions,
  METHODS,
  BASE_URL_IMAGE,
} from "../../../Helper/helper";
import { ImageUpload, fetchData } from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const AddVehiclesItemTypeScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const typeid = location?.state?.id;
  const dinningname = location?.state?.dinningname;

  const [formData, setformData] = useState({
    type: "",
    name: "",
    content: "",
    item_inclusion: "",
    image: "",
    vehicles_id: id,
    max_guest_no: "",
    large_language: "",
    small_language: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");
  const [gallery, setgallery] = useState("");

  useEffect(() => {
    if (typeid != undefined) {
      dataHandler();
    }
  }, []);

  const dataHandler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetVehiclesItemTypeByid + typeid
      );

      if (result.success == true) {
        setformData({
          type: result.data.type,
          name: result.data.name,
          content: result.data.content,
          item_inclusion: result.data.item_inclusion,
          image: result.data.image,
          vehicles_id: result.data.vehicles_id,
          max_guest_no: result.data.max_guest_no,
          large_language: result.data.large_language,
          small_language: result.data.small_language,
        });
        seteditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.item_inclusion)
            )
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (uploadImage) => {
    var raw = JSON.stringify({
      type: formData.type,
      name: formData.name,
      content: formData.content,
      item_inclusion: formData.item_inclusion,
      vehicles_id: id,
      image: uploadImage,
      max_guest_no: formData.max_guest_no,
      large_language: formData.large_language,
      small_language: formData.small_language,
    });
    console.log(raw);

    try {
      const finalEndpoint =
        typeid != undefined
          ? END_POINT.UpdateVehiclesItemType + typeid
          : END_POINT.PostVehiclesItemType;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        // setFormSubmitted(result.data);
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../vehicles-item-type/" + id + "/" + dinningname);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        console.log(values);
        const uploadImage = values[0].image;

        handleSubmit(uploadImage);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    seteditorState(editorState);
    setformData({
      ...formData,
      item_inclusion: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
    });
  };
  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add New Item Type</b>
        </h3>

        <div className="row mb-4"></div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Name:</span> <br />
            <input
              type="text"
              id="name"
              name="name"
              className="input-fields-style"
              value={formData.name}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Content:</span> <br />
            <input
              type="text"
              id="content"
              name="content"
              className="input-fields-style"
              value={formData.content}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Max No. of Guest:</span> <br />
            <input
              type="text"
              id="name"
              name="max_guest_no"
              className="input-fields-style"
              value={formData.max_guest_no}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Large Lauguage:</span> <br />
            <input
              type="text"
              id="name"
              name="large_language"
              className="input-fields-style"
              value={formData.large_language}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Small Lauguage:</span> <br />
            <input
              type="text"
              id="name"
              name="small_language"
              className="input-fields-style"
              value={formData.small_language}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Item inclusion:</span> <br />
            {/* <input
              type="text"
              id="item_inclusion"
              name="item_inclusion"
              className="input-fields-style"
              value={formData.item_inclusion}
              onChange={(e) => handleChange(e)}
            /> */}
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Image *</span> <br />
            <input
              type="file"
              id="fileBox1"
              name="fileBox1"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
            {formData.image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.image}
                target="_blank"
                rel="noopener noreferrer"
              >
                view image
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start">
        <Button
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            AllFileUpload();
          }}
        >
          Submit
        </Button>

        <Button className="btn btn-danger m-2" type="cancel">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddVehiclesItemTypeScreen;
