import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { END_POINT, METHODS } from "../../../Helper/helper";
import { fetchData, ImageUpload } from "../../../Helper/ApiCall";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const AddRoomFacilityScreen = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const roomfacilityid = location?.state?.id;
  const [roomFacility, setRoomFacility] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [image, setimage] = useState();

  useEffect(() => {
    if (roomfacilityid != undefined) {
      dataHandler();
    }
  }, [formSubmitted]);
  const dataHandler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetHotelsRoomFacilityByid + roomfacilityid
      );
      if (result.success == true) {
        setRoomFacility(result.data.room_facility);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const submitHandler = async (icon) => {
    var raw = JSON.stringify({
      room_facility: roomFacility,
      icon: icon,
      status: "Active",
    });

    try {
      const finalEndpoint =
        roomfacilityid != undefined
          ? END_POINT.UpdateRoomFacility + roomfacilityid
          : END_POINT.AddRoomFacility;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        // setFormSubmitted(result.data);
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../room-facility");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        const uploadedImagebanner = values[0].image;
        submitHandler(uploadedImagebanner);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const AllFileUpdate = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        const uploadedImagedininglist =
          values[0].image == undefined ? image : values[0].image;

        submitHandler(uploadedImagedininglist);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div>
      <div>
        <h3 className="mb-4">
          <b>Add Room Facility</b>
        </h3>
      </div>

      <Form onSubmit={submitHandler}>
        <div className="row mb-4">
          <div className="col-lg-6 md-6 sm-12">
            <div>
              <span className="label-style">Room Facility:</span> <br />
              <input
                type="text"
                id="room_facility"
                name="roomFacility"
                className="input-fields-style"
                value={roomFacility}
                onChange={(e) => setRoomFacility(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 md-6 sm-12">
            <div>
              <span className="label-style">Icon *</span> <br />
              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                onChange={(e) => {
                  setimage(e.target.files[0]);
                }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <Button
            id="submit"
            name="submit"
            className="btn btn-primary m-2"
            type="button"
            onClick={() => {
              if (roomfacilityid != undefined) {
                if (image != "") {
                  AllFileUpdate();
                } else {
                  submitHandler(image);
                }
              } else {
                AllFileUpload();
              }
            }}
          >
            Submit
          </Button>

          <Button
            className="btn btn-danger m-2"
            type="cancel"
            onClick={() => {
              navigate("../room-facility");
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddRoomFacilityScreen;
