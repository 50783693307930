import React, { Component } from "react";
import Script from "react-load-script";

import "./Checkout.css";

// import { publicKey } from "../../../confidential/keys";
import { PublicKeYOmise } from "../../../../Helper/helper";

let OmiseCard;

export class CheckoutInternetBanking extends Component {
  handleScriptLoad = () => {
    OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      publicKey: PublicKeYOmise,
      frameLabel: "Bharatchoice",
      submitLabel: "PAY NOW",
      currency: this.props.currency,
    });
  };

  internetBankingConfigure = () => {
    OmiseCard.configure({
      defaultPaymentMethod: "credit_card",
      otherPaymentMethods: [
        "alipay",
        "alipay_cn",
        "bill_payment_tesco_lotus",
        "net_banking",
        "credit_card",
        "googlepay",
        "grabpay",
        "installment",
        "internet_banking",
        "internet_banking_bay",
        "internet_banking_bbl",
        "mobile_banking_bay",
        "mobile_banking_bbl",
        "mobile_banking_kbank",
        "mobile_banking_ktb",
        "mobile_banking_scb",
        "points_citi",
        "promptpay",
        "rabbit_linepay",
        "shopeepay",
        "truemoney",
      ],
    });
    OmiseCard.configureButton("#internet-banking");
    OmiseCard.attach();
  };

  omiseCardHandler = () => {
    OmiseCard.open({
      // frameDescription: "Invoice #3847",
      amount: this.props.totalAmount * 100,
      onCreateTokenSuccess: (token) => {
        console.log("success", token);

        // this.props.handleSubmit();
        this.props.createInternetBankingCharge(token);
      },
      onFormClosed: () => {
        console.log("onFormClosed");
      },
    });
  };

  handleClick = (e) => {
    e.preventDefault();
    this.internetBankingConfigure();
    this.omiseCardHandler();
  };

  render() {
    return (
      <div>
        <Script
          url="https://cdn.omise.co/omise.js"
          onLoad={this.handleScriptLoad}
        />
        <form>
          <button
            id="internet-banking"
            className="btn internet-banking book-now-paybutton"
            type="button"
            onClick={this.handleClick}
          >
            Pay
          </button>
        </form>
      </div>
    );
  }
}

export default CheckoutInternetBanking;
